import React from "react";
import Select from "react-select";

import "./SuggestedInput.css";

/* this is for reference purposes */
// export const customStyles = {
//   // this will deactivate or active the default styles based on requirements
//   control: (provided, state) => ({
//     // ...provided,
//   }),

//   menu: (provided, state) => ({
//     ...provided,
//   }),

//   valueContainer: (provided, state) => ({
//     ...provided,
//   }),

//   indicatorsContainer: (provided, state) => ({
//     ...provided,
//     cursor: "pointer",
//   }),

//   menuList: (provided, state) => ({
//     // ...provided,
//   }),

//   multiValue: (provided, state) => ({
//     // ...provided,
//   }),

//   multiValueLabel: (provided, state) => ({
//     ...provided,
//   }),

//   multiValueRemove: (provided, state) => ({
//     ...provided,
//     cursor: "pointer",
//   }),

//   option: (provided, state) => ({
//     ...provided,
//     cursor: "pointer",
//   }),
// };

export const customStyles = {
  // this will deactivate or active the default styles based on requirements
  control: () => ({
    // ...provided,
  }),

  menu: (provided) => ({
    ...provided,
  }),

  valueContainer: (provided) => ({
    ...provided,
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),

  menuList: () => ({
    // ...provided,
  }),

  multiValue: () => ({
    // ...provided,
  }),

  multiValueLabel: (provided) => ({
    ...provided,
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),

  option: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
};

export const customTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      //       primary: '#2684FF', // outline color of the input
      //       primary75: '#4C9AFF',
      //       primary50: 'var(--light-blue)', // on click color of item
      primary25: "var(--label-blue)", // active/hover item color

      danger: "var(--dark-blue)", // item cross icon color
      dangerLight: "var(--light-blue)", // item cross background color

      //       neutral0: 'var(--background-blue)', // color of select options background as wel as the input background
      //       neutral5: 'hsl(0, 0%, 95%)',
      //       neutral10: 'var(--light-blue)', // this is item background color
      //       neutral20: 'hsl(0, 0%, 80%)', // border color for the input
      //       neutral30: 'hsl(0, 0%, 70%)', // hover color for the input
      //       neutral40: 'var(--dark-blue)', // no options message color
      //       neutral50: 'hsl(0, 0%, 50%)', // placeholder color
      //       neutral60: 'hsl(0, 0%, 40%)', // all cross button
      //       neutral70: 'hsl(0, 0%, 30%)',
      //       neutral80: 'var(--dark-blue)', // item text color and icon color
      //       neutral90: 'hsl(0, 0%, 10%)',
    },
  };
};

export const SuggestedInput = ({
  placeholder = "Please enter here",
  setSelectedInputOptions,
  optionsArr,
  selectedValue,
  isSearchable = true,
}) => {
  const handleSelectChange = (val) => {
    // change
    return setSelectedInputOptions(val.map((cur) => cur.value));
  };

  return (
    <Select
      onChange={handleSelectChange} // change
      noOptionsMessage={() => "No options found."}
      value={selectedValue}
      placeholder={placeholder}
      isMulti
      isSearchable={isSearchable}
      theme={customTheme}
      options={optionsArr}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={customStyles} // this is necessary in order to set styles in css
    />
  );
};

export default SuggestedInput;
