import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeliveryFormInfoContext,
  BottomContext,
  BOTTOM_TAB,
  LoaderContext,
  NotFoundErrorContext,
} from "../OpsComponent";
import { ApiContext } from "../../index.js";
import { postAuthData } from "../../helpers/request";
import { FaSync, FaUserPlus } from "react-icons/fa";
import { useParams } from "react-router";
import TopDashboard from "./components/TopDashboard/TopDashboard";
import LogsTab from "./components/Tabs/LogsTab/LogsTab";
import DeliveryTab from "./components/Tabs/DeliveryTab/DeliveryTab";
import HistoryTab from "./components/Tabs/HistoryTab/HistoryTab";
import DetailsTab from "./components/Tabs/DetailsTab/DetailsTab";
import { TYPE_OF_ALL_ASSIGNMENTS_DATA } from "../../store/features/liveAllAssignmentsData/common";
import { getLiveAssignmentData } from "../../store/features/liveAssignmentData/liveAssignmentDataSlice";
import { getLiveAllAssignmentsData } from "../../store/features/liveAllAssignmentsData/liveAllAssignmentsDataSlice.js";

export const FileContext = React.createContext(); // The File displayed in dashboard
export const DashboardFileContext = React.createContext(); // To save dashboard file
export const OpsDisplayedPageContext = React.createContext();

// FILE ICON
const FileIcon = ({ filename }) => {
  let url;
  if (!filename) {
    url = "/resources/file_type_icons/file.svg";
  } else {
    // switch (filename?.split(".").at(-1).toLowerCase()) {
    switch (filename?.split(".").pop().toLowerCase()) {
      case "doc":
      case "docx":
        url = "/resources/file_type_icons/word.svg";
        break;
      case "ppt":
      case "pptx":
        url = "/resources/file_type_icons/powerpoint.svg";
        break;
      case "pdf":
        url = "/resources/file_type_icons/pdf.svg";
        break;
      case "xlsx":
        url = "/resources/file_type_icons/excel.svg";
        break;
      case "jpeg":
      case "jpg":
        url = "/resources/file_type_icons/image.svg";
        break;
      case "srt":
        url = "/resources/file_type_icons/subtitles.svg";
        break;
      case "mp4":
        url = "/resources/file_type_icons/video.svg";
        break;
      case "png":
        url = "/resources/file_type_icons/png.svg";
        break;
      case "psd":
        url = "/resources/file_type_icons/photoshop.svg";
        break;
      case "ai":
      case "eps":
        url = "/resources/file_type_icons/vector.svg";
        break;
      case "zip":
        url = "/resources/file_type_icons/zip.svg";
        break;
      default:
        url = "/resources/file_type_icons/file.svg";
        break;
    }
  }
  return (
    <div className="file-icon">
      <img src={process.env.PUBLIC_URL + url} alt="File" />
      {/* {type && <span className="ops-file-type">{type}</span>} */}
    </div>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const liveAllAssignmentsData = useSelector(
    (state) => state.liveAllAssignmentsData,
  );
  const { bottomTab, setBottomTab } = useContext(BottomContext);
  const { setLoader } = useContext(LoaderContext);
  const API_URL = useContext(ApiContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { asgn_id } = useParams();

  const showDashboardOutput = (e) => {
    e.preventDefault();
    const btn = e.target.closest(".bottom-dashboard-tab-btn");
    setBottomTab(btn.value);
  };

  const updateAllAssignments = () => {
    switch (liveAllAssignmentsData?.type_of_data) {
      case TYPE_OF_ALL_ASSIGNMENTS_DATA.RECENT:
        dispatch(getLiveAllAssignmentsData({}));
        break;
      case TYPE_OF_ALL_ASSIGNMENTS_DATA.ALL:
        dispatch(getLiveAllAssignmentsData({ month: "all" }));
        break;
      default:
        dispatch(getLiveAllAssignmentsData({ month: "all" }));
        break;
    }
  };

  const updateDisplayedAssignment = () => {
    dispatch(getLiveAssignmentData({ id: asgn_id })); // fetching dashboard assignemnt
  };

  const syncData = (e) => {
    e.target
      .closest(".bottom-dashboard-sync")
      .classList.add("bottom-dashboard-sync-rotate");
    setLoader("Please wait while we sync data with Google Sheet.");
    // sync api
    const data = { delivery_id: liveAssignmentData?.file_details?.batch_id };
    postAuthData(`${API_URL}/api-ops/ops/v1/google-sync-delivery/`, data)
      .then((res) => {
        if (res.success) {
          updateAllAssignments();
          updateDisplayedAssignment();
        } else {
          alert(res?.message || "Failed to sync Data!");
        }
        e.target
          .closest(".bottom-dashboard-sync")
          .classList.remove("bottom-dashboard-sync-rotate");
        setLoader(false);
      })
      .catch(() => {
        e.target
          .closest(".bottom-dashboard-sync")
          .classList.remove("bottom-dashboard-sync-rotate");
        setLoader(false);
        setNotFoundError(true);
      });
  };

  return (
    <div className="ops-dashboard">
      <TopDashboard file={liveAssignmentData?.file_details} />

      <div className="bottom-dashboard">
        <div className="ops-dashboard-selector">
          <div className="ops-dashboard-selector-btn-container">
            <button
              className={`bottom-dashboard-tab-btn ${
                bottomTab === BOTTOM_TAB.DELIVERY &&
                "ops-dashboard-selector-btn-active"
              }`}
              onClick={(e) => showDashboardOutput(e)}
              value={BOTTOM_TAB.DELIVERY}
            >
              DELIVERY
            </button>
            <button
              className={`bottom-dashboard-tab-btn ${
                bottomTab === BOTTOM_TAB.HISTORY &&
                "ops-dashboard-selector-btn-active"
              }`}
              onClick={(e) => showDashboardOutput(e)}
              value={BOTTOM_TAB.HISTORY}
            >
              HISTORY
            </button>
            <button
              className={`bottom-dashboard-tab-btn ${
                bottomTab === BOTTOM_TAB.DETAILS &&
                "ops-dashboard-selector-btn-active"
              }`}
              onClick={(e) => showDashboardOutput(e)}
              value={BOTTOM_TAB.DETAILS}
            >
              DETAILS
            </button>
          </div>
          <div className="ops-dashboard-selector-btn-container">
            <button
              className={`bottom-dashboard-tab-btn bottom-dashboard-share-tab-btn ${
                bottomTab === BOTTOM_TAB.SHARING &&
                "ops-dashboard-selector-btn-active"
              }`}
              title="Share Assignments from here"
              value={BOTTOM_TAB.SHARING}
              onClick={(e) => showDashboardOutput(e)}
            >
              <FaUserPlus />
            </button>
            <button
              className="bottom-dashboard-sync"
              title="Sync Data from Google Sheet"
              onClick={(e) => syncData(e)}
            >
              <FaSync />
            </button>
          </div>
        </div>
        {bottomTab === BOTTOM_TAB.DELIVERY && (
          <DeliveryTab file={liveAssignmentData?.file_details} />
        )}
        {bottomTab === BOTTOM_TAB.HISTORY && (
          <HistoryTab file={liveAssignmentData?.file_details} />
        )}
        {bottomTab === BOTTOM_TAB.DETAILS && (
          <DetailsTab file={liveAssignmentData?.file_details} />
        )}
        {bottomTab === BOTTOM_TAB.SHARING && <LogsTab />}
      </div>
    </div>
  );
};

// // ======================== Displayed Page =================================

// export const PAGE_TYPE = {
//     DASHBOARD : "dashboard",
//     USER_SETTINGS : "users-settings",
//     CLIENT_SETTINGS : "client-settings",
//     ACCOUNT_SETTINGS : "ops_account_settings"
// }

// // const RenderPage = ({page}) => {
// //     const {_,setOpsDisplayedFile} = useContext(OpsDashboardFileContext);

// //     switch (page) {
// //         case PAGE_TYPE.DASHBOARD:
// //             return (
// //                 <>

// //                     <Dashboard />

// //                 </>
// //         )

// //         case  PAGE_TYPE.DELIVER_FILE:
// //             return(
// //                 <>

// //                  <DeliverFileModal />
// //                 </>
// //             )
// //         case PAGE_TYPE.USER_SETTINGS:
// //             return (
// //                 <>
// //                     <OpsUserSettings />
// //                 </>
// //         )
// //         case PAGE_TYPE.CLIENT_SETTINGS:
// //             return (
// //                 <>
// //                     <OpsClientSettings />
// //                 </>
// //         )
// //         case PAGE_TYPE.ACCOUNT_SETTINGS:
// //             return (
// //                 <>
// //                     <OpsAccountSettings />
// //                 </>
// //         )
// //         default:
// //             return (
// //                 <>
// //                     <Dashboard />
// //                 </>
// //             );
// //     }
// // }

// // ======================== Complete dashboard page ========================

const OpsDashboard = () => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const [deliverFileInfo, setDeliverFileInfo] = useState({}); // Stores the values from the deliver file modal to be displayed when edit is clicked.
  const [displayedPage, setDisplayedPage] = useState("dashboard");

  return (
    <>
      {!liveAssignmentData?.file_details?.id ? (
        <OpsEmptyDashboard />
      ) : (
        <DeliveryFormInfoContext.Provider
          value={{ deliverFileInfo, setDeliverFileInfo }}
        >
          <OpsDisplayedPageContext.Provider
            value={{ displayedPage, setDisplayedPage }}
          >
            <Dashboard />
          </OpsDisplayedPageContext.Provider>
        </DeliveryFormInfoContext.Provider>
      )}
    </>
  );
};

const OpsEmptyDashboard = () => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);

  return (
    <section className="ops-empty-dashboard">
      <span className="ops-empty-dashbaord-text">
        {liveAssignmentData?.error}
      </span>
    </section>
  );
};

export { OpsDashboard, FileIcon };
