import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { date_time_convertor } from "../../../..";
import { showFilesSidebar } from "../../../Nav/OpsSideNav/OpsSideNav";

import "./TopDashboard.css";

const TopDashboard = ({ file }) => {
  // console.log(file);

  return (
    <>
      <div className="top-dashboard">
        <div className="top-dashboard-file-name">
          <button
            className="ops-dashboard-back-btn"
            onClick={(e) => {
              showFilesSidebar(e);
            }}
          >
            <FaAngleLeft className="ops-responsive-back-btn" />
          </button>
          <div className="file-logo">
            {/* <FileIcon filename={file?.source_files[0].name} /> */}
            <div className="filename">
              {file?.source_files &&
                `${file?.source_files[0].name || "-"} ${
                  file?.source_files?.length > 1
                    ? "+ " + String(file?.source_files?.length - 1)
                    : ""
                }`}
            </div>
          </div>
        </div>

        {/* <div className="top-dashboard-assignment-stages">
                <div className="file-steps">{file?.assignment_stages}</div>
            </div> */}

        <div className="top-dashboard-delivery-id">
          <div className="delivery-id">
            <div className="delivery-id-head">Delivery ID</div>
            <div className="delivery-id-value">
              <span className="delivery-id-value-text">{file?.batch_id}</span>
            </div>
          </div>
        </div>

        <div className="top-dashboard-client-code">
          <div className="top-dashboard-label">Entity Code</div>
          <div className="top-dashboard-value">{file?.entity_code || "-"}</div>
        </div>

        <div className="top-dashboard-client-charge-code">
          <div className="top-dashboard-label">Client Charge Code</div>
          <div className="dashboard-value">
            {file?.client_charge_code || "-"}
          </div>
        </div>

        <div className="top-dashboard-delivered">
          <div className="top-dashboard-label">Delivered</div>
          <div className="top-dashboard-value">
            <span>{date_time_convertor(file?.actual_delivery)}</span>
            <span
              style={{
                color:
                  parseInt(file?.duration) >= 0 ? "rgb(80, 207, 80)" : "red",
              }}
            >
              {file?.duration}
            </span>
          </div>
        </div>

        <div className="top-dashboard-requester-name">
          <div className="top-dashboard-label">Requester Name</div>
          <div className="dashboard-value">{file?.requester_name || "-"}</div>
        </div>

        <div className="top-dashboard-date-received">
          <div className="top-dashboard-label">Date Received</div>
          <div className="top-dashboard-value">
            {date_time_convertor(file?.requested_delivery) || "-"}
          </div>
          {/* <div className="top-dashboard-value">{date_time_convertor(file?.requested_delivery) || "-"}</div> */}
        </div>

        <div className="top-dashboard-committed-delivery">
          <div className="top-dashboard-label">Last Delivery</div>
          {/* <div className="top-dashboard-value committed-delivery">{file?.committed_delivery+" IST" || "-"}</div> */}
          <div className="top-dashboard-value committed-delivery">
            {date_time_convertor(file?.committed_delivery) || "-"}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopDashboard;
