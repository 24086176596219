import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaBan,
  FaCheck,
  FaHourglassStart,
  // FaRedoAlt,
  FaSpinner,
  FaTimes,
} from "react-icons/fa";
import { postAuthData } from "../../../../helpers/request";
import { PROGRESS_STATUS } from "../../../../utils/fileUploadProgressIdentifier";

import "./UploadFilesTab.css";

const UploadTargetFile = ({ file_data }) => {
  return (
    <div className="upload-section-file-container upload-target-section-file-container">
      <div className="upload-section-file-name upload-target-section-file-name">
        {file_data.file.name}
      </div>
      <div className="upload-section-file-btn-container upload-target-section-file-btn-container">
        {file_data.progress === PROGRESS_STATUS.IN_QUEUE && (
          <span
            className="upload-section-file-btn upload-section-file-btn-inqueue"
            title="In-queue"
          >
            <FaHourglassStart />
          </span>
        )}
        {/* {file_data.progress === PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-retry"
            title="Redo"
          >
            <FaRedoAlt />
          </span>
        )}
        {file_data.progress === PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-reject"
            title="Reject"
          >
            <FaTimes />
          </span>
        )} */}
        {file_data.progress === PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-failed"
            title="Failed"
          >
            <FaBan />
          </span>
        )}
        {file_data.progress === PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-uploading"
            title="Uploading"
          >
            <FaSpinner />
          </span>
        )}
        {file_data.progress === PROGRESS_STATUS.SUCCESS && (
          <span
            className="upload-section-file-btn upload-section-file-btn-success"
            title="Succes"
          >
            <FaCheck />
          </span>
        )}
      </div>
    </div>
  );
};

const UploadSourceFile = ({ file_data }) => {
  return (
    <div className="upload-section-file-container upload-source-section-file-container">
      <div className="upload-section-file-name upload-source-section-file-name">
        {file_data.file.name}
      </div>
      <div className="upload-section-file-btn-container upload-source-section-file-btn-container">
        {file_data.progress === PROGRESS_STATUS.IN_QUEUE && (
          <span
            className="upload-section-file-btn upload-section-file-btn-inqueue"
            title="In-queue"
          >
            <FaHourglassStart />
          </span>
        )}
        {/* {file_data.progress === PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-retry"
            title="Redo"
          >
            <FaRedoAlt />
          </span>
        )} */}
        {/* {file_data.progress === PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-reject"
            title="Reject"
          >
            <FaTimes />
          </span>
        )} */}
        {file_data.progress === PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-failed"
            title="Failed"
          >
            <FaBan />
          </span>
        )}
        {file_data.progress === PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-uploading"
            title="Uploading"
          >
            <FaSpinner />
          </span>
        )}
        {file_data.progress === PROGRESS_STATUS.SUCCESS && (
          <span
            className="upload-section-file-btn upload-section-file-btn-success"
            title="Succes"
          >
            <FaCheck />
          </span>
        )}
      </div>
    </div>
  );
};

const GroupUpload = ({ group_data }) => {
  return (
    <div className="upload-section-container">
      <div className="upload-section-group-id">
        GROUP {group_data?.group_id}
      </div>

      <section className="upload-section upload-target-section">
        <div className="upload-section-head upload-target-section">
          Target Files
        </div>
        <div className="upload-section-files upload-target-section-files">
          {Object.entries(group_data?.target_files)?.map(
            ([file_key, file_data]) => {
              return <UploadTargetFile key={file_key} file_data={file_data} />;
            },
          )}
        </div>
      </section>

      <section className="upload-section upload-source-section">
        <div className="upload-section-head upload-source-section">
          Source Files
        </div>
        <div className="upload-section-files upload-source-section-files">
          {Object.entries(group_data?.source_files)?.map(
            ([file_key, file_data]) => {
              return <UploadSourceFile key={file_key} file_data={file_data} />;
            },
          )}
        </div>
      </section>
    </div>
  );
};

// creating the combination of sourvce Files and target files to track progress for files tab
const createGroupedFileData = (combined_data) => {
  let file_groups;
  let file_ids_arr = [];
  // eslint-disable-next-line no-unused-vars
  combined_data.forEach((data) => {
    let file_data_group = {
      group_id: `G_${data.group_id}`,
      // this obj is made like (has target_files and source_files) this to create ui specific component for source and target
      target_files: {}, // keyname must be same as source_file_data object
      source_files: {}, // keyname must be same as target_file_data object
    };
    data.target_files?.forEach((file, idx) => {
      const target_file_id = {
        group_id: file_data_group.group_id,
        file_type: "target_files", // this must be same as file_data_group target file key
        file_type_id: `target_file_${idx}`,
      };
      file_ids_arr.push(target_file_id);
      let target_file_data = {
        ...file_data_group.target_files,
        [`target_file_${idx}`]: {
          file_id: target_file_id,
          file,
          progress: PROGRESS_STATUS.IN_QUEUE,
          uploading_chunk: 0,
          total_chunks: 0,
          upload_id: null,
        },
      };
      file_data_group.target_files = target_file_data;
    });
    data.source_files?.forEach((file, idx) => {
      const source_file_id = {
        // this data is required to update the progress of the specific file in the state
        group_id: file_data_group.group_id,
        file_type: "source_files", // this must be same as file_data_group source file key
        file_type_id: `source_file_${idx}`,
      };
      file_ids_arr.push(source_file_id);
      let source_file_data = {
        ...file_data_group.source_files,
        [`source_file_${idx}`]: {
          // add file_data here in this object in case you want to send data to the api
          file_id: source_file_id,
          file,
          progress: PROGRESS_STATUS.IN_QUEUE,
          uploading_chunk: null,
          total_chunks: null,
          upload_id: null,
        },
      };
      file_data_group.source_files = source_file_data;
    });
    file_groups = {
      ...file_groups,
      [file_data_group.group_id]: {
        group_id: file_data_group.group_id,
        target_files: file_data_group.target_files,
        source_files: file_data_group.source_files,
      },
    };
  });
  return { file_groups, file_ids_arr };
};

function get_query(url) {
  let qs = url.substring(url.indexOf("?") + 1).split("&");
  for (var i = 0, result = {}; i < qs.length; i++) {
    qs[i] = qs[i].split("=");
    result[qs[i][0]] = decodeURIComponent(qs[i][1]);
  }
  return result;
}

const UploadFilesTab = ({ combinationData, setuploadFileTabState }) => {
  const [fileGroups, setFileGroups] = useState(null); // this state will contain every detail about the files and its progress and groups info
  const [next, setNext] = useState(2); // this will lead on the next file to upload from the lot
  const [fileIdsArr, setFileIdsArr] = useState([]);

  let chunkMB = 5; // Minimum Chunk Size should be 5 MB
  let chunkSize = chunkMB * 1024 * 1024;

  const updateFileStateData = ({
    file_id_data,
    progressData,
    uploadingChunk,
    totalChunks,
    uploadId,
  }) => {
    // updating the file's progress to uploading.
    setFileGroups((prevState) => {
      return {
        ...prevState,
        [file_id_data.group_id]: {
          ...prevState[file_id_data.group_id],
          [file_id_data.file_type]: {
            ...prevState[file_id_data.group_id][file_id_data.file_type],
            [file_id_data.file_type_id]: {
              ...prevState[file_id_data.group_id][file_id_data.file_type][
                file_id_data.file_type_id
              ],
              progress:
                progressData ||
                prevState[file_id_data.group_id][file_id_data.file_type][
                  file_id_data.file_type_id
                ].progress,
              uploading_chunk:
                uploadingChunk ||
                prevState[file_id_data.group_id][file_id_data.file_type][
                  file_id_data.file_type_id
                ].uploading_chunk,
              total_chunks:
                totalChunks ||
                prevState[file_id_data.group_id][file_id_data.file_type][
                  file_id_data.file_type_id
                ].total_chunks,
              upload_id:
                uploadId ||
                prevState[file_id_data.group_id][file_id_data.file_type][
                  file_id_data.file_type_id
                ].upload_id,
            },
          },
        },
      };
    });
  };

  const getFileUploadLinks = (file_id_data) => {
    // getting the file from the group of files state
    const file_data =
      fileGroups[file_id_data.group_id][file_id_data.file_type][
        file_id_data.file_type_id
      ];
    // updating the file's progress to uploading.
    updateFileStateData({
      file_id_data,
      progressData: PROGRESS_STATUS.UPLOADING,
    });

    // preparing data for fetching chunk urls
    let data = {
      file_name: file_data.file.name,
      file_size: file_data.file.size,
      chunk_size: chunkMB,
    };

    //  We will get links from this API and pass to uploadFileChunks function
    postAuthData(
      `http://34.237.48.240:8006/api/upload/multipart/get_urls`,
      data,
    )
      .then((res) => {
        uploadFileChunks(res.urls, file_id_data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const uploadFileChunks = async (links, file_id_data) => {
    // getting the file from the group of files state
    const file_data =
      fileGroups[file_id_data.group_id][file_id_data.file_type][
        file_id_data.file_type_id
      ];
    let total_chunks = Math.ceil(file_data.file.size / chunkSize); // Calculating Number of chunks
    let uploadId = get_query(links[0]).uploadId;

    // updating the file's progress to uploading and updating the total number of chunks of file
    updateFileStateData({
      file_id_data,
      progressData: PROGRESS_STATUS.UPLOADING,
      totalChunks: total_chunks,
    });

    // Function to upload a individual chunk ;  It will be called in "for loop"
    const uploadChunk = async (idx, chunkSize) => {
      const sendAxios = axios.create();
      let initialPointer = idx * chunkSize;
      let chunk = file_data.file.slice(
        initialPointer,
        initialPointer + chunkSize,
        file_data.file.type,
      );
      return sendAxios.put(links[idx], chunk, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };

    (async () => {
      let flag = 0; // if a chunk failed to upload this will be set to 1 and will terminate the loop
      let partsData = []; // It will save the "ETag" and "PartNumber" for every chunk of file and will be returned to COMBINE function
      for (let chunk = 0; chunk < total_chunks; chunk++) {
        if (flag) return Promise.reject(new Error("File can't be uploaded")); // if flag=1 => loop will end and control will move to .catch() block
        await uploadChunk(chunk, chunkSize)
          .then((p) => {
            partsData.push({
              ETag: JSON.parse(p.headers.etag),
              PartNumber: chunk + 1,
            });
            // updating the chunk number that is to be uploaded now
            updateFileStateData({
              file_id_data,
              uploadingChunk:
                fileGroups[file_id_data.group_id][file_id_data.file_type][
                  file_id_data.file_type_id
                ].uploading_chunk + 1,
            });
          })
          .catch((err) => {
            console.log("First Retry: " + err);
            // Trying again to upload if fails 1st time
            uploadChunk(chunk, chunkSize)
              .then((res) => {
                partsData.push({
                  ETag: JSON.parse(res.headers.etag),
                  PartNumber: chunk + 1,
                });
                // updating the chunk number that is to be uploaded now
                updateFileStateData({
                  file_id_data,
                  uploadingChunk:
                    fileGroups[file_id_data.group_id][file_id_data.file_type][
                      file_id_data.file_type_id
                    ].uploading_chunk + 1,
                });
              })
              .catch((err) => {
                console.log("Failed: " + err);
                flag = 1; // when a chunk is not uploaded flag=1 and it will end the loop in next iteration
                // if chunk fails 2nd time also, flag is set to 1, and Progress Status to Retry.
                updateFileStateData({
                  file_id_data,
                  progressData: PROGRESS_STATUS.FAILED,
                  uploadingChunk: 0,
                });
              });
          });
      }
      return { partsData: partsData }; // The loop completes successfully =>  .then() will be executed
    })()
      .then((part_info) => {
        combineFilePartsFromBE(part_info.partsData, uploadId, file_id_data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  // This function will take input an array of "ETag" and "PartNumber" form UploadChunks function.
  // And hit "POST" API: complete Multipart
  const combineFilePartsFromBE = (parts, uploadId, file_id_data) => {
    // getting the file from the group of files state
    const file_data =
      fileGroups[file_id_data.group_id][file_id_data.file_type][
        file_id_data.file_type_id
      ];
    let data = {
      parts: parts,
      upload_id: uploadId,
      file_name: file_data.file.name,
    };
    postAuthData(
      `http://34.237.48.240:8006/api/upload/multipart/complete`,
      data,
    )
      .then((res) => {
        if (res.details === "File upload done.") {
          // all files are part wise organised and now the file is uploaded successfuly
          updateFileStateData({
            file_id_data,
            progressData: PROGRESS_STATUS.SUCCESS,
          });
        } else {
          updateFileStateData({
            file_id_data,
            progressData: PROGRESS_STATUS.FAILED,
            uploadingChunk: 0,
          });
        }
        setNext((prev) => prev + 1); // For Starting : Next file upload
        // when this next will be updated => useEffect will be called and it will start upload the next file in queue
      })
      .catch((err) => {
        console.log("Combination API error: " + err + "\nRetrying...");
        postAuthData(
          `http://34.237.48.240:8006/api/upload/multipart/complete`,
          data,
        )
          .then((res) => {
            if (res.details === "File upload done.") {
              updateFileStateData({
                file_id_data,
                progressData: PROGRESS_STATUS.SUCCESS,
              });
            } else {
              updateFileStateData({
                file_id_data,
                progressData: PROGRESS_STATUS.FAILED,
                uploadingChunk: 0,
              });
            }
            setNext((prev) => prev + 1);
          })
          .catch((err) => {
            console.log("Combination API error: " + err);
            updateFileStateData({
              file_id_data,
              progressData: PROGRESS_STATUS.FAILED,
              uploadingChunk: 0,
            });
            setNext((prev) => prev + 1);
          });
      });
  };

  const UploadFile = () => {
    // Initially File 0,1 and 2 will start uploading
    // Initial value of  [next, setNext] useState will be equal to = 2;
    // If we need to upload  N files initially then value of Next should be (N-1)
    if (fileIdsArr[0]) getFileUploadLinks(fileIdsArr[0]);
    if (fileIdsArr[1]) getFileUploadLinks(fileIdsArr[1]);
    if (fileIdsArr[2]) getFileUploadLinks(fileIdsArr[2]);
  };

  useEffect(() => {
    if (fileIdsArr[next]) getFileUploadLinks(fileIdsArr[next], next);
  }, [next]);

  useEffect(() => {
    const fileGroupData = createGroupedFileData(combinationData);
    setFileGroups(fileGroupData.file_groups);
    setFileIdsArr(fileGroupData.file_ids_arr);
  }, []);

  return (
    <section className="upload-files-section-container">
      <article className="ops-modal-container upload-files-section">
        <div className="ops-modal-head">
          <div className="ops-modal-container-head upload-files-tab-file-modal-head">
            Upload Files Progress
          </div>
          <button onClick={UploadFile}>submit</button>
          <button
            className="ops-modal-container-close-btn"
            onClick={() => {
              setuploadFileTabState(false);
            }}
          >
            <FaTimes />
          </button>
        </div>
        <div className="upload-group-section-container">
          {fileGroups &&
            Object.entries(fileGroups).map(([group_id, group_data]) => {
              return <GroupUpload key={group_id} group_data={group_data} />;
            })}
        </div>
      </article>
    </section>
  );
};

export default UploadFilesTab;
