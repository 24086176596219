import React from "react";
import { assignment_services } from "../../../../../req_jsons/assignment_services";

import "./DetailsTab.css";

const FileService = ({ service }) => {
  return (
    <div className="details-tab-info-service">
      <div className="details-tab-info-service-head">
        <div className="details-tab-info-service-head-logo">
          <img
            src={
              process.env.PUBLIC_URL +
              `/resources/services_icons/blue/${
                assignment_services[service.service].code
              }.svg`
            }
            alt={assignment_services[service.service].code}
          />
        </div>
        <div className="details-tab-info-service-head-text">
          {service.service}
        </div>
      </div>
      <div className="details-tab-info-service-value-container">
        {service.sub_services.map((sub_service, idx) => {
          return (
            <div key={idx} className="details-tab-info-service-value-item">
              <div className="details-tab-info-service-value-item-text">{`${sub_service.unit_count} ${sub_service.unit_type} ${sub_service.sub_service}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DetailsTab = ({ file }) => {
  return (
    <section className="details-tab-section card-layout">
      <span className="details-tab-head">Batch Information</span>
      <div className="details-tab-batch-info-container">
        <span className="details-tab-info-label details-tab-batch-info-head">
          Assignment
        </span>
        <span className="details-tab-batch-info">{file?.batch_id}</span>
        <div className="details-tab-batch-assignment-container">
          {file?.assignments?.map((cur, i) => {
            return (
              <span
                key={i}
                className="details-tab-batch-info details-tab-batch-assignment"
              >
                {cur}
              </span>
            );
          })}
        </div>
      </div>
      <div className="details-tab-info" id="batch-info-services">
        <span className="details-tab-info-label">Services</span>
        <section className="details-tab-info-value">
          {file?.services?.map((service, id) => {
            return <FileService key={id} service={service} />;
          })}
        </section>
      </div>
    </section>
  );
};

export default DetailsTab;
