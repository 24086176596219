export const initialState = {
  client: null,
  shared_emails: [],
  recognised_emails: [],
  unrecognised_emails: [],
  show_warning: false,
  upload_warning_sent: false,
  password_data: null,
  client_code: null,
  entity_code: null,
  delivery_id: null,
};

export const SHARE_RESPONSE_STATUS = {
  SUCCESS: "success",
  WARNING: "warning",
  FAILED: "failed",
};
