import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ToolTipError,
  ERROR_REGEX,
  date_time_convertor,
  FileType,
  FeedbackRatingEmoji,
  devEnv,
} from "../../index.js";
import {
  OpsArchiveCountContext,
  OpsRouteContext,
  OPS_ROUTES,
} from "../OpsComponent";
import { useHistory, Link, useParams } from "react-router-dom";
import {
  FaSearch,
  FaSortAmountDownAlt,
  FaSortAmountDown,
  FaAngleDown,
} from "react-icons/fa";

import "./OpsSideBar.css";

// importing actions
import {
  SORT_ORDERS,
  TYPE_OF_ALL_ASSIGNMENTS_DATA,
} from "../../store/features/liveAllAssignmentsData/common.js";
import { getLiveAssignmentData } from "../../store/features/liveAssignmentData/liveAssignmentDataSlice";
import { getLiveAllAssignmentsData } from "../../store/features/liveAllAssignmentsData/liveAllAssignmentsDataSlice.js";

// // importing Dummy Data
// import dummy_all_assignemnts from "../../archive/test_data/dummy_data-v1/dummy_all_assignment.json";
// // monthly assignmnent dummy data
// import dummy_monthly_recent_assignments from "../../archive/test_data/dummy_data-v2/monthly_assignment_data/dummy_monthly_recent_assignments.json";
// import dummy_monthly_all_assigments from "../../archive/test_data/dummy_data-v2/monthly_assignment_data/dummy_monthly_all_assignments.json";
// import dummy_search_monthly_assignments from "../../archive/test_data/dummy_data-v2/monthly_assignment_data/dummy_search_monthly_assignments.json";
// import dummy_search_monthly_last_assignment from "../../archive/test_data/dummy_data-v2/monthly_assignment_data/dummy_search_monthly_last_assignment.json";
// import dummy_monthly_last_assignment from "../../archive/test_data/dummy_data-v2/monthly_assignment_data/dummy_monthly_last_assignment.json";

// Required JSONs
import { assignment_services } from "../../req_jsons/assignment_services.js";
import NameShorteningCmpn from "../../utils/NameShorteningCmpn.js";

const DashboardSearchContext = React.createContext(); // needed to pass the search string in archive search

//============================SIDEBAR============================
export let file_id = null;

// const FileType = ({filetype, active})=> {

//     return (
//         <>
//             {(filetype?.includes("TR")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>TR</span></div>)}
//             {(filetype?.includes("DP")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>DP</span></div>)}
//             {(filetype?.includes("PD")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>PD</span></div>)}
//             {(filetype?.includes("GD")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>GD</span></div>)}
//             {(filetype?.includes("VG")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>VG</span></div>)}
//         </>
//     )
// }

// const FileType = ({filetype, active})=> {
//     if (filetype?.includes("VG") && filetype?.includes("TR")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TR</div>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>VG</div>
//             </>
//         )
//     } else if (filetype?.includes("TD") && filetype?.includes("TR")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TR</div>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TD</div>
//             </>
//         )
//     } else if (filetype?.includes("VG")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>VG</div>
//             </>
//         )
//     } else if (filetype?.includes("TR")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TR</div>
//             </>
//         )
//     } else {
//         return (<></>)
//     }
// }

const File = ({ file, active, hidden }) => {
  const history = useHistory();

  const showFileDetails = (e) => {
    if (devEnv) {
      history.push(`/deliver/${file.id}`); // Setting Dummy Data
      return;
    }

    // Removing active class from all other files
    document.querySelectorAll(".ops-sidebar-file").forEach((el) => {
      el.classList.remove("ops-sidebar-file-active");
      Array.from(el.querySelectorAll(".ops-file-stage")).map((el) =>
        el.classList.remove("ops-file-stage-active"),
      );
    });
    // adding active file to the clicked file
    e.target
      .closest(".ops-sidebar-file")
      .classList.toggle("ops-sidebar-file-active");
    Array.from(
      e.target.closest(".ops-sidebar-file").querySelectorAll(".ops-file-stage"),
    ).map((el) => el.classList.add("ops-file-stage-active"));
    file?.id
      ? history.push(`/deliver/${file?.id}`)
      : alert("No file id available!");
    // getAuthData(`${API_URL}/api-ops/ops/v1/all-assignments/?id=${asgn_id}`)
    //     .then((res) => {
    //         if (res.success === true) {
    //             file_id=file.id;

    //             setOpsDisplayedFile(res.last_assignment);

    //             // setBottomTab(BOTTOM_TAB.DETAILS);
    //         }
    //     })
    //     .catch(err => {
    //         console.error(err);
    //     })
  };

  return (
    <div
      className={`ops-sidebar-file
            ${active && "ops-sidebar-file-active"} ${
        !hidden && "ops-sidebar-file-show"
      }`}
      onClick={showFileDetails}
    >
      <div className="ops-file-stages">
        <FileType
          filetype={file.services.map(
            (service) => assignment_services[service].code,
          )}
          active={active}
        />
      </div>
      <div className="ops-sidebar-file-feedback">
        <FeedbackRatingEmoji feedback={file.feedback} />
      </div>
      <div className="ops-sidebar-file-info ops-sidebar-file-filename">
        <NameShorteningCmpn
          name={file?.file_names[0]}
          max_length={40}
          last_chars_length={6}
        />
        {` ${
          file?.file_names?.length > 1
            ? "+ " + String(file?.file_names?.length - 1)
            : ""
        }`}
      </div>
      <div className="ops-sidebar-file-info ops-sidebar-file-details">
        {file?.unit_details.length < 2 ? (
          file?.unit_details?.map((detail, idx) => {
            return (
              <span key={idx} className="client-sidebar-file-subservice-detail">
                {`${detail?.unit_count} ${detail?.unit_type}`}
                {file.unit_details.lenght > 1 && ","}
              </span>
            );
          })
        ) : (
          <>
            {file?.unit_details.slice(0, 2)?.map((detail, idx) => {
              return (
                <span
                  key={idx}
                  className="client-sidebar-file-subservice-detail"
                >
                  {`${detail?.unit_count} ${detail?.unit_type}`},
                </span>
              );
            })}
            <span>...</span>
          </>
        )}
      </div>
      <div className="ops-sidebar-file-info ops-sidebar-file-details">
        Delivered{" "}
        <span
          className="delivered-time"
          style={{
            color:
              file?.duration?.substr(0, 1) !== "-" ? "rgb(80, 207, 80)" : "red",
          }}
        >
          {file.duration}
        </span>
      </div>
      <div className="ops-sidebar-file-info ops-sidebar-file-details">
        <NameShorteningCmpn
          name={file?.requester_name}
          max_length={10}
          last_chars_length={3}
        />
        {`, ${file?.client_code}`}
      </div>
      <div className="ops-sidebar-file-info ops-sidebar-file-details">
        {date_time_convertor(file?.committed_delivery)
          .split(",")
          .slice(0, 2)
          .join(",")}
      </div>
    </div>
  );
};

export const sort_orders = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

const FileHistoryHead = () => {
  const liveAllAssignmentsData = useSelector(
    (state) => state.liveAllAssignmentsData,
  );
  const dispatch = useDispatch();
  const search_word = useRef(null);
  const { setOpsArchiveCount } = useContext(OpsArchiveCountContext);
  const { searched, setSearched } = useContext(DashboardSearchContext);

  const sortFetchDispatch = (sort_arg) => {
    switch (liveAllAssignmentsData?.type_of_data) {
      case TYPE_OF_ALL_ASSIGNMENTS_DATA.SEARCH:
        dispatch(
          getLiveAllAssignmentsData({
            sort: sort_arg,
            search_keyword: searched,
          }),
        );
        break;
      case TYPE_OF_ALL_ASSIGNMENTS_DATA.RECENT:
        dispatch(
          getLiveAllAssignmentsData({
            sort: sort_arg,
          }),
        );
        break;
      case TYPE_OF_ALL_ASSIGNMENTS_DATA.ALL:
        dispatch(
          getLiveAllAssignmentsData({
            sort: sort_arg,
            month: "all",
          }),
        );
        break;

      default:
        dispatch(
          getLiveAllAssignmentsData({
            sort: sort_arg,
          }),
        );
        break;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearched(search_word.current.value);
    dispatch(
      getLiveAllAssignmentsData({
        month: "all",
        search_keyword: search_word.current.value,
      }),
    );
    // if (devEnv){
    //     setOpsFilesArray(dummy_all_assignemnts.data) // Setting Dummy Data
    //     return
    // }
    // getAuthData(`${API_URL}/api-ops/ops/v1/all-assignments/?search=${search_word.current.value}`)
    //     .then((res) => {
    //         if (res.success === true) {
    //             setSearched(search_word.current.value)
    //             setOpsFilesArray(res.data);
    //             setOpsArchiveCount(res.archive_count);
    //         }
    //     })
    //     .catch(err => {
    //         console.error(err);
    //     })
  };

  useEffect(() => {
    // this sets the ops archive count the better solution would be to get a new api for this
    if (!liveAllAssignmentsData?.archive_count) return;
    setOpsArchiveCount(liveAllAssignmentsData?.archive_count);
  }, [liveAllAssignmentsData?.archive_count]);

  return (
    <div className="file-history-head">
      <div className="sidebar-head">
        <span>Assignments</span>
        {liveAllAssignmentsData.sort_order === SORT_ORDERS.ASCENDING && (
          <button
            className="ops-sort-btn"
            onClick={() => sortFetchDispatch(SORT_ORDERS.DESCENDING)}
          >
            <FaSortAmountDownAlt style={{ fontSize: "1.3vw" }} />
          </button>
        )}
        {liveAllAssignmentsData.sort_order === SORT_ORDERS.DESCENDING && (
          <button
            className="ops-sort-btn"
            onClick={() => sortFetchDispatch(SORT_ORDERS.ASCENDING)}
          >
            <FaSortAmountDown style={{ fontSize: "1.3vw" }} />
          </button>
        )}
      </div>
      <Link className="ops-btns deliver-btn" to="/addnew">
        Add New
      </Link>
      <form className="search-sort-form" onSubmit={handleSearch}>
        <input
          type="text"
          className="ops-dashboard-input search-input search-file"
          onChange={(e) => {
            ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
          }}
          ref={search_word}
          name="search"
          id="search"
          placeholder="Search..."
        />
        <button className="search-btn" type="submit">
          <FaSearch />
        </button>
      </form>
      {/* <div className="file-history-head">

        </div> */}
    </div>
  );
};

const FileHistory = () => {
  const dispatch = useDispatch();
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const liveAllAssignmentsData = useSelector(
    (state) => state.liveAllAssignmentsData,
  );
  const [hiddenItems, setHiddenItems] = useState([]);
  const { opsArchiveCount } = useContext(OpsArchiveCountContext);
  const { asgn_id } = useParams();
  const history = useHistory();
  const { searched } = useContext(DashboardSearchContext);

  const toggleMonth = (e, item) => {
    e.preventDefault();
    const monthEl = e.target.closest(".ops-month-container");

    if (
      monthEl
        .querySelector(".ops-month-arrow")
        .classList.contains("ops-month-arrow-active")
    ) {
      // Removing item
      setHiddenItems((prev) => {
        const index = prev.indexOf(item);
        if (index === -1) {
          return prev;
        }
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
      monthEl
        .querySelector(".ops-month-arrow")
        .classList.remove("ops-month-arrow-active");
      monthEl.querySelectorAll(".ops-sidebar-file").forEach((file) => {
        file.classList.remove("ops-sidebar-file-hide");
      });
    } else {
      // adding item
      setHiddenItems((prev) => {
        return [...prev, item];
      });
      monthEl
        .querySelector(".ops-month-arrow")
        .classList.add("ops-month-arrow-active");
      monthEl.querySelectorAll(".ops-sidebar-file").forEach((file) => {
        file.classList.add("ops-sidebar-file-hide");
      });
    }

    // monthEl.classList.toggle("client-month-container-hidden")    // simple Hidding without transition
  };

  const closeResponsiveSidebar = (e) => {
    e.preventDefault();
    if (!e.target.closest(".ops-sidebar-file")) return;
    document
      .querySelector(".ops-sidebar")
      ?.classList.remove("ops-sidebar-show");
    document
      .querySelector(".ops-account-sidebar")
      ?.classList.remove("ops-sidebar-show");
  };

  return (
    <div
      className="files-history"
      onClick={(e) => {
        closeResponsiveSidebar(e);
      }}
    >
      {liveAllAssignmentsData?.all_assignments?.length > 0 ? (
        liveAllAssignmentsData?.all_assignments?.map((item, idx) => (
          <div key={idx} className="ops-month-container">
            <div
              className="ops-sidebar-month-head"
              onClick={(e) => toggleMonth(e, item)}
            >
              <span className="ops-month">{item.title}</span>
              <FaAngleDown className="ops-month-arrow" />
            </div>
            <div className="ops-file-list">
              {item?.info?.map((file, idx) => {
                if (
                  parseInt(asgn_id) === file.id ||
                  liveAssignmentData?.file_details?.id === file.id
                ) {
                  return (
                    <File
                      key={idx}
                      file={file}
                      active={true}
                      hidden={hiddenItems.includes(item)}
                    />
                  );
                } else {
                  return (
                    <File
                      key={idx}
                      file={file}
                      active={false}
                      hidden={hiddenItems.includes(item)}
                    />
                  );
                }
              })}
            </div>
          </div>
        ))
      ) : (
        <span className="ops-file-history-empty">
          {liveAssignmentData?.error || "No Assignments Yet"}
        </span>
      )}
      {liveAllAssignmentsData.type_of_data ===
        TYPE_OF_ALL_ASSIGNMENTS_DATA.RECENT && (
        <div className="ops-file-history-see-more">
          <span
            className="ops-file-history-see-more-span"
            onClick={() =>
              dispatch(getLiveAllAssignmentsData({ month: "all" }))
            }
          >
            See more
          </span>
        </div>
      )}
      {liveAllAssignmentsData.type_of_data ===
        TYPE_OF_ALL_ASSIGNMENTS_DATA.ALL && (
        <div className="ops-file-history-see-more">
          <span
            className="ops-file-history-see-more-span"
            onClick={() => dispatch(getLiveAllAssignmentsData({}))}
          >
            See less
          </span>
        </div>
      )}
      {opsArchiveCount && (
        <div
          className="ops-dashboard-archive"
          onClick={() => {
            searched
              ? history.push("/ops-archive?search=" + searched)
              : history.push("/ops-archive");
          }}
        >
          See Archived ({opsArchiveCount})
          {/* <span className="client-month" id="client-dashboard-archive-month">See Archived ({opsArchiveCount})</span> */}
        </div>
      )}
    </div>
  );
};

// const settingDefaultActiveFile = () => {
//     if (Array.from(document.querySelectorAll('.ops-sidebar-file').length>0)){
//         // Removing active class from all other files
//         document.querySelectorAll('.ops-sidebar-file').forEach((el)=>{
//             el.classList.remove('ops-sidebar-file-active')
//             Array.from(el.querySelectorAll('.ops-file-stage'))?.map(el=>el.classList.remove('ops-file-stage-active'));
//         })
//         // adding active file to the clicked file
//         document.querySelectorAll('.ops-sidebar-file')[0]?.classList.add('ops-sidebar-file-active');
//         Array.from(document.querySelectorAll('.ops-sidebar-file')[0].querySelectorAll('.ops-file-stage'))?.map(el=>el.classList.add('ops-file-stage-active'));
//     }

// }

const OpsSideBar = () => {
  // New States using redux
  // const liveAllAssignmentsData = useSelector((state) => state.liveAllAssignmentsData);
  // const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const dispatch = useDispatch();

  const { asgn_id } = useParams();
  const [searched, setSearched] = useState("");
  const route = useContext(OpsRouteContext);
  // const { setOpsArchiveCount } = useContext(OpsArchiveCountContext);

  useEffect(() => {
    dispatch(getLiveAssignmentData({ id: asgn_id }));
  }, [asgn_id]);

  useEffect(() => {
    dispatch(getLiveAllAssignmentsData({}));
  }, []);

  // const fetchAssignmentDetails = (url,id) => {
  //     if (devEnv){
  //         setOpsDisplayedFile(dummy_monthly_last_assignment.last_assignment) // Setting Dummy Data
  //     } else {
  //         getAuthData(`${API_URL}/${url}/${id}/`)
  //         .then((res) => {
  //             if (res.success === true) {
  //                 setOpsDisplayedFile(res.last_assignment);
  //             }
  //         })
  //         .catch(err => {
  //             console.error(err);
  //         })
  //     }

  // }

  // const fetchSortedSearchAssignments = (url,sort=SORT_ORDERS.ASCENDING,search_keyword=null) => {
  //     // url will be based on seach and sort
  //     // because search can be only search or sorted search
  //     // for search dont change the asgn_id but just fetch a particular assignment, bcz the url of search will change
  //     if (devEnv){
  //         setOpsFilesArray(dummy_search_monthly_assignments.data)
  //     } else {
  //         getAuthData(`${API_URL}/${url}/?search=${search_keyword}&sort=${sort}`)
  //         .then((res) => {
  //             if (res.success === true) {
  //                 setSearched(search_keyword)
  //                 setOpsFilesArray(res.data);
  //                 setOpsArchiveCount(res.archive_count);
  //             }
  //         })
  //         .catch(err => {
  //             console.error(err);
  //         })
  //     }

  // }

  // 5 states for assignment fetch -->
  // all_recent--> fethes all recent assignments and last assignments details (when new file delivered, we need to load all assignments to list the new assignment and also show it in the details dashboard view)
  // all --> fetches all the assignments
  // assignment --> fetches just the assignment already in the dashboard (like when comments sent or sharing something like that)
  // other --> fetches based on normal circumstances like reload, assignment selection, page redirection etc
  // none -->  fetches nothing (for some cases where we dont want to fetch anyhting like when on account page)

  // 2 more states for fetching searched and sorted assignments
  // searched_words --> fethes the searcehd keyword based on sorting (default = ascending)

  // sort has 3 cases
  // all_recent_sort -->  fetches all the recent asignments based on sort
  // all_sort --> fetches all the asignments based on sort
  // sorted_search --> feteches a sorted search result (this will happen after the seach has already happened bcz normal search is ascending by default)

  // const fetchAssignmentState = false // this will be a  state to trigger referesh when new assignment is delivered, reloads both all assignments and last assignments

  // const fetchAssignmentsLogic = () => {
  //     let asgn_fetch_url = "api-ops/ops/v2/all-assignments";
  //     // if (fetchAssignmentState){ // reloads both all assignments and last assignments
  //     //     monthlyRecentAssignments()
  //     //     fetchAssignmentDetails(asgn_id,"api-ops/ops/v2/all-assignments")
  //     // } else if ()

  //     // } else {

  //     // }

  //     // OTHER case
  //     if (OpsFilesArray && asgn_id){  // if there are no assignments already in the assgn arr then fetch all assignemnts otherwise just fetch the last assgn
  //         fetchAssignmentDetails(asgn_fetch_url,asgn_id)
  //     } else if (!OpsFilesArray && asgn_id) { // for URL copy paste
  //         // fetchSortedMonthlyAssignmentsArr(asgn_fetch_url)
  //         fetchAssignmentDetails(asgn_fetch_url,asgn_id)
  //     } else if (OpsFilesArray && !asgn_id) { // for just loading the details of a last assignment
  //         fetchAssignmentDetails(asgn_fetch_url,asgn_id)
  //     } else { // for 1st load
  //         // fetchSortedMonthlyAssignmentsArr(asgn_fetch_url)  // for all --> fetchSortedMonthlyAssignmentsArr(asgn_fetch_url,"all")
  //         fetchAssignmentDetails(asgn_fetch_url,asgn_id)
  //     }
  // }

  // old way
  // const allAssignmentFetch = (asgn_id) => {
  //     getAuthData(`${API_URL}/api-ops/ops/v1/all-assignments/?id=${asgn_id}`)
  //     .then((res) => {
  //         if (res.success === true) {
  //             setOpsFilesArray(res.data);
  //             setOpsDisplayedFile(res.last_assignment);
  //             setOpsArchiveCount(null)

  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // const lastAssignmentFetch = (asgn_id) => {
  //     getAuthData(`${API_URL}/api-ops/ops/v1/all-assignments/${asgn_id}/`)
  //     .then((res) => {
  //         if (res.success === true) {
  //             setOpsDisplayedFile(res.last_assignment);
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // const lastTestAssignmentFetch = (asgn_id) => {
  //     getAuthData(`${API_URL}/api-ops/ops/v2/all-assignments/${asgn_id}/`)
  //     .then((res) => {
  //         if (res.success === true) {
  //             console.log(res.last_assignment);
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // const monthlyAllAssignment = (month) => {
  //     getAuthData(`${API_URL}/api-ops/ops/v2/all-assignments/?month=${"all"}`)
  //     .then((res) => {
  //         if (res.success === true) {
  //             console.log(res.data);
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // const monthlyAssignment = (month) => {
  //     getAuthData(`${API_URL}/api-ops/ops/v2/all-assignments/?month=${undefined}`)
  //     .then((res) => {
  //         if (res.success === true) {
  //             console.log(res.data);
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // const fetchAssignments = () => {
  //     if (OpsFilesArray && asgn_id){  // if there are no assignments already in the assgn arr then fetch all assignemnts otherwise just fetch the last assgn
  //         lastAssignmentFetch(asgn_id)
  //     } else {
  //         allAssignmentFetch(asgn_id)
  //     }
  // }

  // this useeffect wont happen coz now when asgn chngs no new data is loaded in the asgn
  // also this is due to the fact tat we dont know if the asgn is in recent or not
  // so by default we always just call the recent asgn
  // useEffect(() => {
  //     if (devEnv){
  //         setOpsFilesArray(dummy_all_assignemnts.data) // Setting Dummy Data
  //         setOpsDisplayedFile(dummy_all_assignemnts.last_assignment);
  //         setOpsArchiveCount(null)
  //         return
  //     }

  //     fetchAssignments();

  // }, [asgn_id])

  return (
    <DashboardSearchContext.Provider value={{ searched, setSearched }}>
      <div
        className={`ops-sidebar ops-sidebar-mobile ${
          route === OPS_ROUTES.ASSIGNMENTS && "ops-sidebar-show"
        }`}
        id="ops-file-sidebar"
      >
        <FileHistoryHead />
        <FileHistory />
      </div>
    </DashboardSearchContext.Provider>
  );
};

export { OpsSideBar };
