import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  getAuthData,
  postAuthData,
  putAuthData,
} from "../../../helpers/request";
import { TAB_CHOICE } from "../../../utils/CustomInputs/nameSpaces";
import {
  createDefaultEntityOptionValue,
  createOptionValue,
  createUSerEntityOptionsArr,
  createUserOptionsArr,
  initialState,
} from "./common";

// temp changes
// const token = "jbDLY7vcpZuGRdA8j3mmnlvkdEiFIf";

export const getUserDefaultEntitiesOptions = createAsyncThunk(
  "user-data/getUserDefaultEntitiesOptions",
  async (_, thunkAPI) => {
    let url = `${
      process.env.REACT_APP_NEW_REQUESTER_API_URL
    }/api-req/requester/v1/default-entity/?cid=${
      thunkAPI.getState().liveClientData?.requester_details?.id
    }`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        return createUSerEntityOptionsArr(res.data);
      } else {
        return thunkAPI.rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getUserCountryOptions = createAsyncThunk(
  "user-data/getUserCountryOptions",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/country/`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        return createUserOptionsArr(res.country);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getUserCityOptions = createAsyncThunk(
  "user-data/getUserCityOptions",
  async ({ country = "India" }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/country/${country}`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        return createUserOptionsArr(res.city);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const postUserCreateFormData = createAsyncThunk(
  "user-data/postUserCreateFormData",
  async ({ data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/user/`;
    try {
      const res = await postAuthData(url, data); // temp changes
      if (res.success) {
        thunkAPI.dispatch(resetUserFormData());
        return res;
      } else {
        // console.log(res?.message);
        toast.error(res?.message);
        return thunkAPI.rejectWithValue({ err: "Failed to create user." });
      }
    } catch (err) {
      return { err: "Failed to create user." };
    }
  },
);

export const putUserUpdateFormData = createAsyncThunk(
  "user-data/putUserUpdateFormData",
  async ({ user_id, data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/user/${user_id}/`;
    try {
      const res = await putAuthData(url, data); // temp changes
      if (res.success) {
        thunkAPI.dispatch(resetUserFormData());
        return res;
      } else {
        console.log(res?.message);
        toast.error(res?.message);
        return thunkAPI.rejectWithValue({ err: "Failed to update user." });
      }
    } catch (err) {
      return { err: "Failed to create user." };
    }
  },
);

export const getEditUserInfo = createAsyncThunk(
  "user-data/getEditUserInfo",
  async ({ id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/user/${id}/`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        // console.log(res.data);
        return res?.data;
      } else {
        return thunkAPI.rejectWithValue({ err: res?.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

// =================== USER lIST =================== //

export const getUserList = createAsyncThunk(
  "user-data/getUserList",
  async ({ id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/user/?cid=${id}`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        return res;
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

// ========================== SLICE ======================

const userDataSlice = createSlice({
  name: "user-data",
  initialState,
  reducers: {
    // TAB SWITCH
    toggleUserTab: (state) => {
      if (state.tab === TAB_CHOICE.FORM) {
        state.tab = TAB_CHOICE.LIST;
      } else if (state.tab === TAB_CHOICE.LIST) {
        state.tab = TAB_CHOICE.FORM;
      }
    },
    openUserForm: (state) => {
      state.tab = TAB_CHOICE.FORM;
    },
    openUserList: (state) => {
      state.tab = TAB_CHOICE.LIST;
    },
    openEditUserForm: (state, { payload }) => {
      state.tab = TAB_CHOICE.FORM;
      state.edit_user_id = payload.id;
    },
    // RESET DATA
    resetUserFormData: (state) => {
      state.user_form_data = initialState.user_form_data;
    },
    resetUserTab: () => initialState,
    // form
    addUserName: (state, { payload }) => {
      state.user_form_data.user_name = payload;
    },
    // addLastUserName: (state, { payload }) => {
    //   state.user_form_data.last_name = payload;
    // },
    addUserEmail: (state, { payload }) => {
      state.user_form_data.email = payload;
    },
    addUserAddressCountry: (state, { payload }) => {
      state.user_form_data.country = payload;
      state.user_form_data.city = null;
    },
    addUserDesignation: (state, { payload }) => {
      state.user_form_data.user_designation = payload;
    },
    addUserJobFunc: (state, { payload }) => {
      state.user_form_data.user_job_function = createOptionValue(payload);
    },
    addUserDefaultEntity: (state, { payload }) => {
      state.user_form_data.default_entity = payload;
    },
    addUserPhone: (state, { payload }) => {
      state.user_form_data.phone = payload;
    },
    addUserAddressCity: (state, { payload }) => {
      state.user_form_data.city = payload;
    },
    addUserIndustry: (state, { payload }) => {
      state.user_form_data.user_industry = createOptionValue(payload);
    },
    addUserCategory: (state, { payload }) => {
      state.user_form_data.user_category = createOptionValue(payload);
    },
  },
  extraReducers: {
    // Fetching Default Entities Options
    [getUserDefaultEntitiesOptions.pending]: (state) => {
      state.user_form_data.form_options.default_entities.loading = true;
    },
    [getUserDefaultEntitiesOptions.fulfilled]: (state, { payload }) => {
      state.user_form_data.form_options.default_entities.loading = false;
      state.user_form_data.form_options.default_entities.list = payload;
    },
    [getUserDefaultEntitiesOptions.rejected]: (state, action) => {
      state.user_form_data.form_options.default_entities.loading = false;
      state.user_form_data.form_options.default_entities.error = action.message;
    },
    // Fetching Country Options
    [getUserCountryOptions.pending]: (state) => {
      state.user_form_data.form_options.country.loading = true;
    },
    [getUserCountryOptions.fulfilled]: (state, { payload }) => {
      state.user_form_data.form_options.country.loading = false;
      state.user_form_data.form_options.country.list = payload;
    },
    [getUserCountryOptions.rejected]: (state, action) => {
      state.user_form_data.form_options.country.loading = false;
      state.user_form_data.form_options.country.error = action.message;
    },

    // Send User Create Form Data
    [postUserCreateFormData.pending]: (state) => {
      state.user_form_data.loading = true;
    },
    [postUserCreateFormData.fulfilled]: (state) => {
      state.user_form_data.loading = false;
      state.user_form_data = initialState.user_form_data; // resets the user form
      state.tab = TAB_CHOICE.LIST; // sends the user to the list page
    },
    [postUserCreateFormData.rejected]: (state, action) => {
      state.user_form_data.loading = false;
      state.user_form_data.error = action.message;
    },

    // Updating the user
    [putUserUpdateFormData.pending]: (state) => {
      state.user_form_data.loading = true;
    },
    [putUserUpdateFormData.fulfilled]: (state) => {
      state.user_form_data.loading = false;
      state.user_form_data = initialState.user_form_data; // resets the user form
      state.tab = TAB_CHOICE.LIST; // sends the user to the list page
    },
    [putUserUpdateFormData.rejected]: (state, action) => {
      state.user_form_data.loading = false;
      state.user_form_data.error = action.message;
    },

    // Fetch Edit User Info
    [getEditUserInfo.pending]: (state) => {
      state.user_form_data.loading = true;
    },

    [getEditUserInfo.fulfilled]: (state, { payload }) => {
      state.user_form_data.loading = false;
      state.user_form_data.user_id = payload?.id;
      // state.user_form_data.first_name = payload?.first_name;
      state.user_form_data.user_name = payload?.username;
      state.user_form_data.email = payload?.email;
      state.user_form_data.phone = [];
      payload?.phone_number_list?.forEach((phoneNum) => {
        let phone = state.user_form_data.phone.some(
          (cur) => cur.label === phoneNum,
        );

        if (!phone) {
          state.user_form_data.phone = [
            ...state.user_form_data.phone,
            createOptionValue(phoneNum),
          ];
        }

        state.user_form_data.phone = [...state.user_form_data.phone];
      });
      state.user_form_data.country = createOptionValue(payload?.country);
      state.user_form_data.city = createOptionValue(payload?.city);
      state.user_form_data.user_category = createOptionValue(payload?.category);
      state.user_form_data.user_designation = payload?.designation;
      state.user_form_data.user_industry = createOptionValue(payload?.industry);
      state.user_form_data.user_job_function = createOptionValue(
        payload?.job_function,
      );
      state.user_form_data.default_entity = createDefaultEntityOptionValue(
        payload.default_entity,
      );
      state.tab = TAB_CHOICE.FORM;
      state.edit_user_id = payload?.id;
    },

    [getEditUserInfo.rejected]: (state, action) => {
      state.user_form_data.loading = false;
      state.user_form_data.error = action.message;
    },

    // Fetching City Options
    [getUserCityOptions.pending]: (state) => {
      state.user_form_data.form_options.city.loading = true;
    },
    [getUserCityOptions.fulfilled]: (state, { payload }) => {
      state.user_form_data.form_options.city.loading = false;
      state.user_form_data.form_options.city.list = payload;
    },
    [getUserCityOptions.rejected]: (state, action) => {
      state.user_form_data.form_options.city.loading = false;
      state.user_form_data.form_options.city.error = action.message;
    },

    // Fetch User List Items
    [getUserList.pending]: (state) => {
      state.user_list_data.loading = true;
    },
    [getUserList.fulfilled]: (state, { payload }) => {
      state.user_list_data.loading = false;
      state.user_list_data.list_data = payload;
    },
    [getUserList.rejected]: (state, action) => {
      state.user_list_data.loading = false;
      state.user_list_data.error = action.message;
    },
  },
});

export const {
  //user tab switch
  toggleUserTab,
  openUserForm,
  openUserList,
  openEditUserForm,
  // reset user form data
  resetUserFormData,
  resetUserTab,
  // Form Details ACTIONS
  // addFirstUserName,
  // addLastUserName,
  addUserName,
  addUserDesignation,
  addUserEmail,
  addUserPhone,
  addUserDefaultEntity,
  addUserAddressCountry,
  addUserAddressCity,
  addUserCategory,
  addUserIndustry,
  addUserJobFunc,
} = userDataSlice.actions;

export default userDataSlice.reducer;
