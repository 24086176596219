import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import { getAuthData } from "../../../helpers/request";
import { SORT_TYPE } from "./common";
import { TYPE_OF_ALL_REQUESTERS_DATA } from "./common";
import { initialState } from "./common";

// temp changes
// const token = "jbDLY7vcpZuGRdA8j3mmnlvkdEiFIf";

export const getLiveAllClientsData = createAsyncThunk(
  "liveAllClientsData/getLiveAllClientsData",
  async (
    { sort = SORT_TYPE.ASCENDING, month = undefined, search_keyword = null },
    { rejectWithValue },
  ) => {
    // URL creation for all requesters
    let query_params;
    let url;
    if (search_keyword) {
      query_params = `q=${search_keyword}`;
      url = "api-req/requester/v1/search";
    } else {
      query_params = `month=${month}&sort=${sort}`;
      url = "api-req/requester/v1/company";
    }
    query_params = `${query_params}`; // adding sort in querry params
    let assignment_url_with_query_param = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/${url}/?${query_params}`; // url to be fetched assignments on

    try {
      // temp changes
      const res = await getAuthData(assignment_url_with_query_param);
      if (res.success) {
        let type_of_data;
        if (month) {
          type_of_data = TYPE_OF_ALL_REQUESTERS_DATA.ALL;
        } else if (!month) {
          type_of_data = TYPE_OF_ALL_REQUESTERS_DATA.RECENT;
        }
        if (search_keyword) {
          type_of_data = TYPE_OF_ALL_REQUESTERS_DATA.SEARCH;
        }
        const all_requesters_data = {
          all_requesters_data: res.data,
          type_of_data: type_of_data,
          sort_order: sort,
        };
        return all_requesters_data;
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

const liveAllClientsDataSlice = createSlice({
  name: "live-all-clients-data",
  initialState,
  reducers: {},
  extraReducers: {
    [getLiveAllClientsData.pending]: (state) => {
      state.loading = true;
    },
    [getLiveAllClientsData.fulfilled]: (state, action) => {
      state.loading = false;
      state.all_requesters_data = action.payload.all_requesters_data;
      state.type_of_data = action.payload.type_of_data;
      state.sort_order = action.payload.sort_order;
    },
    [getLiveAllClientsData.rejected]: (state, action) => {
      state.loading = false;
      state.success = action.message;
    },
  },
});

export default liveAllClientsDataSlice.reducer;
