import { TAB_CHOICE } from "../../../utils/CustomInputs/nameSpaces";

export const invoice_item_details_data = {
  invoice_item_head_info: {
    loading: false,
    data: null,
    error: null,
  },
  invoice_item_data_info: {
    loading: false,
    data: null,
    error: null,
  },
  open: false,
};

export const invoice_tax_options = {
  tax_type: null,
  perc: 0,
};

export const service_price_data = {
  id: null,
  service: null,
  sub_service: {
    selected_option: null,
    options: { list: [], loading: false, error: null },
  },
  list_price: 0,
  msla_price: 0,
  invoice_price: 0,
  discount: 0,
  cum_discount: 0,
  corporate_discounted_price: 10,
};

export const invoice_tax_type_options = [
  { label: "VAT", value: "vat" },
  { label: "GST", value: "gst" },
];

export const currency_options = [
  { label: "USD", value: "DOLLAR" },
  { label: "EURO", value: "EURO" },
  { label: "AED", value: "AED" },
];

export const discount_options = [
  { label: "Offer", value: "Offer" },
  { label: "Festive Offer", value: "Festive Offer" },
];

export const getOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur.country || cur.city, value: cur.country || cur.city };
  });
  return options_format_data;
};

export const createInvoiceOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur, value: cur };
  });
  return options_format_data;
};

export const createSubServiceInvoiceOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur.display_txt, value: cur.id, unit: cur?.unit };
  });
  return options_format_data;
};

export const createInvoiceEntityOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur.entity_code, value: cur.id };
  });
  return options_format_data;
};

export const createInvoiceOptionValue = (val) => {
  return { label: val, value: val };
};

export const createInvoiceSubServiceOptionValue = (val) => {
  return { label: val.display_txt, value: val.id, unit: val?.unit };
};

export const createInvoiceDefaultEntityOptionValue = (val) => {
  return { label: val.entity_code, value: val.id };
};

export const initialState = {
  invoice_form_data: {
    invoice_id: null,
    invoice_code: null,
    pricing_details: {
      valid_thorugh: {
        startDate: null,
        endDate: null,
        key: "pricing-details-valid-through-date-range",
      },
      taxes: [invoice_tax_options],
      default_entity: null,
      currency: currency_options[0],
      discount_type: [],
      comments: null,
    },
    service_prices: [service_price_data],
    form_options: {
      discount_types: { list: discount_options, loading: false, error: null },
      default_entities: { list: [], loading: false, error: null },
      currency: { list: currency_options, loading: false, error: null },
      tax_type: { list: invoice_tax_type_options, loading: false, error: null },
      service: { list: [], loading: false, error: null },
    },
    loading: false,
    error: null,
  },
  invoice_list_data: { list: [], error: null, loading: false },
  tab: TAB_CHOICE.LIST,
  edit_invoice_id: null,
  success: null,
};
