import React, { useContext, useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa";
import {
  DeliveryPageContext,
  DELIVERY_TYPE_PAGE,
  FileGroupsContext,
  LoaderContext,
  uploadFileInitialState,
} from "../../../OpsComponent";
import { ApiContext, devEnv } from "../../../..";
import {
  EXT_LINK_TEXT_CHOICES,
  UpdateAddNewFileDataContext,
  UpdateAsgnCombinationDataContext,
  UpdateAsgnCombinationsContext,
  UpdateAsgnOrgCombinationDataContext,
} from "../../UpdateDelivery";
import { postAuthData } from "../../../../helpers/request";
import { useParams } from "react-router-dom";

// Component Imports
import UpdateBatchDeliveryHead from "../../components/UpdateBatchDeliveryHead/UpdateBatchDeliveryHead";
import UpdateBatchDeliveryCard from "../../components/UpdateBatchDeliveryCard/UpdateBatchDeliveryCard";

// import dummy_update_delivery from "../../../../archive/test_data/dummy_data-v1/DeliveryDummyData/dummy_update_delivery.json";
import dummy_external_update_delivery from "../../../../archive/test_data/dummy_data-v1/DummyExternalUserData/ExternalDeliveryDummyData/dummy_ext_update_delivery.json";

//Style
import "./UpdateAddDelivery.css";
import { Link } from "react-router-dom";
import { CLIENT_USER_TYPE } from "../../../../utils/externalClientUsers";
import { isValidHttpUrl } from "../../../../utils/linkCheck";
import { useSelector } from "react-redux";

const BatchDeliveryInfoContainer = () => {
  const { asgn_id } = useParams();
  const API_URL = useContext(ApiContext);
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const { addNewFileData, setAddNewFileData } = useContext(
    UpdateAddNewFileDataContext,
  ); // this is the data fetched from the create page i.e. the assignments data that we submitted.
  const { asgnCombinations, setAsgnCombinations } = useContext(
    UpdateAsgnCombinationsContext,
  ); // combination for cards
  const { combinationData, setCombinationData } = useContext(
    UpdateAsgnCombinationDataContext,
  ); // combination of data on cards
  const { setLoader } = useContext(LoaderContext);
  const { setOriginalCombinationData } = useContext(
    UpdateAsgnOrgCombinationDataContext,
  );

  const assignmentCombinator = (asgnIdsArr) => {
    let batch_data_obj = {
      // assignment_ids: addNewFileData.data.filter((file_data)=>asgnIdsArr.includes(file_data.assignment_id)).map((cur)=>{return cur.assignment_id}),  // this will cause the order of the selcted ids to be in the order priotised to addNewFileData but we want it to be like what is in combinedAsgns
      // assignment_names : addNewFileData.data.filter((file_data)=>asgnIdsArr.includes(file_data.assignment_id)).map((cur)=>{return cur.assignment_name}),
      // assignment_services: addNewFileData.data.filter((file_data)=>asgnIdsArr.includes(file_data.assignment_id)).map((cur)=>{return cur.services}).flat()
      assignment_ids: asgnIdsArr
        ?.map((cur) => {
          return addNewFileData.data.filter(
            (file_data) => file_data.assignment_id === cur,
          );
        })
        ?.flat()
        ?.map((cur) => {
          return cur.assignment_id;
        }),
      assignment_names: asgnIdsArr
        ?.map((cur) => {
          return addNewFileData.data.filter(
            (file_data) => file_data.assignment_id === cur,
          );
        })
        ?.flat()
        ?.map((cur) => {
          return cur.assignment_name;
        }),
      assignment_services: asgnIdsArr
        ?.map((cur) => {
          return addNewFileData.data.filter(
            (file_data) => file_data.assignment_id === cur,
          );
        })
        ?.flat()
        ?.map((cur) => {
          return cur.services;
        })
        .flat(),
    };
    return batch_data_obj;
  };

  const groupExternalAsgnCombinator = (groupAsgnIdArr) => {
    let combined_data_obj, new_asgn_combined_data;
    if (
      groupAsgnIdArr?.default_assignments &&
      groupAsgnIdArr?.new_assignments
    ) {
      // default_assignments is presnt & new assignment will always be required
      let group_combined_data = addNewFileData.group_data.filter((cur) => {
        return cur.assignment_ids.includes(
          groupAsgnIdArr.default_assignments[0],
        );
      }); // brings the group that contains the data for the assignment 0 of default assignments of default_group
      new_asgn_combined_data = assignmentCombinator(
        groupAsgnIdArr.new_assignments,
      );
      let combined_data = [group_combined_data[0], new_asgn_combined_data]; // finding out the data of the default groups and appending it to the new_asgn_groups data
      combined_data_obj = {
        assignment_ids: [
          ...combined_data[0].assignment_ids,
          ...combined_data[1].assignment_ids,
        ],
        assignment_names: [
          ...combined_data[0].assignment_names,
          ...combined_data[1].assignment_names,
        ],
        assignment_services: [
          ...combined_data[0].assignment_services,
          ...combined_data[1].assignment_services,
        ],
        default_current_deliveries: [...combined_data[0].current_deliveries],
        committed_delivery: combined_data[0].committed_delivery,
        email_attachment: combined_data[0].email_attachment,
        delivery_notes: combined_data[0].delivery_notes,
        external_link_text_choice:
          addNewFileData?.external_type || EXT_LINK_TEXT_CHOICES.LINK,
        external_text: combined_data[0]?.external_text,
        external_link: combined_data[0]?.external_link,
        gid: combined_data[0].gid,
      };
    } else if (
      !groupAsgnIdArr?.default_assignments &&
      groupAsgnIdArr?.new_assignments
    ) {
      // in case only default assignment is not present
      new_asgn_combined_data = assignmentCombinator(
        groupAsgnIdArr.new_assignments,
      );
      combined_data_obj = {
        assignment_ids: new_asgn_combined_data.assignment_ids,
        assignment_names: new_asgn_combined_data.assignment_names,
        assignment_services: new_asgn_combined_data.assignment_services,
        default_current_deliveries: [],
        committed_delivery: new Date().toISOString(),
        delivery_notes: "",
        external_link_text_choice:
          addNewFileData?.external_type || EXT_LINK_TEXT_CHOICES.LINK,
        external_link: "",
        external_text: "",
      };
    }

    return combined_data_obj;
  };

  const groupDefaultAsgnCombinator = (groupAsgnIdArr) => {
    let combined_data_obj, new_asgn_combined_data;
    if (
      groupAsgnIdArr?.default_assignments &&
      groupAsgnIdArr?.new_assignments
    ) {
      // default_assignments is presnt & new assignment will always be required
      let group_combined_data = addNewFileData.group_data.filter((cur) => {
        return cur.assignment_ids.includes(
          groupAsgnIdArr.default_assignments[0],
        );
      }); // brings the group that contains the data for the assignment 0 of default assignments of default_group
      new_asgn_combined_data = assignmentCombinator(
        groupAsgnIdArr.new_assignments,
      );
      let combined_data = [group_combined_data[0], new_asgn_combined_data]; // finding out the data of the default groups and appending it to the new_asgn_groups data
      combined_data_obj = {
        assignment_ids: [
          ...combined_data[0].assignment_ids,
          ...combined_data[1].assignment_ids,
        ],
        assignment_names: [
          ...combined_data[0].assignment_names,
          ...combined_data[1].assignment_names,
        ],
        assignment_services: [
          ...combined_data[0].assignment_services,
          ...combined_data[1].assignment_services,
        ],
        default_current_deliveries: [...combined_data[0].current_deliveries],
        committed_delivery: combined_data[0].committed_delivery,
        // email_attachment: combined_data[0].email_attachment,
        delivery_notes: combined_data[0].delivery_notes,
        gid: combined_data[0].gid,
      };
    } else if (
      !groupAsgnIdArr?.default_assignments &&
      groupAsgnIdArr?.new_assignments
    ) {
      // in case only default assignment is not present
      new_asgn_combined_data = assignmentCombinator(
        groupAsgnIdArr.new_assignments,
      );
      combined_data_obj = {
        assignment_ids: new_asgn_combined_data.assignment_ids,
        assignment_names: new_asgn_combined_data.assignment_names,
        assignment_services: new_asgn_combined_data.assignment_services,
        default_current_deliveries: [],
        committed_delivery: new Date().toISOString(),
        delivery_notes: "",
      };
    }

    return combined_data_obj;
  };

  const groupAsgnCombinator = (cur, i) => {
    switch (addNewFileData.delivery_user_type) {
      case CLIENT_USER_TYPE.EXTERNAL:
        return groupExternalAsgnCombinator(cur, i);
      case CLIENT_USER_TYPE.DEFAULT:
        return groupDefaultAsgnCombinator(cur, i);
      default:
        return groupDefaultAsgnCombinator(cur, i);
    }
  };

  const createCombinationData = () => {
    // this will be the state that will be changed according to the input
    setCombinationData(
      asgnCombinations.map((cur, i) => {
        return {
          group_id: i,
          ...groupAsgnCombinator(cur, i),
          source_files: null,
          target_files: null,
          interim_output: false,
        };
      }),
    );
    // storing the original data received from backend here for analysing and validating info like (email_attachment)
    setOriginalCombinationData(
      asgnCombinations.map((cur, i) => {
        return {
          group_id: i,
          ...groupAsgnCombinator(cur, i),
          source_files: null,
          target_files: null,
          interim_output: false,
        };
      }),
    );
    // switch (addNewFileData.delivery_user_type) {
    //   case CLIENT_USER_TYPE.EXTERNAL:
    //     setCombinationData(
    //       asgnCombinations.map((cur, i) => {
    //         return {
    //           group_id: i,
    //           ...groupAsgnCombinator(cur, i),
    //           source_files: null,
    //           target_files: null,
    //           interim: false,
    //         };
    //       }),
    //     );
    //     break;
    //   case CLIENT_USER_TYPE.DEFAULT:
    //     setCombinationData(
    //       asgnCombinations.map((cur, i) => {
    //         return {
    //           group_id: i,
    //           ...groupAsgnCombinator(cur, i),
    //           source_files: null,
    //           target_files: null,
    //           interim: false,
    //         };
    //       }),
    //     );
    //     break;

    //   default:
    //     setCombinationData(
    //       asgnCombinations.map((cur, i) => {
    //         return {
    //           group_id: i,
    //           ...groupAsgnCombinator(cur, i),
    //           source_files: null,
    //           target_files: null,
    //           interim: false,
    //         };
    //       }),
    //     );
    //     break;
    // }
  };

  const createUpdateBatchData = () => {
    // creating data based on the combinations
    setLoader(true);
    /*
      combinations must be made based on the default groups
      new_assignments must be added in the combinations
      any updates to the combinations must be an update in the new_assignments of asignments combinations and
      the create combinations function must run everytime there is a change in the assignment combinations change
      Now try to add and remove files from the new_assignments list of combinations in asgnCombinations and update the combination data based on that
    */
    if (!asgnCombinations) {
      // if asgnCOmbinations are already present then it means it came through update create page then we must load that daa into the states
      if (devEnv) {
        // ===== default delivery update
        // setAddNewFileData(dummy_update_delivery) // loading file data --> single single assignment Data
        // setAsgnCombinations(dummy_update_delivery.default_groups); // setting the combinations of groups --> default_group
        // ===== External update delivery
        setAddNewFileData(dummy_external_update_delivery);
        setAsgnCombinations(dummy_external_update_delivery.default_groups);
        setLoader(false);
      } else {
        // getRequestAPICallTofetchUpdateData
        // getAuthData(`${API_URL}/api-ops/ops/v1/update-detail-assignments/${liveAssignmentData?.file_details?.data?.file_details?.data?.file_details?.id}`)
        postAuthData(`${API_URL}/api-ops/ops/v1/update-detail-assignments/`, {
          delivery_id: liveAssignmentData?.file_details?.batch_id,
          assignment_id: null,
        })
          .then((res) => {
            if (res.success === true) {
              setAddNewFileData(res); // loading file data
              setAsgnCombinations(res.default_groups); // setting the combinations of groups
            } else {
              alert(
                res?.message || "Something went wrong. Please try again later!",
              );
            }
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
            alert("Cant upload file. Please try again.");
          });
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!liveAssignmentData?.file_details?.batch_id) return;
    createUpdateBatchData(); // getting the update data on first load
  }, [asgn_id, liveAssignmentData?.file_details]);

  useEffect(() => {
    asgnCombinations && createCombinationData();
  }, [asgnCombinations]);

  return (
    <section className="batch-delivery-info-container card-layout">
      <div className="batch-delivery-info-head">
        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Requester Name</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.requester_name}
          </span>
        </div>

        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Client Code</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.client_code}
          </span>
        </div>

        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Charge Code</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.client_charge_code}
          </span>
        </div>

        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Entity Code</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.entity_code}
          </span>
        </div>
      </div>

      <section className="batch-delivery-input-section">
        <div className="batch-delivery-input-section-head">Upload</div>
        <section className="batch-delivery-card-container card-layout">
          {/* {asgnCombinations?.map((cur) =>{return assignmentCombinator(cur)})?.map((batch_data_obj,i)=>{ */}
          {combinationData?.map((batch_data_obj, i) => {
            return (
              <UpdateBatchDeliveryCard
                key={i}
                batch_data_obj={batch_data_obj}
                linkTextChoice={addNewFileData?.external_type}
                asgn_options={addNewFileData.data.map(
                  (cur) => cur.assignment_id,
                )}
                asgnCombinations={asgnCombinations}
                setAsgnCombinations={setAsgnCombinations}
                combinationData={combinationData}
                setCombinationData={setCombinationData}
                delivery_user_type={addNewFileData.delivery_user_type}
              />
            );
          })}
        </section>
      </section>
    </section>
  );
};

const BatchDeliveryContainer = () => {
  const { setDeliverPage } = useContext(DeliveryPageContext);
  const { asgnCombinations } = useContext(UpdateAsgnCombinationsContext); // combination for cards
  const { combinationData } = useContext(UpdateAsgnCombinationDataContext);
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const { addNewFileData } = useContext(UpdateAddNewFileDataContext);
  const { originalCombinationData } = useContext(
    UpdateAsgnOrgCombinationDataContext,
  );

  const ALERT_MSG_TYPES = {
    SOURCE_FILE_ERROR: "source-file-type",
    SOURCE_FILE_EMPTY_ERROR: "source-file-empty-type",
    TARGET_FILE_EMPTY_ERROR: "target-file-empty-type",
    TARGET_FILE_ERROR: "target-file-type",
    EMAIL_ATTACHED_TARGET_FILE_ERROR: "email-attached-target-file-error",
    SOURCE_FILE_SIZE_ERROR: "source-file-size-type",
    TARGET_FILE_SIZE_ERROR: "target-file-size-type",
    EXTERNAL_LINK_ERROR: "external-link-type",
    EXTERNAL_CODE_ERROR: "external-code-type",
    COMMITTED_DELIVERY: "committed-delivery",
    NO_TARGET_FILE_ERROR: "no-target-file-error",
  };

  const alertMsgFn = (type, group_id) => {
    const msg_types = {
      [ALERT_MSG_TYPES.SOURCE_FILE_ERROR]: `Please re-enter source files in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.SOURCE_FILE_EMPTY_ERROR]: `Source files were removed in ${
        group_id + 1
      }. Please re-enter the files.`,
      [ALERT_MSG_TYPES.TARGET_FILE_ERROR]: `Please re-enter target files in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.TARGET_FILE_EMPTY_ERROR]: `Target files were removed in ${
        group_id + 1
      }. Please re-enter the files.`,
      [ALERT_MSG_TYPES.EMAIL_ATTACHED_TARGET_FILE_ERROR]: `To send file in email you need to enter a target file in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.SOURCE_FILE_SIZE_ERROR]: `File size of a source file in group ${
        group_id + 1
      } is too large to send in email.`,
      [ALERT_MSG_TYPES.TARGET_FILE_SIZE_ERROR]: `Total file size of target files in group ${
        group_id + 1
      } is too large to send in email.`,
      [ALERT_MSG_TYPES.EXTERNAL_LINK_ERROR]: `Please check and enter a valid link in group ${
        group_id + 1
      }.\nFormat of link is: \nhttp://www.google.com\nor\nhttps://www.google.com`,
      [ALERT_MSG_TYPES.EXTERNAL_CODE_ERROR]: `Please enter the external code in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.COMMITTED_DELIVERY]: `Please enter the committed delivery in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.NO_TARGET_FILE_ERROR]: `No Target files uploaded. Please enter atleast one file to target.`,
    };
    return msg_types[type];
  };

  const validateInput = (data) => {
    let validation = { valid: true, group: null, err_msg: null };
    data?.forEach((group_obj, i) => {
      // source_file validation
      if (asgnCombinations[i]?.new_assignments?.length > 0) {
        // only checks validation if there is a new assignment in the group
        // source file validation in new assignments
        if (!group_obj?.source_files) {
          validation.valid = false;
          validation.group = i;
          validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.SOURCE_FILE_ERROR, i);
        } else {
          group_obj?.source_files?.forEach((file) => {
            if (!file) {
              validation.valid = false;
              validation.group = i;
              validation.err_msg = alertMsgFn(
                ALERT_MSG_TYPES.SOURCE_FILE_ERROR,
                i,
              );
            }
          });
        }

        //target file validation in new assignments
      } else {
        // for default groups with no new assignments
      }
    });

    // checking if no target files are present, since it must not go to the delivery page as it would try to upload files
    const no_target_files_check = data?.every((group_obj) => {
      return !group_obj?.target_files;
    });
    if (no_target_files_check) {
      validation.valid = false;
      validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.NO_TARGET_FILE_ERROR);
    }

    // this validation is only for external clients
    // if (addNewFileData?.delivery_user_type === CLIENT_USER_TYPE.EXTERNAL) {
    //   data?.forEach((group_obj, i) => {
    // VALIDATION: Checks for all target files and external links
    // // ===== target_file validation
    // if (!group_obj?.target_files){
    //   validation.valid=false
    //   validation.group=i
    // validation.err_msg = alertMsgFn(
    //   ALERT_MSG_TYPES.TARGET_FILE_ERROR,
    //   i,
    // );
    // } else {
    //   group_obj?.target_files?.forEach((file)=>{
    //     if (!file){
    //       validation.valid=false
    //       validation.group=i
    // validation.err_msg = alertMsgFn(
    //   ALERT_MSG_TYPES.TARGET_FILE_ERROR,
    //   i,
    // );
    //     }
    //   })
    // }
    // // ===== external file validation
    // if (group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.LINK){
    //   // ===== external file link validation
    //   if (!group_obj?.external_link || !isValidHttpUrl(group_obj?.external_link)){
    //     validation.valid=false
    //     validation.group=i
    // validation.err_msg = alertMsgFn(
    //   ALERT_MSG_TYPES.EXTERNAL_LINK_ERROR,
    //   i,
    // );
    //   }
    // } else if (group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.TEXT) {
    //   // ===== external file text validation
    //   if (!group_obj?.external_text || group_obj?.external_text===""){
    //     validation.valid=false
    //     validation.group=i
    // validation.err_msg = alertMsgFn(
    //   ALERT_MSG_TYPES.EXTERNAL_CODE_ERROR,
    //   i,
    // );
    //   }
    // }
    // });
    // }

    // Validation only for external users
    if (addNewFileData?.delivery_user_type === CLIENT_USER_TYPE.EXTERNAL) {
      data?.forEach((group_obj, i) => {
        // VALIDATION: only if we have target files in the group then we check for the external link or text
        if (group_obj?.target_files) {
          group_obj?.target_files?.forEach((file) => {
            if (file) {
              // ===== external file validation
              if (
                group_obj.external_link_text_choice ===
                EXT_LINK_TEXT_CHOICES.LINK
              ) {
                // ===== external file link validation
                if (
                  !group_obj?.external_link ||
                  !isValidHttpUrl(group_obj?.external_link)
                ) {
                  validation.valid = false;
                  validation.group = i;
                  validation.err_msg = alertMsgFn(
                    ALERT_MSG_TYPES.EXTERNAL_LINK_ERROR,
                    i,
                  );
                }
              } else if (
                group_obj.external_link_text_choice ===
                EXT_LINK_TEXT_CHOICES.TEXT
              ) {
                // ===== external file text validation
                if (
                  !group_obj?.external_text ||
                  group_obj?.external_text === ""
                ) {
                  validation.valid = false;
                  validation.group = i;
                  validation.err_msg = alertMsgFn(
                    ALERT_MSG_TYPES.EXTERNAL_CODE_ERROR,
                    i,
                  );
                }
              }
            }
          });
        }

        // if there is link/code in any gorup then there must be target file in that group
        if (
          group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.LINK
        ) {
          if (
            group_obj?.external_link !==
              originalCombinationData[i].external_link &&
            !group_obj?.target_files
          ) {
            validation.valid = false;
            validation.group = i;
            validation.err_msg = alertMsgFn(
              ALERT_MSG_TYPES.TARGET_FILE_ERROR,
              i,
            );
          } else if (
            group_obj?.external_link ===
              originalCombinationData[i].external_link &&
            group_obj.assignment_names?.length < 1 &&
            !group_obj?.target_files
          ) {
            validation.valid = false;
            validation.group = i;
            validation.err_msg = alertMsgFn(
              ALERT_MSG_TYPES.TARGET_FILE_ERROR,
              i,
            );
          }
        } else if (
          group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.TEXT
        ) {
          if (
            group_obj?.external_text !==
              originalCombinationData[i].external_text &&
            !group_obj?.target_files
          ) {
            validation.valid = false;
            validation.group = i;
            validation.err_msg = alertMsgFn(
              ALERT_MSG_TYPES.TARGET_FILE_ERROR,
              i,
            );
          } else if (
            group_obj?.external_text ===
              originalCombinationData[i].external_text &&
            group_obj.assignment_names?.length < 1 &&
            !group_obj?.target_files
          ) {
            validation.valid = false;
            validation.group = i;
            validation.err_msg = alertMsgFn(
              ALERT_MSG_TYPES.TARGET_FILE_ERROR,
              i,
            );
          }
        }
        // if (
        //   group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.LINK &&
        //   group_obj?.external_link &&
        //   group_obj.external_link !== "" &&
        //   !group_obj?.target_files &&
        //   group_obj.assignment_names?.length < 1
        // ) {
        //   validation.valid = false;
        //   validation.group = i;
        //   validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.TARGET_FILE_ERROR, i);
        // } else if (
        //   group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.TEXT &&
        //   group_obj?.external_text &&
        //   group_obj.external_text !== "" &&
        //   !group_obj?.target_files &&
        //   group_obj.assignment_names?.length < 1
        // ) {
        //   validation.valid = false;
        //   validation.group = i;
        //   validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.TARGET_FILE_ERROR, i);
        // }
      });
    }

    // Checking if the file size entered is below 25 mb if the email checkbox is checked
    data?.forEach((group_obj, i) => {
      if (group_obj.email_attachment) {
        // if email attachment is true there must be target files in the group
        if (!group_obj?.target_files) {
          validation.valid = false;
          validation.group = i;
          validation.err_msg = alertMsgFn(
            ALERT_MSG_TYPES.EMAIL_ATTACHED_TARGET_FILE_ERROR,
            i,
          );
        } else {
          // the total size of target files combined must be below 25mb
          const totalTargetFileSize = group_obj.target_files?.reduce(
            (total, file) => {
              return total + file.size;
            },
            0,
          );
          if (totalTargetFileSize > 25165824) {
            validation.valid = false;
            validation.group = i;
            validation.err_msg = alertMsgFn(
              ALERT_MSG_TYPES.TARGET_FILE_SIZE_ERROR,
              i,
            );
          }
        }
      }
    });

    data?.forEach((group_obj, i) => {
      // committed_delivery validation
      if (!group_obj?.committed_delivery) {
        validation.valid = false;
        validation.group = i;
        validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.COMMITTED_DELIVERY, i);
      }
    });

    return validation;
  };

  const HandleAsgnCombinationSubmit = () => {
    let valid_check = validateInput(combinationData);
    // put validation before sending data
    if (valid_check.valid) {
      if (devEnv) {
        console.log(combinationData);
      }
      setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.UPLOAD);
    } else {
      alert(valid_check.err_msg);
    }
  };

  return (
    <section className="batch-delivery-container">
      <div className="batch-delivery-head">
        <div className="batch-delivery-head-container">
          <button
            className="ops-dashboard-back-btn batch-delivery-head-back-btn"
            onClick={() => {
              setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.CREATE);
            }}
          >
            <FaAngleLeft />
          </button>
          <span className="batch-delivery-head-text">Enter Batch Details</span>
        </div>
        <div className="batch-delivery-head-container">
          <Link
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-cancel-btn"
            // onClick={()=>{setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.CREATE)}} // this will take to the create page
            to={`/deliver/${liveAssignmentData?.file_details?.id}`}
          >
            Cancel
          </Link>
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-submit-btn"
            onClick={() => {
              HandleAsgnCombinationSubmit();
            }}
          >
            Next
          </button>
        </div>
      </div>

      <BatchDeliveryInfoContainer />
    </section>
  );
};

const UpdateAddDelivery = () => {
  const { setFileUploadData } = useContext(FileGroupsContext);

  // setting the fileGroups data to initial state ... this is bcz if a user clicks back the file gorups must revert back to initial state
  useEffect(() => {
    setFileUploadData(uploadFileInitialState);
  }, []);

  return (
    <main className="file-deliver-container card-layout">
      <UpdateBatchDeliveryHead />
      <BatchDeliveryContainer />
    </main>
  );
};

export default UpdateAddDelivery;
