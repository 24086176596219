import React, { useState, useEffect, useContext } from "react";
import ReactDom from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./index.css";
import { LostError } from "./error/error.js";
import {
  OpsPageComponents,
  OpsLoginComponents,
} from "./ops_module/OpsComponent";
// import { RequesterComponents } from "./requester_module/RequesterComponents";
import countryCodes from "./country_code.json";
import { getAuthData, getData } from "./helpers/request";
import {
  FaDownload,
  FaFrown,
  FaFrownOpen,
  FaKey,
  FaMeh,
  FaRegFrown,
  FaRegFrownOpen,
  FaRegMeh,
  FaRegSmile,
  FaSmile,
  FaUserMinus,
  FaUserPlus,
  FaUserTimes,
} from "react-icons/fa";
import { FiCrosshair } from "react-icons/fi";
import { CgSmileMouthOpen } from "react-icons/cg";
import { IoHappy } from "react-icons/io5";
import { Provider } from "react-redux";
import store from "./store/store";
import { MdFeedback, MdSend } from "react-icons/md";
import { Toaster } from "react-hot-toast";

// Dev environment
// to setup a less dependent environment for developing UI without Backend or server support
export const devEnv = false; // set true to create UI without backend

export const ApiContext = React.createContext();
export const UserContext = React.createContext();
export const HoverToolTipTextContext = React.createContext();

let client_location = "-";
let client_ip = "-";

export const DEVICE_RESPONSIVENESS = {
  MOBILE: "mobile",
  TABLET: "ipad",
  SMALL_DESKTOP: "720p",
  LARGE_DESKTOP: "1080p",
};

// Getting the device info for responsiveness
export const getDeviceResponsiveness = () => {
  if (window.matchMedia("(min-width: 300px) and (max-width: 600px)").matches) {
    return DEVICE_RESPONSIVENESS.MOBILE;
  } else if (
    window.matchMedia(
      "(min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px) , (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px) , (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px)",
    ).matches
  ) {
    return DEVICE_RESPONSIVENESS.TABLET;
  } else if (window.matchMedia("(max-width: 1380px)").matches) {
    return DEVICE_RESPONSIVENESS.SMALL_DESKTOP;
  } else if (
    window.matchMedia("(min-width: 1400px) and (max-width: 2000px)").matches
  ) {
    return DEVICE_RESPONSIVENESS.LARGE_DESKTOP;
  } else {
    return DEVICE_RESPONSIVENESS.LARGE_DESKTOP;
  }
};

// ================== FEEDBACK EMOTIONS =====================================

export const FEEDBACK_EMOTIONS = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
  NEUTRAL: "neutral",
  EMPTY: "empty",
};

export const emojis = {
  positive: {
    normal: <FaRegSmile className="rating-star" />,
    active: <FaSmile className="rating-star" color={"var(--light-green)"} />,
  },
  neutral: {
    normal: <FaRegMeh className="rating-star" />,
    active: (
      <FaMeh className="rating-star" color={"var(--light-blue,#3cc3f2)"} />
    ),
  },
  negative: {
    normal: <FaRegFrown className="rating-star" />,
    active: <FaFrown className="rating-star" color={"var(--orange,#ea7b2c)"} />,
  },
};

export const RatingEmoji = ({ feedback, show }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="ops-feedback-rating-container">
      {!show ? (
        <span
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {feedback && emojis[feedback][hover ? "active" : "normal"]}
          {/* {emojis["positive"][(hover) ? "active" : "normal"]} */}
        </span>
      ) : (
        <span
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {feedback && emojis[feedback]["active"]}
          {/* {emojis["positive"][(hover) ? "active" : "normal"]} */}
        </span>
      )}
    </div>
  );
};

export const emoticons = [
  {
    normal: <FaRegFrownOpen className="rating-star" />,
    active: (
      <FaFrownOpen className="rating-star" color={"var(--red,#C92B2B)"} />
    ),
  },
  {
    normal: <FaRegFrown className="rating-star" />,
    active: <FaFrown className="rating-star" color={"var(--orange,#ea7b2c)"} />,
  },
  {
    normal: <FaRegMeh className="rating-star" />,
    active: <FaMeh className="rating-star" color={"var(--yellow,#f1b715)"} />,
  },
  {
    normal: <CgSmileMouthOpen className="rating-star" />,
    active: (
      <IoHappy className="rating-star" color={"var(--android-green,#A7BC2A)"} />
    ),
  },
  {
    normal: <FaRegSmile className="rating-star" />,
    active: (
      <FaSmile className="rating-star" color={"var(--green-crayola,#1fad5f)"} />
    ),
  },
];

export const FeedbackEmoji = ({ feedback, show }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="client-feedback-rating-container">
      {!show ? (
        <span
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {emoticons[feedback - 1][hover ? "active" : "normal"]}
        </span>
      ) : (
        <span>{emoticons[feedback - 1]["active"]}</span>
      )}
    </div>
  );
};

export const FeedbackRatingEmoji = ({ feedback, show = true }) => {
  if (feedback) {
    if (Number.isInteger(feedback)) {
      return <FeedbackEmoji feedback={feedback} show={show} />;
    } else {
      return <RatingEmoji feedback={feedback} show={show} />;
    }
  } else {
    return <div className="client-feedback-rating-container"></div>;
  }
};

// ================== Access Log Types ======================================

export const ACCESS_LOG_TYPES = {
  DOWNLOAD: "download",
  REQUESTED: "requested",
  ACCESSED: "accessed",
  SHARED: "shared",
  SHARED_OUTSIDE: "shared_outside",
  FEEDBACK: "feedback",
  NEW_VERSION: "new_version",
  NEW_DELIVERY: "new_delivery",
  DECRYPT: "decrypt",
  RESEND_SECURE_KEY: "resend_secure_key",
  SHARE_REQUEST: "request",
  SHARE_ACCEPT: "accept",
  SHARE_DECLINE: "decline",
  SHARE_REVOKE: "revoke",
};

export const AccessLogTypeIcon = ({ type }) => {
  switch (type) {
    case ACCESS_LOG_TYPES.DOWNLOAD:
      return <FaDownload />;
    case ACCESS_LOG_TYPES.REQUESTED:
      return <FaKey title="File Requested" />;
    case ACCESS_LOG_TYPES.ACCESSED:
      return <FiCrosshair title="File Accessed" />;
    case ACCESS_LOG_TYPES.SHARED:
      return <FaUserPlus title="File Shared" />;
    case ACCESS_LOG_TYPES.SHARED_OUTSIDE:
      return <FaUserPlus title="File Shared Outside Organisation" />;
    //only for ops
    case ACCESS_LOG_TYPES.FEEDBACK:
      // return (
      //   <img
      //     className="lazy-img"
      //     onLoad={lazy_load_image}
      //     src={
      //       process.env.PUBLIC_URL +
      //       `/resources/access_log_icons/${user}/feedback_log.svg`
      //     }
      //     title="Feedback"
      //     alt="File"
      //   />
      // );
      return <MdFeedback />;
    case ACCESS_LOG_TYPES.NEW_VERSION:
      return <MdSend title="New Version Delivered" />;
    case ACCESS_LOG_TYPES.NEW_DELIVERY:
      return <MdSend title="New Delivery" />;
    case ACCESS_LOG_TYPES.DECRYPT:
      return <FaKey title="File Decrypted" />;
    case ACCESS_LOG_TYPES.RESEND_SECURE_KEY:
      return <FaKey title="Secure Key Sent" />;
    case ACCESS_LOG_TYPES.SHARE_REQUEST:
      return <FaUserPlus title="File Share Requested" />;
    case ACCESS_LOG_TYPES.SHARE_ACCEPT:
      return <FaUserPlus title="Share Request Accepted" />;
    case ACCESS_LOG_TYPES.SHARE_DECLINE:
      return <FaUserTimes title="Share Request Declined" />;
    case ACCESS_LOG_TYPES.SHARE_REVOKE:
      return <FaUserMinus title="User Revoked" />;
    default:
      return <FaKey />;
  }
};

export const FileType = ({ filetype, active }) => {
  return (
    <>
      {filetype?.includes("TR") && (
        <div className={`ops-file-stage ${active && "ops-file-stage-active"}`}>
          <img
            className="file-type-svg"
            src={
              process.env.PUBLIC_URL + "/resources/services_icons/blue/TR.svg"
            }
            alt="TR"
          ></img>
          {/* {(active) ?
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/blue/TR.svg"} alt="TR"></img>) :
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/white/TR.svg"} alt="TR"></img>)
              } */}
        </div>
      )}
      {filetype?.includes("DP") && (
        <div className={`ops-file-stage ${active && "ops-file-stage-active"}`}>
          <img
            className="file-type-svg"
            src={
              process.env.PUBLIC_URL + "/resources/services_icons/blue/DP.svg"
            }
            alt="DP"
          ></img>
          {/* {(active) ?
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/blue/DP.svg"} alt="DP"></img>) :
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/white/DP.svg"} alt="DP"></img>)
              } */}
        </div>
      )}
      {filetype?.includes("AV") && (
        <div className={`ops-file-stage ${active && "ops-file-stage-active"}`}>
          <img
            className="file-type-svg"
            src={
              process.env.PUBLIC_URL + "/resources/services_icons/blue/AV.svg"
            }
            alt="AV"
          ></img>
          {/* {(active) ?
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/blue/AV.svg"} alt="AV"></img>) :
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/white/AV.svg"} alt="AV"></img>)
              } */}
        </div>
      )}
      {filetype?.includes("PD") && (
        <div className={`ops-file-stage ${active && "ops-file-stage-active"}`}>
          <img
            className="file-type-svg"
            src={
              process.env.PUBLIC_URL + "/resources/services_icons/blue/PD.svg"
            }
            alt="PD"
          ></img>
          {/* {(active) ?
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/blue/PD.svg"} alt="PD"></img>) :
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/white/PD.svg"} alt="PD"></img>)
              } */}
        </div>
      )}
      {filetype?.includes("GD") && (
        <div className={`ops-file-stage ${active && "ops-file-stage-active"}`}>
          <img
            className="file-type-svg"
            src={
              process.env.PUBLIC_URL + "/resources/services_icons/blue/GD.svg"
            }
            alt="GD"
          ></img>
          {/* {(active) ?
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/blue/GD.svg"} alt="GD"></img>) :
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/white/GD.svg"} alt="GD"></img>)
              } */}
        </div>
      )}
      {filetype?.includes("RA") && (
        <div className={`ops-file-stage ${active && "ops-file-stage-active"}`}>
          <img
            className="file-type-svg"
            src={
              process.env.PUBLIC_URL + "/resources/services_icons/blue/RA.svg"
            }
            alt="RA"
          ></img>
          {/* {(active) ?
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/blue/RA.svg"} alt="RA"></img>) :
                  (<img className="file-type-svg" src={process.env.PUBLIC_URL + "/resources/services_icons/white/RA.svg"} alt="RA"></img>)
              } */}
        </div>
      )}
    </>
  );
};

// ==================  Lazy Loading for images =================
// need to set a class of "lazy-img" on the image element which needs to lazy load.
// also need to call the onLoad=={lazy_load_image} on the image element
export const lazy_load_image = (e) => {
  const el = e?.target || e; // checks if its an element if not then it captures the e itself which is passed as the ref.current
  el.classList.add("show-img");
};

// =============== ACCESS LOGS FUNCTION ====================
// ================ get Location ==============
export const getIP = () => {
  const get_ip_url = "https://geolocation-db.com/json/";
  getData(get_ip_url)
    .then((res) => {
      if (res.city && res.country_name) {
        client_location = `${res.city},${res.country_name}`;
      } else if (!res.city && res.country_name) {
        client_location = `${res.country_name}`;
      } else if (!res.country && res.city) {
        client_location = `${res.city}`;
      } else if (!res.country && !res.city) {
        client_location = "-";
      }
      client_ip = res.IPv4;
    })
    .catch(() => {
      throw "Can't locate";
    });
};

// navigator information
const getBrowser = (agent = window.navigator.userAgent.toLowerCase()) => {
  switch (true) {
    case agent.indexOf("edge") > -1:
      return { agent: agent, client: "Edge" };
    case agent.indexOf("edg") > -1:
      return { agent: agent, client: "Chromium based edge (dev or canary)" };
    case agent.indexOf("opr") > -1 && !!window.opr:
      return { agent: agent, client: "Opera" };
    case agent.indexOf("chrome") > -1 && !!window.chrome:
      return { agent: agent, client: "Chrome" };
    case agent.indexOf("trident") > -1:
      return { agent: agent, client: "IE" };
    case agent.indexOf("firefox") > -1:
      return { agent: agent, client: "Firefox" };
    case agent.indexOf("safari") > -1:
      return { agent: agent, client: "Safari" };
    default:
      return { agent: agent, client: "other" };
  }
};

const getPlatform = (agent = navigator.appVersion) => {
  switch (true) {
    case agent.indexOf("Win") > -1:
      return { agent: agent, client: "Windows" };
    case agent.indexOf("Mac") > -1:
      return { agent: agent, client: "Mac" };
    case agent.indexOf("Linux") > -1:
      return { agent: agent, client: "Linux" };
    default:
      return { agent: agent, client: navigator.platform };
  }
};

export const navigator_info = () => {
  // console.log(window.navigator.userAgent)
  // console.log(navigator.platform)
  return {
    platform: navigator.platform,
    generated_platform: getPlatform(navigator.appVersion).client,
    browser_info: window.navigator.userAgent,
    generated_browser_info: getBrowser(window.navigator.userAgent.toLowerCase())
      .client,
  };
};

export const getAccessLogData = (data) => {
  return {
    ...data,
    platform: getBrowser().client,
    device: getPlatform().client,
    address: client_location,
    ip: client_ip,
  };
};

// for sending axios data / form data

export const addAccessLogDataInFormData = (data) => {
  const access_data = getAccessLogData();
  Object.entries(access_data).forEach((item) => {
    data.append(item[0], item[1]);
  });
  return data;
};

export const getAccessDataUrl = (url) => {
  let tracking_data = getAccessLogData();
  // replacing spaces with +
  Object.entries(tracking_data).forEach((tracker_item) => {
    if (tracker_item[1].includes(" ")) {
      tracking_data[tracker_item[0]] = tracker_item[1].replace(" ", "+");
    }
  });
  return `${url}?ip=${tracking_data.ip}&address=${tracking_data.address}&platform=${tracking_data.platform}&device=${tracking_data.device}`;
};

// ====================== Date Time Formatter to local date ========================

export const date_time_convertor = (utc_date) => {
  const day_arr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const month_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (!utc_date || !Date.parse(utc_date)) return null;
  const date_time = new Date(
    utc_date.slice(-1) === "Z" ? utc_date : utc_date + "Z",
  ); // checks if UTC is comming with a Z in the end or not

  // Dissecting date
  const day = day_arr[date_time.getDay()];
  const month = month_arr[date_time.getMonth()];
  const date = date_time.getDate();
  const year = date_time.getFullYear();
  const hours = date_time.getHours();
  const minutes = date_time.getMinutes();
  const seconds = date_time.getSeconds();
  const time_zone = date_time?.toString()?.split("(")[1]?.split(")")[0];

  //Abbreviating the time zone
  const time_zone_arr = time_zone.split(" ");
  const reduce_time_abrv = (tz__arr) => {
    const abrv = [];
    tz__arr.map((cur) => abrv.push(cur.substr(0, 1)));
    return abrv.join("");
  };
  const time_zone_abrv =
    time_zone_arr.length > 1
      ? reduce_time_abrv(time_zone_arr)
      : time_zone_arr[0];

  // 12 Hr converter
  let timeString = `${String(hours).length === 2 ? hours : `0${hours}`}:${
    String(minutes).length === 2 ? minutes : `0${minutes}`
  }:${String(seconds).length === 2 ? seconds : `0${seconds}`}`;
  const H = +timeString.substr(0, 2);
  const h = H % 12 || 12;
  const ampm = H < 12 || H === 24 ? " AM" : " PM";
  timeString =
    (String(h).length === 2 ? h : `0${h}`) + timeString.substr(2, 3) + ampm;

  const converted_date = `${day}, ${date} ${month} ${year}, ${timeString} (${time_zone_abrv})`;

  return converted_date;
};

// ====================== TOOLTIP ERRORs ==================================

export const ERROR_REGEX = {
  // SYMBOLS_ERROR : {REGEX:"[^0-9|^a-z|^A-Z| |.]",ERROR_MSG:"Symbols not allowed",VALIDATE:true},
  SYMBOLS_ERROR: {
    REGEX: '[`~*;"<>|,+=/?\\\\(){}\\[\\]]',
    ERROR_MSG: "Symbols not allowed",
    VALIDATE: true,
  },
  TEXTAREA_ERROR: {
    REGEX: '[`~*;"<>|+=/?\\\\(){}\\[\\]]',
    ERROR_MSG: "Symbols not allowed",
    VALIDATE: true,
  },
  TEXT_ERROR: {
    REGEX: '[a-z]|[`!@#$%^&*~;"<>|,=?\\\\(){}\\[\\]]',
    ERROR_MSG: "Text and symbols not allowed",
    VALIDATE: true,
  },
  PASSWORD_ERROR: {
    REGEX:
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%&()*+,-./:=?@[\\]_{}])(?=.{8,})",
    ERROR_MSG:
      "Please enter an 8 digit password that includes \nat least 1 uppercase, 1 lowercase, 1 number and \n1 special character (except \" , / , ~ , ` , < , > , ; , | , ').",
    VALIDATE: false,
  },
  PASSWORD_SYMBOLS_ERROR: {
    REGEX: '[~^`;/"<>|]',
    ERROR_MSG: "\" , / , ~ , ` , < , > , ^ , ; , | , ' symbols not allowed.",
    VALIDATE: true,
  },
  // PASSWORD_ERROR : {REGEX:"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+()%])(?=.{8,})",ERROR_MSG:"Please enter an 8 digit password that includes \nat least 1 uppercase, 1 lowercase, 1 number and \n1 special character ( ! , @ , # , $ , & , * , ( , ) , + , %).",VALIDATE:false},
};

// to be separated in utils
export const ToolTipError = (e, patternArr) => {
  for (let i = 0; i < patternArr.length; i++) {
    const pattern = patternArr[i];
    const re = new RegExp(pattern.REGEX);
    if (pattern.VALIDATE) {
      if (re.test(e.target.value)) {
        e.target.setCustomValidity(pattern.ERROR_MSG);
        break;
      } else {
        e.target.setCustomValidity("");
      }
    } else {
      if (!re.test(e.target.value)) {
        e.target.setCustomValidity(pattern.ERROR_MSG);
        break;
      } else {
        e.target.setCustomValidity("");
      }
    }
  }
  // patternArr.forEach((pattern)=>{
  //   const re = new RegExp(pattern.REGEX);
  //   if (pattern.VALIDATE){
  //     e.target.setCustomValidity((re.test(e.target.value)) ? pattern.ERROR_MSG : "");
  //   } else {
  //     e.target.setCustomValidity((!re.test(e.target.value)) ? pattern.ERROR_MSG : "");
  //   }
  // });
};

// Country code check list value with field value
export const checkCountryCode = (e) => {
  let checked = false;
  countryCodes.map((cur) => {
    if (cur.country_code === e.target.value) {
      checked = true;
    }
  });
  return checked;
};

export const ToolTipCustomError = (e, msg, check) => {
  e.target.setCustomValidity(check ? msg : "");
};

// ============================= Hovering Tooltip =============================
// need to set a "hover-tooltip" class on the element you want to show the tooltip on
// add a mouseOver property on the "hover-tooltip" element as well and call the showHoverToolTip functino from here.
// Example :  clasName="hover-tooltip" onMouseOver={(e)=>{showHoverToolTip(e,TOOLTIP_HOVER_TYPE.OPEN)}} onMouseLeave={(e)=>{showHoverToolTip(e,TOOLTIP_HOVER_TYPE.CLOSE)}}
// Also need to set the tooltip element itself inside the hover tooltip class element with a class of "tooltip-container"
// Example : <span className="tooltip-container"> {clientDisplayedFile?.source_file_name || "-"} </span>
export const TOOLTIP_HOVER_TYPE = { OPEN: "open", CLOSE: "close" };

export const showHoverToolTip = (e, type) => {
  const tooltipEl = document.querySelector(".tooltip-container");

  // Opening the tooltip and closing logic
  if (type === TOOLTIP_HOVER_TYPE.OPEN) {
    // setting the text inside the tooltip based on the input
    // tooltipEl.innerText = tooltip_str;
    // this class will make the tooltip visible
    tooltipEl.classList.add("tooltip-container-hover");
  } else if (type === TOOLTIP_HOVER_TYPE.CLOSE) {
    tooltipEl.classList.remove("tooltip-container-hover");
  }

  window.onmousemove = function (e) {
    let x = e.clientX,
      y = e.clientY;
    if (!tooltipEl) return; // bcz sometimes the element is not caputred successfully and it may throw an error.
    tooltipEl.style.top = y + 10 + "px";
    tooltipEl.style.left = x + 10 + "px";
    // if (window.matchMedia('(min-width: 300px) and (max-width: 600px)').matches){}
  };
};

// This element will be shown as tooltip
const HoverToolTip = () => {
  const { hoverTooltipStr } = useContext(HoverToolTipTextContext);

  if (Array.isArray(hoverTooltipStr)) {
    // if we have an array of data then we split it and show it vertically stacked up
    return (
      <span className="tooltip-container">
        {hoverTooltipStr.map((item, i) => {
          return <span key={i}>{item}</span>;
        })}
      </span>
    );
  } else if (typeof hoverTooltipStr === "string") {
    return <span className="tooltip-container">{hoverTooltipStr}</span>;
  } else {
    return <span className="tooltip-container"></span>;
  }
};

// ============================== DOWNLOAD FILES =============================

const downloadAll = (files) => {
  if (files.length === 0) return;
  let file = files.pop();
  let theAnchor = document.createElement("a");
  theAnchor.setAttribute("href", file[1]);
  theAnchor.setAttribute("download", file[0]);
  theAnchor.click();
  theAnchor.remove();
  setTimeout(() => {
    downloadAll(files);
  }, 2000);
};

export const DownloadFiles = (fileObjectsArr, all = false) => {
  let files = [];
  Object.values(fileObjectsArr)?.forEach((cur) => {
    try {
      const tracker_url =
        fileObjectsArr.length > 1 || all
          ? getAccessDataUrl(cur.link) + "&all=true"
          : getAccessDataUrl(cur.link) + "&all=false";
      getAuthData(tracker_url).then((res) => {
        files.push([cur.name, res.download_url]);
        if (files.length === fileObjectsArr.length) downloadAll(files); // checking if we recieved all file locations
      });
    } catch (err) {
      alert(
        "There was a problem while downloading the files. Please try again after sometime.",
      );
    }
  });
};

// ===================== ROUTER SETUP ===============================

const ReactRouterSetup = () => {
  const [profile, setProfile] = useState({});
  const [hoverTooltipStr, setHoverTooltipStr] = useState(null);

  // getting clinet location as soon as he gets into the application so as to set the variable in access log data
  useEffect(() => {
    if (!devEnv) {
      getIP();
    }
  }, []);

  return (
    <ApiContext.Provider value={process.env.REACT_APP_API_URL}>
      <UserContext.Provider value={{ profile, setProfile }}>
        <HoverToolTipTextContext.Provider
          value={{ hoverTooltipStr, setHoverTooltipStr }}
        >
          <HoverToolTip />
          <Router>
            <Switch>
              {/* =====================OPS ROUTER==================== */}

              <Route exact path="/">
                <OpsLoginComponents page="login" />
              </Route>

              <Route exact path="/oppsforgotpassword">
                <OpsLoginComponents page="oppsforgotpassword" />
              </Route>

              <Route exact path="/oppsforgotpassword/token">
                <OpsLoginComponents page="oppsforgetpasswordtoken" />
              </Route>

              <Route exact path="/deliver/:asgn_id">
                <OpsPageComponents page="deliver" />
              </Route>

              <Route exact path="/deliver">
                <OpsPageComponents page="deliver" />
              </Route>

              <Route exact path="/ops-archive/:asgn_id">
                <OpsPageComponents page="ops-archive" />
              </Route>

              <Route path="/ops-archive">
                <OpsPageComponents page="ops-archive" />
              </Route>

              <Route exact path="/profile">
                <OpsPageComponents page="accountsettings" />
              </Route>

              {/* <Route exact path="/addclient">
                <OpsPageComponents page="addclient" />
              </Route> */}

              <Route exact path="/adduser">
                <OpsPageComponents page="adduser" />
              </Route>

              <Route exact path="/addnew">
                <OpsPageComponents page="addnew" />
              </Route>

              <Route exact path="/update-delivery/:asgn_id">
                <OpsPageComponents page="updatedelivery" />
              </Route>

              {/* Requester routes */}
              {/* <Route exact path="/requester">  //requester routes removed
                <RequesterComponents page="requester" />
              </Route>

              <Route exact path="/requester/:requester_id">
                <RequesterComponents page="requester" />
              </Route>

              <Route exact path="/addrequester">
                <RequesterComponents page="addrequester" />
              </Route>

              <Route exact path="/updaterequester/:requester_id">
                <RequesterComponents page="updaterequester" />
              </Route> */}
              {/* for future optimization */}
              {/* <Route exact path="/requesterprofile">
                <RequesterComponents page="" />
              </Route> */}

              <Route path="*">
                <LostError />
              </Route>
            </Switch>
          </Router>
        </HoverToolTipTextContext.Provider>
      </UserContext.Provider>
    </ApiContext.Provider>
  );
};
function App() {
  return (
    <Provider store={store}>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "var(--light-blue)",
              color: "var(--white)",
            },
          },
          error: {
            iconTheme: {
              primary: "red",
            },
            style: {
              background: "var(--orange)",
              color: "var(--white)",
            },
          },
        }}
      />
      <ReactRouterSetup />
    </Provider>
  );
}

ReactDom.render(<App />, document.getElementById("root"));
