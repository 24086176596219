import React, { useContext, useState } from "react";
import { FaTimes } from "react-icons/fa";
import {
  DeliveryFormInfoContext,
  NewSecureKeyContext,
  ModalDispatchContext,
  OpsDashboardFileContext,
  OpsFilesArrayContext,
  BottomContext,
  BOTTOM_TAB,
  MODAL_TYPE,
} from "../OpsComponent";
import { addAccessLogDataInFormData, ApiContext } from "../../index.js";
import { getAuthData } from "../../helpers/request";
import { FileIcon } from "../Dashboard/OpsDashboard";
import { useHistory } from "react-router-dom";
import { makeSecureDecrypt } from "../../helpers/security";
import axios from "axios";

const CopySecureKeyModal = () => {
  let fetchingText_interval;
  const history = useHistory();
  const API_URL = useContext(ApiContext);
  const { setOpsFilesArray } = useContext(OpsFilesArrayContext);
  const { setBottomTab } = useContext(BottomContext);
  const { setOpsDisplayedFile } = useContext(OpsDashboardFileContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const [fetching, setFetching] = useState(null);
  const deliverFormData = useContext(DeliveryFormInfoContext).deliverFileInfo;
  const { setNewSecureKey } = useContext(NewSecureKeyContext);

  const getkey = (e) => {
    let isMounted = true;
    e.preventDefault();
    if (isMounted) {
      setFetching("Fetching.");
      fetchingText_interval = setInterval(() => {
        setFetching((prev) => {
          if (prev === "Fetching.") {
            return "Fetching..";
          } else if (prev === "Fetching..") {
            return "Fetching...";
          } else if (prev === "Fetching...") {
            return "Fetching.";
          }
        });
      }, 1000);
      const user = JSON.parse(
        makeSecureDecrypt(sessionStorage.getItem("token")),
      );
      let formData = new FormData();
      formData.append("assignment_id", deliverFormData.assignmentId);
      deliverFormData.uploadSource.forEach((file) => {
        formData.append("source_file", file);
      });
      deliverFormData.uploadTarget.forEach((file) => {
        formData.append("target_file", file);
      });
      formData.append("delivery_notes", deliverFormData.deliveryNotes);

      axios({
        method: "post",
        url: `${API_URL}/api-ops/ops/v1/upload-new-assignment/`,
        data: addAccessLogDataInFormData(formData),
        headers: { Authorization: `Bearer ${user}` },
      })
        .then((res) => {
          setNewSecureKey(res.data.secure_key);
          clearInterval(fetchingText_interval);
          setFetching("Fetched!!");
          setTimeout(() => {
            setFetching(null);
            modal_dispatch({ modal_show_type: MODAL_TYPE.NONE }); // this line will show the copy secure key modal
          }, 1000);

          getAuthData(
            `${API_URL}/api-ops/ops/v1/all-assignments/?id=${undefined}`,
          ).then((res) => {
            if (res.success === true) {
              setOpsFilesArray(res.data);
              setOpsDisplayedFile(res.last_assignment);
              setBottomTab(BOTTOM_TAB.DELIVERY);
              // settingDefaultActiveFile();
              history.push(`/deliver/${res?.last_assignment?.id}`);
            }
          });
        })
        .catch(() => {
          clearInterval(fetchingText_interval);
          setFetching("Failed to generate !");
          setTimeout(() => {
            setFetching(null);
          }, 5000);
        });
    }
    isMounted = false;
  };

  return (
    <div className="file-container copy-secure-key-container">
      <div className="file-container-head-new copy-secure-key-head">
        Confirm Assignment Details
      </div>
      <button
        className="file-container-close-btn"
        onClick={() => {
          modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
        }}
      >
        <FaTimes style={{ color: "black" }} />
      </button>

      <div className="copy-secure-key-label">Assignment ID </div>
      <div className="copy-secure-key-value">
        {deliverFormData.assignmentId}
      </div>

      <div className="copy-secure-key-label">Source File </div>
      <div className="copy-secure-key-value">
        {deliverFormData.uploadSource?.map((file) => (
          <div className="file-logo-new" key={file.name}>
            <FileIcon filename={file.name} />
            <div className="filename-new">{file.name}</div>
          </div>
        ))}
      </div>

      <div className="copy-secure-key-label">Outputs File(s) </div>
      <div className="copy-secure-key-value">
        {deliverFormData.uploadTarget?.map((file) => (
          <div className="file-logo-new" key={file.name}>
            <FileIcon filename={file.name} />
            <div className="filename-new">{file.name}</div>
          </div>
        ))}
      </div>

      <div className="copy-secure-key-label copy-key-delivery-notes-label">
        Delivery Notes{" "}
      </div>
      <div className="copy-secure-key-value copy-key-delivery-notes-value">
        {deliverFormData.deliveryNotes}
      </div>

      <div className="deliver-new-version-btn-container">
        <button
          className="copy-secure-key-edit-btn"
          onClick={(e) => {
            e.preventDefault();
            modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          Edit
        </button>
        <button
          className="copy-secure-key-copy-btn"
          onClick={getkey}
          style={fetching && { background: "var(--orange,#ea7b2c)" }}
        >
          {fetching || "Confirm"}
        </button>
      </div>
    </div>
  );
};

export { CopySecureKeyModal };
