import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { postAuthData } from "../../../helpers/request";
import { initialState, UPDATE_FILES_PROGRESS_STATUS } from "./common";

// get the links to the file chunks
export const getFileUpdateLinks = createAsyncThunk(
  "update-data/getFileUpdateLinks",
  async (file_id_data, thunkAPI) => {
    const update_file_groups_data =
      thunkAPI.getState().updateData.update_file_groups_data;

    // getting the file from the group of files state
    const file_data =
      update_file_groups_data.file_groups[file_id_data.group_id][
        file_id_data.file_type
      ][file_id_data.file_type_id];

    const file_group =
      update_file_groups_data.file_groups[file_id_data.group_id];

    // preparing data for fetching chunk urls
    let data = {
      group: file_group.group.toString(), // requested by shagun on 6th sept 5.44pm to be in string
      delivery_id: file_group.delivery_id,
      time: file_group.time,
      interim: file_group.interim_output.toString(), // requested by shagun on 6th sept 5.44pm to be in string
      file_type: file_id_data.file_type,
      file_name: file_data.file.name,
      file_size: file_data.file.size,
      chunk_size: update_file_groups_data.chunk_size,
    };

    // updating the file's progress to uploading.
    thunkAPI.dispatch(
      updateUpdateFileData({
        file_id_data: file_data.file_id,
        progressData: UPDATE_FILES_PROGRESS_STATUS.UPLOADING,
      }),
    );

    try {
      const res = await postAuthData(
        update_file_groups_data.get_links_api_url,
        data,
      );
      let res_data = { upload_id: res?.upload_id, urls: res?.urls };
      thunkAPI.dispatch(
        updateUpdateFileData({
          file_id_data,
          uploadId: res?.upload_id,
          chunkUploadUrls: res?.urls,
        }),
      );
      thunkAPI.dispatch(uploadFileChunks(file_id_data));
      return res_data;
    } catch (error) {
      thunkAPI.rejectWithValue(
        error?.message || "Failed to fetch upload links!",
      );
    }
  },
);

// uploading chunks of a file
export const uploadFileChunks = createAsyncThunk(
  "update-data/uploadFileChunks",
  async (file_id_data, thunkAPI) => {
    const update_file_groups_data =
      thunkAPI.getState().updateData.update_file_groups_data;

    // getting the file from the group of files state
    const file_data =
      update_file_groups_data.file_groups[file_id_data.group_id][
        file_id_data.file_type
      ][file_id_data.file_type_id];

    const chunkSize = update_file_groups_data.chunk_size * 1024 * 1024;
    // Calculating Number of chunks
    let total_chunks = Math.ceil(file_data.file.size / chunkSize);

    // updating the file's progress to uploading and updating the total number of chunks of file
    thunkAPI.dispatch(
      updateUpdateFileData({
        file_id_data: file_data.file_id,
        progressData: UPDATE_FILES_PROGRESS_STATUS.UPLOADING,
        totalChunks: total_chunks,
      }),
    );

    // Function to upload a individual chunk ;  It will be called in "for loop"
    const uploadChunk = async (idx, chunkSize) => {
      const sendAxios = axios.create();
      let initialPointer = idx * chunkSize;
      let chunk = file_data.file.slice(
        initialPointer,
        initialPointer + chunkSize,
        file_data.file.type,
      );
      return sendAxios.put(file_data.chunk_upload_urls[idx], chunk, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };

    (async () => {
      let flag = false; // if a chunk failed to upload this will be set to 1 and will terminate the loop

      for (let chunk = 0; chunk < total_chunks; chunk++) {
        // if (flag) return Promise.reject(new Error("File can't be uploaded")); // if flag=true => loop will end and control will move to .catch() block
        await uploadChunk(chunk, chunkSize)
          .then((res) => {
            let partData = {
              ETag: JSON.parse(res.headers.etag),
              PartNumber: chunk + 1,
            };
            // updating the chunk number and part data that is to be uploaded now
            thunkAPI.dispatch(
              updateUpdateFileData({
                file_id_data: file_data.file_id,
                uploadingChunk: file_data.uploading_chunk + 1,
                chunksPartUploadInfo: [
                  ...thunkAPI.getState().updateData.update_file_groups_data
                    .file_groups[file_id_data.group_id][file_id_data.file_type][
                    file_id_data.file_type_id
                  ].chunks_part_upload_info,
                  partData,
                ],
              }),
            );
          })
          .catch(async (err) => {
            console.log("First Retry: " + err);
            // Trying again to upload if fails 1st time
            await uploadChunk(chunk, chunkSize)
              .then((res) => {
                let partData = {
                  ETag: JSON.parse(res.headers.etag),
                  PartNumber: chunk + 1,
                };
                // updating the chunk number that is to be uploaded now
                thunkAPI.dispatch(
                  updateUpdateFileData({
                    file_id_data: file_data.file_id,
                    uploadingChunk: file_data.uploading_chunk + 1,
                    chunksPartUploadInfo: [
                      ...thunkAPI.getState().updateData.update_file_groups_data
                        .file_groups[file_id_data.group_id][
                        file_id_data.file_type
                      ][file_id_data.file_type_id].chunks_part_upload_info,
                      partData,
                    ],
                  }),
                );
              })
              .catch((err) => {
                console.log("Failed: " + err);
                flag = true; // when a chunk is not uploaded flag=1 and it will end the loop in next iteration
                // if chunk fails 2nd time also, flag is set to 1, and Progress Status to Retry.
                thunkAPI.dispatch(
                  updateUpdateFileData({
                    file_id_data: file_data.file_id,
                    progressData: UPDATE_FILES_PROGRESS_STATUS.FAILED,
                    uploadingChunk: 0,
                  }),
                );
              });
          });
        if (flag) return Promise.reject(new Error("File can't be uploaded")); // if flag=true => loop will end and control will move to .catch() block
      }
    })()
      .then(() => {
        thunkAPI.dispatch(combineFilePartsFromBE(file_id_data));
      })
      .catch((err) => {
        console.error(err);
      });
  },
);

// This function will take input an array of "ETag" and "PartNumber" form UploadChunks function.
// And hit "POST" API: complete Multipart
export const combineFilePartsFromBE = createAsyncThunk(
  "update-data/combineFilePartsFromBE",
  async (file_id_data, thunkAPI) => {
    const combination_url = `${process.env.REACT_APP_API_URL}/api-ops/ops/v1/multipart-complete/`;
    const update_file_groups_data =
      thunkAPI.getState().updateData.update_file_groups_data;

    // getting the file from the group of files state
    const file_data =
      update_file_groups_data.file_groups[file_id_data.group_id][
        file_id_data.file_type
      ][file_id_data.file_type_id];

    let combination_be_data = {
      parts: file_data.chunks_part_upload_info,
      upload_id: file_data.upload_id,
      file_name: file_data.file.name,
    };

    const checkAllFileStatusesForSuccess = () => {
      // checking every files progressData and if they are all success then the whole will be success
      const checkStatus = (statusTypeCheck) => {
        const checkTypeStatus = Object.values(
          thunkAPI.getState().updateData.update_file_groups_data.file_groups,
        )
          .map((group_data) => {
            const target_files = Object.values(group_data.target_files).map(
              (file) => file.progress,
            );
            const source_files = Object.values(group_data.source_files).map(
              (file) => file.progress,
            );
            return [...target_files, ...source_files];
          })
          .flat()
          .every((progress) => progress === statusTypeCheck);
        return checkTypeStatus;
      };

      const checkFailStatus = (statusTypeCheck) => {
        let checkFailure = false;
        const checkStatuses = Object.values(
          thunkAPI.getState().updateData.update_file_groups_data.file_groups,
        )
          .map((group_data) => {
            const target_files = Object.values(group_data.target_files).map(
              (file) => file.progress,
            );
            const source_files = Object.values(group_data.source_files).map(
              (file) => file.progress,
            );
            return [...target_files, ...source_files];
          })
          .flat();
        // this check is to make sure every file has been uploaded otherwise the modal will close even when the next changes to upload the next file
        const uploadingStatusCheck = checkStatuses.some((progress) => {
          return (
            progress === UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE ||
            progress === UPDATE_FILES_PROGRESS_STATUS.UPLOADING
          );
        });
        // now if all files are uploaded then we check failed status
        if (!uploadingStatusCheck) {
          checkFailure = checkStatuses.some(
            (progress) => progress === statusTypeCheck,
          );
        }
        return checkFailure;
      };

      if (checkStatus(UPDATE_FILES_PROGRESS_STATUS.SUCCESS)) {
        thunkAPI.dispatch(
          addUpdateFilesTotalProgress(UPDATE_FILES_PROGRESS_STATUS.SUCCESS),
        );
      } else if (checkFailStatus(UPDATE_FILES_PROGRESS_STATUS.FAILED)) {
        thunkAPI.dispatch(
          addUpdateFilesTotalProgress(UPDATE_FILES_PROGRESS_STATUS.FAILED),
        );
      }
    };

    const nextFileUpload = () => {
      let next =
        thunkAPI.getState().updateData.update_file_groups_data.uploading_file +
        1;
      let nextFileId =
        thunkAPI.getState().updateData.update_file_groups_data
          .file_groups_ids_arr[next];

      // check if all files are uploaded and set overall progress
      checkAllFileStatusesForSuccess();

      // start uploading next file
      if (nextFileId) {
        thunkAPI.dispatch(addUpdateFileNumber(next));
        thunkAPI.dispatch(getFileUpdateLinks(nextFileId));
      }
    };

    postAuthData(
      // `http://34.237.48.240:8006/api/upload/multipart/complete`,
      combination_url,
      combination_be_data,
    )
      .then((res) => {
        if (res.success === true) {
          // all files are part wise organised and now the file is uploaded successfuly
          thunkAPI.dispatch(
            updateUpdateFileData({
              file_id_data: file_data.file_id,
              progressData: UPDATE_FILES_PROGRESS_STATUS.SUCCESS,
            }),
          );
        } else {
          thunkAPI.dispatch(
            updateUpdateFileData({
              file_id_data: file_data.file_id,
              progressData: UPDATE_FILES_PROGRESS_STATUS.FAILED,
              uploadingChunk: 0,
            }),
          );
        }
        nextFileUpload();
      })
      .catch((err) => {
        console.log("Combination API error: " + err + "\nRetrying...");
        postAuthData(
          // `http://34.237.48.240:8006/api/upload/multipart/complete`,
          combination_url,
          combination_be_data,
        )
          .then((res) => {
            if (res.success === true) {
              // all files are part wise organised and now the file is uploaded successfuly
              thunkAPI.dispatch(
                updateUpdateFileData({
                  file_id_data: file_data.file_id,
                  progressData: UPDATE_FILES_PROGRESS_STATUS.SUCCESS,
                }),
              );
            } else {
              thunkAPI.dispatch(
                updateUpdateFileData({
                  file_id_data: file_data.file_id,
                  progressData: UPDATE_FILES_PROGRESS_STATUS.FAILED,
                  uploadingChunk: 0,
                }),
              );
            }
            nextFileUpload();
          })
          .catch((err) => {
            console.log("Combination API error: " + err);
            thunkAPI.dispatch(
              updateUpdateFileData({
                file_id_data: file_data.file_id,
                progressData: UPDATE_FILES_PROGRESS_STATUS.FAILED,
                uploadingChunk: 0,
              }),
            );
            nextFileUpload();
          });
      });
  },
);

// start uploading files
export const uploadFiles = createAsyncThunk(
  ("update-data/uploadFiles",
  async (num_of_files, thunkAPI) => {
    const { file_groups_ids_arr } =
      thunkAPI.getState().updateData.update_file_groups_data;
    thunkAPI.dispatch(addUpdateFileNumber(num_of_files));
    for (let index = 0; index < num_of_files; index++) {
      thunkAPI.dispatch(getFileUpdateLinks(file_groups_ids_arr[index]));
    }
  }),
);

// to change file information like progress total chunks etc
const updateFileStateData = (
  prevState,
  {
    file_id_data,
    progressData,
    uploadingChunk,
    totalChunks,
    uploadId,
    chunkUploadUrls,
    chunksPartUploadInfo,
  },
) => {
  if (progressData) {
    prevState.update_file_groups_data.file_groups[file_id_data.group_id][
      file_id_data.file_type
    ][file_id_data.file_type_id].progress = progressData;
  }
  if (uploadingChunk) {
    prevState.update_file_groups_data.file_groups[file_id_data.group_id][
      file_id_data.file_type
    ][file_id_data.file_type_id].uploading_chunk = uploadingChunk;
  }
  if (totalChunks) {
    prevState.update_file_groups_data.file_groups[file_id_data.group_id][
      file_id_data.file_type
    ][file_id_data.file_type_id].total_chunks = totalChunks;
  }
  if (uploadId) {
    prevState.update_file_groups_data.file_groups[file_id_data.group_id][
      file_id_data.file_type
    ][file_id_data.file_type_id].upload_id = uploadId;
  }
  if (chunkUploadUrls) {
    prevState.update_file_groups_data.file_groups[file_id_data.group_id][
      file_id_data.file_type
    ][file_id_data.file_type_id].chunk_upload_urls = chunkUploadUrls;
  }
  if (chunksPartUploadInfo) {
    prevState.update_file_groups_data.file_groups[file_id_data.group_id][
      file_id_data.file_type
    ][file_id_data.file_type_id].chunks_part_upload_info = chunksPartUploadInfo;
  }
};

const updateDataSlice = createSlice({
  name: "update-data",
  initialState,
  reducers: {
    addUpdateCombinationData: (state, { payload }) => {
      state.combination_data = payload;
    },
    addUpdateFileGroup: (state, { payload }) => {
      state.update_file_groups_data.file_groups = payload;
    },
    addUpdateFileGroupsIdsArr: (state, { payload }) => {
      state.update_file_groups_data.file_groups_ids_arr = payload;
    },
    addUpdateFileChunkSize: (state, { payload }) => {
      state.update_file_groups_data.chunk_size = payload;
    },
    addUpdateFileNumber: (state, { payload }) => {
      state.update_file_groups_data.uploading_file = payload;
    },
    addUpdateFileLinksApi: (state, { payload }) => {
      state.update_file_groups_data.get_links_api_url = payload;
    },
    addUpdateFilesTotalProgress: (state, { payload }) => {
      state.update_file_groups_data.upload_files_total_progress = payload;
    },
    updateUpdateFileData: (state, { payload }) => {
      updateFileStateData(state, payload);
    },
    resetUpdateFileProgressData: (state, { payload }) => {
      let file =
        state.update_file_groups_data.file_groups[payload.group_id][
          payload.file_type
        ][payload.file_type_id];
      file.progress = UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE;
      file.uploading_chunk = 0;
      file.total_chunks = 0;
      file.upload_id = null;
      file.chunk_upload_urls = [];
      file.chunks_part_upload_info = [];
    },
    resetUpdateData: () => initialState,
  },
  extraReducers: {
    [getFileUpdateLinks.pending]: () => {
      return;
    },
    [getFileUpdateLinks.fulfilled]: () => {
      return;
    },
    [getFileUpdateLinks.rejected]: () => {
      return;
    },
    [uploadFileChunks.pending]: () => {
      return;
    },
    [uploadFileChunks.fulfilled]: () => {
      return;
    },
    [uploadFileChunks.rejected]: () => {
      return;
    },
    [combineFilePartsFromBE.pending]: () => {
      return;
    },
    [combineFilePartsFromBE.fulfilled]: () => {
      return;
    },
    [combineFilePartsFromBE.rejected]: () => {
      return;
    },
    [uploadFiles.pending]: () => {
      return;
    },
    [uploadFiles.fulfilled]: () => {
      return;
    },
    [uploadFiles.rejected]: () => {
      return;
    },
  },
});

export const {
  addUpdateFileGroup,
  addUpdateFileGroupsIdsArr,
  addUpdateFileChunkSize,
  addUpdateFileNumber,
  addUpdateFileLinksApi,
  addUpdateFilesTotalProgress,
  updateUpdateFileData,
  addUpdateCombinationData,
  resetUpdateFileProgressData,
  resetUpdateData,
} = updateDataSlice.actions;

export default updateDataSlice.reducer;
