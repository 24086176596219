// import requesters from "../../../archive/test_data/dummy_requester_data/dummy_requesters.json";

export const SORT_TYPE = {
  ASCENDING: "asc",
  DESCENDING: "dsc",
};

export const TYPE_OF_ALL_REQUESTERS_DATA = {
  INIT: "initial",
  ALL: "all",
  SEARCH: "search",
  RECENT: "recent",
  NONE: "none",
  ERROR: "error",
};

export const initialState = {
  // requesters, //dummy data
  all_requesters_data: null,
  type_of_data: TYPE_OF_ALL_REQUESTERS_DATA.INIT,
  sort_order: SORT_TYPE.ASCENDING,
  success: null,
  loading: true,
};
