import { LOGGED_USER_TYPE } from "../../../utils/CustomInputs/userTypes";

export const setUserType = (userType) => {
  switch (userType) {
    case LOGGED_USER_TYPE.OPS_ADMIN:
      return LOGGED_USER_TYPE.OPS_ADMIN;
    case LOGGED_USER_TYPE.OPS_USER:
      return LOGGED_USER_TYPE.OPS_USER;
    case LOGGED_USER_TYPE.SALES:
      return LOGGED_USER_TYPE.SALES;
    case LOGGED_USER_TYPE.CRM:
      return LOGGED_USER_TYPE.CRM;
    case LOGGED_USER_TYPE.TEAM_LEAD:
      return LOGGED_USER_TYPE.TEAM_LEAD;
    default:
      return LOGGED_USER_TYPE.NULL_USER;
  }
};

export const accessPermit = (userType) => {
  // const valid = false;
  console.log(userType);
  if (userType !== LOGGED_USER_TYPE.TEAM_LEAD || LOGGED_USER_TYPE.OPS_USER)
    console.log("true");
  return { valid: true };
  // return true;

  // if (!valid) {
  // let it hit post or put
  // }
};

// const accessPermission = accessPermit(sessionStorage.getItem("user_type"));
// if (accessPermission.valid) {
//   // yes it can edit or add
// }

export const initialState = {
  data: {
    first_name: null,
    last_name: null,
    organization: null,
    phone_number_ext: null,
    phone_number: null,
    whatsapp_number_ext: null,
    whatsapp_number: null,
    image: null,
    email: null,
    profile_id: null,
    user_type: LOGGED_USER_TYPE.NULL_USER,
  },
  error: null,
  loading: false,
};
