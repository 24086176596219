import React, { useContext, useEffect, useState, useRef } from "react";
import {
  ApiContext,
  ToolTipError,
  ERROR_REGEX,
  date_time_convertor,
  FileType,
  devEnv,
  FeedbackRatingEmoji,
} from "../../index.js";
import {
  OpsArchiveDashboardFileContext,
  OpsArchiveFilesArrayContext,
  LoaderContext,
  OPS_ROUTES,
  OpsRouteContext,
} from "../OpsComponent";
import { useHistory, useParams } from "react-router-dom";
import { getAuthData } from "../../helpers/request";
import {
  FaSearch,
  FaSortAmountDownAlt,
  FaSortAmountDown,
  FaAngleDown,
} from "react-icons/fa";
import { assignment_services } from "../../req_jsons/assignment_services.js";
import dummy_all_assignment from "../../archive/test_data/dummy_data-v1/dummy_all_assignment.json";
import NameShorteningCmpn from "../../utils/NameShorteningCmpn.js";
const opsSidebarTextContext = React.createContext();

//============================SIDEBAR============================
export let file_id = null;

// const FileType = ({filetype, active})=> {

//     return (
//         <>
//             {(filetype?.includes("TR")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>TR</span></div>)}
//             {(filetype?.includes("DP")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>DP</span></div>)}
//             {(filetype?.includes("PD")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>PD</span></div>)}
//             {(filetype?.includes("GD")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>GD</span></div>)}
//             {(filetype?.includes("VG")) && (<div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}><span>VG</span></div>)}
//         </>
//     )
// }

// const FileType = ({filetype, active})=> {
//     if (filetype?.includes("VG") && filetype?.includes("TR")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TR</div>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>VG</div>
//             </>
//         )
//     } else if (filetype?.includes("TD") && filetype?.includes("TR")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TR</div>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TD</div>
//             </>
//         )
//     } else if (filetype?.includes("VG")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>VG</div>
//             </>
//         )
//     } else if (filetype?.includes("TR")){
//         return (
//             <>
//                 <div className={`ops-file-stage ${(active) && "ops-file-stage-active"}`}>TR</div>
//             </>
//         )
//     } else {
//         return (<></>)
//     }
// }

// const File = ({ file,active}) => {
//     const history = useHistory();
//     const API_URL = useContext(ApiContext)
//     const {asgn_id} = useParams();
//     const {opsArchiveDisplayedFile, setOpsArchiveDisplayedFile } = useContext(OpsArchiveDashboardFileContext);
//     const { setBottomTab } = useContext(BottomContext);

//     const showFileDetails = (e) => {
//         // Removing active class from all other files
//         document.querySelectorAll('.sidebar-ops-file').forEach((el) => {
//             el.classList.remove('sidebar-ops-file-active')
//             Array.from(el.querySelectorAll('.ops-file-stage')).map(el => el.classList.remove('ops-file-stage-active'));
//         })
//         // adding active file to the clicked file
//         e.target.closest('.sidebar-ops-file').classList.toggle('sidebar-ops-file-active');
//         Array.from(e.target.closest('.sidebar-ops-file').querySelectorAll('.ops-file-stage')).map(el => el.classList.add('ops-file-stage-active'));

//         // getAuthData(`${API_URL}/api-ops/archive/v1/all-assignments/?id=${asgn_id}`)
//         //     .then((res) => {
//         //         if (res.success === true) {
//         //             file_id=file.id;
//         //             setOpsArchiveDisplayedFile(res.last_assignment);
//         //             history.push(`/ops-archive/${file?.id}`);
//         //             setBottomTab(BOTTOM_TAB.DETAILS);
//         //         }
//         //     })
//         //     .catch(err => {
//         //         console.error(err);
//         //     })
//         if(devEnv){
//             setOpsArchiveDisplayedFile(dummy_all_assignment.last_assignment);
//             history.push(`/ops-archive/${file?.id}`);
//             setBottomTab(BOTTOM_TAB.DETAILS);
//         }else{
//         getAuthData(`${API_URL}/api-ops/archive/v1/all-assignments/${file?.id}/`)
//             .then((res) => {
//                 if (res.success === true) {
//                     file_id=file.id;
//                     setOpsArchiveDisplayedFile(res.last_assignment);
//                     history.push(`/ops-archive/${file?.id}`);
//                     setBottomTab(BOTTOM_TAB.DETAILS);
//                 }
//             })
//             .catch(err => {
//                 console.error(err);
//             })
//         }
//     }

//     return (
//         <div className={`sidebar-ops-file ${(active) && "sidebar-ops-file-active"}`} onClick={showFileDetails}>
//             <div className="ops-file-stages">
//                 <FileType filetype={file.services.map((service)=>assignment_services[service].code)} active={active}/>
//             </div>
//             <div className="sidebar-file-info sidebar-file-info-head">
//                 <FileIcon filename={file?.file_name} />
//                 {/* <span>{(file?.file_names.length>50) ? (file?.file_names?.substr(0,50)+'...') : (file?.file_names)}</span> */}
//                 <span>
//                     {
//                         `${(file?.file_names[0].length>50) ? (file?.file_names[0]?.substr(0,40)+'...') : (file?.file_names[0])} ${(file?.file_names?.length>1) ? ("+ "+String(file?.file_names?.length-1)) : ''}`
//                     }
//                 </span>
//             </div>
//             {/* <div className="sidebar-file-info sidebar-file-filename"></div> */}
//             <div className="sidebar-file-info sidebar-file-word-count">{file?.sidebar_counts}</div>
//             {/* <div className="sidebar-file-info sidebar-file-word-count">{file?.word_count} Words,{file?.page_count || 0} Pages</div> */}
//             <div className="sidebar-file-info sidebar-delivered">Delivered <span className="delivered-time" style={{ color: (parseInt(file?.duration) >= 0) ? 'rgb(80, 207, 80)' : 'red', }}>{file.duration}</span></div>
//             <div className="sidebar-file-info sidebar-owner">{file?.requester_name}</div>
//             {/* <div className="sidebar-file-info sidebar-committed-delivery">{date_time_convertor(file?.committed_delivery).split(",").slice(0,2).join(',')}</div> */}
//             {/* <div className="sidebar-file-info sidebar-committed-delivery">{file?.committed_delivery+" IST"}</div> */}

//         </div>
//     )
// }

const File = ({ file, active, hidden }) => {
  const history = useHistory();
  // const API_URL = useContext(ApiContext);
  // const { asgn_id } = useParams();
  // const { setOpsArchiveDisplayedFile } = useContext(
  //   OpsArchiveDashboardFileContext,
  // );

  const showFileDetails = (e) => {
    // if (devEnv) {
    //   history.push(`/ops-archive/${file.id}`); // Setting Dummy Data
    //   return;
    // }
    history.push(`/ops-archive/${file?.id}`);
    // Removing active class from all other files
    document.querySelectorAll(".ops-sidebar-file").forEach((el) => {
      el.classList.remove("ops-sidebar-file-active");
      Array.from(el.querySelectorAll(".ops-file-stage")).map((el) =>
        el.classList.remove("ops-file-stage-active"),
      );
    });
    // adding active file to the clicked file
    e.target
      .closest(".ops-sidebar-file")
      .classList.toggle("ops-sidebar-file-active");
    Array.from(
      e.target.closest(".ops-sidebar-file").querySelectorAll(".ops-file-stage"),
    ).map((el) => el.classList.add("ops-file-stage-active"));

    // getAuthData(`${API_URL}/api-ops/archive/v1/all-assignments/?id=${asgn_id}`)
    //   .then((res) => {
    //     if (res.success === true) {
    //       file_id = file.id;

    //       setOpsArchiveDisplayedFile(res.last_assignment);
    //       history.push(`/ops-archive/${file?.id}`);
    //       // setBottomTab(BOTTOM_TAB.DETAILS);
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  };

  return (
    <>
      {/* <div className={`ops-sidebar-file ${(active) && "ops-sidebar-file-active"}`} onClick={showFileDetails}>
            <div className="ops-file-stages">
                <FileType filetype={file.assignment_type} active={active}/>
            </div>
            <div className="sidebar-file-info sidebar-file-info-head">
                <span>{(file?.file_name.length>50) ? (file?.file_name?.substr(0,50)+'...') : (file?.file_name)}</span>
            </div>
            <div className="sidebar-file-info sidebar-file-word-count">{file?.sidebar_counts}</div>
            <div className="sidebar-file-info sidebar-delivered">Delivered <span className="delivered-time" style={{ color: (parseInt(file?.duration) >= 0) ? 'rgb(80, 207, 80)' : 'red', }}>{file.duration}</span></div>
            <div className="sidebar-file-info sidebar-owner">{file?.requester_name}</div>
            <div className="sidebar-file-info sidebar-committed-delivery">{date_time_convertor(file?.committed_delivery).split(",").slice(0,2).join(',')}</div>

        </div> */}
      <div
        className={`ops-sidebar-file
            ${active && "ops-sidebar-file-active"} ${
          !hidden && "ops-sidebar-file-show"
        }`}
        onClick={showFileDetails}
      >
        <div className="ops-file-stages">
          <FileType
            filetype={file.services.map(
              (service) => assignment_services[service].code,
            )}
            active={active}
          />
        </div>
        <div className="ops-sidebar-file-feedback">
          <FeedbackRatingEmoji feedback={file.feedback} />
        </div>
        <div className="ops-sidebar-file-info ops-sidebar-file-filename">
          <NameShorteningCmpn
            name={file?.file_names[0]}
            max_length={40}
            last_chars_length={6}
          />
          {` ${
            file?.file_names?.length > 1
              ? "+ " + String(file?.file_names?.length - 1)
              : ""
          }`}
        </div>
        <div className="ops-sidebar-file-info ops-sidebar-file-details">
          {file?.unit_details.length < 2 ? (
            file?.unit_details?.map((detail, idx) => {
              return (
                <span
                  key={idx}
                  className="client-sidebar-file-subservice-detail"
                >
                  {`${detail?.unit_count} ${detail?.unit_type}`}
                  {file.unit_details.lenght > 1 && ","}
                </span>
              );
            })
          ) : (
            <>
              {file?.unit_details.slice(0, 2)?.map((detail, idx) => {
                return (
                  <span
                    key={idx}
                    className="client-sidebar-file-subservice-detail"
                  >
                    {`${detail?.unit_count} ${detail?.unit_type}`},
                  </span>
                );
              })}
              <span>...</span>
            </>
          )}
        </div>
        <div className="ops-sidebar-file-info ops-sidebar-file-details">
          Delivered{" "}
          <span
            className="delivered-time"
            style={{
              color: parseInt(file?.duration) >= 0 ? "rgb(80, 207, 80)" : "red",
            }}
          >
            {file.duration}
          </span>
        </div>
        <div className="ops-sidebar-file-info ops-sidebar-file-details">
          <NameShorteningCmpn
            name={file?.requester_name}
            max_length={10}
            last_chars_length={3}
          />
          {`, ${file?.client_code}`}
        </div>
        <div className="ops-sidebar-file-info ops-sidebar-file-details">
          {date_time_convertor(file?.committed_delivery)
            ?.split(",")
            .slice(0, 2)
            .join(",")}
        </div>
      </div>
    </>
  );
};

export const sort_orders = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

const FileHistoryHead = () => {
  const [sort, setSort] = useState(sort_orders.ASCENDING);
  const [, setSearched] = useState("");
  const search_word = useRef(null);
  const API_URL = useContext(ApiContext);
  const { setOpsArchiveFilesArray } = useContext(OpsArchiveFilesArrayContext);
  const { setOpsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );
  const { setSidebarText } = useContext(opsSidebarTextContext);
  const { setLoader } = useContext(LoaderContext);
  const history = useHistory();

  const handleSort = (e) => {
    e.preventDefault();
    setSort((prev) => {
      if (prev === sort_orders.ASCENDING) {
        getAuthData(
          `${API_URL}/api-ops/archive/v1/all-assignments/?sort=${sort_orders.DESCENDING}`,
        )
          .then((res) => {
            if (res.success === true) {
              setOpsArchiveFilesArray(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return sort_orders.DESCENDING;
      } else if (prev === sort_orders.DESCENDING) {
        getAuthData(
          `${API_URL}/api-ops/archive/v1/all-assignments/?sort=${sort_orders.ASCENDING}`,
        )
          .then((res) => {
            if (res.success === true) {
              setOpsArchiveFilesArray(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return sort_orders.ASCENDING;
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoader("Please wait while the data is being loaded.");
    setSidebarText("Fetching...");
    history.push(`/ops-archive?search=${search_word.current.value}`);

    if (devEnv) {
      setOpsArchiveFilesArray(dummy_all_assignment.data);
      setOpsArchiveDisplayedFile(dummy_all_assignment.last_assignment);
      setLoader(false);
    } else {
      getAuthData(
        `${API_URL}/api-ops/archive/v1/all-assignments/?search=${search_word.current.value}`,
      )
        .then((res) => {
          if (res.success === true) {
            setSearched(search_word.current.value);
            setOpsArchiveFilesArray(res.data);
            setOpsArchiveDisplayedFile(res.last_assignment);
            setLoader(false);
            res.data.length < 1 && setSidebarText("No assignments found");
          }
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    }
  };

  return (
    <div className="file-history-head">
      <div className="sidebar-head">
        <span>Archive</span>
        {sort === sort_orders.ASCENDING && (
          <button className="ops-sort-btn" onClick={handleSort}>
            <FaSortAmountDownAlt style={{ fontSize: "1.3vw" }} />
          </button>
        )}
        {sort === sort_orders.DESCENDING && (
          <button className="ops-sort-btn" onClick={handleSort}>
            <FaSortAmountDown style={{ fontSize: "1.3vw" }} />
          </button>
        )}
      </div>
      <form className="search-sort-form" onSubmit={handleSearch}>
        <input
          type="text"
          className="search-input search-file"
          onChange={(e) => {
            ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
          }}
          ref={search_word}
          defaultValue={window.location?.search.split("=")[1] || ""}
          name="search"
          id="search"
          placeholder="Search..."
        />
        <button className="search-btn" type="submit">
          <FaSearch />
        </button>
      </form>
    </div>
  );
};

const FileHistory = () => {
  const { opsArchiveFilesArray } = useContext(OpsArchiveFilesArrayContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );
  const [hiddenItems, setHiddenItems] = useState([]);
  const { asgn_id } = useParams();

  const toggleMonth = (e, item) => {
    e.preventDefault();
    const monthEl = e.target.closest(".ops-month-container");

    if (
      monthEl
        .querySelector(".ops-month-arrow")
        .classList.contains("ops-month-arrow-active")
    ) {
      // Removing item
      setHiddenItems((prev) => {
        const index = prev.indexOf(item);
        if (index === -1) {
          return prev;
        }
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
      monthEl
        .querySelector(".ops-month-arrow")
        .classList.remove("ops-month-arrow-active");
      monthEl.querySelectorAll(".ops-sidebar-file").forEach((file) => {
        file.classList.remove("ops-sidebar-file-hide");
      });
    } else {
      // adding item
      setHiddenItems((prev) => {
        return [...prev, item];
      });
      monthEl
        .querySelector(".ops-month-arrow")
        .classList.add("ops-month-arrow-active");
      monthEl.querySelectorAll(".ops-sidebar-file").forEach((file) => {
        file.classList.add("ops-sidebar-file-hide");
      });
    }

    // monthEl.classList.toggle("client-month-container-hidden")    // simple Hidding without transition
  };

  const closeResponsiveSidebar = (e) => {
    e.preventDefault();
    if (!e.target.closest(".ops-sidebar-file")) return;
    document
      .querySelector(".ops-sidebar")
      ?.classList.remove("ops-sidebar-show");
    document
      .querySelector(".ops-account-sidebar")
      ?.classList.remove("ops-sidebar-show");
  };

  return (
    // <div className="files-history" onClick={(e)=>{closeResponsiveSidebar(e)}}>
    //     <div className="assignments">
    //         {(opsArchiveFilesArray?.length>0) ? opsArchiveFilesArray?.map((item) => (
    //                     <Collapsible trigger={item.title} key={item.title} open={true} className="sidebar-collapse" triggerOpenedClassName="CustomTriggerCSS--open" triggerClassName="CustomTriggerCSS" >
    //                         {item?.info?.map((file) => {
    //                             if (parseInt(asgn_id) === file.id || opsArchiveDisplayedFile?.id === file.id) {
    //                                 return <File key={file?.id} file={file} active={true} />
    //                             }
    //                             else {
    //                                 return <File key={file?.id} file={file} active={false} />
    //                             }
    //                         })}
    //                     </Collapsible>
    //         ))
    //         :
    //         <span className="ops-file-history-empty">{sidebarText}</span>
    //     }
    //     </div>
    // </div>
    <div
      className="files-history"
      onClick={(e) => {
        closeResponsiveSidebar(e);
      }}
    >
      {opsArchiveFilesArray?.length > 0 ? (
        opsArchiveFilesArray?.map((item, idx) => (
          <div key={idx} className="ops-month-container">
            <div
              className="ops-sidebar-month-head"
              onClick={(e) => toggleMonth(e, item)}
            >
              <span className="ops-month">{item.title}</span>
              <FaAngleDown className="ops-month-arrow" />
            </div>
            <div className="ops-file-list">
              {item?.info?.map((file, idx) => {
                if (
                  parseInt(asgn_id) === file.id ||
                  opsArchiveDisplayedFile?.id === file.id
                ) {
                  return (
                    <File
                      key={idx}
                      file={file}
                      active={true}
                      hidden={hiddenItems.includes(item)}
                    />
                  );
                } else {
                  return (
                    <File
                      key={idx}
                      file={file}
                      active={false}
                      hidden={hiddenItems.includes(item)}
                    />
                  );
                }
              })}
            </div>
          </div>
        ))
      ) : (
        <span className="ops-file-history-empty">No Assignments Yet</span>
      )}
    </div>
  );
};
const settingDefaultActiveArchiveFile = () => {
  if (Array.from(document.querySelectorAll(".sidebar-ops-file").length > 0)) {
    // Removing active class from all other files
    document.querySelectorAll(".sidebar-ops-file").forEach((el) => {
      el.classList.remove("sidebar-ops-file-active");
      Array.from(el.querySelectorAll(".ops-file-stage"))?.map((el) =>
        el.classList.remove("ops-file-stage-active"),
      );
    });
    // adding active file to the clicked file
    document
      .querySelectorAll(".sidebar-ops-file")[0]
      ?.classList.add("sidebar-ops-file-active");
    Array.from(
      document
        .querySelectorAll(".sidebar-ops-file")[0]
        .querySelectorAll(".ops-file-stage"),
    )?.map((el) => el.classList.add("ops-file-stage-active"));
  }
};

const OpsArchiveSideBar = () => {
  const API_URL = useContext(ApiContext);
  const { setOpsArchiveFilesArray } = useContext(OpsArchiveFilesArrayContext);
  const { setOpsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );
  const { asgn_id } = useParams();
  const { setLoader } = useContext(LoaderContext);
  const [sidebarText, setSidebarText] = useState(null);
  const route = useContext(OpsRouteContext);

  const getArchiveData = () => {
    setLoader("Please wait while the data is being loaded.");
    if (devEnv) {
      // console.log(dummy_all_assignment)
      setOpsArchiveFilesArray(dummy_all_assignment.data);
      setOpsArchiveDisplayedFile(dummy_all_assignment.last_assignment);
      setLoader(false);
    } else {
      getAuthData(
        `${API_URL}/api-ops/archive/v1/all-assignments/?id=${asgn_id}`,
      )
        .then((res) => {
          if (res.success === true) {
            setOpsArchiveFilesArray(res.data);
            setOpsArchiveDisplayedFile(res.last_assignment);
            // setOpsArchiveDisplayedFile(res.last_assignment);
            // settingDefaultActiveFile();
            setLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    }
  };

  const getArchiveAsgnData = () => {
    setLoader("Please wait while the data is being loaded.");
    if (devEnv) {
      setOpsArchiveDisplayedFile(dummy_all_assignment.last_assignment);
      setLoader(false);
    } else {
      getAuthData(`${API_URL}/api-ops/archive/v1/all-assignments/${asgn_id}/`)
        .then((res) => {
          if (res.success === true) {
            setOpsArchiveDisplayedFile(res.last_assignment);
            // setOpsArchiveDisplayedFile(res.last_assignment);
            // settingDefaultActiveFile();
            setLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    }
  };

  const getSearchArchiveData = (search_word) => {
    setLoader("Please wait while the data is being loaded.");
    if (devEnv) {
      // console.log(dummy_all_assignment)
      setOpsArchiveFilesArray(dummy_all_assignment.data);
      setOpsArchiveDisplayedFile(dummy_all_assignment.last_assignment);
      setLoader(false);
    } else {
      getAuthData(
        `${API_URL}/api-ops/archive/v1/all-assignments/?search=${search_word}`,
      )
        .then((res) => {
          if (res.success === true) {
            // setSearched(search_word.current.value)
            setOpsArchiveFilesArray(res.data);
            setOpsArchiveDisplayedFile(res.last_assignment);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    }
  };
  // useEffect(() => {
  //     getAuthData(`${API_URL}/api-ops/archive/v1/all-assignments/?id=${asgn_id}`)
  //         .then((res) => {
  //             if (res.success === true) {
  //                 setOpsArchiveFilesArray(res.data);
  //                 setOpsArchiveDisplayedFile(res.last_assignment);
  //                 settingDefaultActiveFile();

  //             }
  //         })
  //         .catch(err => {
  //             console.error(err);
  //         })
  // }, [asgn_id])

  useEffect(() => {
    const search = window.location?.search.split("=")[1];
    if (search) {
      getSearchArchiveData(search);
    } else {
      getArchiveAsgnData();
    }
  }, [asgn_id]);

  useEffect(() => {
    const search = window.location?.search.split("=")[1];
    if (search) {
      getSearchArchiveData(search);
    } else {
      getArchiveData();
    }
  }, []);

  return (
    <opsSidebarTextContext.Provider value={{ sidebarText, setSidebarText }}>
      <div
        className={`ops-sidebar ops-sidebar-mobile ${
          route === OPS_ROUTES.ARCHIVE && "ops-sidebar-show"
        }`}
        id="ops-archive-sidebar"
      >
        <FileHistoryHead />
        <FileHistory />
      </div>
    </opsSidebarTextContext.Provider>
  );
};

export { OpsArchiveSideBar, settingDefaultActiveArchiveFile };
