import React, { useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { getAuthData } from "../../../../helpers/request.js";
import useDebounceEffect from "../../../../hooks/useDebounceEffect.js";
import { devEnv } from "../../../../index.js";

import "./UploadShare.css";

const UploadShare = ({
  client_code,
  entity_code,
  uploadShareEmails,
  setUploadShareEmails,
  uploadShareError,
  setUploadShareError,
}) => {
  // const API_URL = useContext(ApiContext);
  const [shareNameInput, setShareNameInput] = useState(null);
  const sharedNameInputRef = useRef(null);
  const [shareNameSuggestions, setShareNameSuggestions] = useState([]);
  const maxSharingNames = 10;

  const addSuggestion = (e) => {
    if (uploadShareEmails.length === maxSharingNames) {
      setUploadShareError({
        ...uploadShareError,
        message: `Only ${maxSharingNames} names can be shared at a time.`,
      });
      return;
    } // setting a limit for names
    const emailRegex = /\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*/g;
    const share_form_input = sharedNameInputRef.current.value?.toLowerCase();
    const suggested_item = e?.target.innerText?.toLowerCase();
    setUploadShareError({ ...uploadShareError, message: null });
    // only submit if its an email
    if (suggested_item && uploadShareEmails.includes(suggested_item)) {
      setUploadShareError({
        ...uploadShareError,
        message: "Email already added.",
      });
      return;
    } else if (suggested_item && emailRegex.test(suggested_item)) {
      setUploadShareEmails([...uploadShareEmails, suggested_item]);
      clearShareInput();
      return;
    } else if (
      !suggested_item &&
      uploadShareEmails.includes(share_form_input)
    ) {
      setUploadShareError({
        ...uploadShareError,
        message: "Email already added.",
      });
      return;
    } else if (!suggested_item && emailRegex.test(share_form_input)) {
      setUploadShareEmails([...uploadShareEmails, share_form_input]);
      clearShareInput();
      return;
    } else {
      setUploadShareError({
        ...uploadShareError,
        message: "Please enter an email.",
      });
      return;
    }
  };

  const removeName = (_e, idx) => {
    // instantly change the state when remove
    setUploadShareEmails([
      ...uploadShareEmails.slice(0, idx),
      ...uploadShareEmails.slice(idx + 1, uploadShareEmails.length),
    ]);
  };

  const extractEmailArrays = (data) => {
    const new_arr = data.map((cur) => {
      return Object.values(cur)[0];
    });
    return new_arr;
  };

  const getSuggestions = () => {
    if (devEnv) {
      console.log("client code", client_code);
      setShareNameSuggestions(["soorajghosh@gmail.com", "sourabh@gmail.com"]);
    } else if (!devEnv && shareNameInput && shareNameInput?.length > 1) {
      getAuthData(
        `${process.env.REACT_APP_API_URL}/api-ops/misc/v1/suggestion/?search=${shareNameInput}&entity_code=${entity_code}&client_code=${client_code}`,
      )
        .then((res) => {
          if (res.success === true) {
            setShareNameSuggestions([...extractEmailArrays(res.data)]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const clearShareInput = () => {
    // Clearing the input and its suggestions after submitting the name
    sharedNameInputRef.current.value = "";
    setShareNameInput(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addSuggestion();
  };

  //   const removeShareErrors = () => {
  //     setUploadShareError({ emails: [], message: null });
  //     Array.from(document.querySelectorAll(".upload-share-name-item")).map(
  //       (el) => {
  //         el.classList.remove("upload-share-name-item-err");
  //       }
  //     );
  //   };

  useDebounceEffect(getSuggestions, [shareNameInput], 700);

  return (
    <div className="upload-share-container">
      <div className="upload-share-names-container">
        {uploadShareEmails?.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`upload-share-name-item ${
                uploadShareError?.emails?.includes(item) &&
                "upload-share-name-item-err"
              }`}
            >
              <span className="upload-share-name">{item}</span>
              <button
                className="upload-share-remove-btn"
                onClick={(e) => {
                  removeName(e, idx);
                }}
              >
                <FaTimes />
              </button>
            </div>
          );
        })}
      </div>

      <form className="upload-share-modal-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="ops-dashboard-input upload-share-modal-input"
          ref={sharedNameInputRef}
          defaultValue={shareNameInput}
          onChange={(e) => {
            setUploadShareError({ ...uploadShareError, message: null });
            setShareNameInput(e.target.value);
            getSuggestions(e);
          }}
          autoFocus
        />

        {uploadShareError?.message && (
          <div className="ops-input-error upload-share-error">
            {uploadShareError?.message}
          </div>
        )}

        {shareNameInput && (
          <div className="upload-share-suggestions-container">
            <span
              className="upload-share-suggestion-item"
              onClick={addSuggestion}
            >
              {shareNameInput}
            </span>
            {shareNameSuggestions.map((item, idx) => {
              return (
                <span
                  className="upload-share-suggestion-item"
                  key={idx}
                  onClick={addSuggestion}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}
      </form>
    </div>
  );
};

export default UploadShare;
