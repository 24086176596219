export const createClientOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur, value: cur };
  });
  return options_format_data;
};

export const createOptionValue = (val) => {
  return { label: val, value: val };
};

export const createClientListOptionValue = (val) => {
  return { label: val, value: val, id: val?.id };
};

export const createClientListOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return {
      label: cur?.company_name,
      value: cur?.company_name,
      id: cur?.id,
      domain: cur?.company_domain_list,
      code: cur?.client_code,
    };
  });
  return options_format_data;
};

export const user_industry_options = [
  {
    label: "Accomodation & Food Services",
    value: "Accomodation & Food Services",
  },
  { label: "Accounting and Tax", value: "Accounting and Tax" },
  { label: "Administrative Services", value: "Administrative Services" },
  { label: "Agriculture & Forestry", value: "Agriculture & Forestry" },
  { label: "Construction", value: "Construction" },
  { label: "Economic & Social Welfare", value: "Economic & Social Welfare" },
  { label: "Educational Services", value: "Educational Services" },
  {
    label: "Electronics & Communication",
    value: "Electronics & Communication",
  },
  { label: "Engineering", value: "Engineering" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Finance & Insurance", value: "Finance & Insurance" },
  { label: "Foreign Relations", value: "Foreign Relations" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Human Resources", value: "Human Resources" },
  { label: "Law & Order", value: "Law & Order" },
  { label: "Legal Services", value: "Legal Services" },
  { label: "Consulting", value: "Consulting" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Marketing & Advertising", value: "Marketing & Advertising" },
  { label: "Military & Defense", value: "Military & Defense" },
  { label: "Mining & Extraction", value: "Mining & Extraction" },
  { label: "News & Media", value: "News & Media" },
  { label: "Other", value: "Other" },
  { label: "Public Goods & Services", value: "Public Goods & Services" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Retail & Ecommerce", value: "Retail & Ecommerce" },
  { label: "Professional Services", value: "Professional Services" },
  { label: "Trade", value: "Trade" },
  {
    label: "Transportation & Warehousing",
    value: "Transportation & Warehousing",
  },
  { label: "Travel & Tourism", value: "Travel & Tourism" },
  {
    label: "Utilities (Electricity, Water and Sewage)",
    value: "Utilities (Electricity, Water and Sewage)",
  },
];

export const job_func_options = [
  { label: "Accounts", value: "Accounts" },
  { label: "Facilities", value: "Facilities" },
  { label: "Human Resources", value: "Human Resources" },
  { label: "Marketing", value: "Marketing" },
  { label: "Operations", value: "Operations" },
  { label: "Procurement", value: "Procurement" },
  { label: "Sales", value: "Sales" },
  { label: "Technology", value: "Technology" },
];

export const initialState = {
  client_form_data: {
    is_note_understand: false,
    is_instruction_understand: false,
    is_copy_address: false,
    client_data: {
      client_id: null,
      client_name: null,
      client_domain_list: [],
      client_code: {
        error: null,
        loading: false,
        value: null,
      },
      client_logo: null,
    },
    entity_data: {
      entity_name: null,
      address_1: null,
      address_2: null,
      entity_zip_code: null,
      entity_country: null,
      entity_city: null,
    },
    user_data: {
      user_industry: null,
      user_name: null,
      user_email: null,
      user_phone_no: [],
      user_country: null,
      user_city: null,
      user_designation: null,
      user_job_function: null,
    },
    form_options: {
      existing_clients: { list: [], loading: false, error: null },
      entity_country: { list: [], loading: false, error: null },
      entity_city: { list: [], loading: false, error: null },
      user_city: { list: [], loading: false, error: null },
      user_country: { list: [], loading: false, error: null },
      industry: { list: user_industry_options, loading: false, error: null },
      job_func: { list: job_func_options, loading: false, error: null },
    },
    loading: false,
    error: null,
  },
  client_list_data: {
    loading: false,
    list_data: null,
    error: null,
  },
  edit_client_data: {
    edit_client_id: null,
    edit_client_name: null,
    edit_client_domain_list: [],
    edit_client_code: null,
    loading: false,
    error: null,
  },
  edit_client_modal: false,
  edit_client_id: null,
  success: null,
};
