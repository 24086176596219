import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const utilsDataSlice = createSlice({
  name: "utils-data",
  initialState,
});

export default utilsDataSlice.reducer;
