// import requesterInfo from "../../../archive/test_data/dummy_requester_data/dummy_requesters_info.json";

export const TYPE_OF_REQUESTER_DATA = {
  INIT: "initial",
  ID: "id",
  UNDEFINED: "undefined_id",
  NONE: "none",
  ERROR: "error",
};

export const initialState = {
  // requesterInfo, //dummy data
  requester_details: null,
  type_of_data: TYPE_OF_REQUESTER_DATA.INIT,
  success: null,
};
