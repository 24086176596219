export const TYPE_OF_ASSIGNMENT_DATA = {
  INIT: "initial",
  ID: "id",
  UNDEFINED: "undefined_id",
  NONE: "none",
  ERROR: "error",
};

export const initialState = {
  loading: false,
  file_details: null,
  type_of_data: TYPE_OF_ASSIGNMENT_DATA.INIT,
  error: null,
};

export const file_links_obj = (file_obj) => {
  /*
      This functions converts the links object to this:
          const obj1 = {
              name: "new_file.jpg",
              links: {
                  ppt: "https://link.com",
                  docx: "https://link2.com",
                  external_link: "https://ex_link.com"
              }
          }
      to
          const obj3 = {
              file_links:[
                  {
                      name: "new_file.ppt",
                      link: "https://link.com"
                  },
                  {
                      name: "new_file.docx",
                      link: "https://link2.com"
                  }
              ],
              external_link:[
                  {
                      name: "new_file.jpg",
                      link: "https://ex_link.com"
                  },
              ]
          }

          OR

          const obj1 = {
              name: "new_file.jpg",
              links: {
                  ppt: "https://link.com",
                  docx: "https://link2.com",
                  external_text: 1234
              }
          }

          to

          const obj3 = {
              file_links:[
                  {
                      name: "new_file.ppt",
                      link: "https://link.com"
                  },
                  {
                      name: "new_file.docx",
                      link: "https://link2.com"
                  }
              ],
              external_text:[
                  {
                      name: "new_file.jpg",
                      text: 1234
                  },
              ]
          }
      */
  let file_link_obj = {
    file_links: [],
    external_link: [],
    external_text: [],
  };
  file_obj?.links &&
    Object.entries(file_obj?.links)?.forEach(([ext, link]) => {
      if (ext !== "external_link" && ext !== "external_text") {
        const download_file_name =
          file_obj.name.split(".").slice(0, -1).join(".") + "." + ext; // making the file name with the extention provided
        file_link_obj.file_links.push({
          name: download_file_name,
          link: link,
          ext,
        });
      } else if (ext === "external_link") {
        file_link_obj.external_link.push({
          name: file_obj.name,
          link: link,
          ext,
        });
      } else if (ext === "external_text") {
        file_link_obj.external_text.push({
          name: file_obj.name,
          text: link,
          ext,
        });
      }
    });
  return file_link_obj;
};
