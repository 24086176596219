import React, { useContext, useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  date_time_convertor,
  DownloadFiles,
  FeedbackRatingEmoji,
} from "../../../../..";
import { file_links_obj } from "../../../../../store/features/liveAssignmentData/common";
import {
  NotFoundErrorContext,
  OpsArchiveDashboardFileContext,
  OpsRouteContext,
  OPS_ROUTES,
} from "../../../../OpsComponent";

import "./HistoryTab.css";

// const RatingContainer = ({ feedback }) => {
//   const [hover, setHover] = useState(false);

//   return (
//     <div className="ops-feedback-rating-container">
//       <span
//         onMouseOver={() => setHover(true)}
//         onMouseLeave={() => setHover(false)}
//       >
//         {emojis[feedback][hover ? "active" : "normal"]}
//       </span>
//     </div>
//   );
// };

const VersionCard = ({ version, active, idx }) => {
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  // const handleDownloadTargetAll = (e) => {
  //     e.preventDefault();
  //     let allFileLinks = version?.files.map((file)=>{
  //         return Object.entries(file?.links)?.map((link)=>{
  //             const filename = file.name.split('.').slice(0,-1).join('.')+'.'+link[0]
  //             return {link:link[1],name:filename}
  //         })
  //     })?.flat();
  //     if (allFileLinks && allFileLinks.length>0){
  //         try{
  //             DownloadFiles(allFileLinks)
  //         } catch (err) {
  //             setNotFoundError(true)
  //         }
  //     } else {
  //         alert('No Links found to download!')
  //     }

  // }

  const handleDownloadTargetAll = (e) => {
    e.preventDefault();

    // let allFileLinks = version?.files
    //   .map((file) => {
    //     return (
    //       file?.links &&
    //       Object.entries(file?.links).map((link) => {
    //         const filename =
    //           file.name.split(".").slice(0, -1).join(".") + "." + link[0];
    //         return { link: link[1], name: filename };
    //       })
    //     );
    //   })
    //   .flat();

    let allFilesLinks = version?.files
      ?.map((file) => file_links_obj(file).file_links)
      .flat();

    let zip_link_arr = version?.zip_link &&
      version?.zip_link?.name &&
      version?.zip_link?.link && [
        { name: version?.zip_link?.name, link: version?.zip_link?.link },
      ];

    try {
      if (zip_link_arr) {
        DownloadFiles(zip_link_arr, true);
      } else {
        DownloadFiles(allFilesLinks, true);
      }
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <div className="version-card card-layout" data-idx={idx}>
      <div className={`version-card-head`}>
        <div className="version-card-info">
          <span className="version-card-info-value">
            Version {version.version}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-value">
            {version.files.length} file(s)
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Committed </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.committed_delivery)}
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Delivered </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.actual_delivery)}
          </span>
        </div>
        <div className="version-card-info">
          <FeedbackRatingEmoji feedback={version.overall_feedback} />
        </div>
      </div>

      <div
        className={`version-card-content ${
          active && "version-card-content-show"
        }`}
      >
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Committed </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.committed_delivery)}
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Delivered </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.actual_delivery)}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-label">Delivery Notes</span>
          <span className="version-card-info-value">
            {version?.delivery_notes?.map((source, idx) => {
              return (
                <div className="version-card-delivery-notes" key={idx}>
                  {source?.names?.map((name, idx) => (
                    <span
                      key={idx}
                      className="version-card-delivery-notes-file-name"
                    >
                      @{name}
                    </span>
                  ))}
                  <span className="version-card-delivery-note">
                    {source.notes}
                  </span>
                </div>
              );
            })}
          </span>
        </div>
        <div className="version-card-info">
          <div className="version-card-info-head">
            <span className="version-card-info-label">File(s)</span>

            {version?.files?.every((file) => {
              return file?.links && Object.entries(file?.links)?.length > 0;
            }) /* Checks if all links are present only then Downlaod all will appear */ ? (
              <span
                className="file-info-file-download-btn"
                onClick={handleDownloadTargetAll}
              >
                <span>Download All</span>
                <span>
                  <FaDownload />
                </span>
              </span>
            ) : (
              <span className="file-info-file-download-err-text">
                <span>File unavailable, Please contact EZ Support</span>
              </span>
            )}
          </div>
          <span className="version-card-info-value">
            {version?.files?.map((file, i) => {
              return <span key={i}>{file.name}</span>;
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

const SourceFileCard = ({ active }) => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const handleDownloadSourceAll = (e) => {
    e.preventDefault();
    let allFileLinks = liveAssignmentData?.file_details?.source_files
      ?.map((file) => {
        return Object.entries(file.links).map((link) => {
          const filename =
            file.name.split(".").slice(0, -1).join(".") + "." + link[0];
          return { link: link[1], name: filename };
        });
      })
      .flat();
    try {
      DownloadFiles(allFileLinks);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <div className="version-card card-layout" data-idx={0}>
      <div className={`version-card-head ${!active && "glass-container"}`}>
        <div className="version-card-info">
          <span className="version-card-info-value version-card-info-version-head">
            Input Files{" "}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-value">
            {liveAssignmentData?.file_details?.source_files?.length} file(s)
          </span>
        </div>
        <div className="version-card-info"></div>
      </div>

      <div
        className={`version-card-content ${
          active && "version-card-content-show"
        }`}
      >
        <div className="version-card-info">
          <div className="version-card-info-head">
            <span className="version-card-info-label">File(s)</span>
            {liveAssignmentData?.file_details?.source_files?.every(
              (file) => file?.links,
            ) ? (
              <div
                className="file-info-file-download-btn"
                onClick={(e) => {
                  handleDownloadSourceAll(e);
                }}
              >
                <span>Download All</span>
                <FaDownload />
              </div>
            ) : (
              <div className="file-info-file-download-err-text">
                <span>File unavailable, Please contact EZ Support</span>
              </div>
            )}
          </div>
          <span className="version-card-info-value">
            {liveAssignmentData?.file_details?.source_files?.map((file, i) => {
              return <span key={i}>{file.name}</span>;
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

const HistoryTab = ({ file }) => {
  const [activeCard] = useState(0);
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);

  const [displayedFile, setDisplayedFile] = useState(file);
  const route = useContext(OpsRouteContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );

  useEffect(() => {
    if (route === OPS_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(liveAssignmentData);
    } else if (route === OPS_ROUTES.ARCHIVE) {
      setDisplayedFile(opsArchiveDisplayedFile);
    }
  }, [liveAssignmentData, opsArchiveDisplayedFile]);

  //   const toggleActiveCard = (e) => {
  //     if (e?.target?.closest(".file-info-file-download-btn")) return;
  //     const activeVersionCard = e?.target?.closest(".version-card")?.dataset.idx; // this value is the index to activate that card
  //     if (
  //       (parseInt(activeVersionCard) || parseInt(activeVersionCard) == 0) &&
  //       parseInt(activeVersionCard) !== activeCard
  //     ) {
  //       // if the selected card and the active card id is same then the card will close (remove the last conditional in order to make one card open all the time)
  //       setActiveCard(parseInt(activeVersionCard));
  //     } else {
  //       setActiveCard(null); // closes any selected card if we click on the white space
  //     }
  //   };

  const activateCard = (e) => {
    if (e?.target?.closest(".file-info-file-download-btn")) return;
    const history_item = e?.target?.closest(".version-card");
    if (history_item) {
      const history_item_head =
        history_item.querySelector(".version-card-head");
      const history_item_content = history_item.querySelector(
        ".version-card-content",
      );
      history_item_head.classList.toggle("glass-container");
      history_item_content.classList.toggle("version-card-content-show");
    }
  };

  return (
    <section className="history-tab-section">
      <main className="history-tabs-container" onClick={activateCard}>
        {liveAssignmentData?.file_details?.target_versions?.map(
          (version, i) => (
            <VersionCard
              key={version?.version || i}
              version={version}
              active={activeCard == i}
              idx={i}
            />
          ),
        )}
        <SourceFileCard active={true} displayedFile={displayedFile} />
      </main>
    </section>
  );
};

export default HistoryTab;
