import { createSlice } from "@reduxjs/toolkit";
import { initialState, TYPE_OF_ASSIGNMENT_DATA } from "./common";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import { getAuthData } from "../../../helpers/request";

// setting up other state values

export const getLiveAssignmentData = createAsyncThunk(
  "liveAssignmentData/getLiveAssignmentData",
  async (
    { url = "api-ops/ops/v2/all-assignments", id },
    { rejectWithValue },
  ) => {
    let get_live_asgn_url = `${process.env.REACT_APP_API_URL}/${url}/${id}/`;
    try {
      let type_of_data;
      if (id) {
        type_of_data = TYPE_OF_ASSIGNMENT_DATA.ID;
      } else {
        type_of_data = TYPE_OF_ASSIGNMENT_DATA.UNDEFINED;
      }
      const res = await getAuthData(get_live_asgn_url);
      if (res.success) {
        return { file_details: res.last_assignment, type_of_data };
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

const liveAssignmentDataSlice = createSlice({
  name: "live-assignment-data",
  initialState,
  reducers: {},
  extraReducers: {
    [getLiveAssignmentData.pending]: (state) => {
      state.loading = true;
    },
    [getLiveAssignmentData.fulfilled]: (state, action) => {
      state.loading = false;
      state.file_details = action.payload.file_details;
      state.type_of_data = action.payload.type_of_data;
    },
    [getLiveAssignmentData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default liveAssignmentDataSlice.reducer;
