import React, { useContext, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { ApiContext, devEnv, getAccessLogData } from "../../../index.js";
import {
  ModalDispatchContext,
  OpsSharingLogContext,
  OpsAccessLogContext,
  NotFoundErrorContext,
  MODAL_TYPE,
} from "../../OpsComponent";
import { getAuthData, postAuthData } from "../../../helpers/request";
import { useDispatch, useSelector } from "react-redux";
import dummy_share_warning_data from "../../../archive/test_data/dummy_data-v2/share_warning/share_warning.json";

import "./OpsSharingModal.css";
import useDebounceEffect from "../../../hooks/useDebounceEffect.js";
import {
  shareAddClientCode,
  shareAddDeliveryId,
  shareAddEmail,
  shareAddEntityCode,
  shareAddRecognisedEmail,
  shareAddUnrecognisedEmail,
  shareShowWarning,
  shareUpdateClient,
} from "../../../store/features/shareData/shareDataSlice.js";
import { SHARE_RESPONSE_STATUS } from "../../../store/features/shareData/common.js";

const OpsSharingModal = () => {
  const dispatch = useDispatch();
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const shareData = useSelector((state) => state.shareData);
  const modal_dispatch = useContext(ModalDispatchContext);
  const API_URL = useContext(ApiContext);
  const [shareNameInput, setShareNameInput] = useState(null);
  const suggestionContainerRef = useRef(null);
  const shareModalRef = useRef(null);
  const sharedNameInputRef = useRef(null);
  const [shareError, setShareError] = useState(null);
  const [sharedNames, setSharedNames] = useState([]);
  const [shareNameSuggestions, setShareNameSuggestions] = useState([]);
  const [shareErrNameArr, setShareErrNameArr] = useState([]);
  const shareSubmitBtn = useRef(null);
  const [disableShareBtn, setDisableShareBtn] = useState(false);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setSharedLogData } = useContext(OpsSharingLogContext);
  const { setAccessLogData } = useContext(OpsAccessLogContext);
  const maxSharingNames = 10;

  const closeSharingModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
  };

  const addSuggestion = (e) => {
    if (sharedNames.length === maxSharingNames) {
      setShareError(`Only ${maxSharingNames} names can be shared at a time.`);
      return;
    } // setting a limit for names
    const emailRegex = /\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*/g;
    const share_form_input = sharedNameInputRef.current.value?.toLowerCase();
    const suggested_item = e?.target.innerText?.toLowerCase();
    setShareError(null);
    // only submit if its an email
    if (suggested_item && sharedNames.includes(suggested_item)) {
      setShareError("Email already added.");
      return;
    } else if (suggested_item && emailRegex.test(suggested_item)) {
      setSharedNames([...sharedNames, suggested_item]);
      clearShareInput();
      return;
    } else if (!suggested_item && sharedNames.includes(share_form_input)) {
      setShareError("Email already added.");
      return;
    } else if (!suggested_item && emailRegex.test(share_form_input)) {
      setSharedNames([...sharedNames, share_form_input]);
      clearShareInput();
      return;
    } else {
      setShareError("Please enter an email.");
      return;
    }
  };

  const removeName = (e, idx) => {
    // instantly change the state when remove
    setSharedNames([
      ...sharedNames.slice(0, idx),
      ...sharedNames.slice(idx + 1, sharedNames.length),
    ]);
  };

  const extractEmailArrays = (data) => {
    const new_arr = data.map((cur) => {
      return Object.values(cur)[0];
    });
    return new_arr;
  };

  const getSuggestions = () => {
    if (devEnv && !shareNameInput) {
      return;
    }
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-ops/misc/v1/suggestion/?search=${shareNameInput}&delivery_id=${liveAssignmentData?.file_details?.batch_id}`,
    )
      .then((res) => {
        if (res.success === true) {
          setShareNameSuggestions([...extractEmailArrays(res.data)]);
        }
      })
      .catch(() => {
        // console.error(err);
      });
  };

  const clearShareInput = () => {
    // Clearing the input and its suggestions after submitting the name
    sharedNameInputRef.current.value = "";
    setShareNameInput(null);
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Shared!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Share";
      closeSharingModal();
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addSuggestion();
  };

  const removeShareErrors = () => {
    setShareError(null);
    Array.from(document.querySelectorAll(".ops-share-name-item")).map((el) => {
      el.classList.remove("ops-share-name-item-err");
    });
  };

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-ops/misc/v1/access-log/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch(() => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-ops/misc/v1/sharing-file/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch(() => {
        setNotFoundError(true);
      });
  };

  const submitSharedArray = (e) => {
    e.preventDefault();
    const data = {
      delivery_id: liveAssignmentData?.file_details?.batch_id,
      emails: sharedNames,
      accept_warning: shareData.show_warning,
      entity_code: liveAssignmentData?.file_details?.entity_code, // required when uploading/updating the files
      client_code: liveAssignmentData?.file_details?.client_code,
    };

    // checking of empty list is being sent
    if (sharedNames.length < 1) {
      setShareError("Please enter an email!");
      return;
    }

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    // checking if more than 10 names are shared
    if (sharedNames.length > maxSharingNames) {
      setShareError(`More than ${maxSharingNames} names shared.`);
      return;
    } // setting a limit for names

    // setting dummy data for experimenting share warning
    const setShareInfoData = (data) => {
      dispatch(shareUpdateClient(data?.client));
      dispatch(
        shareAddEmail([
          ...(data?.emails?.within_org || null),
          ...(data?.emails?.outside_org || null),
        ]),
      );
      dispatch(shareAddRecognisedEmail(data?.emails?.within_org));
      dispatch(shareAddUnrecognisedEmail(data?.emails?.outside_org));
      dispatch(shareShowWarning(true)); // this needs to be set whenever we have checked the data already
      dispatch(
        shareAddClientCode(liveAssignmentData?.file_details?.client_code),
      );
      dispatch(
        shareAddEntityCode(liveAssignmentData?.file_details?.entity_code),
      );
      dispatch(shareAddDeliveryId(liveAssignmentData?.file_details?.batch_id));
    };

    if (devEnv) {
      setShareInfoData(dummy_share_warning_data);
      modal_dispatch({ modal_show_type: MODAL_TYPE.SHARE_WARNING });
      return;
    }
    // Sending Data
    postAuthData(
      `${API_URL}/api-ops/misc/v1/sharing-file/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res.status === SHARE_RESPONSE_STATUS.SUCCESS) {
          removeShareErrors();
          submitBtnSuccess();
          clearShareInput();
          setSharedNames([]);
          setDisableShareBtn(false);
          fetch_shared_log();
          fetch_access_log();
          modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
        } else if (res.status === SHARE_RESPONSE_STATUS.WARNING) {
          setShareInfoData(res);
          setDisableShareBtn(false);
          modal_dispatch({ modal_show_type: MODAL_TYPE.SHARE_WARNING });
        } else if (res.status === SHARE_RESPONSE_STATUS.FAILED) {
          setShareError(res.message);
          setDisableShareBtn(false);
          if (res.emails.length > 0) {
            setShareErrNameArr([...res.emails]);
          }
        } else {
          setShareError("Please refresh and try again or contact Tech Team.");
          setDisableShareBtn(false);
        }
      })
      .catch(() => {
        setDisableShareBtn(false);
      });

    // temp data for error handling
    // // error handling
    // setShareError(err_res.message);
    // if (err_res.emails.length>0){
    //     setShareErrNameArr([...err_res.emails]);
    // }
  };

  useDebounceEffect(getSuggestions, [shareNameInput], 700);

  return (
    <div
      className="ops-modal-container ops-shared-modal-container"
      ref={shareModalRef}
    >
      <div className="ops-modal-head">
        <div className="ops-modal-container-head" id="ops-share-heading">
          Share Assignment
        </div>
        <button
          className="ops-modal-container-close-btn"
          id="ops-share-heading-close-btn"
          onClick={closeSharingModal}
        >
          <FaTimes />
        </button>
      </div>
      <p>
        The assignment can only be shared with people from within your
        organisation.
      </p>

      <div className="ops-share-names-container">
        {sharedNames?.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`ops-share-name-item ${
                shareErrNameArr.includes(item) && "ops-share-name-item-err"
              }`}
            >
              <span className="ops-share-name">{item}</span>
              <button
                className="ops-share-remove-btn"
                onClick={(e) => {
                  removeName(e, idx);
                }}
              >
                <FaTimes />
              </button>
            </div>
          );
          // return <SharedName key={idx} name={item} idx={idx} setSharedNames={setSharedNames}/>
        })}
      </div>

      <form className="ops-share-modal-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="ops-dashboard-input ops-share-modal-input"
          ref={sharedNameInputRef}
          defaultValue={shareNameInput}
          onChange={(e) => {
            setShareError(null);
            setShareNameInput(e.target.value);
          }}
          autoFocus
        />

        {shareError && (
          <div className="ops-input-error ops-share-error">{shareError}</div>
        )}

        {shareNameInput && (
          <div
            className="ops-share-suggestions-container"
            ref={suggestionContainerRef}
          >
            <span className="ops-share-suggestion-item" onClick={addSuggestion}>
              {shareNameInput}
            </span>
            {shareNameSuggestions.map((item, idx) => {
              return (
                <span
                  className="ops-share-suggestion-item"
                  key={idx}
                  onClick={addSuggestion}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}
      </form>
      <div className="ops-share-btn-container">
        <button
          className="ops-btns"
          id="ops-share-modal-cancel-btn"
          onClick={closeSharingModal}
        >
          Cancel
        </button>
        <button
          className="ops-btns"
          ref={shareSubmitBtn}
          id="ops-share-modal-okay-btn"
          disabled={disableShareBtn}
          onClick={submitSharedArray}
        >
          Share
        </button>
      </div>
    </div>
  );
};

export { OpsSharingModal };
