import React, { useContext, useRef, useState } from "react";
import { FaEye, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiContext,
  devEnv,
  ERROR_REGEX,
  getAccessLogData,
  ToolTipError,
} from "../../..";
import { getAuthData, postAuthData } from "../../../helpers/request";
import {
  shareAddPasswordData,
  shareRemoveEmail,
  shareRemoveRecognisedEmail,
  shareRemoveUnrecognisedEmail,
  shareResetData,
  shareUploadWarningSent,
} from "../../../store/features/shareData/shareDataSlice";
import removeArrEl from "../../../utils/removeArrEl";
import {
  ModalDispatchContext,
  MODAL_TYPE,
  NotFoundErrorContext,
  OpsAccessLogContext,
  OpsRouteContext,
  OpsSharingLogContext,
  OPS_ROUTES,
} from "../../OpsComponent";

import "./OpsShareWarningModal.css";

const OpsShareWarningModal = () => {
  const API_URL = useContext(ApiContext);
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const pswdInputRef = useRef(null);
  const [shareWarningPswdErr, setShareWarningPswdErr] = useState(null);
  const shareData = useSelector((state) => state.shareData);
  const dispatch = useDispatch();
  const modal_dispatch = useContext(ModalDispatchContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setSharedLogData } = useContext(OpsSharingLogContext);
  const { setAccessLogData } = useContext(OpsAccessLogContext);
  const shareSubmitBtn = useRef(null);
  const [disableShareBtn, setDisableShareBtn] = useState(false);
  const route = useContext(OpsRouteContext);

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-ops/misc/v1/access-log/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch(() => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-ops/misc/v1/sharing-file/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch(() => {
        setNotFoundError(true);
      });
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Shared!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Share";
      closeSharingModal();
    }, 1000);
  };

  const closeSharingModal = (e) => {
    e?.preventDefault();
    dispatch(shareResetData()); // to clear all data once we close the modal so that it takes one more request to fill up the data
    modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
  };

  const removeRecognisedName = (e, item) => {
    e?.preventDefault();
    let new_shared_arr = removeArrEl(
      item,
      JSON.parse(JSON.stringify(shareData.shared_emails)),
    );
    let new_recognised_arr = removeArrEl(
      item,
      JSON.parse(JSON.stringify(shareData.recognised_emails)),
    );
    dispatch(shareRemoveEmail(new_shared_arr));
    dispatch(shareRemoveRecognisedEmail(new_recognised_arr));
  };

  const removeUnrecognisedName = (e, item) => {
    e?.preventDefault();
    let new_shared_arr = removeArrEl(
      item,
      JSON.parse(JSON.stringify(shareData.shared_emails)),
    );
    let new_unrecognised_arr = removeArrEl(
      item,
      JSON.parse(JSON.stringify(shareData.unrecognised_emails)),
    );
    dispatch(shareRemoveEmail(new_shared_arr));
    dispatch(shareRemoveUnrecognisedEmail(new_unrecognised_arr));
  };

  const sendShareData = () => {
    let sharing_url;
    let data = {
      delivery_id: shareData?.delivery_id,
      emails: shareData.shared_emails,
      accept_warning: shareData.show_warning,
      password: pswdInputRef.current.value,
      entity_code: shareData?.entity_code, // required when uploading/updating the files
      client_code: shareData?.client_code, // required when uploading/updating the files
    };

    if (devEnv) {
      console.log(getAccessLogData(data));
      submitBtnSuccess();
      setDisableShareBtn(false);
      // upload files
      if (route !== OPS_ROUTES.ASSIGNMENTS) {
        dispatch(shareUploadWarningSent(true)); // to upload files
      } else {
        // this log fetching is only for normal sharing and not upload and update sharing
        dispatch(shareResetData()); // we clear the warning data as we are closing the modal and fetching assignment data nowbut not for upload update as we need the data even after closing the modal.
        // hit logs
      }
      // close modals
      modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
      return;
    }

    // this is bcz sharing url changes when file is shared from add_delivery/update_delivery and dashboard
    if (route === OPS_ROUTES.ASSIGNMENTS) {
      sharing_url = `${API_URL}/api-ops/misc/v1/sharing-file/`;
    } else {
      sharing_url = `${API_URL}/api-ops/ops/v1/share-email/`;
      // data = {...data,"entity_code": liveAssignmentData?.entity_code}  // this will be added only when uploading/updating the files
    }

    postAuthData(sharing_url, getAccessLogData(data))
      .then((res) => {
        if (res.success === true) {
          submitBtnSuccess();
          setDisableShareBtn(false);
          // upload files
          if (route !== OPS_ROUTES.ASSIGNMENTS) {
            dispatch(shareUploadWarningSent(true)); // to upload files
          } else {
            // this log fetching is only for normal sharing and not upload and update sharing
            dispatch(shareResetData()); // we clear the warning data as we are closing the modal and fetching assignment data nowbut not for upload update as we need the data even after closing the modal.
            // hit logs
            fetch_shared_log();
            fetch_access_log();
          }
          // close modals
          modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
        } else {
          // if response want me to update the warning check key do it here
          setShareWarningPswdErr(res.message);
          // enabling button
          setDisableShareBtn(false);
        }
      })
      .catch((err) => {
        setDisableShareBtn(false);
        setShareWarningPswdErr(err.message);
        console.log(err.message);
      });
  };

  const sendPswdData = () => {
    setDisableShareBtn(true);
    const data = { password: pswdInputRef.current.value };
    if (devEnv) {
      console.log(data);
      dispatch(shareAddPasswordData(data));
      sendShareData();
      return;
    }
    postAuthData(`${API_URL}/api-ops/auth/v1/validate-password/`, data) // ====> change url ask shagun for it
      .then((res) => {
        if (res.success === true) {
          dispatch(shareAddPasswordData(data));
          sendShareData();
          setShareWarningPswdErr(null);
        } else {
          setDisableShareBtn(false);
          setShareWarningPswdErr(res.message);
        }
      })
      .catch((err) => {
        setDisableShareBtn(false);
        setShareWarningPswdErr(err.message);
        console.error(err.message);
      });
  };

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  const namesValidation = () => {
    if (shareData.shared_emails.length < 1) {
      alert("No emails added for sharing!");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (namesValidation()) {
      sendPswdData();
    }
  };

  // useEffect(() => {
  //   dispatch(shareShowWarning(false)); // this need to be different as the warning is already given
  // }, []);

  return (
    <div className="ops-modal-container ops-share-warning-modal-container">
      <div className="ops-modal-head">
        <div
          className="ops-modal-container-head"
          id="ops-share-warning-heading"
        >
          Warning!
        </div>
        <button
          className="ops-modal-container-close-btn"
          id="ops-share-warning-heading-close-btn"
          onClick={closeSharingModal}
        >
          <FaTimes />
        </button>
      </div>

      <div className="ops-share-warning-modal-text-container">
        <p className="ops-share-warning-modal-para">
          <span className="ops-share-warning-modal-para-span ops-share-warning-modal-para-span-orange">
            {"You're sharing the file outside the client entity."}
          </span>
          <span className="ops-share-warning-modal-para-span ops-share-warning-modal-para-span-orange">
            This might result in a breach of contract and Information Security.
          </span>
        </p>
        <p className="ops-share-warning-modal-para">
          <span className="ops-share-warning-modal-para-span">
            Please ensure that all the people listed below are authorised by{" "}
            {shareData.client} to receive these files.{" "}
            <b>Remove the people not authorised to access.</b>
          </span>
        </p>
      </div>

      {shareData?.recognised_emails?.length > 0 && (
        <div className="ops-share-warning-name-container ops-share-warning-recoginsed-name-container">
          <div className="ops-share-warning-name-container-head">
            Within Client Organisation
          </div>
          <div className="ops-share-warning-names-container">
            {shareData?.recognised_emails?.map((item, idx) => {
              return (
                <div key={idx} className={`ops-share-warning-name-item`}>
                  <span className="ops-share-warning-name">{item}</span>
                  <button
                    className="ops-share-warning-remove-btn"
                    onClick={(e) => {
                      removeRecognisedName(e, item);
                    }}
                  >
                    <FaTimes />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {shareData?.unrecognised_emails?.length > 0 && (
        <div className="ops-share-warning-name-container ops-share-warning-unrecoginsed-name-container">
          <div className="ops-share-warning-name-container-head">
            Outside Client Organisation
          </div>
          <div className="ops-share-warning-names-container">
            {shareData?.unrecognised_emails?.map((item, idx) => {
              return (
                <div key={idx} className={`ops-share-warning-name-item`}>
                  <span className="ops-share-warning-name">{item}</span>
                  <button
                    className="ops-share-warning-remove-btn"
                    onClick={(e) => {
                      removeUnrecognisedName(e, item);
                    }}
                  >
                    <FaTimes />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <form className="ops-share-modal-form" onSubmit={handleSubmit}>
        <div className="ops-old-password-input">
          <div className="ops-share-warning-input-contianer">
            <input
              className="ops-dashboard-input ops-share-modal-input"
              onChange={(e) => {
                ToolTipError(e, [
                  ERROR_REGEX.PASSWORD_ERROR,
                  ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                ]);
              }}
              ref={pswdInputRef}
              type="password"
              id="ops-share-warning-password"
              name="ops-share-warning-password"
              placeholder="Password"
              required
            />
            <span
              className="ops-share-warning-form-input-show-btn"
              onClick={(e) => {
                e.preventDefault();
                e.target
                  .closest(".ops-share-warning-form-input-show-btn")
                  .classList.toggle(
                    "ops-share-warning-form-input-show-btn-active",
                  );
                showPassword(pswdInputRef);
                pswdInputRef.current.focus();
              }}
            >
              <FaEye />
            </span>
          </div>
          {shareWarningPswdErr && (
            <div className="ops-share-warning-pswd-error">
              {shareWarningPswdErr}
            </div>
          )}
        </div>
        <div className="ops-share-warning-btn-container">
          <div
            className="ops-btns"
            id="ops-share-warning-modal-cancel-btn"
            onClick={closeSharingModal}
          >
            Cancel
          </div>
          <button
            className="ops-btns"
            ref={shareSubmitBtn}
            id="ops-share-warning-modal-okay-btn"
            disabled={disableShareBtn}
            type="submit"
          >
            Share
          </button>
        </div>
      </form>
    </div>
  );
};

export default OpsShareWarningModal;
