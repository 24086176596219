import React, { useContext } from "react";
import { FaAngleLeft, FaCopy } from "react-icons/fa";
import { Link } from "react-router-dom";
import { date_time_convertor } from "../../../..";
import { showFilesSidebar } from "../../../Nav/OpsSideNav/OpsSideNav";
import {
  LoaderContext,
  ModalDispatchContext,
  MODAL_TYPE,
} from "../../../OpsComponent";
import { FileIcon } from "../../OpsDashboard";

import "./TopDashboard.css";

const TopDashboard = ({ file }) => {
  const modal_dispatch = useContext(ModalDispatchContext);
  const { setLoader } = useContext(LoaderContext);

  const copyKey = () => {
    setLoader(
      "Please stay on the page, so the new key can be copied in your clipboard.",
    );

    navigator.clipboard.writeText(file?.delivery_copy_data || file?.batch_id);

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const openSourceFileDownloadModal = (e) => {
    e.preventDefault();
    modal_dispatch({ modal_show_type: MODAL_TYPE.SOURCE_FILE_MODAL });
  };

  return (
    <>
      <div className="top-dashboard">
        <div className="top-dashboard-file-name">
          <button
            className="ops-dashboard-back-btn"
            onClick={(e) => {
              showFilesSidebar(e);
            }}
          >
            <FaAngleLeft className="ops-responsive-back-btn" />
          </button>
          <div className="file-logo">
            <FileIcon filename={file?.source_files[0].name} />
            <div className="filename" onClick={openSourceFileDownloadModal}>
              {file?.source_files &&
                `${file?.source_files[0].name || "-"} ${
                  file?.source_files?.length > 1
                    ? "+ " + String(file?.source_files?.length - 1)
                    : ""
                }`}
            </div>
          </div>
        </div>

        {/* <div className="top-dashboard-assignment-stages">
                <div className="file-steps">{file?.assignment_stages}</div>
            </div> */}

        <div className="top-dashboard-delivery-id">
          <div className="delivery-id">
            <div className="delivery-id-head">Delivery ID</div>
            <div className="delivery-id-value">
              <span className="delivery-id-value-text">
                {file?.general_delivery_id || file?.batch_id}
              </span>
              <span className="delivery-id-value-copy-btn" onClick={copyKey}>
                <FaCopy className="delivery-id-copy" />
              </span>
            </div>
          </div>
          <Link
            className="ops-btns top-dashboard-update-delivery-btn"
            to={`/update-delivery/${file?.id}`}
          >
            Update Delivery
          </Link>
        </div>

        <div className="top-dashboard-client-code">
          <div className="top-dashboard-label">Entity Code</div>
          <div className="top-dashboard-value">{file?.entity_code || "-"}</div>
        </div>

        <div className="top-dashboard-client-charge-code">
          <div className="top-dashboard-label">Client Charge Code</div>
          <div className="top-dashboard-value">
            {file?.client_charge_code || "-"}
          </div>
        </div>

        <div className="top-dashboard-delivered">
          <div className="top-dashboard-label">Delivered</div>
          <div
            className="top-dashboard-value"
            id="delivered-top-dashboard-value"
          >
            <span>{date_time_convertor(file?.actual_delivery)}</span>
            {/* <span style={{color: (parseInt(file?.duration)>0) ? 'rgb(80, 207, 80)':'red'}}>{file?.duration}</span> */}
            <span
              className="delivered-top-dashboard-value-duration"
              style={{
                color:
                  file?.duration?.substr(0, 1) !== "-"
                    ? "rgb(80, 207, 80)"
                    : "red",
              }}
            >
              {file?.duration}
            </span>
          </div>
        </div>

        <div className="top-dashboard-requester-name">
          <div className="top-dashboard-label">Requester Name</div>
          <div className="top-dashboard-value">
            {file?.requester_name || "-"}
          </div>
        </div>

        <div className="top-dashboard-date-received">
          <div className="top-dashboard-label">Date Received</div>
          <div className="top-dashboard-value">
            {date_time_convertor(file?.requested_delivery) || "-"}
          </div>
          {/* <div className="top-dashboard-value">{date_time_convertor(file?.requested_delivery) || "-"}</div> */}
        </div>

        <div className="top-dashboard-committed-delivery">
          <div className="top-dashboard-label">Committed Delivery</div>
          {/* <div className="top-dashboard-value committed-delivery">{file?.committed_delivery+" IST" || "-"}</div> */}
          <div className="top-dashboard-value committed-delivery">
            {date_time_convertor(file?.committed_delivery) || "-"}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopDashboard;
