import React, { useState, useContext, useReducer, useEffect } from "react";
import { makeSecureDecrypt } from "../helpers/security";
// import { getAuthData } from "../helpers/request";
import { Redirect } from "react-router-dom";
import { UserContext, lazy_load_image, devEnv } from "../index";

// STYLE
// DASHBOARD
import "./style/dashboard/OpsDashboard.css";
import "./style/dashboard/OpsDashboard500p.css";
// LOGIN
import "./style/login/OpsLogin.css";
import "./style/login/OpsLogin500p.css";

// Importing Dummy Data
import dummy_profile from "../archive/test_data/dummy_data-v1/dummy_profile.json";

// OPS IMPORTS
import { OpsTopNav } from "./Nav/OpsTopNav/OpsTopNav";
import { OpsSideNav } from "./Nav/OpsSideNav/OpsSideNav";
import { OpsDashboard } from "./Dashboard/OpsDashboard";
import { OpsAccountSettings } from "./Settings/OpsAccountSettings";
import { OpsLoginForm } from "./Login/OpsLogin";
import {
  OpsForgetPasswordForm,
  OpsChangePasswordForm,
  OpsCheckForgetVerificationEmail,
} from "./Login/OpsForgetPassword";
// import { OpsClientSettings } from './Settings/OpsClientSettings';
// import { OpsUserSettings } from './Settings/OpsUserSettings';
// import { DeliverFileModal } from './Dashboard/DeliverFile'
import { OpsSideBar } from "./OpsSidebar/OpsSideBar";
import { DeliverNewVersionModal } from "./Modal/DeliverNewVersionModal";
import { OpsPswdChangeModal } from "./Modal/OpsPswdChangeModal/OpsPswdChangeModal";
import { CopySecureKeyModal } from "./Modal/CopySecureKeyModal";
import {
  LoaderModal,
  NotFoundError,
  ComingSoonError,
} from "./OpsError/OpsError";
import { OpsArchiveSideBar } from "./OpsArchive/OpsArchiveSidebar";
import { OpsArchive } from "./OpsArchive/OpsArchive";
import { OpsSharingModal } from "./Modal/OpsSharingModal/OpsSharingModal";

// Delivery & update new files
import NewDelivery from "./Delivery/NewDelivery";
import UpdateDelivery from "./UpdateDelivery/UpdateDelivery";
import { SourceFileDownloadModal } from "./Modal/SourceFileDownloadModal/SourceFileDownloadModal";
import {
  // useDispatch,
  useSelector,
} from "react-redux";
import OpsShareWarningModal from "./Modal/OpsShareWarningModal/OpsShareWarningModal";
import { OpsShareRevokeModal } from "./Modal/OpsShareRevokeModal/OpsShareRevokeModal";
import FileUploadModal from "./Modal/FileUploadModal/FileUploadModal";
import { UPLOAD_FILES_PROGRESS_STATUS } from "../store/features/uploadData/common";
// import { getProfileData } from "../store/features/profileData/profileDataSlice";
import { getAuthData } from "../helpers/request";

export const OpsFilesArrayContext = React.createContext();
export const OpsDashboardFileContext = React.createContext();
export const DeliveryFormInfoContext = React.createContext();
export const NewSecureKeyContext = React.createContext();
export const ModalDispatchContext = React.createContext();
export const DeliverNewVersionInfoContext = React.createContext();
export const BottomContext = React.createContext();
export const ForgetMailSentContext = React.createContext();
export const LoaderContext = React.createContext();
export const NotFoundErrorContext = React.createContext();
export const ComingSoonErrorContext = React.createContext();
export const OpsArchiveFilesArrayContext = React.createContext();
export const OpsArchiveDashboardFileContext = React.createContext();
export const OpsArchiveCountContext = React.createContext();
export const OpsSharingLogContext = React.createContext();
export const OpsAccessLogContext = React.createContext();
export const OpsArchiveSharingLogContext = React.createContext();
export const OpsArchiveAccessLogContext = React.createContext();
export const OpsRouteContext = React.createContext(); // Required to see which tab is active in sidenav and also to set active tab in mobile sidebar
export const DeliveryPageContext = React.createContext(); // Delivery Page Context
export const FileGroupsContext = React.createContext(); // store file data for chunks upload in upload/update

export const BOTTOM_TAB = {
  DETAILS: "details",
  OUTPUT: "output",
  SHARING: "sharing",
  DELIVERY: "delivery",
  HISTORY: "history",
};

//=======================OPS COMPONENTS=========================

// const OpsLoginContainer = () => {
//     return (
//         <div className='login'>
//             <div className="login-form-container">
//                 <div className="brand"><img src={process.env.PUBLIC_URL + "/resources/nav_icons/logo.png"} alt="EZ Works" /></div>
//                 <OpsLoginForm />
//                 <div className="login-footer">© 2020 ArabEasy | Privacy Policy</div>
//             </div>
//             {/* <img className="iso-logo" src={process.env.PUBLIC_URL + "/resources/backgrounds/iso.png"} alt="EZ People"></img> */}
//             <div className="login-img-container"><img src={process.env.PUBLIC_URL + "/resources/backgrounds/login_background.png"} alt="EZ People" /></div>
//         </div>
//     )
// }

const OpsLoginComponents = ({ page }) => {
  const [opsForgetMailSent, setOpsForgetMailSent] = useState(false);
  switch (page) {
    case "login":
      return (
        // <OpsLogin />
        <div className="login">
          <div className="login-form-container">
            <div className="brand">
              <img
                className="lazy-img"
                onLoad={lazy_load_image}
                src={process.env.PUBLIC_URL + "/resources/nav_icons/logo.png"}
                alt="EZ Works"
              />
            </div>
            <OpsLoginForm />
            <div className="login-footer">
              © 2020 ArabEasy |{" "}
              <a
                target="_blank"
                href="https://ez.works/privacy-policy"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          {/* <img className="iso-logo" src={process.env.PUBLIC_URL + "/resources/backgrounds/iso.png"} alt="EZ People"></img> */}
          <div className="login-img-container">
            <img
              className="lazy-img"
              onLoad={lazy_load_image}
              src={
                process.env.PUBLIC_URL +
                "/resources/backgrounds/login_background.png"
              }
              alt="EZ People"
            />
          </div>
        </div>
      );
    case "oppsforgotpassword":
      return (
        <ForgetMailSentContext.Provider
          value={{ opsForgetMailSent, setOpsForgetMailSent }}
        >
          <div className="login">
            <div className="login-form-container">
              <div className="brand">
                <img
                  src={process.env.PUBLIC_URL + "/resources/nav_icons/logo.png"}
                  alt="EZ Works"
                />
              </div>
              {opsForgetMailSent ? (
                <OpsCheckForgetVerificationEmail />
              ) : (
                <OpsForgetPasswordForm />
              )}
              <div className="login-footer">
                © 2020 ArabEasy |{" "}
                <a
                  target="_blank"
                  href="https://ez.works/privacy-policy"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            {/* <img className="iso-logo" src={process.env.PUBLIC_URL + "/resources/backgrounds/iso.png"} alt="EZ People"></img> */}
            <div className="login-img-container">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/resources/backgrounds/login_background.png"
                }
                alt="EZ People"
              />
            </div>
          </div>
        </ForgetMailSentContext.Provider>
      );

    case "oppsforgetpasswordtoken":
      return (
        <ForgetMailSentContext.Provider
          value={{ opsForgetMailSent, setOpsForgetMailSent }}
        >
          <div className="login">
            <div className="login-form-container">
              <div className="brand">
                <img
                  src={process.env.PUBLIC_URL + "/resources/nav_icons/logo.png"}
                  alt="EZ Works"
                />
              </div>
              <OpsChangePasswordForm />
              <div className="login-footer">
                © 2020 ArabEasy |{" "}
                <a
                  target="_blank"
                  href="https://ez.works/privacy-policy"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            {/* <img className="iso-logo" src={process.env.PUBLIC_URL + "/resources/backgrounds/iso.png"} alt="EZ People"></img> */}
            <div className="login-img-container">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/resources/backgrounds/login_background.png"
                }
                alt="EZ People"
              />
            </div>
          </div>
        </ForgetMailSentContext.Provider>
      );
  }
};

// ================== MODAL COMPONENT AND REQUIRED FUCNTIONS =====================

export const MODAL_TYPE = {
  OPS_PASSWORD_CHANGE: "ops-password-change",
  COPY_SECURE_KEY: "copy-secure-key",
  COPY_NEW_VERSION_KEY: "copy-new-version-key",
  SHARING: "sharing",
  SHARE_WARNING: "share-warning",
  SHARE_REVOKE: "share-revoke",
  SOURCE_FILE_MODAL: "source-file-download-modal",
  FILE_UPLOAD_MODAL: "file-upload-modal",
  NONE: "none",
};

export const modalReducer = (state, action) => {
  switch (action.modal_show_type) {
    case MODAL_TYPE.NONE:
      return { modal_show_type: MODAL_TYPE.NONE };
    case MODAL_TYPE.OPS_PASSWORD_CHANGE:
      return { modal_show_type: MODAL_TYPE.OPS_PASSWORD_CHANGE };
    case MODAL_TYPE.COPY_SECURE_KEY:
      return { modal_show_type: MODAL_TYPE.COPY_SECURE_KEY };
    case MODAL_TYPE.DELIVER_NEW_VERSION:
      return { modal_show_type: MODAL_TYPE.DELIVER_NEW_VERSION };
    case MODAL_TYPE.SHARING:
      return { modal_show_type: MODAL_TYPE.SHARING };
    case MODAL_TYPE.SHARE_WARNING:
      return { modal_show_type: MODAL_TYPE.SHARE_WARNING };
    case MODAL_TYPE.SHARE_REVOKE:
      return { modal_show_type: MODAL_TYPE.SHARE_REVOKE };
    case MODAL_TYPE.SOURCE_FILE_MODAL:
      return { modal_show_type: MODAL_TYPE.SOURCE_FILE_MODAL };
    case MODAL_TYPE.FILE_UPLOAD_MODAL:
      return { modal_show_type: MODAL_TYPE.FILE_UPLOAD_MODAL };
    default:
      return state;
  }
};
// eslint-disable-next-line no-unused-vars
const Modal = ({ type, modalDispatch }) => {
  const modal_dispatch = useContext(ModalDispatchContext);

  switch (type.modal_show_type) {
    case MODAL_TYPE.OPS_PASSWORD_CHANGE:
      return (
        <section className="ops_modal_password">
          <OpsPswdChangeModal />
        </section>
      );

    case MODAL_TYPE.COPY_SECURE_KEY:
      return (
        <section
          className="ops_modal"
          onClick={(e) => {
            e.target.className === "ops_modal" &&
              modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          <CopySecureKeyModal />
        </section>
      );

    case MODAL_TYPE.DELIVER_NEW_VERSION:
      return (
        <section
          className="ops_modal"
          onClick={(e) => {
            e.target.className === "ops_modal" &&
              modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          <DeliverNewVersionModal />
        </section>
      );
    case MODAL_TYPE.SHARING:
      return (
        <section
          className="ops_modal"
          onClick={(e) => {
            e.target.className === "ops_modal" &&
              modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          <OpsSharingModal />
        </section>
      );
    case MODAL_TYPE.SHARE_WARNING:
      return (
        <section
          className="ops_modal"
          onClick={(e) => {
            e.target.className === "ops_modal" &&
              modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          <OpsShareWarningModal />
        </section>
      );
    case MODAL_TYPE.SHARE_REVOKE:
      return (
        <section
          className="ops_modal"
          onClick={(e) => {
            e.target.className === "ops_modal" &&
              modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          <OpsShareRevokeModal />
        </section>
      );
    case MODAL_TYPE.SOURCE_FILE_MODAL:
      return (
        <section
          className="ops_modal"
          onClick={(e) => {
            e.target.className === "ops_modal" &&
              modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          <SourceFileDownloadModal />
        </section>
      );
    case MODAL_TYPE.FILE_UPLOAD_MODAL:
      return (
        <section
          className="ops_modal"
          // onClick={(e) => {
          //   e.target.className === "ops_modal" &&
          //     modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          // }}
        >
          <FileUploadModal />
        </section>
      );
    default:
      return <></>;
  }
};

// ROUTES

export const OPS_ROUTES = {
  ASSIGNMENTS: "deliver",
  ARCHIVE: "ops-archive",
  ACCOUNT: "accountsettings",
  ADD_NEW: "addnew",
  UPDATE_DELIVERY: "updatedelivery",
};

// Delivery Pages and types
export const DELIVERY_TYPE_PAGE = {
  NEW: {
    CREATE: "new_crewate",
    ADD: "new_add",
    UPLOAD: "new_upload",
  },
  UPDATE: {
    CREATE: "update_crewate",
    ADD: "update_add",
    UPLOAD: "update_upload",
  },
};

export const uploadFileInitialState = {
  fileGroups: null,
  fileIdsArr: [],
  uploadFilesSuccess: UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE,
};

const OpsPageComponents = ({ page }) => {
  // const dispatch = useDispatch();
  const { setProfile } = useContext(UserContext);
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const liveAllAssignmentsData = useSelector(
    (state) => state.liveAllAssignmentsData,
  );
  const validUser =
    sessionStorage.getItem("is_verified") &&
    JSON.parse(makeSecureDecrypt(sessionStorage.getItem("is_verified")));
  const [deliverFileInfo, setDeliverFileInfo] = useState({});
  const [newSecureKey, setNewSecureKey] = useState(null);
  const [OpsFilesArray, setOpsFilesArray] = useState(null); // Saving array state from fetch
  const [OpsDisplayedFile, setOpsDisplayedFile] = useState({}); // Saving dashboard display state
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    modal_show_type: !validUser
      ? MODAL_TYPE.OPS_PASSWORD_CHANGE
      : MODAL_TYPE.NONE,
  });
  const [deliverNewVersionInfo, setDeliverNewVersionInfo] = useState({});
  const [bottomTab, setBottomTab] = useState(BOTTOM_TAB.DELIVERY);
  const [opsArchiveFilesArray, setOpsArchiveFilesArray] = useState([]);
  const [opsArchiveDisplayedFile, setOpsArchiveDisplayedFile] = useState({});
  const [opsArchiveCount, setOpsArchiveCount] = useState(null);
  const [sharedLogData, setSharedLogData] = useState(null);
  const [accessLogData, setAccessLogData] = useState(null);
  const [archiveSharedLogData, setArchiveSharedLogData] = useState(null);
  const [archiveAccessLogData, setArchiveAccessLogData] = useState(null);
  const [fileUploadData, setFileUploadData] = useState(uploadFileInitialState);

  //error states
  const [notFoundError, setNotFoundError] = useState(false);
  const [comingSoonError, setCommingSoonError] = useState(false);
  const [loader, setLoader] = useState(null);

  // Getting the account
  const fetchOps = () => {
    if (devEnv) {
      setProfile(dummy_profile.data); // Setting Dummy Data
      return;
    }
    // dispatch(getProfileData());
    getAuthData(`${process.env.REACT_APP_API_URL}/api-ops/ops/v1/profile/`)
      .then((res) => {
        if (res.success === true) {
          setProfile(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    // adding token to session storage when a component page is loaded and local storage already has the tokken saved for the client
    localStorage.getItem("token") &&
      makeSecureDecrypt(localStorage.getItem("token")) &&
      sessionStorage.setItem("token", localStorage.getItem("token"));
    localStorage.getItem("is_verified") &&
      makeSecureDecrypt(localStorage.getItem("is_verified")) &&
      sessionStorage.setItem(
        "is_verified",
        localStorage.getItem("is_verified"),
      );
    fetchOps();
  }, []);

  const hideLogoutbox = (e) => {
    if (
      !e.target.closest(".ops-user") &&
      !e.target.closest(".ops-logout-box")
    ) {
      document.querySelector(".ops-logout-box").style.display = "none";
      document.querySelector(".ops-user-name").style.color = "white";
    }
  };

  const hideFeedbackBox = (e) => {
    if (
      e.target.id === "opps-feedback-btn" ||
      e.target.closest(".ops-feedback-popup")
    )
      return;
    document
      .querySelector(".ops-feedback-popup")
      ?.classList.remove("ops-feedback-popup-active");
  };

  if (
    sessionStorage.getItem("token") &&
    makeSecureDecrypt(sessionStorage.getItem("token"))
  ) {
    switch (page) {
      case OPS_ROUTES.ASSIGNMENTS:
        return (
          <ModalDispatchContext.Provider value={modalDispatch}>
            <LoaderContext.Provider value={{ loader, setLoader }}>
              <ComingSoonErrorContext.Provider
                value={{ comingSoonError, setCommingSoonError }}
              >
                <NotFoundErrorContext.Provider
                  value={{ notFoundError, setNotFoundError }}
                >
                  <BottomContext.Provider value={{ bottomTab, setBottomTab }}>
                    <DeliverNewVersionInfoContext.Provider
                      value={{
                        deliverNewVersionInfo,
                        setDeliverNewVersionInfo,
                      }}
                    >
                      <OpsFilesArrayContext.Provider
                        value={{ OpsFilesArray, setOpsFilesArray }}
                      >
                        <OpsDashboardFileContext.Provider
                          value={{ OpsDisplayedFile, setOpsDisplayedFile }}
                        >
                          <DeliveryFormInfoContext.Provider
                            value={{ deliverFileInfo, setDeliverFileInfo }}
                          >
                            <NewSecureKeyContext.Provider
                              value={{ newSecureKey, setNewSecureKey }}
                            >
                              <OpsArchiveDashboardFileContext.Provider
                                value={{
                                  opsArchiveDisplayedFile,
                                  setOpsArchiveDisplayedFile,
                                }}
                              >
                                <OpsArchiveCountContext.Provider
                                  value={{
                                    opsArchiveCount,
                                    setOpsArchiveCount,
                                  }}
                                >
                                  <OpsSharingLogContext.Provider
                                    value={{ sharedLogData, setSharedLogData }}
                                  >
                                    <OpsAccessLogContext.Provider
                                      value={{
                                        accessLogData,
                                        setAccessLogData,
                                      }}
                                    >
                                      <OpsRouteContext.Provider
                                        value={OPS_ROUTES.ASSIGNMENTS}
                                      >
                                        <Modal type={modalState} />
                                        {(liveAssignmentData.loading ||
                                          liveAllAssignmentsData.loading) && (
                                          <LoaderModal errMsg={loader} />
                                        )}
                                        <div
                                          className="ops-dashboard-container"
                                          onClick={(e) => {
                                            hideLogoutbox(e);
                                            hideFeedbackBox(e);
                                          }}
                                        >
                                          <OpsTopNav />
                                          <OpsSideNav />
                                          {notFoundError && <NotFoundError />}
                                          {comingSoonError && (
                                            <ComingSoonError />
                                          )}
                                          {!notFoundError && !comingSoonError && (
                                            <>
                                              <OpsSideBar />
                                              <OpsDashboard />
                                            </>
                                          )}
                                        </div>
                                      </OpsRouteContext.Provider>
                                    </OpsAccessLogContext.Provider>
                                  </OpsSharingLogContext.Provider>
                                </OpsArchiveCountContext.Provider>
                              </OpsArchiveDashboardFileContext.Provider>
                            </NewSecureKeyContext.Provider>
                          </DeliveryFormInfoContext.Provider>
                        </OpsDashboardFileContext.Provider>
                      </OpsFilesArrayContext.Provider>
                    </DeliverNewVersionInfoContext.Provider>
                  </BottomContext.Provider>
                </NotFoundErrorContext.Provider>
              </ComingSoonErrorContext.Provider>
            </LoaderContext.Provider>
          </ModalDispatchContext.Provider>
        );
      case OPS_ROUTES.ARCHIVE:
        return (
          <ModalDispatchContext.Provider value={modalDispatch}>
            <LoaderContext.Provider value={{ loader, setLoader }}>
              <ComingSoonErrorContext.Provider
                value={{ comingSoonError, setCommingSoonError }}
              >
                <NotFoundErrorContext.Provider
                  value={{ notFoundError, setNotFoundError }}
                >
                  <BottomContext.Provider value={{ bottomTab, setBottomTab }}>
                    <DeliverNewVersionInfoContext.Provider
                      value={{
                        deliverNewVersionInfo,
                        setDeliverNewVersionInfo,
                      }}
                    >
                      <OpsFilesArrayContext.Provider
                        value={{ OpsFilesArray, setOpsFilesArray }}
                      >
                        <OpsDashboardFileContext.Provider
                          value={{ OpsDisplayedFile, setOpsDisplayedFile }}
                        >
                          <DeliveryFormInfoContext.Provider
                            value={{ deliverFileInfo, setDeliverFileInfo }}
                          >
                            <NewSecureKeyContext.Provider
                              value={{ newSecureKey, setNewSecureKey }}
                            >
                              <OpsArchiveFilesArrayContext.Provider
                                value={{
                                  opsArchiveFilesArray,
                                  setOpsArchiveFilesArray,
                                }}
                              >
                                <OpsArchiveDashboardFileContext.Provider
                                  value={{
                                    opsArchiveDisplayedFile,
                                    setOpsArchiveDisplayedFile,
                                  }}
                                >
                                  <OpsArchiveCountContext.Provider
                                    value={{
                                      opsArchiveCount,
                                      setOpsArchiveCount,
                                    }}
                                  >
                                    <OpsArchiveSharingLogContext.Provider
                                      value={{
                                        archiveSharedLogData,
                                        setArchiveSharedLogData,
                                      }}
                                    >
                                      <OpsArchiveAccessLogContext.Provider
                                        value={{
                                          archiveAccessLogData,
                                          setArchiveAccessLogData,
                                        }}
                                      >
                                        <OpsRouteContext.Provider
                                          value={OPS_ROUTES.ARCHIVE}
                                        >
                                          <Modal type={modalState} />
                                          {loader && (
                                            <LoaderModal errMsg={loader} />
                                          )}
                                          <div
                                            className="ops-dashboard-container"
                                            onClick={(e) => {
                                              hideLogoutbox(e);
                                              hideFeedbackBox(e);
                                            }}
                                          >
                                            <OpsTopNav />
                                            <OpsSideNav />
                                            {notFoundError && <NotFoundError />}
                                            {comingSoonError && (
                                              <ComingSoonError />
                                            )}
                                            {!notFoundError &&
                                              !comingSoonError && (
                                                <>
                                                  <OpsArchiveSideBar />
                                                  <OpsArchive />
                                                </>
                                              )}
                                          </div>
                                        </OpsRouteContext.Provider>
                                      </OpsArchiveAccessLogContext.Provider>
                                    </OpsArchiveSharingLogContext.Provider>
                                  </OpsArchiveCountContext.Provider>
                                </OpsArchiveDashboardFileContext.Provider>
                              </OpsArchiveFilesArrayContext.Provider>
                            </NewSecureKeyContext.Provider>
                          </DeliveryFormInfoContext.Provider>
                        </OpsDashboardFileContext.Provider>
                      </OpsFilesArrayContext.Provider>
                    </DeliverNewVersionInfoContext.Provider>
                  </BottomContext.Provider>
                </NotFoundErrorContext.Provider>
              </ComingSoonErrorContext.Provider>
            </LoaderContext.Provider>
          </ModalDispatchContext.Provider>
        );
      case OPS_ROUTES.ACCOUNT:
        return (
          <LoaderContext.Provider value={{ loader, setLoader }}>
            <ComingSoonErrorContext.Provider
              value={{ comingSoonError, setCommingSoonError }}
            >
              <NotFoundErrorContext.Provider
                value={{ notFoundError, setNotFoundError }}
              >
                <OpsRouteContext.Provider value={OPS_ROUTES.ACCOUNT}>
                  {loader && <LoaderModal errMsg={loader} />}
                  <div
                    className="ops-dashboard-container"
                    onClick={(e) => {
                      hideLogoutbox(e);
                      hideFeedbackBox(e);
                    }}
                  >
                    <OpsTopNav />
                    <OpsSideNav />
                    {notFoundError && <NotFoundError />}
                    {comingSoonError && <ComingSoonError />}
                    {!notFoundError && !comingSoonError && (
                      <>
                        <OpsAccountSettings />
                      </>
                    )}
                  </div>
                  {/* <div className='ops-dashboard-container' onClick={(e) => { hideLogoutbox(e);hideFeedbackBox(e); }}>
                        <OpsTopNav />
                        <OpsSideNav />
                        <OpsAccountSettings />
                    </div> */}
                </OpsRouteContext.Provider>
              </NotFoundErrorContext.Provider>
            </ComingSoonErrorContext.Provider>
          </LoaderContext.Provider>
        );
      // case "addclient":
      //     return (
      //         <>
      //             <div className='ops-dashboard-container' onClick={(e) => { hideLogoutbox(e);hideFeedbackBox(e); }}>
      //                 <OpsTopNav />
      //                 <OpsSideNav />
      //                 <OpsClientSettings />
      //             </div>
      //         </>
      //     )
      // case "adduser":
      //     return (
      //         <>
      //             <div className='ops-dashboard-container' onClick={(e) => { hideLogoutbox(e);hideFeedbackBox(e); }}>
      //                 <OpsTopNav />
      //                 <OpsSideNav />
      //                 <OpsUserSettings />
      //             </div>
      //         </>
      //     )
      case OPS_ROUTES.ADD_NEW:
        return (
          <ModalDispatchContext.Provider value={modalDispatch}>
            <LoaderContext.Provider value={{ loader, setLoader }}>
              <ComingSoonErrorContext.Provider
                value={{ comingSoonError, setCommingSoonError }}
              >
                <NotFoundErrorContext.Provider
                  value={{ notFoundError, setNotFoundError }}
                >
                  <BottomContext.Provider value={{ bottomTab, setBottomTab }}>
                    <DeliverNewVersionInfoContext.Provider
                      value={{
                        deliverNewVersionInfo,
                        setDeliverNewVersionInfo,
                      }}
                    >
                      <OpsFilesArrayContext.Provider
                        value={{ OpsFilesArray, setOpsFilesArray }}
                      >
                        <OpsDashboardFileContext.Provider
                          value={{ OpsDisplayedFile, setOpsDisplayedFile }}
                        >
                          <DeliveryFormInfoContext.Provider
                            value={{ deliverFileInfo, setDeliverFileInfo }}
                          >
                            <NewSecureKeyContext.Provider
                              value={{ newSecureKey, setNewSecureKey }}
                            >
                              <OpsArchiveCountContext.Provider
                                value={{ opsArchiveCount, setOpsArchiveCount }}
                              >
                                <OpsSharingLogContext.Provider
                                  value={{ sharedLogData, setSharedLogData }}
                                >
                                  <OpsAccessLogContext.Provider
                                    value={{ accessLogData, setAccessLogData }}
                                  >
                                    <OpsRouteContext.Provider
                                      value={OPS_ROUTES.ADD_NEW}
                                    >
                                      <FileGroupsContext.Provider
                                        value={{
                                          fileUploadData,
                                          setFileUploadData,
                                        }}
                                      >
                                        {loader && (
                                          <LoaderModal errMsg={loader} />
                                        )}
                                        <Modal type={modalState} />
                                        <div
                                          className="ops-dashboard-container"
                                          onClick={(e) => {
                                            hideLogoutbox(e);
                                            hideFeedbackBox(e);
                                          }}
                                        >
                                          <OpsTopNav />
                                          <OpsSideNav />
                                          {notFoundError && <NotFoundError />}
                                          {comingSoonError && (
                                            <ComingSoonError />
                                          )}
                                          {!notFoundError && !comingSoonError && (
                                            <>
                                              <OpsSideBar />
                                              <NewDelivery />
                                            </>
                                          )}
                                        </div>
                                      </FileGroupsContext.Provider>
                                    </OpsRouteContext.Provider>
                                  </OpsAccessLogContext.Provider>
                                </OpsSharingLogContext.Provider>
                              </OpsArchiveCountContext.Provider>
                            </NewSecureKeyContext.Provider>
                          </DeliveryFormInfoContext.Provider>
                        </OpsDashboardFileContext.Provider>
                      </OpsFilesArrayContext.Provider>
                    </DeliverNewVersionInfoContext.Provider>
                  </BottomContext.Provider>
                </NotFoundErrorContext.Provider>
              </ComingSoonErrorContext.Provider>
            </LoaderContext.Provider>
          </ModalDispatchContext.Provider>
        );

      case OPS_ROUTES.UPDATE_DELIVERY:
        return (
          <ModalDispatchContext.Provider value={modalDispatch}>
            <LoaderContext.Provider value={{ loader, setLoader }}>
              <ComingSoonErrorContext.Provider
                value={{ comingSoonError, setCommingSoonError }}
              >
                <NotFoundErrorContext.Provider
                  value={{ notFoundError, setNotFoundError }}
                >
                  <BottomContext.Provider value={{ bottomTab, setBottomTab }}>
                    <DeliverNewVersionInfoContext.Provider
                      value={{
                        deliverNewVersionInfo,
                        setDeliverNewVersionInfo,
                      }}
                    >
                      <OpsFilesArrayContext.Provider
                        value={{ OpsFilesArray, setOpsFilesArray }}
                      >
                        <OpsDashboardFileContext.Provider
                          value={{ OpsDisplayedFile, setOpsDisplayedFile }}
                        >
                          <DeliveryFormInfoContext.Provider
                            value={{ deliverFileInfo, setDeliverFileInfo }}
                          >
                            <NewSecureKeyContext.Provider
                              value={{ newSecureKey, setNewSecureKey }}
                            >
                              <OpsArchiveCountContext.Provider
                                value={{ opsArchiveCount, setOpsArchiveCount }}
                              >
                                <OpsSharingLogContext.Provider
                                  value={{ sharedLogData, setSharedLogData }}
                                >
                                  <OpsAccessLogContext.Provider
                                    value={{ accessLogData, setAccessLogData }}
                                  >
                                    <OpsRouteContext.Provider
                                      value={OPS_ROUTES.ADD_NEW}
                                    >
                                      <FileGroupsContext.Provider
                                        value={{
                                          fileUploadData,
                                          setFileUploadData,
                                        }}
                                      >
                                        {loader && (
                                          <LoaderModal errMsg={loader} />
                                        )}
                                        <Modal type={modalState} />
                                        <div
                                          className="ops-dashboard-container"
                                          onClick={(e) => {
                                            hideLogoutbox(e);
                                            hideFeedbackBox(e);
                                          }}
                                        >
                                          <OpsTopNav />
                                          <OpsSideNav />
                                          {notFoundError && <NotFoundError />}
                                          {comingSoonError && (
                                            <ComingSoonError />
                                          )}
                                          {!notFoundError && !comingSoonError && (
                                            <>
                                              <OpsSideBar />
                                              <UpdateDelivery />
                                            </>
                                          )}
                                        </div>
                                      </FileGroupsContext.Provider>
                                    </OpsRouteContext.Provider>
                                  </OpsAccessLogContext.Provider>
                                </OpsSharingLogContext.Provider>
                              </OpsArchiveCountContext.Provider>
                            </NewSecureKeyContext.Provider>
                          </DeliveryFormInfoContext.Provider>
                        </OpsDashboardFileContext.Provider>
                      </OpsFilesArrayContext.Provider>
                    </DeliverNewVersionInfoContext.Provider>
                  </BottomContext.Provider>
                </NotFoundErrorContext.Provider>
              </ComingSoonErrorContext.Provider>
            </LoaderContext.Provider>
          </ModalDispatchContext.Provider>
        );

      default:
        return <Redirect to="/" />;
    }
  } else {
    sessionStorage.setItem("redirect", window.location.pathname);
    return <Redirect to="/" />;
  }
};
export { OpsLoginComponents, OpsPageComponents };
