export const TYPE_OF_ASSIGNMENT_DATA = {
  INIT: "initial",
  ID: "id",
  UNDEFINED: "undefined_id",
  NONE: "none",
  ERROR: "error",
};

export const initialState = {
  loading: false,
  file_details: null,
  type_of_data: TYPE_OF_ASSIGNMENT_DATA.INIT,
  error: null,
};
