import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthData, postAuthData } from "../../../helpers/request";
// import { makeSecureDecrypt } from "../../../helpers/security";
import { initialState, setUserType } from "./common";

export const putProfileData = createAsyncThunk(
  "profile-data/putProfileData",
  async ({ profile_id, data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-ops/ops/v1/profile/${profile_id}/`;
    try {
      const res = await postAuthData(
        url,
        data,
        // token
      );
      if (res.success) {
        thunkAPI.dispatch(resetProfileData());
        thunkAPI.dispatch(
          getProfileData({
            id: thunkAPI.getState().profileData.data.profile_id,
          }),
        );
        thunkAPI.dispatch(resetProfile());
        return res;
      } else {
        console.log(res?.message);
        return thunkAPI.rejectWithValue({ err: "Failed to create profile." });
      }
    } catch (err) {
      return { err: "Failed to create profile." };
    }
  },
);

export const getProfileData = createAsyncThunk(
  "profile-data/getProfileData",
  async (thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-ops/ops/v1/profile/`;
    try {
      const res = await getAuthData(
        url,
        // token
      );
      if (res.success) {
        // thunkAPI.dispatch(resetProfileData());
        // thunkAPI.dispatch(
        //   addUserType({
        //     value: thunkAPI.getState().ProfileData.data.user_type,
        //     // value: makeSecureDecrypt(sessionStorage.getItem("user_type")),
        //   }),
        // );
        // console.log(res.data);
        return res?.data;
      } else {
        return thunkAPI.rejectWithValue(res?.message || "Profile Error!");
      }
    } catch (err) {
      return err;
    }
  },
);

const profileDataSlice = createSlice({
  name: "profile-data",
  initialState,
  reducers: {
    addFirstName: (state, { payload }) => {
      state.data.first_name = payload;
    },
    addLastName: (state, { payload }) => {
      state.data.last_name = payload;
    },
    addEmail: (state, { payload }) => {
      state.data.email = payload;
    },
    addOrgName: (state, { payload }) => {
      state.data.organization = payload;
    },
    addPhoneNum: (state, { payload }) => {
      state.data.phone_number = payload;
    },
    addPhoneNumCode: (state, { payload }) => {
      state.data.phone_number_ext = payload;
    },
    addWhatsAppNum: (state, { payload }) => {
      state.data.whatsapp_number = payload;
    },
    addWhatsAppNumCode: (state, { payload }) => {
      state.data.whatsapp_number_ext = payload;
    },
    addProfileImage: (state, { payload }) => {
      state.data.image = payload;
    },
    addProfileId: (state, { payload }) => {
      state.data.profile_id = payload;
    },
    // fetching userType from login
    addUserType: (state, { payload }) => {
      // console.log(payload);
      state.data.user_type = payload;
    },
    // RESET DATA
    resetProfileData: (state) => {
      state.data = initialState;
    },
    resetProfile: () => initialState,
  },
  extraReducers: {
    // Fetching Entity Details
    [getProfileData.pending]: (state) => {
      state.loading = true;
    },
    [getProfileData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data.first_name = payload?.first_name;
      state.data.last_name = payload?.last_name;
      state.data.email = payload?.email;
      state.data.organization = payload?.organization;
      state.data.phone_number = payload?.phone_number;
      state.data.phone_number_ext = payload?.phone_number_ext;
      state.data.whatsapp_number = payload?.whatsapp_number;
      state.data.whatsapp_number_ext = payload?.whatsapp_number_ext;
      state.data.profile_id = payload?.id;
      state.data.image = payload?.image;
      state.data.user_type = setUserType(payload?.user_type);
      // console.log(payload);
    },
    [getProfileData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.message;
    },
  },
});

export const {
  addFirstName,
  addEmail,
  addOrgName,
  addPhoneNum,
  addPhoneNumCode,
  addWhatsAppNum,
  addWhatsAppNumCode,
  addProfileImage,
  addProfileId,
  addUserType,
  // reset profile
  resetProfileData,
  resetProfile,
} = profileDataSlice.actions;

export default profileDataSlice.reducer;
