import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./common";

const shareDataSlice = createSlice({
  name: "share-data",
  initialState,
  reducers: {
    shareUpdateClient: (state, { payload }) => {
      state.client = payload;
    },
    shareAddEmail: (state, { payload }) => {
      state.shared_emails = payload;
    },
    shareRemoveEmail: (state, { payload }) => {
      state.shared_emails = payload;
    },
    shareAddRecognisedEmail: (state, { payload }) => {
      state.recognised_emails = payload;
    },
    shareRemoveRecognisedEmail: (state, { payload }) => {
      state.recognised_emails = payload;
    },
    shareAddUnrecognisedEmail: (state, { payload }) => {
      state.unrecognised_emails = payload;
    },
    shareRemoveUnrecognisedEmail: (state, { payload }) => {
      state.unrecognised_emails = payload;
    },
    shareShowWarning: (state, { payload }) => {
      state.show_warning = payload;
    },
    shareUploadWarningSent: (state, { payload }) => {
      state.upload_warning_sent = payload;
    },
    shareAddPasswordData: (state, { payload }) => {
      state.password_data = payload;
    },
    shareAddClientCode: (state, { payload }) => {
      state.client_code = payload;
    },
    shareAddEntityCode: (state, { payload }) => {
      state.entity_code = payload;
    },
    shareAddDeliveryId: (state, { payload }) => {
      state.delivery_id = payload;
    },
    shareResetData: () => initialState,
  },
});

export const {
  shareUpdateClient,
  shareAddEmail,
  shareRemoveEmail,
  shareAddRecognisedEmail,
  shareRemoveRecognisedEmail,
  shareAddUnrecognisedEmail,
  shareRemoveUnrecognisedEmail,
  shareShowWarning,
  shareUploadWarningSent,
  shareAddPasswordData,
  shareAddClientCode,
  shareAddEntityCode,
  shareAddDeliveryId,
  shareResetData,
} = shareDataSlice.actions;

export default shareDataSlice.reducer;
