import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaCheck,
  FaMinusCircle,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountDownAlt,
  FaUserPlus,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AccessLogTypeIcon,
  ACCESS_LOG_TYPES,
  ApiContext,
  date_time_convertor,
  devEnv,
  ERROR_REGEX,
  // getAccessLogData,
  HoverToolTipTextContext,
  showHoverToolTip,
  ToolTipError,
  TOOLTIP_HOVER_TYPE,
} from "../../../../..";
import { getAuthData } from "../../../../../helpers/request";
import {
  ModalDispatchContext,
  MODAL_TYPE,
  // NotFoundErrorContext,
  OpsAccessLogContext,
  OpsArchiveDashboardFileContext,
  OpsRouteContext,
  OpsSharingLogContext,
  OPS_ROUTES,
} from "../../../../OpsComponent";
import { shareAddEmail } from "../../../../../store/features/shareData/shareDataSlice";
import dummy_shared_logs from "../../../../../archive/test_data/dummy_data-v2/dummy_logs/dummy_shared_logs.json";
import dummy_access_logs from "../../../../../archive/test_data/dummy_data-v2/dummy_logs/dummy_access_logs.json";

import "./LogsTab.css";

// ==================== Shared list card =====================
// ===================================== Data Share card ==============================================

const SharedDataUserCard = ({ user }) => {
  return (
    <div className="ops-live-shared-user-card">
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-user">
        <span className="ops-live-shared-user-card-info-head">User</span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.shared_to_user_email || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-shared-by">
        <span className="ops-live-shared-user-card-info-head">Shared By</span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.shared_by || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-time-accessed ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Times Accessed
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.accessed_number ?? "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-last-downloaded-version ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Last Downloaded Version
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.last_download_version || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-last-accessed-on ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Last Accessed on
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {date_time_convertor(user.last_accessed_time) || "-"}
        </span>
        {/* <span className="ops-live-shared-user-card-info-value">Date</span> */}
      </div>
    </div>
  );
};

// ===================================== RequestedShare card ==============================================

const RequestedSharedUserCard = ({ user }) => {
  return (
    <div className="ops-live-shared-user-card ops-requested-shared-user-card">
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-user">
        <span className="ops-live-shared-user-card-info-head">User</span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.shared_to_user_email || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-shared-by">
        <span className="ops-live-shared-user-card-info-head">Shared By</span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.shared_by || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-time-accessed ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Times Accessed
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.accessed_number ?? "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-last-downloaded-version ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Last Downloaded Version
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.last_download_version || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-last-accessed-on ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Last Accessed on
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          Requested
        </span>
        {/* <span className="ops-live-shared-user-card-info-value">Date</span> */}
      </div>
      <span className="ops-live-log-item-card ops-live-log-item-requested-share-btns ops-live-log-item-card-check-btn"></span>
      <span className="ops-live-log-item-card ops-live-log-item-requested-share-btns ops-live-log-item-card-revoke-btn"></span>
    </div>
  );
};

// ===================================== Share card ==============================================

const SharedUserCard = ({ user }) => {
  const dispatch = useDispatch();
  const modal_dispatch = useContext(ModalDispatchContext);
  // const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  // const API_URL = useContext(ApiContext);
  // const { setNotFoundError } = useContext(NotFoundErrorContext);
  // const { setSharedLogData } = useContext(OpsSharingLogContext);
  // const { setAccessLogData } = useContext(OpsAccessLogContext);

  // const fetch_access_log = () => {
  //   getAuthData(
  //     `${API_URL}/api-ops/misc/v1/access-log/${liveAssignmentData?.file_details?.id}/`,
  //   )
  //     .then((res) => {
  //       if (res.success === true) {
  //         setAccessLogData(res.data);
  //       } else {
  //         setNotFoundError(true);
  //       }
  //     })
  //     .catch(() => {
  //       setNotFoundError(true);
  //     });
  // };

  // const fetch_shared_log = () => {
  //   getAuthData(
  //     `${API_URL}/api-ops/misc/v1/sharing-file/${liveAssignmentData?.file_details?.id}/`,
  //   )
  //     .then((res) => {
  //       if (res.success === true) {
  //         setSharedLogData(res);
  //       } else {
  //         setNotFoundError(true);
  //       }
  //     })
  //     .catch(() => {
  //       setNotFoundError(true);
  //     });
  // };

  const handleRevoke = (e) => {
    e.preventDefault();
    dispatch(shareAddEmail([user?.shared_to_user_email]));
    modal_dispatch({ modal_show_type: MODAL_TYPE.SHARE_REVOKE });
    // const data = {
    //   delivery_id: liveAssignmentData.file_details?.batch_id,
    //   email: user?.shared_to_user_email,
    // };
    // const confirm_ans = confirm("Do you really want to revoke?");
    // if (!confirm_ans) return;
    // if (devEnv) {
    //   console.log(getAccessLogData(data));
    //   return;
    // }
    // postAuthData(
    //   `${process.env.REACT_APP_API_URL}/api-ops/misc/v1/revoke/`,
    //   getAccessLogData(data),
    // )
    //   .then((res) => {
    //     if (res.success) {
    //       fetch_shared_log();
    //       fetch_access_log();
    //     }
    //   })
    //   .catch(() => {});
  };

  return (
    <div className="ops-live-shared-user-card">
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-user">
        <span className="ops-live-shared-user-card-info-head">User</span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.shared_to_user_email || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-shared-by">
        <span className="ops-live-shared-user-card-info-head">Shared By</span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.shared_by || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-time-accessed ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Times Accessed
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.accessed_number ?? "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-last-downloaded-version ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Last Downloaded Version
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {user?.last_download_version || "-"}
        </span>
      </div>
      <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-last-accessed-on ops-live-shared-user-card-info-center">
        <span className="ops-live-shared-user-card-info-head">
          Last Accessed on
        </span>
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value">
          {date_time_convertor(user.last_accessed_time) || "-"}
        </span>
        {/* <span className="ops-live-shared-user-card-info-value">Date</span> */}
      </div>
      <span className="ops-live-log-item-card ops-live-log-item-btns ops-live-log-item-card-check-btn">
        <FaCheck />
      </span>
      <span
        className="ops-live-log-item-card ops-live-log-item-btns ops-live-log-item-card-revoke-btn"
        title="Revoke Share"
        onClick={handleRevoke}
      >
        <FaMinusCircle />
      </span>
      {/* <div className="ops-live-log-item-card ops-live-shared-user-card-info ops-share-revoke-options">
        <span className="ops-live-log-item-value ops-live-shared-user-card-info-value ops-live-shared-user-card-info-value-check-btn">
          <FaCheck />
        </span>
        <span
          className="ops-live-log-item-value ops-live-shared-user-card-info-value ops-live-shared-user-card-info-value-revoke-btn"
          title="Coming soon!"
          onClick={handleRevoke}
        >
          <FaMinusCircle />
        </span>
      </div> */}
    </div>
  );
};

const OpsShareCard = ({ setDisplayedFile }) => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const route = useContext(OpsRouteContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const API_URL = useContext(ApiContext);
  // const {OpsDisplayedFile} = useContext(OpsDashboardFileContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );
  // const {setNotFoundError} = useContext(NotFoundErrorContext);
  const { sharedLogData, setSharedLogData } = useContext(OpsSharingLogContext);
  const [shareCardMsg, setShareCardMsg] = useState(null);
  const { asgn_id } = useParams();

  // If 0 logs are found then set a message
  useEffect(() => {
    if (sharedLogData && sharedLogData?.length === 0) {
      setShareCardMsg("No logs found!");
    } else {
      setShareCardMsg(null);
    }
  }, [sharedLogData]);

  const fetch_shared_log = () => {
    setShareCardMsg("Fetching...");
    if (devEnv) {
      setSharedLogData(dummy_shared_logs);
      return;
    }
    getAuthData(
      `${API_URL}/api-ops/misc/v1/sharing-file/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setShareCardMsg("Can't fetch logs.");
        }
      })
      .catch(() => {
        setShareCardMsg("Can't fetch logs.");
      });
  };

  // useEffect(() => {
  //     fetch_shared_log();
  // }, [])

  useEffect(() => {
    liveAssignmentData?.file_details?.id && fetch_shared_log();
  }, [liveAssignmentData]);

  useEffect(() => {
    if (route === OPS_ROUTES.ARCHIVE) {
      setDisplayedFile(opsArchiveDisplayedFile);
    }
  }, [liveAssignmentData, opsArchiveDisplayedFile]);

  useEffect(() => {
    setShareCardMsg("Fetching...");
  }, [asgn_id]);

  const openSharingModal = (e) => {
    e.preventDefault();
    modal_dispatch({ modal_show_type: MODAL_TYPE.SHARING });
  };

  return (
    <div className="ops-live-log-card-container">
      <div className="ops-dashboard-card-head ops-share-card-head">
        <span>Users</span>
        <button className="ops-share-open-modal-btn" onClick={openSharingModal}>
          <FaUserPlus />
        </button>
      </div>

      <div className="ops-live-shared-users-container-head">
        <span className="ops-live-shared-users-container-head-value">User</span>
        <span className="ops-live-shared-users-container-head-value">
          Shared By
        </span>
        <span className="ops-live-shared-users-container-head-value ops-live-shared-users-container-head-value-center">
          Times Accessed
        </span>
        <span className="ops-live-shared-users-container-head-value ops-live-shared-users-container-head-value-center">
          Last Downloaded Version
        </span>
        <span className="ops-live-shared-users-container-head-value ops-live-shared-users-container-head-value-center">
          Last Accessed on
        </span>
        <span className="ops-live-shared-users-container-head-value ops-live-shared-users-container-head-value-center ops-live-shared-users-container-head-value-revoke"></span>
      </div>
      {shareCardMsg && (
        <div className="ops-live-log-items-container">
          <div className="ops-live-log-msg">{shareCardMsg}</div>
        </div>
      )}
      {!shareCardMsg && (
        <div className="ops-live-log-items-container">
          {sharedLogData?.request_shared_log?.map((user, i) => {
            return <RequestedSharedUserCard key={i} user={user} />;
          })}
          {sharedLogData?.shared_log?.map((user, i) => {
            return <SharedUserCard key={i} user={user} />;
          })}
          {sharedLogData?.data?.map((user, i) => {
            /*this will be removed after the revoke update*/
            return <SharedDataUserCard key={i} user={user} />;
          })}
        </div>
      )}
    </div>
  );
};

// ========================= Access Logs =======================================

// this function will split the text upon a specific point and apply hover on the 2nd part after the split
const HoverText = ({ tooltip_txt, hover_str, split_txt }) => {
  const log_str = hover_str.split(split_txt)[0] + split_txt;
  const log_hover = hover_str.split(split_txt)[1];
  const { setHoverTooltipStr } = useContext(HoverToolTipTextContext);

  return (
    <span>
      {log_str + " "}
      <u
        className="hover-tooltip"
        onMouseOver={(e) => {
          setHoverTooltipStr(tooltip_txt);
          showHoverToolTip(e, TOOLTIP_HOVER_TYPE.OPEN);
        }}
        onMouseLeave={(e) => {
          showHoverToolTip(e, TOOLTIP_HOVER_TYPE.CLOSE, null);
        }}
      >
        {log_hover}
      </u>
    </span>
  );
};

const OpsAccessLogItemCard = ({ access_log }) => {
  return (
    <div className="ops-live-log-item-container ops-access-log-item-container">
      <div className="ops-live-log-item-card ops-access-log-type-logo">
        <div className="ops-live-log-item-value">
          <AccessLogTypeIcon type={access_log.type} />
        </div>
      </div>
      <div className="ops-live-log-item-card ops-access-log-string">
        <div className="ops-live-log-item-value">
          {[
            ACCESS_LOG_TYPES.DOWNLOAD,
            ACCESS_LOG_TYPES.SHARED,
            ACCESS_LOG_TYPES.SHARE_REVOKE,
            ACCESS_LOG_TYPES.SHARED_OUTSIDE,
            ACCESS_LOG_TYPES.SHARE_REQUEST,
          ].includes(access_log.type) ? (
            <>
              {access_log.type === ACCESS_LOG_TYPES.DOWNLOAD && (
                <HoverText
                  tooltip_txt={access_log.tooltip.files}
                  hover_str={access_log.access_log_string}
                  split_txt={"downloaded "}
                />
              )}
              {(access_log.type === ACCESS_LOG_TYPES.SHARED ||
                access_log.type === ACCESS_LOG_TYPES.SHARED_OUTSIDE ||
                access_log.type === ACCESS_LOG_TYPES.SHARE_REQUEST) && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"with "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.SHARE_REVOKE && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"for "}
                />
              )}
            </>
          ) : (
            access_log.access_log_string
          )}
        </div>
      </div>
      <div className="ops-live-log-item-card ops-access-log-platform ops-blue-access-log">
        <div className="ops-live-log-item-value">
          {access_log?.tooltip?.ip ? (
            <HoverText
              tooltip_txt={access_log.tooltip.ip}
              hover_str={access_log.platform}
              split_txt={"from "}
            />
          ) : (
            access_log.platform
          )}
        </div>
      </div>
      <div className="ops-live-log-item-card ops-access-log-date ops-blue-access-log">
        <div className="ops-live-log-item-value">
          {date_time_convertor(access_log.date)}
        </div>
      </div>
    </div>
  );
};

export const access_log_sort_order = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

const OpsAccessLogCard = ({ setDisplayedFile }) => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const API_URL = useContext(ApiContext);
  const [sort, setSort] = useState(access_log_sort_order.ASCENDING);
  const access_log_search_word = useRef(null);
  const route = useContext(OpsRouteContext);
  // const {OpsDisplayedFile} = useContext(OpsDashboardFileContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );
  const { accessLogData, setAccessLogData } = useContext(OpsAccessLogContext);
  // const {setNotFoundError} = useContext(NotFoundErrorContext);
  const [accessCardMsg, setAccessCardMsg] = useState(null);
  const { asgn_id } = useParams();

  // If 0 logs are found then set a message
  useEffect(() => {
    if (accessLogData && accessLogData?.length === 0) {
      setAccessCardMsg("No logs found!");
    } else {
      setAccessCardMsg(null);
    }
  }, [accessLogData]);

  const fetch_access_log = () => {
    setAccessCardMsg("Fetching...");
    if (devEnv) {
      // setAccessCardMsg("Turn off dev environment to fetch real data.")
      setAccessLogData(dummy_access_logs.data);
      return;
    }
    getAuthData(
      `${API_URL}/api-ops/misc/v1/access-log/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setAccessCardMsg("Can't fetch logs.");
        }
      })
      .catch(() => {
        setAccessCardMsg("Can't fetch logs.");
      });
  };

  const fetch_sorted_access_log = (sort) => {
    setAccessCardMsg("Fetching...");
    if (devEnv) {
      setAccessCardMsg("Turn off dev environment to fetch real data.");
      return;
    }
    getAuthData(
      `${API_URL}/api-ops/misc/v1/access-log/${liveAssignmentData?.file_details?.id}/?sort=${sort}&search=${access_log_search_word?.current?.value}`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessCardMsg(null);
          setAccessLogData(res.data);
        } else {
          setAccessCardMsg("Can't fetch logs.");
        }
      })
      .catch(() => {
        setAccessCardMsg("Can't fetch logs.");
      });
  };

  // useEffect(() => {
  //     return (liveAssignmentData?.file_details?.id) && fetch_access_log();
  // }, [])

  useEffect(() => {
    access_log_search_word.current.value = "";
    liveAssignmentData?.file_details?.id && fetch_access_log();
  }, [liveAssignmentData.file_details]);

  useEffect(() => {
    if (route === OPS_ROUTES.ARCHIVE) {
      setDisplayedFile(opsArchiveDisplayedFile);
    }
  }, [liveAssignmentData.file_details, opsArchiveDisplayedFile]);

  useEffect(() => {
    setAccessCardMsg("Fetching...");
  }, [asgn_id]);

  const handleSort = (e) => {
    e.preventDefault();
    setSort((prev) => {
      if (prev === access_log_sort_order.ASCENDING) {
        fetch_sorted_access_log(access_log_sort_order.DESCENDING);
        return access_log_sort_order.DESCENDING;
      } else if (prev === access_log_sort_order.DESCENDING) {
        fetch_sorted_access_log(access_log_sort_order.ASCENDING);
        return access_log_sort_order.ASCENDING;
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetch_sorted_access_log(access_log_sort_order.ASCENDING);
  };

  return (
    <div className="ops-live-log-card-container">
      <div className="ops-dashboard-card-head ops-live-log-card-head">
        <div className="ops-access-head">
          <span>Access Logs</span>
          {sort === access_log_sort_order.ASCENDING && (
            <button className="ops-sort-btn" onClick={handleSort}>
              <FaSortAmountDownAlt />
            </button>
          )}
          {sort === access_log_sort_order.DESCENDING && (
            <button className="ops-sort-btn" onClick={handleSort}>
              <FaSortAmountDown />
            </button>
          )}
        </div>
        <div className="ops-access-log-search-container">
          <form className="ops-access-log-search-form" onSubmit={handleSearch}>
            <input
              type="text"
              className="ops-dashboard-input ops-access-log-search-input"
              ref={access_log_search_word}
              onChange={(e) => {
                ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
              }}
              name="ops-search"
              id="ops-search"
              placeholder="Search..."
            />
            <button type="submit" className="ops-access-log-search-btn">
              <FaSearch />
            </button>
          </form>
        </div>
      </div>

      <div className="ops-live-log-items-container">
        {accessCardMsg ? (
          <div className="ops-live-log-msg">{accessCardMsg}</div>
        ) : (
          accessLogData?.map((access_log, i) => {
            {
              /* {test_data.access_logs?.map((access_log,i)=>{ */
            }
            return <OpsAccessLogItemCard key={i} access_log={access_log} />;
          })
        )}
      </div>
    </div>
  );
};

// This combines all the logs tab i.e. shared and access

const LogsTab = ({ file }) => {
  const [displayedFile, setDisplayedFile] = useState(file);

  return (
    <div className="ops-logs-container">
      <OpsShareCard
        displayedFile={displayedFile}
        setDisplayedFile={setDisplayedFile}
      />
      <OpsAccessLogCard
        displayedFile={displayedFile}
        setDisplayedFile={setDisplayedFile}
      />
    </div>
  );
};

export default LogsTab;
