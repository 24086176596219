import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAuthData,
  postAuthData,
  putAuthData,
} from "../../../helpers/request";
import { TAB_CHOICE } from "../../../utils/CustomInputs/nameSpaces";
import roundNum from "../../../utils/roundNumber";
import {
  createInvoiceDefaultEntityOptionValue,
  createInvoiceEntityOptionsArr,
  createInvoiceOptionsArr,
  createInvoiceOptionValue,
  createInvoiceSubServiceOptionValue,
  createSubServiceInvoiceOptionsArr,
  initialState,
  invoice_item_details_data,
  invoice_tax_options,
  service_price_data,
} from "./common";

// Async fetches for options
// setting up other state values
// temp changes
// const token = "jbDLY7vcpZuGRdA8j3mmnlvkdEiFIf";

export const getInvoiceDefaultEntitiesOptions = createAsyncThunk(
  "invoice-data/getInvoiceDefaultEntitiesOptions",
  async (_, thunkAPI) => {
    let url = `${
      process.env.REACT_APP_NEW_REQUESTER_API_URL
    }/api-req/requester/v1/default-entity/?cid=${
      thunkAPI.getState().liveClientData?.requester_details?.id
    }`;
    try {
      const res = await getAuthData(
        url,
        // token
      );
      if (res.success) {
        return createInvoiceEntityOptionsArr(res.entities_list);
      } else {
        return thunkAPI.rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getInvoiceServiceOptions = createAsyncThunk(
  "invoice-data/getInvoiceServiceOptions",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/service/`;
    try {
      const res = await postAuthData(url);
      if (res.success) {
        return createInvoiceOptionsArr(res.services);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getInvoiceSubServiceOptions = createAsyncThunk(
  "invoice-data/getInvoiceSubServiceOptions",
  async ({ id, service }, { rejectWithValue }) => {
    let serviceDataObj = { service: service };
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/service/`;
    try {
      const res = await postAuthData(url, serviceDataObj);
      if (res.success) {
        return {
          idx: id,
          res: createSubServiceInvoiceOptionsArr(res.services),
        };
      } else {
        return rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

export const getInvoiceListPrice = createAsyncThunk(
  "invoice-data/getInvoiceListPrice",
  async ({ id }, thunkAPI) => {
    let sub_service =
      thunkAPI.getState().invoiceData.invoice_form_data.service_prices[id]
        .sub_service.selected_option.value;
    let url = `${
      process.env.REACT_APP_NEW_REQUESTER_API_URL
    }/api-req/requester/v1/service/${sub_service}/?currency=${
      thunkAPI.getState().invoiceData.invoice_form_data.pricing_details.currency
        ?.value
    }`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return { idx: id, res: res.list_price };
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

export const getInvoiceMSLAPrice = createAsyncThunk(
  "invoice-data/getInvoiceMSLAPrice",
  async ({ id }, thunkAPI) => {
    let sub_service =
      thunkAPI.getState().entityData.invoice_form_data.service_prices[id]
        .sub_service.selected_option.value;
    let url = `${
      process.env.REACT_APP_NEW_REQUESTER_API_URL
    }/api-req/requester/v1/service/${sub_service}/?curr=${
      thunkAPI.getState().entityData.invoice_form_data.pricing_details.currency
        ?.value
    }`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return { idx: id, res: res.list_price };
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

export const postInvoiceCreateFormData = createAsyncThunk(
  "invoice-data/postInvoiceCreateFormData",
  async ({ data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/invoice/`;
    try {
      const res = await postAuthData(
        url,
        data,
        // token
      );
      if (res.success) {
        // thunkAPI.dispatch(resetInvoiceItemDetails()); // reseting the list item details after submit
        // thunkAPI.dispatch(resetInvoiceFormData()); // reseting the invoice form data after submit
        thunkAPI.dispatch(resetInvoiceTab()); // resetting the whole state to initial state
        thunkAPI.dispatch(
          getInvoiceList({
            id: thunkAPI.getState().liveClientData.requester_details.id,
          }), // getting the invoice list based on client id
          // getInvoiceListItemDetails({
          // id: thunkAPI.getState().invoiceData.invoice_form_data.invoice_id,
          // id: thunkAPI.getState().entityData.entit.invoice_id,
          // }),
        );
        return res;
      } else {
        console.log(res?.message);
        return thunkAPI.rejectWithValue({ err: "Failed to create invoice." });
      }
    } catch (err) {
      return { err: "Failed to create invoice." };
    }
  },
);

export const putInvoiceUpdateFormData = createAsyncThunk(
  "invoice-data/putInvoiceUpdateFormData",
  async ({ invoice_id, data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/invoice/${invoice_id}/`;
    try {
      const res = await putAuthData(
        url,
        data,
        // token
      );
      if (res.success) {
        thunkAPI.dispatch(resetInvoiceTab()); // resetting the whole state to initial state
        thunkAPI.dispatch(resetInvoiceFormData());
        thunkAPI.dispatch(resetInvoiceItemDetails());
        // thunkAPI.dispatch(resetInvoiceItemDetails());
        // thunkAPI.dispatch(
        //   getInvoiceListItemDetails({
        //     id: thunkAPI.getState().invoiceData.invoice_form_data.invoice_id,
        //   }),
        // );
        return res;
      } else {
        console.log(res?.message);
        return thunkAPI.rejectWithValue({ err: "Failed to update invoice." });
      }
    } catch (err) {
      return { err: "Failed to create entity." };
    }
  },
);

export const getEditInvoiceInfo = createAsyncThunk(
  "invoice-data/getEditInvoiceInfo",
  async ({ id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/invoice/${id}/`;
    try {
      const res = await getAuthData(
        url,
        // token
      );
      if (res.success) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue({ err: res?.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

// =================== iNVOICE lIST =================== //

export const getInvoiceList = createAsyncThunk(
  "invoice-data/getInvoiceList",
  async ({ id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/invoice/?cid=${id}`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        thunkAPI.dispatch(resetInvoiceItemDetails());
        return res;
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

export const getInvoiceListItemDetails = createAsyncThunk(
  "invoice-data/getInvoiceListItemDetails",
  async ({ id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/invoice/${id}`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return { idx: id, res: res?.data };
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

// ======================= SLICE ========================

const invoiceDataSlice = createSlice({
  name: "invoice-data",
  initialState,
  reducers: {
    // TAB SWITCH
    toggleInvoiceTab: (state) => {
      if (state.tab === TAB_CHOICE.FORM) {
        state.tab = TAB_CHOICE.LIST;
      } else if (state.tab === TAB_CHOICE.LIST) {
        state.tab = TAB_CHOICE.FORM;
      }
    },
    openInvoiceForm: (state) => {
      state.tab = TAB_CHOICE.FORM;
    },
    openInvoiceList: (state) => {
      state.tab = TAB_CHOICE.LIST;
    },
    openEditInvoiceForm: (state, { payload }) => {
      state.tab = TAB_CHOICE.FORM;
      state.edit_invoice_id = payload.id;
    },
    // toggle list
    toggleInvoiceListCard: (state, { payload }) => {
      if (state.invoice_list_data.list[payload.idx]?.open) {
        state.invoice_list_data.list[payload.idx].open = false;
      } else if (!state.invoice_list_data.list[payload.idx]?.open) {
        state.invoice_list_data.list[payload.idx].open = true;
      }
    },

    // RESET DATA
    resetInvoiceFormData: (state) => {
      state.invoice_form_data = initialState.invoice_form_data;
    },
    resetInvoiceTab: () => initialState,
    // ================== PRICING DETAILS FORM ==========================
    addPricingDetailsValidity: (state, { payload }) => {
      state.invoice_form_data.pricing_details.valid_thorugh = payload;
    },
    addPricingDetailsTaxArrItem: (state) => {
      state.invoice_form_data.pricing_details.taxes = [
        ...state.invoice_form_data.pricing_details.taxes,
        invoice_tax_options,
      ];
    },
    addPricingDetailsTaxType: (state, { payload }) => {
      // required input format==> {item_id: 0, value: "VAT"}
      state.invoice_form_data.pricing_details.taxes[payload.item_id].tax_type =
        payload.value;
    },
    addPricingDetailsTaxPerc: (state, { payload }) => {
      // required input fromat ==> {item_id: 0, value: "VAT"}
      state.invoice_form_data.pricing_details.taxes[payload.item_id].perc =
        payload.value;
    },
    addPricingDetailsCurrency: (state, { payload }) => {
      state.invoice_form_data.pricing_details.currency = payload;
    },
    addPricingDetailsDefaultEntity: (state, { payload }) => {
      state.invoice_form_data.pricing_details.default_entity = payload;
    },
    addPricingDetailsDiscountType: (state, { payload }) => {
      state.invoice_form_data.pricing_details.discount_type = payload;
    },
    addPricingDetailsComments: (state, { payload }) => {
      state.invoice_form_data.pricing_details.comments = payload;
    },

    // ============================ SERVICE PRICES ========================
    addServicePriceArrItem: (state) => {
      state.invoice_form_data.service_prices = [
        ...state.invoice_form_data.service_prices,
        service_price_data,
      ];
    },
    removeServicePriceArrItem: (state, { payload }) => {
      state.invoice_form_data.service_prices.splice(payload.id, 1);
    },
    resetServicePriceArr: (state) => {
      state.invoice_form_data.service_prices =
        initialState.invoice_form_data.service_prices;
    },
    addServicePriceService: (state, { payload }) => {
      // required input format==> {item_id: 0, value: {label: somethign, value:"Translation"}}
      state.invoice_form_data.service_prices[payload.item_id].service =
        payload.value;
    },
    addServicePriceSubService: (state, { payload }) => {
      // required input format==> {item_id: 0, value: {label: somethign, value:"Translation"}}
      state.invoice_form_data.service_prices[
        payload.item_id
      ].sub_service.selected_option = payload.value;
    },
    addServicePriceListPrice: (state, { payload }) => {
      // required input format==> {item_id: 0, value: "Translation"}
      state.invoice_form_data.service_prices[payload.item_id].list_price =
        payload.value;
    },
    addServicePriceInvoicePrice: (state, { payload }) => {
      // required input format==> {item_id: 0, value: "Translation"}
      state.invoice_form_data.service_prices[payload.item_id].invoice_price =
        payload.value;
      const msla_price =
        !state.invoice_form_data.service_prices[payload.item_id]?.msla_price ||
        state.invoice_form_data.service_prices[payload.item_id].msla_price === 0
          ? state.invoice_form_data.service_prices[payload.item_id]?.list_price
          : state.invoice_form_data.service_prices[payload.item_id].msla_price;
      // formula for discount
      state.invoice_form_data.service_prices[payload.item_id].cum_discount =
        100 -
        (100 * payload.value) /
          state.invoice_form_data.service_prices[payload.item_id].list_price;
      state.invoice_form_data.service_prices[payload.item_id].cum_discount =
        roundNum(
          100 -
            (100 * payload.value) /
              state.invoice_form_data.service_prices[payload.item_id]
                .list_price,
        );
      state.invoice_form_data.service_prices[payload.item_id].discount =
        roundNum(100 - (100 * payload.value) / msla_price);
    },
    addServicePriceDiscount: (state, { payload }) => {
      // required input format==> {item_id: 0, value: "Translation"}
      state.invoice_form_data.service_prices[payload.item_id].discount =
        payload.value;
    },

    // Invoice list data
    resetInvoiceItemDetails: (state) => {
      state.invoice_list_data = initialState.invoice_list_data;
    },
  },
  extraReducers: {
    // Fetching Default Entities Options
    [getInvoiceDefaultEntitiesOptions.pending]: (state) => {
      state.invoice_form_data.form_options.default_entities.loading = true;
    },
    [getInvoiceDefaultEntitiesOptions.fulfilled]: (state, { payload }) => {
      state.invoice_form_data.form_options.default_entities.loading = false;
      state.invoice_form_data.form_options.default_entities.list = payload;
    },
    [getInvoiceDefaultEntitiesOptions.rejected]: (state, action) => {
      state.invoice_form_data.form_options.default_entities.loading = false;
      state.invoice_form_data.form_options.default_entities.error =
        action.message;
    },

    // Fetching Service Options
    [getInvoiceServiceOptions.pending]: (state) => {
      state.invoice_form_data.form_options.service.loading = true;
    },
    [getInvoiceServiceOptions.fulfilled]: (state, { payload }) => {
      state.invoice_form_data.form_options.service.loading = false;
      state.invoice_form_data.form_options.service.list = payload;
    },
    [getInvoiceServiceOptions.rejected]: (state, action) => {
      state.invoice_form_data.form_options.service.loading = false;
      state.invoice_form_data.form_options.service.error = action.message;
    },

    // Fetching Sub Service Options
    [getInvoiceSubServiceOptions.pending]: (state, action) => {
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].sub_service.options.loading = true;
    },
    [getInvoiceSubServiceOptions.fulfilled]: (state, { payload }) => {
      state.invoice_form_data.service_prices[
        payload.idx
      ].sub_service.options.loading = false;
      state.invoice_form_data.service_prices[
        payload.idx
      ].sub_service.options.list = payload.res;
      // removing invoice pirce so as to get new invoice price and calculate discount
      state.invoice_form_data.service_prices[payload.idx].invoice_price = 0;
      state.invoice_form_data.service_prices[payload.idx].cum_discount = 0;
      state.invoice_form_data.service_prices[payload.idx].discount = 0;
      state.invoice_form_data.service_prices[payload.idx].list_price = 0;
      // state.invoice_form_data.service_prices[
      //   payload.idx
      // ].sub_service.selected_option =
      //   initialState.invoice_form_data.service_prices[
      //     payload.idx
      //   ].sub_service.selected_option;
    },
    [getInvoiceSubServiceOptions.rejected]: (state, action) => {
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].sub_service.options.loading = false;
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].sub_service.options.error = action.payload;
    },

    // Fetching List Price
    [getInvoiceListPrice.pending]: (state, action) => {
      state.invoice_form_data.service_prices[action.meta.arg.id].list_price =
        "Loading...";
    },
    [getInvoiceListPrice.fulfilled]: (state, action) => {
      state.invoice_form_data.service_prices[action.meta.arg.id].list_price =
        action.payload.res;
      // removing invoice pirce so as to get new invoice price and calculate discount
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].invoice_price = 0;
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].cum_discount = 0;
      state.invoice_form_data.service_prices[action.meta.arg.id].discount = 0;
    },
    [getInvoiceListPrice.rejected]: (state, action) => {
      state.invoice_form_data.service_prices[action.meta.arg.id].list_price =
        null;
      alert(
        "List Price with this sub service can't be found, Please select another sub service",
      );

      // clearing the selected sub-service whose list price is not found after alert
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].sub_service.selected_option =
        initialState.invoice_form_data.service_prices[
          action.meta.arg.id
        ].sub_service.selected_option;
    },

    // Fetching MSLA Price
    [getInvoiceMSLAPrice.pending]: (state, action) => {
      state.invoice_form_data.service_prices[action.meta.arg.id].msla_price =
        "Loading...";
    },
    [getInvoiceMSLAPrice.fulfilled]: (state, action) => {
      state.invoice_form_data.service_prices[action.meta.arg.id].msla_price =
        action.payload.res;
      // removing invoice pirce so as to get new invoice price and calculate discount
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].invoice_price = 0;
      state.invoice_form_data.service_prices[
        action.meta.arg.id
      ].cum_discount = 0;
    },
    [getInvoiceMSLAPrice.rejected]: (state, action) => {
      state.invoice_form_data.service_prices[action.meta.arg.id].msla_price =
        action.payload;
    },

    // Send Entity Create Form Data
    [postInvoiceCreateFormData.pending]: (state) => {
      state.invoice_form_data.loading = true;
    },
    [postInvoiceCreateFormData.fulfilled]: (state) => {
      state.invoice_form_data.loading = false;
      state.invoice_form_data = initialState.invoice_form_data; // resets the entity form
      state.tab = TAB_CHOICE.LIST; // sends the entity to the list page
    },
    [postInvoiceCreateFormData.rejected]: (state, action) => {
      state.invoice_form_data.loading = false;
      state.invoice_form_data.error = action.message;
    },

    // Updating the entity
    [putInvoiceUpdateFormData.pending]: (state) => {
      state.invoice_form_data.loading = true;
    },
    [putInvoiceUpdateFormData.fulfilled]: (state) => {
      state.invoice_form_data.loading = false;
      state.invoice_form_data = initialState.invoice_form_data; // resets the entity form
      state.tab = TAB_CHOICE.LIST; // sends the entity to the list page
    },
    [putInvoiceUpdateFormData.rejected]: (state, action) => {
      state.invoice_form_data.loading = false;
      state.invoice_form_data.error = action.message;
    },

    // Fetch Edit Entity Info
    [getEditInvoiceInfo.pending]: (state) => {
      state.invoice_form_data.loading = true;
    },
    [getEditInvoiceInfo.fulfilled]: (state, { payload }) => {
      state.invoice_form_data.loading = false;
      state.invoice_form_data.invoice_id = payload?.id;
      // state.invoice_form_data.entity_code = payload?.entity_code;
      state.invoice_form_data.pricing_details.valid_thorugh.startDate =
        new Date(payload?.invoice_validity_start);
      state.invoice_form_data.pricing_details.valid_thorugh.endDate = new Date(
        payload?.invoice_validity_end,
      );
      state.invoice_form_data.pricing_details.default_entity =
        createInvoiceDefaultEntityOptionValue(payload.default_entity);
      state.invoice_form_data.pricing_details.currency =
        createInvoiceOptionValue(payload?.currency_used);
      state.invoice_form_data.pricing_details.comments = payload?.comments;
      state.invoice_form_data.pricing_details.discount_type = [];
      payload.types_of_discounts?.forEach((discount) => {
        state.invoice_form_data.pricing_details.discount_type = [
          ...state.invoice_form_data.pricing_details.discount_type,
          createInvoiceOptionValue(discount),
        ];
      });
      // tax arr items
      state.invoice_form_data.pricing_details.taxes = []; // this clears out the default one tax value before fillign the taxes in edit form
      payload?.taxes_list?.forEach((tax) => {
        // checking if options has this value or we need to add it in the values.
        let check_tax = state.invoice_form_data.form_options.tax_type.list.some(
          (cur) => cur.label === tax.tax_type,
        );
        if (!check_tax) {
          state.invoice_form_data.form_options.tax_type.list = [
            ...state.invoice_form_data.form_options.tax_type.list,
            createInvoiceOptionValue(tax.tax_type),
          ];
        }
        let tax_arr_val = JSON.parse(JSON.stringify(invoice_tax_options));
        tax_arr_val.tax_type = createInvoiceOptionValue(tax.tax_type);
        tax_arr_val.perc = tax.percentage;
        // adding val in arr
        state.invoice_form_data.pricing_details.taxes = [
          ...state.invoice_form_data.pricing_details.taxes,
          tax_arr_val,
        ];
      });
      // services arr item
      state.invoice_form_data.service_prices = []; // this clears out the default one services value before fillign the taxes in edit form
      payload?.list_of_services?.forEach((service_opt) => {
        let service_arr_val = JSON.parse(JSON.stringify(service_price_data));
        service_arr_val.id = service_opt?.id;
        service_arr_val.service = createInvoiceOptionValue(service_opt.service);
        service_arr_val.sub_service.selected_option =
          createInvoiceSubServiceOptionValue(service_opt.sub_service);
        service_arr_val.list_price = service_opt.list_price;
        service_arr_val.msla_price = service_opt.msla_price;
        service_arr_val.invoice_price = service_opt.offered_price;
        service_arr_val.discount = service_opt.cumm_discount_listprice;
        service_arr_val.cum_discount = service_opt.cumm_discount_mslaprice;
        // adding value in arr
        state.invoice_form_data.service_prices = [
          ...state.invoice_form_data.service_prices,
          service_arr_val,
        ];
      });
      // opening the form after loading the data
      state.tab = TAB_CHOICE.FORM;
      state.edit_entity_id = payload?.id;
    },
    [getEditInvoiceInfo.rejected]: (state, action) => {
      state.invoice_form_data.loading = false;
      state.invoice_form_data.error = action.message;
    },

    // Fetch Entity List Items
    [getInvoiceList.pending]: (state) => {
      state.invoice_list_data.loading = true;
    },
    [getInvoiceList.fulfilled]: (state, { payload }) => {
      state.invoice_list_data.loading = false;
      if (!state.invoice_list_data.list.length) {
        payload?.invoice_codes_list?.forEach((invoice) => {
          let invoice_arr_val = JSON.parse(
            JSON.stringify(invoice_item_details_data),
          );
          invoice_arr_val.invoice_item_head_info.loading = false;
          invoice_arr_val.invoice_item_head_info.data = invoice;
          state.invoice_list_data.list = [
            ...state.invoice_list_data.list,
            invoice_arr_val,
          ];
        });
      }
    },
    [getInvoiceList.rejected]: (state, action) => {
      state.invoice_list_data.loading = false;
      state.invoice_list_data.error = action.message;
    },
    // Fetch Entity Item data
    [getInvoiceListItemDetails.pending]: (state, action) => {
      state.invoice_list_data.list[
        action.meta.arg.idx
      ].invoice_item_data_info.loading = true;
    },
    [getInvoiceListItemDetails.fulfilled]: (state, action) => {
      state.invoice_list_data.list[
        action.meta.arg.idx
      ].invoice_item_data_info.loading = false;
      state.invoice_list_data.list[
        action.meta.arg.idx
      ].invoice_item_data_info.data = action.payload.res;
    },
    [getInvoiceListItemDetails.rejected]: (state, action) => {
      state.invoice_list_data.list[
        action.meta.arg.idx
      ].invoice_item_data_info.loading = false;
      state.invoice_list_data.list[
        action.meta.arg.idx
      ].invoice_item_data_info.error = action.err;
    },
  },
});

export const {
  // invoicw tab switch
  toggleInvoiceTab,
  openEditInvoiceForm,
  openInvoiceForm,
  openInvoiceList,
  // toggle list
  toggleInvoiceListCard,
  // Reset invoice form data
  resetInvoiceFormData,
  resetInvoiceTab,
  // Pricing Details ACTIONS
  addPricingDetailsValidity,
  addPricingDetailsTaxArrItem,
  addPricingDetailsTaxType,
  addPricingDetailsTaxPerc,
  addPricingDetailsCurrency,
  addPricingDetailsDefaultEntity,
  addPricingDetailsDiscountType,
  addPricingDetailsComments,
  // Service Price ACTIONS
  addServicePriceArrItem,
  removeServicePriceArrItem,
  resetServicePriceArr,
  addServicePriceService,
  addServicePriceSubService,
  addServicePriceListPrice,
  addServicePriceInvoicePrice,
  addServicePriceDiscount,
  // Invoice list data
  resetInvoiceItemDetails,
} = invoiceDataSlice.actions;

export default invoiceDataSlice.reducer;
