import React, { useState, useEffect, useContext } from "react";
import { DeliveryPageContext, DELIVERY_TYPE_PAGE } from "../../../OpsComponent";

import "./BatchDeliveryHead.css";

const HEAD_TYPE = {
  CREATE: "create",
  ADD: "add",
  UPLOAD: "upload",
};

const BatchDeliveryHead = () => {
  const { deliverPage } = useContext(DeliveryPageContext);
  const [activeStep, setActiveStep] = useState(null);
  const getHeadingText = () => {
    switch (deliverPage) {
      case DELIVERY_TYPE_PAGE.NEW.ADD:
      case DELIVERY_TYPE_PAGE.NEW.CREATE:
      case DELIVERY_TYPE_PAGE.NEW.UPLOAD:
        return "New Delivery";
      case DELIVERY_TYPE_PAGE.UPDATE.ADD:
      case DELIVERY_TYPE_PAGE.UPDATE.CREATE:
      case DELIVERY_TYPE_PAGE.UPDATE.UPLOAD:
        return "Update Delivery";
      default:
        break;
    }
  };

  useEffect(() => {
    switch (deliverPage) {
      case DELIVERY_TYPE_PAGE.NEW.CREATE:
      case DELIVERY_TYPE_PAGE.UPDATE.CREATE:
        setActiveStep(HEAD_TYPE.CREATE);
        break;
      case DELIVERY_TYPE_PAGE.NEW.ADD:
      case DELIVERY_TYPE_PAGE.UPDATE.ADD:
        setActiveStep(HEAD_TYPE.ADD);
        break;
      case DELIVERY_TYPE_PAGE.NEW.UPLOAD:
      case DELIVERY_TYPE_PAGE.UPDATE.UPLOAD:
        setActiveStep(HEAD_TYPE.UPLOAD);
        break;
      default:
        break;
    }
  }, [deliverPage]);

  return (
    <header className="file-deliver-head">
      <div className="file-deliver-head-text">{getHeadingText()}</div>

      <section className="file-deliver-head-steps-container">
        <div
          className={`file-deliver-head-step ${
            (activeStep === HEAD_TYPE.CREATE ||
              activeStep === HEAD_TYPE.ADD ||
              activeStep === HEAD_TYPE.UPLOAD) &&
            "file-deliver-head-step-active"
          }`}
        >
          <span className="file-deliver-head-step-name">
            Select Assignments
          </span>
        </div>
        <div
          className={`file-deliver-head-step ${
            (activeStep === HEAD_TYPE.ADD || activeStep === HEAD_TYPE.UPLOAD) &&
            "file-deliver-head-step-active"
          }`}
        >
          <span className="file-deliver-head-step-line"></span>
          <span className="file-deliver-head-step-name">Upload Files</span>
        </div>
        <div
          className={`file-deliver-head-step ${
            activeStep === HEAD_TYPE.UPLOAD && "file-deliver-head-step-active"
          }`}
        >
          <span className="file-deliver-head-step-line"></span>
          <span className="file-deliver-head-step-name">Deliver</span>
        </div>
      </section>
    </header>
  );
};

export default BatchDeliveryHead;
