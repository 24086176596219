import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import { getAuthData } from "../../../helpers/request";
import { SORT_ORDERS } from "./common";
import { TYPE_OF_ALL_ASSIGNMENTS_DATA } from "./common";
import { initialState } from "./common";

export const getLiveAllAssignmentsData = createAsyncThunk(
  "liveAllAssignmentsData/getLiveAllAssignments",
  async (
    {
      url = "api-ops/ops/v2/all-assignments",
      sort = SORT_ORDERS.ASCENDING,
      month = undefined,
      search_keyword = null,
    },
    { rejectWithValue },
  ) => {
    // URL creation for all assignments
    let query_params;
    if (search_keyword) {
      query_params = `search=${search_keyword}`;
    } else {
      query_params = `month=${month}`;
    }
    query_params = `${query_params}&sort=${sort}`; // adding sort in querry params

    let assignment_url_with_query_param = `${process.env.REACT_APP_API_URL}/${url}/?${query_params}`; // url to be fetched assignments on

    try {
      const res = await getAuthData(assignment_url_with_query_param);
      if (res.success) {
        let type_of_data;
        if (month) {
          type_of_data = TYPE_OF_ALL_ASSIGNMENTS_DATA.ALL;
        } else if (!month) {
          type_of_data = TYPE_OF_ALL_ASSIGNMENTS_DATA.RECENT;
        }
        if (search_keyword) {
          type_of_data = TYPE_OF_ALL_ASSIGNMENTS_DATA.SEARCH;
        }
        const all_assignments = {
          all_assignments: res.data,
          type_of_data: type_of_data,
          sort_order: sort,
        };
        return all_assignments;
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

const liveAllAssignmentsDataSlice = createSlice({
  name: "live-all-assignments-data",
  initialState,
  reducers: {},
  extraReducers: {
    [getLiveAllAssignmentsData.pending]: (state) => {
      state.loading = true;
    },
    [getLiveAllAssignmentsData.fulfilled]: (state, action) => {
      state.loading = false;
      state.all_assignments = action.payload.all_assignments;
      state.type_of_data = action.payload.type_of_data;
      state.sort_order = action.payload.sort_order;
    },
    [getLiveAllAssignmentsData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.message;
    },
  },
});

export default liveAllAssignmentsDataSlice.reducer;
