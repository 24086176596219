import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  FaBan,
  FaCheck,
  FaHourglassStart,
  // FaRedoAlt,
  FaSpinner,
  // FaTimes,
} from "react-icons/fa";
import { postAuthData } from "../../../helpers/request";
import { UPLOAD_FILES_PROGRESS_STATUS } from "../../../store/features/uploadData/common";
import {
  FileGroupsContext,
  ModalDispatchContext,
  MODAL_TYPE,
} from "../../OpsComponent";

import "./FileUploadModal.css";

const UploadTargetFile = ({ file_data }) => {
  return (
    <div className="upload-section-file-container upload-target-section-file-container">
      <div className="upload-section-file-name upload-target-section-file-name">
        {file_data.file.name}
      </div>
      <div className="upload-section-file-btn-container upload-target-section-file-btn-container">
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE && (
          <span
            className="upload-section-file-btn upload-section-file-btn-inqueue"
            title="In-queue"
          >
            <FaHourglassStart />
          </span>
        )}
        {/* {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-retry"
            title="Redo"
          >
            <FaRedoAlt />
          </span>
        )}
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-reject"
            title="Reject"
          >
            <FaTimes />
          </span>
        )} */}
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-failed"
            title="Failed"
          >
            <FaBan />
          </span>
        )}
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-uploading"
            title="Uploading"
          >
            <FaSpinner />
          </span>
        )}
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.SUCCESS && (
          <span
            className="upload-section-file-btn upload-section-file-btn-success"
            title="Succes"
          >
            <FaCheck />
          </span>
        )}
      </div>
    </div>
  );
};

const UploadSourceFile = ({ file_data }) => {
  return (
    <div className="upload-section-file-container upload-source-section-file-container">
      <div className="upload-section-file-name upload-source-section-file-name">
        {file_data.file.name}
      </div>
      <div className="upload-section-file-btn-container upload-source-section-file-btn-container">
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE && (
          <span
            className="upload-section-file-btn upload-section-file-btn-inqueue"
            title="In-queue"
          >
            <FaHourglassStart />
          </span>
        )}
        {/* {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-retry"
            title="Redo"
          >
            <FaRedoAlt />
          </span>
        )} */}
        {/* {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-reject"
            title="Reject"
          >
            <FaTimes />
          </span>
        )} */}
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.FAILED && (
          <span
            className="upload-section-file-btn upload-section-file-btn-failed"
            title="Failed"
          >
            <FaBan />
          </span>
        )}
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING && (
          <span
            className="upload-section-file-btn upload-section-file-btn-uploading"
            title="Uploading"
          >
            <FaSpinner />
          </span>
        )}
        {file_data.progress === UPLOAD_FILES_PROGRESS_STATUS.SUCCESS && (
          <span
            className="upload-section-file-btn upload-section-file-btn-success"
            title="Succes"
          >
            <FaCheck />
          </span>
        )}
      </div>
    </div>
  );
};

const GroupUpload = ({ group_data }) => {
  const group_target_files = Object.entries(group_data?.target_files);
  const group_source_files = Object.entries(group_data?.source_files);
  return (
    <div className="upload-section-container">
      <div className="upload-section-group-id">
        GROUP {group_data?.group_id}
      </div>

      <section className="upload-section upload-target-section">
        <div className="upload-section-head upload-target-section">
          Target Files
        </div>
        <div className="upload-section-files upload-target-section-files">
          {group_target_files?.length > 0 ? (
            group_target_files?.map(([file_key, file_data]) => {
              return <UploadTargetFile key={file_key} file_data={file_data} />;
            })
          ) : (
            <span>
              <i>No files uploaded!</i>
            </span>
          )}
        </div>
      </section>

      <section className="upload-section upload-source-section">
        <div className="upload-section-head upload-source-section">
          Source Files
        </div>
        <div className="upload-section-files upload-source-section-files">
          {group_source_files?.length > 0 ? (
            group_source_files?.map(([file_key, file_data]) => {
              return <UploadSourceFile key={file_key} file_data={file_data} />;
            })
          ) : (
            <span>
              <i>No files uploaded!</i>
            </span>
          )}
        </div>
      </section>
    </div>
  );
};

function get_query(url) {
  let qs = url.substring(url.indexOf("?") + 1).split("&");
  for (var i = 0, result = {}; i < qs.length; i++) {
    qs[i] = qs[i].split("=");
    result[qs[i][0]] = decodeURIComponent(qs[i][1]);
  }
  return result;
}

const FileUploadModal = () => {
  const modal_dispatch = useContext(ModalDispatchContext);
  const { fileUploadData, setFileUploadData } = useContext(FileGroupsContext); // this state will contain every detail about the files and its progress and groups info
  const [next, setNext] = useState(2); // this will lead on the next file to upload from the lot

  let chunkMB = 5; // Minimum Chunk Size should be 5 MB
  let chunkSize = chunkMB * 1024 * 1024;

  const updateFileStateData = ({
    file_id_data,
    progressData,
    uploadingChunk,
    totalChunks,
    uploadId,
  }) => {
    // updating the file's progress to uploading.
    setFileUploadData((prevState) => {
      return {
        ...prevState,
        fileGroups: {
          ...prevState.fileGroups,
          [file_id_data.group_id]: {
            ...prevState.fileGroups[file_id_data.group_id],
            [file_id_data.file_type]: {
              ...prevState.fileGroups[file_id_data.group_id][
                file_id_data.file_type
              ],
              [file_id_data.file_type_id]: {
                ...prevState.fileGroups[file_id_data.group_id][
                  file_id_data.file_type
                ][file_id_data.file_type_id],
                progress:
                  progressData ||
                  prevState.fileGroups[file_id_data.group_id][
                    file_id_data.file_type
                  ][file_id_data.file_type_id].progress,
                uploading_chunk:
                  uploadingChunk ||
                  prevState.fileGroups[file_id_data.group_id][
                    file_id_data.file_type
                  ][file_id_data.file_type_id].uploading_chunk,
                total_chunks:
                  totalChunks ||
                  prevState.fileGroups[file_id_data.group_id][
                    file_id_data.file_type
                  ][file_id_data.file_type_id].total_chunks,
                upload_id:
                  uploadId ||
                  prevState.fileGroups[file_id_data.group_id][
                    file_id_data.file_type
                  ][file_id_data.file_type_id].upload_id,
              },
            },
          },
        },
      };
    });
  };

  const getFileUploadLinks = (file_id_data) => {
    // getting the file from the group of files state
    const file_data =
      fileUploadData.fileGroups[file_id_data.group_id][file_id_data.file_type][
        file_id_data.file_type_id
      ];
    const file_group = fileUploadData.fileGroups[file_id_data.group_id];
    // updating the file's progress to uploading.
    updateFileStateData({
      file_id_data,
      progressData: UPLOAD_FILES_PROGRESS_STATUS.UPLOADING,
    });

    // preparing data for fetching chunk urls
    let data = {
      group: file_group.group.toString(), // requested by shagun on 6th sept 5.44pm to be in string
      delivery_id: file_group.delivery_id,
      time: file_group.time,
      interim: file_group.interim_output.toString(), // requested by shagun on 6th sept 5.44pm to be in string
      file_type: file_id_data.file_type,
      file_name: file_data.file.name,
      file_size: file_data.file.size,
      chunk_size: chunkMB,
    };

    //  We will get links from this API and pass to uploadFileChunks function
    postAuthData(
      // `http://34.237.48.240:8006/api/upload/multipart/get_urls`,
      fileUploadData.get_links_api_url,
      data,
    )
      .then((res) => {
        if (res.success === true) {
          uploadFileChunks(res.urls, file_id_data);
        } else {
          console.error(res.message);
          closeModal();
        }
      })
      .catch((err) => {
        console.error(err);
        closeModal();
      });
  };

  const uploadFileChunks = async (links, file_id_data) => {
    // getting the file from the group of files state
    const file_data =
      fileUploadData.fileGroups[file_id_data.group_id][file_id_data.file_type][
        file_id_data.file_type_id
      ];
    let total_chunks = Math.ceil(file_data.file.size / chunkSize); // Calculating Number of chunks
    let uploadId = get_query(links[0]).uploadId;

    // updating the file's progress to uploading and updating the total number of chunks of file
    updateFileStateData({
      file_id_data,
      progressData: UPLOAD_FILES_PROGRESS_STATUS.UPLOADING,
      totalChunks: total_chunks,
    });

    // Function to upload a individual chunk ;  It will be called in "for loop"
    const uploadChunk = async (idx, chunkSize) => {
      const sendAxios = axios.create();
      let initialPointer = idx * chunkSize;
      let chunk = file_data.file.slice(
        initialPointer,
        initialPointer + chunkSize,
        file_data.file.type,
      );
      return sendAxios.put(links[idx], chunk, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };

    (async () => {
      let flag = 0; // if a chunk failed to upload this will be set to 1 and will terminate the loop
      let partsData = []; // It will save the "ETag" and "PartNumber" for every chunk of file and will be returned to COMBINE function
      for (let chunk = 0; chunk < total_chunks; chunk++) {
        if (flag) return Promise.reject(new Error("File can't be uploaded")); // if flag=1 => loop will end and control will move to .catch() block
        await uploadChunk(chunk, chunkSize)
          .then((p) => {
            partsData.push({
              ETag: JSON.parse(p.headers.etag),
              PartNumber: chunk + 1,
            });
            // updating the chunk number that is to be uploaded now
            updateFileStateData({
              file_id_data,
              uploadingChunk:
                fileUploadData.fileGroups[file_id_data.group_id][
                  file_id_data.file_type
                ][file_id_data.file_type_id].uploading_chunk + 1,
            });
          })
          .catch((err) => {
            console.log("First Retry: " + err);
            // Trying again to upload if fails 1st time
            uploadChunk(chunk, chunkSize)
              .then((res) => {
                partsData.push({
                  ETag: JSON.parse(res.headers.etag),
                  PartNumber: chunk + 1,
                });
                // updating the chunk number that is to be uploaded now
                updateFileStateData({
                  file_id_data,
                  uploadingChunk:
                    fileUploadData.fileGroups[file_id_data.group_id][
                      file_id_data.file_type
                    ][file_id_data.file_type_id].uploading_chunk + 1,
                });
              })
              .catch((err) => {
                console.log("Failed: " + err);
                flag = 1; // when a chunk is not uploaded flag=1 and it will end the loop in next iteration
                // if chunk fails 2nd time also, flag is set to 1, and Progress Status to Retry.
                updateFileStateData({
                  file_id_data,
                  progressData: UPLOAD_FILES_PROGRESS_STATUS.FAILED,
                  uploadingChunk: 0,
                });
              });
          });
      }
      return { partsData: partsData }; // The loop completes successfully =>  .then() will be executed
    })()
      .then((part_info) => {
        combineFilePartsFromBE(part_info.partsData, uploadId, file_id_data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  // This function will take input an array of "ETag" and "PartNumber" form UploadChunks function.
  // And hit "POST" API: complete Multipart
  const combineFilePartsFromBE = (parts, uploadId, file_id_data) => {
    const combination_url = `${process.env.REACT_APP_API_URL}/api-ops/ops/v1/multipart-complete/`;
    // getting the file from the group of files state
    const file_data =
      fileUploadData.fileGroups[file_id_data.group_id][file_id_data.file_type][
        file_id_data.file_type_id
      ];
    let data = {
      parts: parts,
      upload_id: uploadId,
      file_name: file_data.file.name,
    };
    postAuthData(
      // `http://34.237.48.240:8006/api/upload/multipart/complete`,
      combination_url,
      data,
    )
      .then((res) => {
        if (res.success === true) {
          // all files are part wise organised and now the file is uploaded successfuly
          updateFileStateData({
            file_id_data,
            progressData: UPLOAD_FILES_PROGRESS_STATUS.SUCCESS,
          });
        } else {
          updateFileStateData({
            file_id_data,
            progressData: UPLOAD_FILES_PROGRESS_STATUS.FAILED,
            uploadingChunk: 0,
          });
        }
        setNext((prev) => prev + 1); // For Starting : Next file upload
        // when this next will be updated => useEffect will be called and it will start upload the next file in queue
      })
      .catch((err) => {
        console.log("Combination API error: " + err + "\nRetrying...");
        postAuthData(
          // `http://34.237.48.240:8006/api/upload/multipart/complete`,
          combination_url,
          data,
        )
          .then((res) => {
            if (res.success === true) {
              updateFileStateData({
                file_id_data,
                progressData: UPLOAD_FILES_PROGRESS_STATUS.SUCCESS,
              });
            } else {
              updateFileStateData({
                file_id_data,
                progressData: UPLOAD_FILES_PROGRESS_STATUS.FAILED,
                uploadingChunk: 0,
              });
            }
            setNext((prev) => prev + 1); // For Starting : Next file upload
          })
          .catch((err) => {
            console.log("Combination API error: " + err);
            updateFileStateData({
              file_id_data,
              progressData: UPLOAD_FILES_PROGRESS_STATUS.FAILED,
              uploadingChunk: 0,
            });
            setNext((prev) => prev + 1);
          });
      });
  };

  const checkAllFileStatusesForSuccess = () => {
    // checking every files progressData and if they are all success then the whole will be success
    const checkStatus = (statusTypeCheck) => {
      const checkTypeStatus = Object.values(fileUploadData.fileGroups)
        .map((group_data) => {
          const target_files = Object.values(group_data.target_files).map(
            (file) => file.progress,
          );
          const source_files = Object.values(group_data.source_files).map(
            (file) => file.progress,
          );
          return [...target_files, ...source_files];
        })
        .flat()
        .every((progress) => progress === statusTypeCheck);
      return checkTypeStatus;
    };

    const checkFailStatus = (statusTypeCheck) => {
      let checkFailure = false;
      const checkStatuses = Object.values(fileUploadData.fileGroups)
        .map((group_data) => {
          const target_files = Object.values(group_data.target_files).map(
            (file) => file.progress,
          );
          const source_files = Object.values(group_data.source_files).map(
            (file) => file.progress,
          );
          return [...target_files, ...source_files];
        })
        .flat();
      // this check is to make sure every file has been uploaded otherwise the modal will close even when the next changes to upload the next file
      const uploadingStatusCheck = checkStatuses.some((progress) => {
        return (
          progress === UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE ||
          progress === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING
        );
      });
      // now if all files are uploaded then we check failed status
      if (!uploadingStatusCheck) {
        checkFailure = checkStatuses.some((progress) => {
          if (progress === statusTypeCheck)
            return (progress !== progress) === statusTypeCheck;
        });
      }
      return checkFailure;
    };

    if (checkStatus(UPLOAD_FILES_PROGRESS_STATUS.SUCCESS)) {
      setFileUploadData((prev) => {
        return {
          ...prev,
          uploadFilesSuccess: UPLOAD_FILES_PROGRESS_STATUS.SUCCESS,
        };
      });
      modal_dispatch({ modal_show_type: MODAL_TYPE.NONE }); // closing the modal after all files are upload
    } else if (checkFailStatus(UPLOAD_FILES_PROGRESS_STATUS.FAILED)) {
      // if failed then also close modal
      setFileUploadData((prev) => {
        return {
          ...prev,
          uploadFilesSuccess: UPLOAD_FILES_PROGRESS_STATUS.FAILED,
        };
      });
      modal_dispatch({ modal_show_type: MODAL_TYPE.NONE }); // closing the modal after all files are upload
    }
  };

  const closeModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
  };

  const UploadFile = () => {
    // beginning the uploading
    setFileUploadData((prev) => {
      return {
        ...prev,
        uploadFilesSuccess: UPLOAD_FILES_PROGRESS_STATUS.UPLOADING,
      };
    });
    // Initially File 0,1 and 2 will start uploading
    // Initial value of  [next, setNext] useState will be equal to = 2;
    // If we need to upload  N files initially then value of Next should be (N-1)
    if (fileUploadData.fileIdsArr[0])
      getFileUploadLinks(fileUploadData.fileIdsArr[0]);
    if (fileUploadData.fileIdsArr[1])
      getFileUploadLinks(fileUploadData.fileIdsArr[1]);
    if (fileUploadData.fileIdsArr[2])
      getFileUploadLinks(fileUploadData.fileIdsArr[2]);
  };

  useEffect(() => {
    // next should only work if the whole file success status is uploading and next element is a valid element
    checkAllFileStatusesForSuccess(); // this is to set the uploadFileSuccess status of whole state to success
    if (
      fileUploadData.fileIdsArr[next] &&
      fileUploadData.uploadFilesSuccess ===
        UPLOAD_FILES_PROGRESS_STATUS.UPLOADING
    )
      getFileUploadLinks(fileUploadData.fileIdsArr[next], next);
  }, [next]);

  useEffect(() => {
    // upload files will only run if the status is of in queue
    if (
      fileUploadData.uploadFilesSuccess ===
      UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE
    ) {
      UploadFile();
    }
  }, []);

  return (
    <article className="ops-modal-container upload-files-section">
      <div className="ops-modal-head">
        <div className="ops-modal-container-head upload-files-tab-file-modal-head">
          Upload Files Progress
        </div>
        {/* <button
          className="ops-modal-container-close-btn"
          onClick={() => {
            modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          }}
        >
          <FaTimes />
        </button> */}
      </div>
      <div className="upload-group-section-container">
        {fileUploadData.fileGroups &&
          Object.entries(fileUploadData.fileGroups).map(
            ([group_id, group_data]) => {
              return <GroupUpload key={group_id} group_data={group_data} />;
            },
          )}
      </div>
    </article>
  );
};

export default FileUploadModal;
