import { React, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { postAuthData } from "../../../helpers/request.js";
import { ApiContext, UserContext } from "../../../index.js";
import { LoaderContext, NotFoundErrorContext } from "../../OpsComponent.js";

import "./OpsTopNav.css";

const OpsTopNav = () => {
  const history = useHistory();
  const API_URL = useContext(ApiContext);
  const { profile, setProfile } = useContext(UserContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setLoader } = useContext(LoaderContext);

  //const {displayedPage,setDisplayedPage} = useContext(OpsDisplayedPageContext)
  //  const {displayedFile,} = useContext(DashboardFileContext);

  const toggleLogoutBox = (e) => {
    if (e.target.closest(".ops-user") || e.target.closest(".ops-logout-box")) {
      const logoutBox = document.querySelector(".ops-logout-box");
      if (getComputedStyle(logoutBox).display === "none") {
        document.querySelector(".ops-logout-box").style.display = "grid";
        //document.querySelector('.ops-user-name').style.color = "black";
      } else if (getComputedStyle(logoutBox).display === "grid") {
        document.querySelector(".ops-logout-box").style.display = "none";
        //  document.querySelector('.ops-user-name').style.color = "black";
      }
    } else if (
      !e.target.closest(".ops-user") &&
      !e.target.closest(".ops-logout-box")
    ) {
      document.querySelector(".ops-logout-box").style.display = "none";
      //document.querySelector('.ops-user-name').style.color = "black";
    }
  };

  const handleLogout = () => {
    postAuthData(`${API_URL}/api-ops/auth/v1/logout/`)
      .then((res) => {
        if (res.success === true) {
          setProfile({});
          window.localStorage.clear();
          window.sessionStorage.clear();
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="ops-topnav">
      <Link className="ops-topnav-btn ops-topnav-brand" to="/addnew">
        <div
          className="ez-logo"
          onClick={() => {
            setNotFoundError(false);
            setLoader(false);
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/resources/nav_icons/logo.png"}
            alt="EZ"
          />
        </div>
      </Link>
      <div className="ops-user" onClick={toggleLogoutBox}>
        <div className="ops-user-name">Hi {profile?.first_name || "User"},</div>
        <img
          className="user-img"
          src={
            profile?.image
              ? `${API_URL}` + profile?.image
              : process.env.PUBLIC_URL + "/resources/nav_icons/user.svg"
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              process.env.PUBLIC_URL + "/resources/nav_icons/user.svg";
          }}
          alt="Profile"
        />
      </div>
      <div className="ops-logout-box">
        <div
          className="ops-logout-btns"
          id="ops-user-name-btn"
          onClick={toggleLogoutBox}
        >
          <div className="ops-user-name">
            Hi {profile?.first_name || "User"},
          </div>
          <img
            className="user-img"
            src={
              profile?.image
                ? `${API_URL}` + profile?.image
                : process.env.PUBLIC_URL + "/resources/nav_icons/user.svg"
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                process.env.PUBLIC_URL + "/resources/nav_icons/user.svg";
            }}
            alt="Profile"
          />
        </div>
        <button
          className="ops-logout-btns1"
          onClick={(e) => {
            toggleLogoutBox(e);
            history.push("/profile");
          }}
        >
          Account
        </button>
        <button className="ops-logout-btns1" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};
export { OpsTopNav };
