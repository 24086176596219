import React, { useContext, useRef, useState, useEffect } from "react";
// import {CLIENT_MODAL_TYPE, ENCRYPTION_TYPE, ModalDispatchContext, SecureKeyContext, EncryptedContext} from './ClientDashboard.js';
import { FaPen, FaEye, FaAngleLeft } from "react-icons/fa";
import {
  ApiContext,
  UserContext,
  ToolTipError,
  ERROR_REGEX,
  checkCountryCode,
  ToolTipCustomError,
  lazy_load_image,
} from "../../index.js";
import { getAuthData, postAuthData } from "../../helpers/request";
import axios from "axios";
import { makeSecureDecrypt, makeSecureEncrypt } from "../../helpers/security";
import { useHistory } from "react-router-dom";
// import OutsideClickHandler from 'react-outside-click-handler';
import countryCodes from "../../country_code.json";

import "./OpsAccountSettings.css";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getProfileData,
//   putProfileData,
// } from "../../store/features/profileData/profileDataSlice.js";

const renderPageContext = React.createContext();
const accountContext = React.createContext();
// const profileContext = React.createContext();

// ===================== SIDEBAR =======================

const AccountSidebar = () => {
  const { setRenderPage } = useContext(renderPageContext);
  const showSetting = (e) => {
    // Removing active class from all other files
    document.querySelectorAll(".ops-sidebar-account-item").forEach((el) => {
      el.classList.remove("ops-sidebar-account-item-active");
    });
    // adding active file to the clicked file
    e.target
      .closest(".ops-sidebar-account-item")
      .classList.add("ops-sidebar-account-item-active");
  };

  const closeResponsiveSidebar = (e) => {
    e.preventDefault();
    if (!e.target.closest(".ops-sidebar-account-item")) return;
    document
      .querySelector("#ops-file-sidebar")
      ?.classList.remove("ops-sidebar-show");
    document
      .querySelector(".ops-account-sidebar")
      ?.classList.remove("ops-sidebar-show");
  };

  return (
    <div className="ops-sidebar ops-sidebar-mobile ops-account-sidebar">
      <div className="ops-profile-sidebar-head">
        <div className="ops-profile-sidebar-head-top">
          <div className="ops-profile-sidebar-text">Account</div>
        </div>
      </div>

      <div
        className="ops-profile-sidebar-items"
        onClick={(e) => {
          closeResponsiveSidebar(e);
        }}
      >
        <div
          className="ops-sidebar-account-item ops-sidebar-account-item-active"
          onClick={(e) => {
            showSetting(e);
            setRenderPage(PAGE.PROFILE);
          }}
        >
          <div className="ops-sidebar-account-item-head">
            Profile Information
          </div>
        </div>
        <div
          className="ops-sidebar-account-item"
          onClick={(e) => {
            showSetting(e);
            setRenderPage(PAGE.PASSWORD);
          }}
        >
          <div className="ops-sidebar-account-item-head">Change Password</div>
        </div>
      </div>
    </div>
  );
};

// =================== DASHBOARD ========================

// ====================== Toggling Sidebar =================================
const showAccountSidebar = (e) => {
  e.preventDefault();
  document
    .querySelector(".ops-account-sidebar")
    ?.classList.toggle("ops-sidebar-show");
};

const AccountProfileForm = () => {
  // const dispatch = useDispatch();
  // const { data } = useSelector((state) => state.profileData);
  const history = useHistory();
  const { profile, setProfile } = useContext(UserContext);
  const API_URL = useContext(ApiContext);

  const f_name_ref = useRef();
  const l_name_ref = useRef();
  const email_ref = useRef();
  const organisation_ref = useRef();
  const phone_num_code_ref = useRef();
  const phone_num_ref = useRef();
  const whatsapp_num_code_ref = useRef();
  const whatsapp_num_ref = useRef();
  const profile_pic_ref = useRef();
  const [image, setImage] = useState(null);

  // console.log(profile);

  const btnChange = () => {
    const profile_submit_btn = document.querySelector(
      "#ops-profile-submit-btn",
    );
    profile_submit_btn.innerText = "Saved";
    profile_submit_btn.style.background = "var(--orange,#ea7b2c)";
    profile_submit_btn.style.borderColor = "var(--orange,#ea7b2c)";
    setTimeout(() => {
      profile_submit_btn.innerText = "Save";
      profile_submit_btn.style.background = "var(--light-blue,#3cc3f2)";
      profile_submit_btn.style.borderColor = "var(--light-blue,#3cc3f2)";
    }, 1000);
  };

  // for future optimisation
  // const createProfileSubmitData = () => {
  //   let profile_form_data = {
  //     first_name: data.first_name,
  //     last_name: data.last_name,
  //     email: data.email,
  //     organization: data.organization,
  //   };
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("token")));
    let formData = new FormData();
    formData.append("first_name", f_name_ref.current.value);
    formData.append("last_name", l_name_ref.current.value);
    formData.append("organization", organisation_ref.current.value);
    formData.append("phone_number_ext", phone_num_code_ref.current.value);
    formData.append("phone_number", phone_num_ref.current.value);
    formData.append("whatsapp_number_ext", whatsapp_num_code_ref.current.value);
    formData.append("whatsapp_number", whatsapp_num_ref.current.value);
    image && formData.append("image", image);

    // for future optimization
    // dispatch(
    //   putProfileData({
    //     profile_id: data?.profile_id,
    //     data: profile_form_data,
    //   }),
    // );

    axios({
      method: "put",
      url: `${API_URL}/api-ops/ops/v1/profile/${profile?.id}/`,
      data: formData,
      headers: { Authorization: `Bearer ${user}` },
    })
      .then(() => {
        // dispatch(getProfileData());
        btnChange();
        getAuthData(`${API_URL}/api-ops/ops/v1/profile/`)
          .then((res) => {
            if (res.success === true) {
              btnChange();
              setProfile(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        history.push("/profile");
      })
      .catch((err) => {
        console.error(err.response.data.message);
      });
  };

  // Getting the account
  const fetchClient = () => {
    if (
      sessionStorage.getItem("token") &&
      makeSecureDecrypt(sessionStorage.getItem("token"))
    ) {
      // dispatch(getProfileData());
      // lazy_load_image(profile_pic_ref.current);
      getAuthData(`${process.env.REACT_APP_API_URL}/api-ops/ops/v1/profile/`)
        .then((res) => {
          if (res.success === true) {
            setProfile(res.data);
            lazy_load_image(profile_pic_ref.current);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {}, [profile]);

  useEffect(() => {
    fetchClient();
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    history.push("/deliver");
  };

  useEffect(() => {
    if (image) {
      let reader = new FileReader();
      reader.onload = function () {
        profile_pic_ref.current.src = reader.result;
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  return (
    <section className="ops-profile-section">
      <div className="ops-profile-head ops-profile-head-mobile">
        <button
          className="ops-dashboard-back-btn"
          onClick={(e) => {
            showAccountSidebar(e);
          }}
        >
          <FaAngleLeft />
        </button>
        <span>Profile Information</span>
      </div>

      <div className="ops-profile-form-container glass-container-active">
        <div className="ops-profile-head">Profile Information</div>

        <form className="ops-profile-form" onSubmit={handleSubmit}>
          <div className="ops-profile-form-part">
            <div className="ops-profile-image-container">
              <img
                className="ops-profile-image lazy-img"
                ref={profile_pic_ref}
                src={
                  profile?.image
                    ? `${API_URL}` + profile?.image
                    : process.env.PUBLIC_URL +
                      "/resources/account_icons/user.png"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    process.env.PUBLIC_URL +
                    "/resources/account_icons/user.png";
                }}
                alt="Profile"
              />
              <div className="ops-profile-image-edit">
                <input
                  className="ops-profile-image-edit-input"
                  type="file"
                  accept="image/*"
                  onChange={(e) => setImage(Array.from(e.target.files)[0])}
                  id="ops-profile-image-edit-input"
                />
                <label htmlFor="ops-profile-image-edit-input">
                  <FaPen className="ops-profile-image-edit-pen" />
                  <p className="new-ops-profile-image"></p>
                </label>
              </div>
            </div>
          </div>

          <div className="ops-profile-form-part ops-form-fields-part">
            <div className="ops-profile-field">
              <label
                className="ops-profile-form-label"
                htmlFor="ops-profile-form-first-name"
              >
                First Name
              </label>
              <input
                className="ops-dashboard-input ops-profile-form-input"
                ref={f_name_ref}
                type="text"
                id="ops-profile-form-first-name"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                defaultValue={profile?.first_name}
                // defaultValue={data?.first_name}
                placeholder="First Name"
              />
            </div>

            <div className="ops-profile-field">
              <label
                className="ops-profile-form-label"
                htmlFor="ops-profile-form-last-name"
              >
                Last Name
              </label>
              <input
                className="ops-dashboard-input ops-profile-form-input"
                ref={l_name_ref}
                type="text"
                id="ops-profile-form-last-name"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                name="ops-profile-form-last-name"
                defaultValue={profile?.last_name}
                // defaultValue={data?.last_name}
                placeholder="Last Name"
              />
            </div>

            <div className="ops-profile-field">
              <label
                className="ops-profile-form-label"
                htmlFor="ops-profile-form-email"
              >
                Email
              </label>
              <input
                className="ops-dashboard-input ops-profile-form-input"
                ref={email_ref}
                type="text"
                id="ops-profile-form-email"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                name="ops-profile-form-email"
                defaultValue={profile?.email}
                // defaultValue={data?.email}
                disabled
                placeholder="Email"
                style={{ color: "var(--dark-grey)" }}
              />
            </div>

            <div className="ops-profile-field">
              <label
                className="ops-profile-form-label"
                htmlFor="ops-profile-form-organisation"
              >
                Organisation
              </label>
              <input
                className="ops-dashboard-input ops-profile-form-input"
                ref={organisation_ref}
                type="text"
                id="ops-profile-form-organisation"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                name="ops-profile-form-organisation"
                defaultValue={profile?.organization}
                // defaultValue={data?.organization}
                placeholder="Organisation"
              />
            </div>

            <div className="ops-profile-field">
              <label
                className="ops-profile-form-label"
                htmlFor="ops-profile-form-phone-number-type"
              >
                Phone Number
              </label>
              <div className="ops-profile-number-field">
                <div className="ops-profile-input-select  ops-profile-input-number-select">
                  <input
                    list="phone-number-codes"
                    id="phone-number-extention"
                    onChange={(e) => {
                      ToolTipError(e, [ERROR_REGEX.TEXT_ERROR]);
                      ToolTipCustomError(
                        e,
                        "Please select a value from the list!",
                        !checkCountryCode(e),
                      );
                    }}
                    name="phone-number-code-extention"
                    ref={phone_num_code_ref}
                    defaultValue={profile?.phone_number_ext}
                    // defaultValue={data?.phone_number_ext}
                    className="ops-dashboard-input ops-profile-form-input ops-number-type-input"
                  />
                  <datalist id="phone-number-codes">
                    {countryCodes.map((cur, i) => {
                      return (
                        <option key={i} value={cur.country_code}>
                          {cur.country_code}
                        </option>
                      );
                    })}
                  </datalist>
                </div>

                <input
                  className="ops-dashboard-input ops-profile-form-input ops-profile-number-input"
                  ref={phone_num_ref}
                  type="number"
                  id="ops-profile-form-phone-number"
                  name="ops-profile-form-phone-number"
                  defaultValue={profile?.phone_number}
                  // defaultValue={data?.phone_number}
                  placeholder="Number"
                />
              </div>
            </div>

            <div className="ops-profile-field">
              <label
                className="ops-profile-form-label"
                htmlFor="ops-profile-form-whatsapp-number-type"
              >
                WhatsApp Number
              </label>
              <div className="ops-profile-number-field">
                <div className="ops-profile-input-select ops-profile-input-number-select">
                  <input
                    list="whatsapp-number-codes"
                    id="whatsapp-number-extention"
                    onChange={(e) => {
                      ToolTipError(e, [ERROR_REGEX.TEXT_ERROR]);
                      ToolTipCustomError(
                        e,
                        "Please select value from the list!",
                        !checkCountryCode(e),
                      );
                    }}
                    name="whatsapp-number-code-extention"
                    ref={whatsapp_num_code_ref}
                    defaultValue={profile?.whatsapp_number_ext}
                    // defaultValue={data?.whatsapp_number_ext}
                    className="ops-dashboard-input ops-profile-form-input ops-number-type-input"
                  />
                  <datalist id="whatsapp-number-codes">
                    {countryCodes.map((cur, i) => {
                      return (
                        <option key={i} value={cur.country_code}>
                          {cur.country_code}
                        </option>
                      );
                    })}
                  </datalist>
                </div>
                <input
                  className="ops-dashboard-input ops-profile-form-input ops-profile-number-input"
                  ref={whatsapp_num_ref}
                  type="number"
                  id="ops-profile-form-whatsapp-number"
                  name="ops-profile-form-whatsapp-number"
                  defaultValue={profile?.whatsapp_number}
                  // defaultValue={data?.whatsapp_number}
                  placeholder="Number"
                />
              </div>
            </div>
          </div>

          <div className="ops-profile-form-part">
            <div className="ops-profile-form-btns">
              <div
                className="ops-btns ops-profile-form-btn ops-profile-form-clear-btn"
                onClick={handleCancel}
              >
                Cancel
              </div>
              <button
                type="submit"
                className="ops-btns ops-profile-form-btn ops-profile-form-submit-btn"
                id="ops-profile-submit-btn"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

/*
const AccountPasswordFormOld = () => {
    const API_URL = useContext(ApiContext);
    const [oldPswdError, setOldPswdError] = useState(null);
    const [newPswdError, setNewPswdError] = useState(null);
    const [confirmPswdError, setConfirmPswdError] = useState(null);

    const oldPassword = useRef(null);
    const newPassword = useRef(null);
    const confirmNewPassword = useRef(null);

    const showPassword = (pswd_input) => {
        if (pswd_input.current.type === "password") {
            pswd_input.current.type = "text";
        } else {
            pswd_input.current.type = "password";
        }
    }

    const ShowErrror = ({ errorMsg }) => {
        return (
            <div className="ops-settings-pswd-change-error">{errorMsg}</div>
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const pswdRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        // if (!pswdRegex.test(newPassword.current.value)) {
        //     setNewPswdError("Please enter an 8 digit password that includes at least 1 uppercase, 1 lowercase, 1 number and 1 special character  ( $, @, #, %, &, * ).")
        // }
        // else
        if (newPassword.current.value !== confirmNewPassword.current.value) {
            setConfirmPswdError("Passwords don’t match, Check Again")
        }
        else {
            setNewPswdError(null);
            setConfirmPswdError(null);
            const data = { password: oldPassword.current.value, new_password: newPassword.current.value }
            postAuthData(`${API_URL}/api-ops/auth/v1/reset-password/`, data)
                .then((res) => {
                    if (res.success === true) {
                        window.localStorage.clear();
                        window.sessionStorage.clear();
                        window.location.href = '/ops';
                    }
                    if (res.success === false) {
                        setOldPswdError(res.message)
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        }
    };
    return (
        <div className="bg-white">
        <div className="client-account-update-password">
            <div className='update-ops-pswd-head'>Update Your Password</div>
            <form className="update-ops-pswd-form" onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="ops-pswd-change-form-label2" htmlFor="old_password">Old Password</label>
                                <input className="ops-pswd-change-form-input" ref={oldPassword} type="password" id='old_password' name='old_password' />
                                <span className="ops-pswd-change-form-input-show-btn" onClick={(e) => {
                                    e.preventDefault();
                                    showPassword(oldPassword);
                                    oldPassword.current.focus();
                                }
                                }><FaEye /></span>
                                {oldPswdError && <ShowErrror errorMsg={oldPswdError} />}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label className="ops-pswd-change-form-label" htmlFor="new_password">New Password</label>
                            </td>
                            <td>
                                <label className="ops-pswd-change-form-label" htmlFor="confirm_new_password">Confirm New Password</label>
                            </td>
                            <td>
                                <div className="ops-pswd-change-form-btn ops-pswd-change-cancel-btn" onClick={(e) => { e.preventDefault(); newPassword.current.value = ""; confirmNewPassword.current.value = "" }}>Clear</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input className="ops-pswd-change-form-input" ref={newPassword} type="password" id='new_password' name='new_password' />
                                <span className="ops-pswd-change-form-input-show-btn" onClick={(e) => {
                                    e.preventDefault();
                                    showPassword(newPassword);
                                    newPassword.current.focus();
                                }
                                }><FaEye /></span>
                            </td>
                            <td>
                                <input className="ops-pswd-change-form-input" ref={confirmNewPassword} type="password" id='confirm_new_password' name='confirm_new_password' />
                                <span className="ops-pswd-change-form-input-show-btn" onClick={(e) => {
                                    e.preventDefault();
                                    showPassword(confirmNewPassword);
                                    confirmNewPassword.current.focus();
                                }
                                }><FaEye /></span>
                            </td>
                            <td>
                                <button className="ops-pswd-change-form-btn ops-pswd-change-update-btn" type='submit' onClick={handleSubmit}>Change Password</button>
                            </td>
                        </tr>
                        <tr>
                            <td className="update-password-error">
                                {newPswdError && <ShowErrror errorMsg={newPswdError} />}
                            </td>
                            <td className="update-password-error">
                                {confirmPswdError && <ShowErrror errorMsg={confirmPswdError} />}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </form>
        </div>
        </div>
    )
}
*/

const AccountPasswordForm = () => {
  const API_URL = useContext(ApiContext);
  const [oldPswdError, setOldPswdError] = useState(null);
  const [newPswdError, setNewPswdError] = useState(null);
  const [confirmPswdError, setConfirmPswdError] = useState(null);

  const oldPassword = useRef(null);
  const newPassword = useRef(null);
  const confirmNewPassword = useRef(null);

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  const ShowErrror = ({ errorMsg }) => {
    return <div className="ops-settings-pswd-change-error">{errorMsg}</div>;
  };

  const handleClear = (e) => {
    e && e.preventDefault();
    oldPassword.current.value = "";
    newPassword.current.value = "";
    confirmNewPassword.current.value = "";
    setOldPswdError(null);
    setNewPswdError(null);
    setConfirmPswdError(null);
  };

  const btnChange = () => {
    const profile_submit_btn = document.querySelector(
      "#ops-password-submit-btn",
    );
    profile_submit_btn.innerText = "Saved";
    profile_submit_btn.style.background = "var(--orange,#ea7b2c)";
    profile_submit_btn.style.borderColor = "var(--orange,#ea7b2c)";
    setTimeout(() => {
      profile_submit_btn.innerText = "Save";
      profile_submit_btn.style.background = "var(--light-blue,#3cc3f2)";
      profile_submit_btn.style.borderColor = "var(--light-blue,#3cc3f2)";
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!oldPassword.current.value) {
      setNewPswdError(null);
      setConfirmPswdError(null);
      setOldPswdError("Enter Current Password");
    } else if (newPassword.current.value !== confirmNewPassword.current.value) {
      setOldPswdError(null);
      setNewPswdError(null);
      setConfirmPswdError("Passwords don't match, Enter Again");
      confirmNewPassword.current.value = "";
    } else if (newPassword.current.value === oldPassword.current.value) {
      setOldPswdError(null);
      setConfirmPswdError(null);
      setNewPswdError("New password can't be same as old password.");
    } else {
      const data = {
        password: oldPassword.current.value,
        new_password: newPassword.current.value,
      };
      postAuthData(`${API_URL}/api-ops/auth/v1/reset-password/`, data)
        .then((res) => {
          if (res.success === true) {
            handleClear();
            sessionStorage.setItem(
              "token",
              makeSecureEncrypt(JSON.stringify(res.token.access_token)),
            );
            // if local storage already has token then it will save the token otherwise it won't
            localStorage.getItem("token") &&
              makeSecureDecrypt(localStorage.getItem("token")) &&
              localStorage.setItem("token", sessionStorage.getItem("token"));
            btnChange();
          }
          if (res.success === false) {
            setConfirmPswdError(null);
            setNewPswdError(null);
            setOldPswdError(res.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  return (
    <section className="ops-profile-section">
      <div className="ops-profile-head ops-profile-head-mobile">
        <button
          className="ops-dashboard-back-btn"
          onClick={(e) => {
            showAccountSidebar(e);
          }}
        >
          <FaAngleLeft />
        </button>
        <span>Change Password</span>
      </div>

      <div className="ops-profile-form-container glass-container-active">
        <div className="ops-profile-head">Change Password</div>

        <form className="ops-password-form-container" onSubmit={handleSubmit}>
          <div className="ops-form-grid-item" id="ops-old-password-field">
            <label className="ops-profile-form-label" htmlFor="old_password">
              Old Password
            </label>
            <div className="ops-old-password-input">
              <div className="ops-pswd-input-contianer">
                <input
                  className="ops-dashboard-input ops-profile-form-input"
                  onChange={(e) => {
                    ToolTipError(e, [
                      ERROR_REGEX.PASSWORD_ERROR,
                      ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                    ]);
                  }}
                  ref={oldPassword}
                  type="password"
                  id="old_password"
                  name="old_password"
                  placeholder="Old Password"
                />
                <span
                  className="ops-pswd-change-form-input-show-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    e.target
                      .closest(".ops-pswd-change-form-input-show-btn")
                      .classList.toggle(
                        "ops-pswd-change-form-input-show-btn-active",
                      );
                    showPassword(oldPassword);
                    oldPassword.current.focus();
                  }}
                >
                  <FaEye />
                </span>
              </div>
              {oldPswdError && <ShowErrror errorMsg={oldPswdError} />}
            </div>
          </div>

          <div className="ops-form-grid-item" id="ops-new-password-field">
            <label className="ops-profile-form-label" htmlFor="new_password">
              New Password
            </label>
            <div className="ops-pswd-input-contianer">
              <input
                className="ops-dashboard-input ops-profile-form-input"
                onChange={(e) => {
                  ToolTipError(e, [
                    ERROR_REGEX.PASSWORD_ERROR,
                    ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                  ]);
                }}
                ref={newPassword}
                type="password"
                id="new_password"
                name="new_password"
                placeholder="New Password"
              />
              <span
                className="ops-pswd-change-form-input-show-btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target
                    .closest(".ops-pswd-change-form-input-show-btn")
                    .classList.toggle(
                      "ops-pswd-change-form-input-show-btn-active",
                    );
                  showPassword(newPassword);
                  newPassword.current.focus();
                }}
              >
                <FaEye />
              </span>
            </div>
            {newPswdError && <ShowErrror errorMsg={newPswdError} />}
          </div>

          <div className="ops-form-grid-item" id="ops-confirm-password-field">
            <label
              className="ops-profile-form-label"
              htmlFor="confirm_new_password"
            >
              Confirm New Password
            </label>
            <div className="ops-pswd-input-contianer">
              <input
                className="ops-dashboard-input ops-profile-form-input"
                onChange={(e) => {
                  ToolTipError(e, [
                    ERROR_REGEX.PASSWORD_ERROR,
                    ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                  ]);
                }}
                ref={confirmNewPassword}
                type="password"
                id="confirm_new_password"
                name="confirm_new_password"
                placeholder="Confirm New Password"
              />
              <span
                className="ops-pswd-change-form-input-show-btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target
                    .closest(".ops-pswd-change-form-input-show-btn")
                    .classList.toggle(
                      "ops-pswd-change-form-input-show-btn-active",
                    );
                  showPassword(confirmNewPassword);
                  confirmNewPassword.current.focus();
                }}
              >
                <FaEye />
              </span>
            </div>
            {confirmPswdError && <ShowErrror errorMsg={confirmPswdError} />}
          </div>

          <div className="ops-form-grid-item" id="ops-password-btns-container">
            <div className="ops-profile-form-btns">
              <div
                className="ops-btns ops-profile-form-btn ops-profile-form-clear-btn"
                onClick={handleClear}
              >
                Cancel
              </div>
              <button
                type="submit"
                className="ops-btns ops-profile-form-btn ops-profile-form-submit-btn"
                id="ops-password-submit-btn"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

const AccountDashboard = () => {
  const { renderPage } = useContext(renderPageContext);
  return (
    <div className="ops-dashboard">
      {renderPage === PAGE.PASSWORD && <AccountPasswordForm />}
      {renderPage === PAGE.PROFILE && <AccountProfileForm />}
    </div>
  );
};

const PAGE = {
  PASSWORD: "password",
  PROFILE: "profile",
};

const OpsAccountSettings = () => {
  const [renderPage, setRenderPage] = useState(PAGE.PROFILE);
  const [account] = useState({});

  return (
    <>
      <accountContext.Provider value={{ account }}>
        <renderPageContext.Provider value={{ renderPage, setRenderPage }}>
          <AccountSidebar />
          <AccountDashboard />
        </renderPageContext.Provider>
      </accountContext.Provider>
    </>
  );
};
export { OpsAccountSettings };
