import { createSlice } from "@reduxjs/toolkit";
import { initialState, TYPE_OF_REQUESTER_DATA } from "./common";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthData } from "../../../helpers/request";

// setting up other state values

// temp changes
// const token = "jbDLY7vcpZuGRdA8j3mmnlvkdEiFIf";

export const getLiveClientData = createAsyncThunk(
  "liveClientData/getLiveClientData",
  async ({ url = "api-req/requester/v1/company", id }, { rejectWithValue }) => {
    let get_live_asgn_url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/${url}/${id}/`;
    try {
      let type_of_data;
      if (id) {
        type_of_data = TYPE_OF_REQUESTER_DATA.ID;
      } else {
        type_of_data = TYPE_OF_REQUESTER_DATA.UNDEFINED;
      }
      const res = await getAuthData(get_live_asgn_url);
      if (res.success) {
        return { requester_details: res.data, type_of_data };
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

const liveClientDataSlice = createSlice({
  name: "live-client-data",
  initialState,
  reducers: {
    updateDomainList: (state, { payload }) => {
      state.requester_details.domains = payload;
    },
  },
  extraReducers: {
    [getLiveClientData.pending]: (state) => {
      state.loading = true;
    },
    [getLiveClientData.fulfilled]: (state, action) => {
      state.loading = false;
      state.requester_details = action.payload.requester_details;
      state.type_of_data = action.payload.type_of_data;
    },
    [getLiveClientData.rejected]: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
  },
});

export const { updateDomainList } = liveClientDataSlice.actions;

export default liveClientDataSlice.reducer;
