export const SORT_ORDERS = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

export const TYPE_OF_ALL_ASSIGNMENTS_DATA = {
  INIT: "initial",
  ALL: "all",
  RECENT: "recent",
  SEARCH: "search",
  NONE: "none",
  ERROR: "error",
};

export const initialState = {
  loading: false,
  all_assignments: null,
  type_of_data: TYPE_OF_ALL_ASSIGNMENTS_DATA.INIT,
  error: null,
  sort_order: SORT_ORDERS.ASCENDING,
};
