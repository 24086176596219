const removeArrEl = (el, arr) => {
  let el_id = arr?.findIndex((item) => item == el);
  // if (arr[idx].length==1){
  //     return arr.splice(el_id, 1)
  //   } else {
  //     let arr_item_el_idx = arr[el_id].findIndex((el) => el==item)
  //     return arr[el_id].splice(arr_item_el_idx,1)
  //   }
  arr.splice(el_id, 1);
  return arr;
};

export default removeArrEl;
