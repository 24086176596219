import { makeSecureDecrypt } from "./security";

export async function postData(url, data) {
  const response = await fetch(url, {
    method: "POST",
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response.json();
}

export async function getData(url) {
  const response = await fetch(url, {
    method: "GET",
  });

  return response.json();
}

// temp changes
export async function postAuthData(url, data) {
  if (sessionStorage.getItem("token") === null) {
    window.location.reload();
  }
  // temp changes
  const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("token")));

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status === 403 || response.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
  return response.json();
}

// temp changes
export async function putAuthData(url, data) {
  if (sessionStorage.getItem("token") === null) {
    window.location.reload();
  }
  // temp changes
  const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("token")));

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${user}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status === 403 || response.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
  return response.json();
}

// temp changes
export async function getAuthData(url) {
  if (sessionStorage.getItem("token") === null) {
    window.location.reload();
  }
  // temp changes
  const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("token")));

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user}`,
    },
  });
  if (response.status === 403 || response.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
  return response.json();
}

export const getDownloadData = async (url) => {
  if (sessionStorage.getItem("token") === null) {
    window.location.reload();
  }
  const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("token")));
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user}`,
    },
  });
  if (response.status === 403 || response.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
  return response;
};
