import React, { useContext, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { ApiContext, devEnv, getAccessLogData } from "../../../index.js";
import {
  ModalDispatchContext,
  OpsSharingLogContext,
  OpsAccessLogContext,
  NotFoundErrorContext,
  MODAL_TYPE,
} from "../../OpsComponent";
import { shareResetData } from "../../../store/features/shareData/shareDataSlice.js";
import { getAuthData, postAuthData } from "../../../helpers/request";
import { useDispatch, useSelector } from "react-redux";

import "./OpsShareRevokeModal.css";

const OpsShareRevokeModal = () => {
  const dispatch = useDispatch();
  const shareData = useSelector((state) => state.shareData);
  const API_URL = useContext(ApiContext);
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const modal_dispatch = useContext(ModalDispatchContext);
  const shareModalRef = useRef(null);
  //   const sharedNameInputRef = useRef(null);
  //   const [shareError, setShareError] = useState(null);
  const shareSubmitBtn = useRef(null);
  const [disableShareBtn, setDisableShareBtn] = useState(false);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setSharedLogData } = useContext(OpsSharingLogContext);
  const { setAccessLogData } = useContext(OpsAccessLogContext);

  const closeSharingModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Shared!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Share";
      closeSharingModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-ops/misc/v1/access-log/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch(() => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-ops/misc/v1/sharing-file/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch(() => {
        setNotFoundError(true);
      });
  };

  // const removeName = () => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      delivery_id: liveAssignmentData.file_details?.batch_id,
      email: shareData.shared_emails[0],
    };

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    if (devEnv) {
      console.log(getAccessLogData(data));
      modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
      dispatch(shareResetData());
      return;
    }
    // Sending Data
    postAuthData(
      `${process.env.REACT_APP_API_URL}/api-ops/misc/v1/revoke/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res.success) {
          submitBtnSuccess();
          setDisableShareBtn(false);
          fetch_shared_log();
          fetch_access_log();
          modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
          dispatch(shareResetData());
        } else {
          //   setShareError("Please refresh and try again or contact Tech Team.");
          setDisableShareBtn(false);
        }
      })
      .catch(() => {
        setDisableShareBtn(false);
      });
  };

  return (
    <div
      className="ops-modal-container ops-shared-modal-container"
      ref={shareModalRef}
    >
      <div className="ops-modal-head">
        <div className="ops-modal-container-head" id="ops-revoke-share-heading">
          Revoke Access
        </div>
        <button
          className="ops-modal-container-close-btn"
          id="ops-share-heading-close-btn"
          onClick={closeSharingModal}
        >
          <FaTimes />
        </button>
      </div>
      <span className="ops-share-head-text">
        You are revoking the access for the following user
      </span>
      <div className="ops-share-names-container">
        {shareData.shared_emails?.map((item, idx) => {
          return (
            <div key={idx} className="ops-share-name-item">
              <span className="ops-share-name">{item}</span>
              {/* <button
                className="ops-share-remove-btn"
                onClick={(e) => {
                  removeName(e, idx);
                }}
              >
                <FaTimes />
              </button> */}
            </div>
          );
          // return <SharedName key={idx} name={item} idx={idx} setSharedNames={setSharedNames}/>
        })}
      </div>
      {/* <form className="ops-share-modal-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="ops-dashboard-input ops-share-modal-input"
          ref={sharedNameInputRef}
          autoFocus
        />

        {shareError && (
          <div className="ops-input-error ops-share-error">{shareError}</div>
        )}
      </form> */}
      <div className="ops-share-btn-container">
        <button
          className="ops-btns"
          id="ops-share-modal-cancel-btn"
          onClick={closeSharingModal}
        >
          Cancel
        </button>
        <button
          className="ops-btns"
          ref={shareSubmitBtn}
          id="ops-share-modal-revoke-btn"
          disabled={disableShareBtn}
          onClick={handleSubmit}
        >
          Revoke
        </button>
      </div>
    </div>
  );
};

export { OpsShareRevokeModal };
