import React, { useContext, useEffect, useState } from "react";
import {
  FaAngleLeft,
  FaBan,
  FaCheck,
  FaHourglassStart,
  FaRedoAlt,
  FaSpinner,
} from "react-icons/fa";
import { assignment_services } from "../../../../req_jsons/assignment_services";
import {
  DeliveryPageContext,
  DELIVERY_TYPE_PAGE,
  ModalDispatchContext,
  MODAL_TYPE,
} from "../../../OpsComponent";
import UpdateBatchDeliveryHead from "../../components/UpdateBatchDeliveryHead/UpdateBatchDeliveryHead";
import {
  EXT_LINK_TEXT_CHOICES,
  UpdateAddNewFileDataContext,
  UpdateAsgnCombinationDataContext,
} from "../../UpdateDelivery";
import axios from "axios";
import "./UpdateUploadDelivery.css";
import { makeSecureDecrypt } from "../../../../helpers/security";
import { addAccessLogDataInFormData, ApiContext, devEnv } from "../../../..";
import { postAuthData } from "../../../../helpers/request";
import { useHistory } from "react-router-dom";
import UpdateUploadShare from "../../components/UpdateUploadShare/UpdateUploadShare";
import { CLIENT_USER_TYPE } from "../../../../utils/externalClientUsers";
import { useDispatch, useSelector } from "react-redux";
import { getLiveAllAssignmentsData } from "../../../../store/features/liveAllAssignmentsData/liveAllAssignmentsDataSlice";
import { getLiveAssignmentData } from "../../../../store/features/liveAssignmentData/liveAssignmentDataSlice";
import { TYPE_OF_ALL_ASSIGNMENTS_DATA } from "../../../../store/features/liveAllAssignmentsData/common";
import dummy_upload_share_warning from "../../../../archive/test_data/dummy_data-v2/share_warning/upload_share_warning.json";
import {
  shareAddClientCode,
  shareAddDeliveryId,
  shareAddEmail,
  shareAddEntityCode,
  shareAddRecognisedEmail,
  shareAddUnrecognisedEmail,
  shareResetData,
  shareShowWarning,
  shareUpdateClient,
} from "../../../../store/features/shareData/shareDataSlice";
import { SHARE_RESPONSE_STATUS } from "../../../../store/features/shareData/common";
import {
  createUpdateGroupedFileData,
  UPDATE_FILES_PROGRESS_STATUS,
} from "../../../../store/features/updateData/common";
import {
  addUpdateFileGroup,
  addUpdateFileGroupsIdsArr,
  addUpdateFileLinksApi,
  addUpdateFilesTotalProgress,
  getFileUpdateLinks,
  resetUpdateData,
  resetUpdateFileProgressData,
} from "../../../../store/features/updateData/updateDataSlice";

export const FileService = ({ service }) => {
  return (
    <div className="batch-delivery-upload-info-service">
      <div className="batch-delivery-upload-info-service-head">
        <div className="batch-delivery-upload-info-service-head-logo">
          <img
            src={
              process.env.PUBLIC_URL +
              `/resources/services_icons/blue/${
                assignment_services[service.service].code
              }.svg`
            }
            alt={assignment_services[service.service].code}
          />
        </div>
        <div className="batch-delivery-upload-info-service-head-text">
          {service.service}
        </div>
      </div>
      <div className="batch-delivery-upload-info-service-value-container">
        {service.sub_services.map((sub_service, idx) => {
          return (
            <div
              key={idx}
              className="batch-delivery-upload-info-service-value-item"
            >
              <div className="batch-delivery-upload-info-service-value-item-text">{`${sub_service.unit_count} ${sub_service.unit_type} ${sub_service.sub_service}`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UpdateFileData = ({ file_data }) => {
  const dispatch = useDispatch();

  const handleRedo = () => {
    dispatch(resetUpdateFileProgressData(file_data.file_id));
    dispatch(getFileUpdateLinks(file_data.file_id));
  };

  return (
    <div className="update-file-container update-target-section-file-container">
      <div className="update-file-name update-target-section-file-name">
        {file_data.file.name}
      </div>
      <div className="update-file-btn-container update-target-section-file-btn-container">
        {file_data.progress === UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE && (
          <span
            className="update-file-btn update-file-btn-inqueue"
            title="In-queue"
          >
            <FaHourglassStart />
          </span>
        )}
        {file_data.progress === UPDATE_FILES_PROGRESS_STATUS.FAILED && (
          <span
            className="update-file-btn update-file-btn-retry"
            title="Redo"
            onClick={handleRedo}
          >
            <FaRedoAlt />
          </span>
        )}
        {/* {file_data.progress === UPDATE_FILES_PROGRESS_STATUS.UPLOADING && (
          <span
            className="update-file-btn update-file-btn-reject"
            title="Reject"
          >
            <FaTimes />
          </span>
        )} */}
        {file_data.progress === UPDATE_FILES_PROGRESS_STATUS.FAILED && (
          <span
            className="update-file-btn update-file-btn-failed"
            title="Failed"
          >
            <FaBan />
          </span>
        )}
        {file_data.progress === UPDATE_FILES_PROGRESS_STATUS.UPLOADING && (
          <span
            className="update-file-btn update-file-btn-uploading"
            title="Uploading"
          >
            <FaSpinner />
          </span>
        )}
        {file_data.progress === UPDATE_FILES_PROGRESS_STATUS.SUCCESS && (
          <span
            className="update-file-btn update-file-btn-success"
            title="Succes"
          >
            <FaCheck />
          </span>
        )}
      </div>
    </div>
  );
};

const UploadInfoContainer = () => {
  const { addNewFileData } = useContext(UpdateAddNewFileDataContext);
  const { combinationData } = useContext(UpdateAsgnCombinationDataContext);
  const updateData = useSelector((state) => state.updateData);

  const combineServices = () => {
    const services = combinationData
      .map((data) => {
        return data?.assignment_services.map((service) => {
          return service.service;
        });
      })
      .flat();

    const unique_services = services.filter(
      (element, index, array) => array.indexOf(element) === index,
    );

    // recreatng service obj with combined services
    const service_obj_arr = unique_services.map((service) => {
      return {
        service: service,
        sub_services: [],
      };
    });
    // adding subservices in the combined services obj
    combinationData?.forEach((data) => {
      data?.assignment_services?.forEach((service) => {
        const service_index = service_obj_arr?.findIndex(
          (service_obj) => service_obj.service === service.service,
        );
        if (service_index !== -1) {
          service.sub_services.forEach((sub_service) => {
            service_obj_arr[service_index].sub_services.push(sub_service);
          });
        }
      });
    });

    return service_obj_arr;
  };

  return (
    <section className="batch-delivery-upload-info-container">
      <div
        className="batch-delivery-upload-info-details"
        id="batch-info-details"
      >
        <div className="batch-delivery-upload-info">
          <span className="batch-delivery-upload-info-label">
            Requester Name
          </span>
          <span className="batch-delivery-upload-info-value">
            {addNewFileData?.requester_name}
          </span>
        </div>

        <div className="batch-delivery-upload-info">
          <span className="batch-delivery-upload-info-label">Client Code</span>
          <span className="batch-delivery-upload-info-value">
            {addNewFileData?.client_code}
          </span>
        </div>

        <div className="batch-delivery-upload-info">
          <span className="batch-delivery-upload-info-label">Charge Code</span>
          <span className="batch-delivery-upload-info-value">
            {addNewFileData?.client_charge_code}
          </span>
        </div>

        <div className="batch-delivery-upload-info">
          <span className="batch-delivery-upload-info-label">Entity Code</span>
          <span className="batch-delivery-upload-info-value">
            {addNewFileData?.entity_code}
          </span>
        </div>
      </div>

      <div className="batch-delivery-upload-info" id="batch-info-services">
        <span className="batch-delivery-upload-info-label">Services</span>
        <section className="batch-delivery-upload-info-value">
          {combineServices()?.map((service, id) => {
            return <FileService key={id} service={service} />;
          })}
        </section>
      </div>

      <div className="batch-delivery-upload-info" id="batch-info-input">
        <span className="batch-delivery-upload-info-label">Input</span>
        <div className="batch-delivery-upload-info-value">
          {/* {combinationData
            ?.map((cur) => {
              return cur.source_files;
            })
            ?.flat()
            ?.map((file, idx) => {
              return (
                <span
                  key={idx}
                  className="batch-delivery-upload-info-input-file-name"
                >
                  {file?.name}
                </span>
              );
            })} */}
          {updateData.update_file_groups_data.file_groups_ids_arr
            ?.filter((f_id) => {
              return f_id.file_type === "source_files";
            })
            ?.map((file_id_data) => {
              return updateData.update_file_groups_data.file_groups[
                file_id_data.group_id
              ][file_id_data.file_type][file_id_data.file_type_id];
            })
            ?.map((fileData, idx) => {
              return <UpdateFileData key={idx} file_data={fileData} />;
            })}
        </div>
      </div>

      <div className="batch-delivery-upload-info" id="batch-info-output">
        <span className="batch-delivery-upload-info-label">Output</span>
        <div className="batch-delivery-upload-info-value">
          {/* {!combinationData
            .map((cur) => {
              return cur.target_files;
            })
            ?.flat()
            ?.every((cur) => cur === null) ? ( // checks if no target file there
            combinationData
              ?.map((cur) => {
                return cur.target_files;
              })
              ?.flat()
              ?.map((file, idx) => {
                return (
                  <span
                    key={idx}
                    className="batch-delivery-upload-info-input-file-name"
                  >
                    {file?.name}
                  </span>
                );
              })
          ) : (
            <span className="batch-delivery-upload-info-value-error">
              No New Attachments!
            </span>
          )} */}
          {updateData.update_file_groups_data.file_groups_ids_arr
            ?.filter((f_id) => {
              return f_id.file_type === "target_files";
            })
            ?.map((file_id_data) => {
              return updateData.update_file_groups_data.file_groups[
                file_id_data.group_id
              ][file_id_data.file_type][file_id_data.file_type_id];
            })
            ?.map((fileData, idx) => {
              return <UpdateFileData key={idx} file_data={fileData} />;
            })}
        </div>
      </div>

      {addNewFileData.delivery_user_type === CLIENT_USER_TYPE.EXTERNAL && (
        <div
          className="batch-delivery-upload-info"
          id="batch-info-external-links"
        >
          <span className="batch-delivery-upload-info-label">
            External File Links
          </span>
          <span className="version-card-info-value">
            {combinationData?.map((group, idx) => {
              if (
                group.external_link_text_choice === EXT_LINK_TEXT_CHOICES.LINK
              ) {
                return (
                  <div className="version-card-external-link" key={idx}>
                    {group.external_link}
                  </div>
                );
              } else {
                return (
                  <div className="version-card-external-link" key={idx}>
                    {group.external_text}
                  </div>
                );
              }
            })}
          </span>
        </div>
      )}

      <div className="batch-delivery-upload-info" id="batch-info-notes">
        <span className="batch-delivery-upload-info-label">Delivery Notes</span>
        <span className="version-card-info-value">
          {combinationData?.map((group, idx) => {
            return (
              <div className="version-card-delivery-notes" key={idx}>
                {group?.source_files?.map((file, idx) => (
                  <span
                    key={idx}
                    className="version-card-delivery-notes-file-name"
                  >
                    @{file?.name}
                  </span>
                ))}
                <span className="version-card-delivery-note">
                  {group.delivery_notes}
                </span>
              </div>
            );
          })}
        </span>
      </div>
    </section>
  );
};

const UploadInfoHead = ({
  uploadShareEmails,
  uploadShareError,
  setUploadShareError,
}) => {
  let fetchingText_interval;
  const modal_dispatch = useContext(ModalDispatchContext);
  const dispatch = useDispatch();
  const liveAllAssignmentsData = useSelector(
    (state) => state.liveAllAssignmentsData,
  );
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const [fetching, setFetching] = useState(null);
  const history = useHistory();
  const API_URL = useContext(ApiContext);
  const { combinationData } = useContext(UpdateAsgnCombinationDataContext);
  const { setDeliverPage } = useContext(DeliveryPageContext);
  const { addNewFileData } = useContext(UpdateAddNewFileDataContext);
  const updateData = useSelector((state) => state.updateData);
  const maxSharingNames = 10;
  const shareData = useSelector((state) => state.shareData);

  const createFormData = () => {
    let formData = new FormData();

    formData.append("delivery_id", addNewFileData.delivery_id); // --form delivery_id

    formData.append("batch_id", addNewFileData.batch_id); // --form batch_id

    formData.append("current_delivery_time", new Date().toISOString()); // -- adds time to form data

    shareData?.password_data?.password &&
      formData.append("password", shareData.password_data.password);

    formData.append("total_group", combinationData.length); // --form total_group

    combinationData?.forEach((group_obj, i) => {
      // --form delivery_notes
      formData.append(`committed_delivery_${i}`, group_obj.committed_delivery);
    });

    shareData?.shared_emails?.forEach((cur) => {
      // --form emails
      formData.append("share_emails", cur);
    });

    combinationData?.forEach((group_obj, i) => {
      // --form assignment_id
      group_obj?.assignment_ids.forEach((asgn_id) => {
        formData.append(`assignment_id_${i}`, asgn_id);
      });
    });

    combinationData?.forEach((group_obj, i) => {
      // --form source_file
      formData.append(
        `is_source_${i}`,
        group_obj?.source_files ? true : group_obj?.gid,
      );
      group_obj?.source_files?.forEach((file) => {
        // formData.append(`source_file_${i}${(group_obj?.gid)?`_${group_obj?.gid}`:''}`,file)
        formData.append(`source_file_${i}`, file.name);
      });
    });

    combinationData?.forEach((group_obj, i) => {
      // --form target_file
      formData.append(
        `is_target_${i}`,
        group_obj?.target_files ? true : group_obj?.gid || true,
      );
      group_obj?.target_files?.forEach((file) => {
        formData.append(`target_file_${i}`, file.name);
      });
    });

    combinationData?.forEach((group_obj, i) => {
      // --form delivery_notes
      formData.append(`delivery_notes_${i}`, group_obj.delivery_notes);
    });

    combinationData?.forEach((group_obj, i) => {
      // --form interim_output
      formData.append(`interim_${i}`, group_obj.interim_output);
    });

    if (addNewFileData.delivery_user_type === CLIENT_USER_TYPE.EXTERNAL) {
      combinationData?.forEach((group_obj, i) => {
        // --form external_link
        if (
          group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.LINK &&
          group_obj?.external_link
        ) {
          formData.append(`external_link_${i}`, group_obj.external_link);
        } else if (
          group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.TEXT &&
          group_obj?.external_text
        ) {
          formData.append(`external_text_${i}`, group_obj.external_text);
        }
      });

      combinationData?.forEach((group_obj, i) => {
        // --form email_attachment
        formData.append(`email_attachment_${i}`, group_obj.email_attachment);
      });
    }

    return formData;
  };

  const updateAllAssignments = () => {
    switch (liveAllAssignmentsData?.type_of_data) {
      case TYPE_OF_ALL_ASSIGNMENTS_DATA.RECENT:
        dispatch(getLiveAllAssignmentsData({}));
        break;
      case TYPE_OF_ALL_ASSIGNMENTS_DATA.ALL:
        dispatch(getLiveAllAssignmentsData({ month: "all" }));
        break;

      default:
        dispatch(getLiveAllAssignmentsData({ month: "all" }));
        break;
    }
    dispatch(getLiveAssignmentData({})); // fetching dashboard assignemnt
    history.push(`/deliver/${liveAssignmentData?.file_details?.id}`);
  };

  const sendFormData = (formData) => {
    setFetching("Fetching.");
    fetchingText_interval = setInterval(() => {
      setFetching((prev) => {
        if (prev === "Fetching.") {
          return "Fetching..";
        } else if (prev === "Fetching..") {
          return "Fetching...";
        } else if (prev === "Fetching...") {
          return "Fetching.";
        }
      });
    }, 1000);
    const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("token")));

    if (devEnv) {
      // we need this dev env whenever we use share warning demo
      console.log(...addAccessLogDataInFormData(formData));
      clearInterval(fetchingText_interval);
      setFetching("Fetched!!");
      dispatch(shareResetData()); //clearning the share warning state here bcz we needed the data till now
      setTimeout(() => {
        setFetching(null);
      }, 1000);
      return;
    }

    axios({
      method: "post",
      url: `${API_URL}/api-ops/ops/v1/update-delivery/`,
      data: addAccessLogDataInFormData(formData),
      headers: { Authorization: `Bearer ${user}` },
    })
      .then(() => {
        clearInterval(fetchingText_interval);
        setFetching("Fetched!!");
        dispatch(shareResetData()); //clearning the share warning state here bcz we needed the data till now
        dispatch(resetUpdateData());
        setTimeout(() => {
          setFetching(null);
        }, 1000);
        updateAllAssignments();
        // if (res.success === true) {
        //   clearInterval(fetchingText_interval)
        //   setFetching("Fetched!!")
        //   setTimeout(() => {
        //     setFetching(null)
        //   },1000)
        //   updateAllAssignments();
        // } else {
        //   clearInterval(fetchingText_interval)
        //   setFetching("Failed to upload !")
        //   setTimeout(() => {
        //       setFetching(null)
        //   },2000)
        // }

        // old way
        // getAuthData(`${API_URL}/api-ops/ops/v1/all-assignments/?id=${undefined}`)
        // .then((res) => {
        //   if (res.success === true) {
        //     setOpsFilesArray(res.data);
        //     setOpsDisplayedFile(res.last_assignment);
        //     history.push(`/deliver/${res?.last_assignment?.id}`);
        //   } else {
        //     clearInterval(fetchingText_interval)
        //     setFetching("Failed to upload !")
        //     alert(res?.message)
        //     setTimeout(() => {
        //         setFetching(null)
        //     },2000)
        //   }
        // })
      })
      .catch(() => {
        clearInterval(fetchingText_interval);
        setFetching("Failed to upload !");
        setTimeout(() => {
          setFetching(null);
        }, 2000);
      });
  };

  const submitFile = () => {
    const shared_emails_data = {
      client_code: addNewFileData?.client_code,
      emails: uploadShareEmails,
      entity_code: addNewFileData?.entity_code,
      accept_warning: shareData.show_warning,
    };

    // checking if more than 10 names are shared
    if (uploadShareEmails.length > maxSharingNames) {
      setUploadShareError({
        ...uploadShareError,
        message: `More than ${maxSharingNames} names shared.`,
      });
      return;
    }

    // checks if no target file there
    if (
      combinationData
        .map((cur) => {
          return cur.target_files;
        })
        ?.flat()
        ?.every((cur) => cur === null)
    ) {
      alert("No new Target Files present.");
      return;
    }

    // Sending Data
    if (devEnv) {
      if (!uploadShareError.message) {
        if (
          dummy_upload_share_warning.status === SHARE_RESPONSE_STATUS.SUCCESS
        ) {
          setUploadShareError({ emails: [], message: null });
          console.log(shared_emails_data);
          let formData = createFormData();
          console.log(...addAccessLogDataInFormData(formData));
        } else if (
          dummy_upload_share_warning.status === SHARE_RESPONSE_STATUS.WARNING
        ) {
          openShareWarningModal(dummy_upload_share_warning);
        } else {
          setUploadShareError((prev) => {
            return {
              ...prev,
              message: [...dummy_upload_share_warning.message],
            };
          });
          if (dummy_upload_share_warning.emails.length > 0) {
            setUploadShareError((prev) => {
              return {
                ...prev,
                emails: [...dummy_upload_share_warning.emails],
              };
            });
          }
        }
      } else {
        alert("Please clear the share field errors.");
      }
    } else {
      if (uploadShareError.message == null) {
        if (uploadShareEmails.length > 0) {
          // this will allow us to send form data without share emails
          postAuthData(
            `${API_URL}/api-ops/ops/v1/share-email/`,
            shared_emails_data,
          )
            .then((res) => {
              if (res.status === SHARE_RESPONSE_STATUS.SUCCESS) {
                setUploadShareError({ emails: [], message: null }); // removing errors in share field
                // send uploaded files here
                let formData = createFormData();
                sendFormData(formData);
              } else if (res.status === SHARE_RESPONSE_STATUS.WARNING) {
                setUploadShareError({ emails: [], message: null });
                openShareWarningModal(res);
              } else {
                // this wont happen bcz of share warning but still keeping the code just in case
                setUploadShareError((prev) => {
                  return { ...prev, message: [...res.message] };
                });
                if (res.emails.length > 0) {
                  setUploadShareError((prev) => {
                    return { ...prev, emails: [...res.emails] };
                  });
                }
              }
            })
            .catch(() => {});
        } else {
          let formData = createFormData();
          sendFormData(formData);
        }
      } else {
        alert("Please clear the share field errors.");
      }
    }
  };

  const openShareWarningModal = (data) => {
    // add data to the store
    dispatch(shareUpdateClient(data.client));
    dispatch(
      shareAddEmail([...data.emails.within_org, ...data.emails.outside_org]),
    );
    dispatch(shareAddRecognisedEmail(data.emails.within_org));
    dispatch(shareAddUnrecognisedEmail(data.emails.outside_org));
    dispatch(shareShowWarning(true)); // this needs to be set whenever we have checked the data already
    dispatch(shareAddClientCode(addNewFileData?.client_code));
    dispatch(shareAddEntityCode(addNewFileData?.entity_code));
    dispatch(shareAddDeliveryId(addNewFileData?.batch_id));
    // open the modal
    modal_dispatch({ modal_show_type: MODAL_TYPE.SHARE_WARNING });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   submitFile();
  //   // let formData = createFormData();
  //   // if (devEnv){
  //   //   console.log(...addAccessLogDataInFormData(formData))
  //   // }
  //   // else {
  //   //   sendFormData(formData);
  //   // }
  // };

  // useEffect(() => {
  //   // this use Effect is for those cases when we get a share warning and we need to send the data after the share warning data is verified
  //   if (shareData.upload_warning_sent) {
  //     let formData = createFormData();
  //     sendFormData(formData);
  //   }
  // }, [shareData.upload_warning_sent]);

  // useEffect(() => {
  //   // this is required in order to send the email data along with deliver data
  //   dispatch(shareAddEmail(uploadShareEmails));
  // }, [uploadShareEmails]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitFile();
  };

  // // this function is used to create the file data to be transfered in chunks upload
  // // and also sets the uploadFIleSuccess to uploading and begins the transfer of files
  // const createFileChunksData = () => {
  //   const fileGroupData = createGroupedFileData(
  //     combinationData,
  //     addNewFileData.delivery_id, // since the get urls require delivery id
  //   );
  //   setFileUploadData((prev) => {
  //     return {
  //       ...prev,
  //       fileGroups: fileGroupData.file_groups,
  //       fileIdsArr: fileGroupData.file_ids_arr,
  //       uploadFilesSuccess: UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE,
  //       get_links_api_url: `${process.env.REACT_APP_API_URL}/api-ops/ops/v1/update-get-url/`, // since upload and update urls are different we have to send the data form here.
  //     };
  //   });
  // };

  // const handleFileChunksUpload = (e) => {
  //   e?.preventDefault();
  //   // open modal to see the uploading of the files status
  //   // modal_dispatch({ modal_show_type: MODAL_TYPE.FILE_UPLOAD_MODAL });
  // };

  // this use Effect is for those cases when we get a share warning and we need to send the data after the share warning data is verified
  useEffect(() => {
    if (shareData.upload_warning_sent) {
      let formData = createFormData();
      sendFormData(formData);
    }
  }, [shareData.upload_warning_sent]);

  // this is required in order to send the email data along with deliver data
  useEffect(() => {
    dispatch(shareAddEmail(uploadShareEmails));
  }, [uploadShareEmails]);

  // // // this useEffect is starting the upload of the files in chunks
  // useEffect(() => {
  //   if (
  //     fileUploadData.uploadFilesSuccess ===
  //     UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE
  //   ) {
  //     createFileChunksData();
  //     handleFileChunksUpload();
  //   } else {
  //     console.log("cant upload");
  //   }
  // }, []);

  const handleCancel = (e) => {
    e?.preventDefault();
    setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.ADD);
    dispatch(resetUpdateData());
  };

  return (
    <div className="batch-delivery-head">
      <div className="batch-delivery-head-container">
        <button
          className="ops-dashboard-back-btn batch-delivery-head-back-btn"
          onClick={handleCancel}
        >
          <FaAngleLeft />
        </button>
        <span className="batch-delivery-head-text">Confirm Delivery</span>
      </div>
      <div className="batch-delivery-head-container">
        <button
          className="ops-btns batch-delivery-head-btn"
          id="batch-delivery-cancel-btn"
          onClick={handleCancel}
        >
          Cancel
        </button>
        {updateData.update_file_groups_data.upload_files_total_progress ===
          UPDATE_FILES_PROGRESS_STATUS.UPLOADING && (
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-submit-btn"
          >
            Uploading...
          </button>
        )}
        {updateData.update_file_groups_data.upload_files_total_progress ===
          UPDATE_FILES_PROGRESS_STATUS.FAILED && (
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-submit-btn"
          >
            Failed to upload!
          </button>
        )}
        {updateData.update_file_groups_data.upload_files_total_progress ===
          UPDATE_FILES_PROGRESS_STATUS.SUCCESS && (
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-submit-btn"
            onClick={handleSubmit}
            disabled={fetching}
            style={fetching && { background: "var(--orange,#ea7b2c)" }}
          >
            {fetching || "Deliver"}
          </button>
        )}
      </div>
    </div>
  );
};

const UploadShareContainer = ({
  uploadShareEmails,
  setUploadShareEmails,
  uploadShareError,
  setUploadShareError,
}) => {
  const { addNewFileData } = useContext(UpdateAddNewFileDataContext);

  return (
    <>
      <div className="batch-delivery-head">
        <div className="batch-delivery-head-container">
          <span className="batch-delivery-head-text">Share with Client</span>
        </div>
      </div>
      <div className="batch-delivery-upload-share-card">
        <div className="batch-delivery-div-info">
          <div className="batch-delivery-form-info-label">Email Address</div>
          <UpdateUploadShare
            client_code={addNewFileData?.client_code}
            entity_code={addNewFileData?.entity_code}
            uploadShareEmails={uploadShareEmails}
            setUploadShareEmails={setUploadShareEmails}
            uploadShareError={uploadShareError}
            setUploadShareError={setUploadShareError}
          />
        </div>
      </div>
    </>
  );
};

const UploadDeliveryContainer = () => {
  const dispatch = useDispatch();
  const [uploadShareEmails, setUploadShareEmails] = useState([]);
  const [uploadShareError, setUploadShareError] = useState({
    emails: [],
    message: null,
  });
  const { addNewFileData } = useContext(UpdateAddNewFileDataContext);
  const { combinationData } = useContext(UpdateAsgnCombinationDataContext);

  const createUpdateFileGroupsData = () => {
    const fileGroupData = createUpdateGroupedFileData(
      combinationData,
      addNewFileData.delivery_id, // since the get urls require delivery id
    );
    dispatch(addUpdateFileGroup(fileGroupData.file_groups));
    dispatch(addUpdateFileGroupsIdsArr(fileGroupData.file_ids_arr));
    dispatch(
      addUpdateFileLinksApi(
        `${process.env.REACT_APP_API_URL}/api-ops/ops/v1/update-get-url/`,
      ),
    );
    dispatch(
      addUpdateFilesTotalProgress(UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE),
    );
    // starting upload
    fileGroupData.file_ids_arr[0] &&
      dispatch(getFileUpdateLinks(fileGroupData.file_ids_arr[0]));
    fileGroupData.file_ids_arr[1] &&
      dispatch(getFileUpdateLinks(fileGroupData.file_ids_arr[1]));
    fileGroupData.file_ids_arr[2] &&
      dispatch(getFileUpdateLinks(fileGroupData.file_ids_arr[2]));

    // setting overall progress to
    dispatch(
      addUpdateFilesTotalProgress(UPDATE_FILES_PROGRESS_STATUS.UPLOADING),
    );
  };

  useEffect(() => {
    createUpdateFileGroupsData();
  }, []);

  return (
    <section className="batch-delivery-container">
      <UploadInfoHead
        uploadShareEmails={uploadShareEmails}
        uploadShareError={uploadShareError}
        setUploadShareError={setUploadShareError}
      />
      <UploadInfoContainer />
      <UploadShareContainer
        uploadShareEmails={uploadShareEmails}
        setUploadShareEmails={setUploadShareEmails}
        uploadShareError={uploadShareError}
        setUploadShareError={setUploadShareError}
      />
    </section>
  );
};

const UpdateUploadDelivery = () => {
  return (
    <main className="file-deliver-container card-layout">
      <UpdateBatchDeliveryHead />
      <UploadDeliveryContainer />
    </main>
  );
};

export default UpdateUploadDelivery;
