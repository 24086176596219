const NameShorteningCmpn = ({ name, max_length, last_chars_length = 5 }) => {
  return (
    <span title={name?.length > max_length ? name : ""}>
      {`${
        name?.length > max_length
          ? name?.substr(0, max_length) +
            "..." +
            name?.substr(name?.length - last_chars_length, name?.length)
          : name
      }`}
    </span>
  );
};

export default NameShorteningCmpn;
