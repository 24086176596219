import React from "react";
import "./error.css";
import { Link } from "react-router-dom";

const LostError = () => {
  return (
    // <div className='error-div'>
    //     <div className="error-head">I think you got lost.</div>
    //     <Link to="/" className="get-back"> Go back to Home </Link>
    // </div>
    <section className="client-lost-error-section">
      <Link className="client-lost-ez-logo" to="/deliver">
        <img
          src={process.env.PUBLIC_URL + "/resources/nav_icons/logo.png"}
          alt="EZ"
        />
      </Link>
      <div className="client-lost-error-msg">
        <span className="client-lost-error-msg-big">
          {"This is not the link you're looking for"}
        </span>
      </div>
      <img
        className="client-lost-error-img"
        src={process.env.PUBLIC_URL + "/resources/client-errors/not-found.png"}
        alt="Not Found"
      />
    </section>
  );
};

const RetryError = () => {
  return (
    // <div className='error-div'>
    //     <div className="error-head">I think you got lost.</div>
    //     <Link to="/" className="get-back"> Go back to Home </Link>
    // </div>
    <section className="client-lost-error-section">
      <Link className="client-lost-ez-logo" to="/deliver">
        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="EZ" />
      </Link>
      <div className="client-lost-error-msg">
        <span className="client-lost-error-msg-big">
          Something went wrong. Please Try again.
        </span>
      </div>
      <img
        className="client-lost-error-img"
        src={process.env.PUBLIC_URL + "/resources/client-errors/not-found.png"}
        alt="Not Found"
      />
    </section>
  );
};

export { LostError, RetryError };
