import { TAB_CHOICE } from "../../../utils/CustomInputs/nameSpaces";
import { job_func_options, user_industry_options } from "../clientData/common";
// import user_list_data from "../../../archive/requester_test_data/userData/userList/user_list_data.json";

export const createUserOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur, value: cur };
  });
  return options_format_data;
};

export const createOptionValue = (val) => {
  return { label: val, value: val };
};

export const createDefaultEntityOptionValue = (val) => {
  return { label: val.entity_code, value: val.id };
};

export const createUSerEntityOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur.entity_code, value: cur.id };
  });
  return options_format_data;
};

const category_options = [
  { label: "B School Alumni", value: "B School Alumni" },
  { label: "Start up", value: "Start up" },
  { label: "Enterprise", value: "Enterprise" },
  { label: "Government Entity", value: "Government Entity" },
];

export const initialState = {
  user_form_data: {
    user_id: null,
    user_name: null,
    // last_name: null,
    email: null,
    country: null,
    user_designation: null,
    user_job_function: null,
    default_entity: null,
    phone: [],
    city: null,
    user_industry: null,
    user_category: null,
    form_options: {
      country: { list: [], loading: false, error: null },
      city: { list: [], loading: false, error: null },
      default_entities: { list: [], loading: false, error: null },
      industry: { list: user_industry_options, loading: false, error: null },
      job_func: { list: job_func_options, loading: false, error: null },
      category: { list: category_options, lodaing: false, error: null },
    },
    loading: false,
    error: null,
  },
  user_list_data: {
    loading: false,
    list_data: null,
    error: null,
  },
  tab: TAB_CHOICE.LIST,
  edit_user_id: null,
  success: null,
};
