import React, { useState, useRef, useContext } from "react";
import { postAuthData } from "../../../helpers/request";
import {
  ApiContext,
  ERROR_REGEX,
  ToolTipError,
  UserContext,
} from "../../../index.js";
import { FaEye } from "react-icons/fa";
import { makeSecureEncrypt } from "../../../helpers/security";
import { useHistory } from "react-router-dom";

import "./OpsPswdChangeModal.css";

const OpsPswdChangeModal = () => {
  const API_URL = useContext(ApiContext);
  const { setProfile } = useContext(UserContext);
  const history = useHistory();

  const [newPswdError, setNewPswdError] = useState(null);
  const [confirmPswdError, setConfirmPswdError] = useState(null);

  const newPassword = useRef(null);
  const confirmNewPassword = useRef(null);

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  const ShowErrror = ({ errorMsg }) => {
    return <div className="ops-pswd-change-error">{errorMsg}</div>;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const password = { password: newPassword.current.value };
    // if (!pswdRegex.test(newPassword.current.value)){
    //     setNewPswdError("Please include at least one uppercase, one lowercase, one number and one special character and must be 8 characters long.")
    // }
    // else
    if (newPassword.current.value !== confirmNewPassword.current.value) {
      setConfirmPswdError("Passwords don't match!");
    } else if (
      newPassword.current.value == "" ||
      confirmNewPassword.current.value == ""
    ) {
      setNewPswdError(null);
      setConfirmPswdError(null);
    } else {
      setNewPswdError(null);
      setConfirmPswdError(null);
      postAuthData(`${API_URL}/api-ops/ops/v1/change-password/`, password)
        .then((res) => {
          if (res.success === true) {
            sessionStorage.setItem(
              "token",
              makeSecureEncrypt(JSON.stringify(res.token.access_token)),
            );
            sessionStorage.setItem(
              "is_verified",
              makeSecureEncrypt(JSON.stringify(res.is_verified)),
            );
            window.location.href = "/deliver";
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const handleLogout = () => {
    postAuthData(`${API_URL}/api-ops/auth/v1/logout/`)
      .then((res) => {
        if (res.success === true) {
          setProfile({});
          window.sessionStorage.clear();
          window.localStorage.clear();
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="ops-change-password-container">
      <div className="update-ops-pswd-head">Update Your Password</div>
      <form className="update-ops-pswd-form" onSubmit={handleSubmit}>
        <div
          className="update-pswd-modal-info"
          id="update-pswd-modal-info-new-password"
        >
          <label
            className="ops-pswd-change-form-label update-pswd-modal-info-label"
            htmlFor="new_password"
          >
            New Password
          </label>
          <div className="update-pswd-modal-info-input">
            <input
              className="ops-dashboard-input ops-pswd-change-form-input"
              onChange={(e) => {
                ToolTipError(e, [
                  ERROR_REGEX.PASSWORD_ERROR,
                  ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                ]);
              }}
              ref={newPassword}
              type="password"
              id="new_password"
              name="new_password"
            />
            <span
              className="ops-pswd-change-form-input-show-btn"
              onClick={(e) => {
                e.preventDefault();
                showPassword(newPassword);
                newPassword.current.focus();
              }}
            >
              <FaEye />
            </span>
          </div>
          {newPswdError && <ShowErrror errorMsg={newPswdError} />}
        </div>
        <div
          className="update-pswd-modal-info"
          id="update-pswd-modal-info-confirm-password"
        >
          <label
            className="ops-pswd-change-form-label update-pswd-modal-info-label"
            htmlFor="confirm_new_password"
          >
            Confirm New Password
          </label>
          <div className="update-pswd-modal-info-input">
            <input
              className="ops-dashboard-input ops-pswd-change-form-input"
              onChange={(e) => {
                ToolTipError(e, [
                  ERROR_REGEX.PASSWORD_ERROR,
                  ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                ]);
              }}
              ref={confirmNewPassword}
              type="password"
              id="confirm_new_password"
              name="confirm_new_password"
            />
            <span
              className="ops-pswd-change-form-input-show-btn"
              onClick={(e) => {
                e.preventDefault();
                showPassword(confirmNewPassword);
                confirmNewPassword.current.focus();
              }}
            >
              <FaEye />
            </span>
          </div>
          {confirmPswdError && <ShowErrror errorMsg={confirmPswdError} />}
        </div>
        <div
          className="update-pswd-modal-info"
          id="update-pswd-modal-btn-container"
        >
          <div
            className="ops-btns ops-pswd-change-form-btn ops-pswd-change-cancel-btn"
            onClick={handleLogout}
          >
            Logout
          </div>
          <button
            className="ops-btns ops-pswd-change-form-btn ops-pswd-change-update-btn"
            type="submit"
            onClick={handleSubmit}
          >
            Change Password
          </button>
        </div>

        {/* <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="ops-pswd-change-form-label" htmlFor="new_password">New Password</label>
                            </td>
                            <td>
                                <label className="ops-pswd-change-form-label" htmlFor="confirm_new_password">Confirm New Password</label>
                            </td>
                            <td>
                                <button className="ops-pswd-change-form-btn ops-pswd-change-cancel-btn"  onClick={handleLogout}>Logout</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input className="ops-pswd-change-form-input" onChange={(e)=>{ToolTipError(e,[ERROR_REGEX.PASSWORD_ERROR,ERROR_REGEX.PASSWORD_SYMBOLS_ERROR])}} ref={newPassword} type="password" id='new_password' name='new_password' />
                                <button className="ops-pswd-change-form-input-show-btn" onClick={(e)=>{
                                    e.preventDefault();
                                    showPassword(newPassword);
                                    newPassword.current.focus();}
                                }><FaEye/></button>
                            </td>
                            <td>
                                <input className="ops-pswd-change-form-input" onChange={(e)=>{ToolTipError(e,[ERROR_REGEX.PASSWORD_ERROR,ERROR_REGEX.PASSWORD_SYMBOLS_ERROR])}} ref={confirmNewPassword} type="password" id='confirm_new_password' name='confirm_new_password' />
                                <button className="ops-pswd-change-form-input-show-btn" onClick={(e)=>{
                                    e.preventDefault();
                                    showPassword(confirmNewPassword);
                                    confirmNewPassword.current.focus();}
                                }><FaEye/></button>
                            </td>
                            <td>
                                <button className="ops-pswd-change-form-btn ops-pswd-change-update-btn" type='submit' onClick={handleSubmit}>Change Password</button>
                            </td>
                        </tr>
                        <tr>
                            <td className="update-password-error">
                                {newPswdError && <ShowErrror errorMsg={newPswdError}/>}
                            </td>
                            <td className="update-password-error">
                                {confirmPswdError && <ShowErrror errorMsg={confirmPswdError}/>}
                            </td>
                        </tr>
                    </tbody>
                </table> */}
      </form>
    </div>
  );
};

export { OpsPswdChangeModal };
