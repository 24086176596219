import React, { useContext, useEffect } from "react";
import { FaAngleLeft } from "react-icons/fa";
import {
  DeliveryPageContext,
  DELIVERY_TYPE_PAGE,
  FileGroupsContext,
  uploadFileInitialState,
} from "../../../OpsComponent";
import {
  AddNewFileDataContext,
  AsgnCombinationDataContext,
  AsgnCombinationsContext,
  EXT_LINK_TEXT_CHOICES,
} from "../../NewDelivery";

// Component Imports
import BatchDeliveryHead from "../../components/BatchDeliveryHead/BatchDeliveryHead";
import BatchDeliveryCard from "../../components/BatchDeliveryCard/BatchDeliveryCard";

//Style
import "./AddDelivery.css";
import { CLIENT_USER_TYPE } from "../../../../utils/externalClientUsers";
import { isValidHttpUrl } from "../../../../utils/linkCheck";

const BatchDeliveryInfoContainer = () => {
  const { addNewFileData } = useContext(AddNewFileDataContext); // this is the data fetched from the create page i.e. the assignments data that we submitted.
  const { asgnCombinations, setAsgnCombinations } = useContext(
    AsgnCombinationsContext,
  ); // combination for cards
  const { combinationData, setCombinationData } = useContext(
    AsgnCombinationDataContext,
  ); // combination of data on cards

  const assignmentCombinator = (asgnIdsArr) => {
    let batch_data_obj = {
      // assignment_ids: addNewFileData.data.filter((file_data)=>asgnIdsArr.includes(file_data.assignment_id)).map((cur)=>{return cur.assignment_id}),  // this will cause the order of the selcted ids to be in the order priotised to addNewFileData but we want it to be like what is in combinedAsgns
      // assignment_names : addNewFileData.data.filter((file_data)=>asgnIdsArr.includes(file_data.assignment_id)).map((cur)=>{return cur.assignment_name}),
      // assignment_services: addNewFileData.data.filter((file_data)=>asgnIdsArr.includes(file_data.assignment_id)).map((cur)=>{return cur.services}).flat()
      assignment_ids: asgnIdsArr
        ?.map((cur) => {
          return addNewFileData.data.filter(
            (file_data) => file_data.assignment_id === cur,
          );
        })
        ?.flat()
        ?.map((cur) => {
          return cur.assignment_id;
        }),
      assignment_names: asgnIdsArr
        ?.map((cur) => {
          return addNewFileData.data.filter(
            (file_data) => file_data.assignment_id === cur,
          );
        })
        ?.flat()
        ?.map((cur) => {
          return cur.assignment_name;
        }),
      assignment_services: asgnIdsArr
        ?.map((cur) => {
          return addNewFileData.data.filter(
            (file_data) => file_data.assignment_id === cur,
          );
        })
        ?.flat()
        ?.map((cur) => {
          return cur.services;
        })
        .flat(),
    };
    return batch_data_obj;
  };

  const createCombinationData = () => {
    let combined_data;
    switch (addNewFileData?.delivery_user_type) {
      case CLIENT_USER_TYPE.EXTERNAL:
        combined_data = asgnCombinations.map((cur, i) => {
          return {
            group_id: i,
            ...assignmentCombinator(cur),
            source_files: null,
            target_files: null,
            external_link_text_choice:
              addNewFileData?.external_type || EXT_LINK_TEXT_CHOICES.LINK,
            external_link: null, // External Links will be stored here and this key will also validate if we see the link input in the card or not
            external_text: null, // External Links will be stored here and this key will also validate if we see the link input in the card or not
            email_attachment: false,
            interim_output: false,
            delivery_notes: "",
          };
        });
        break;

      case CLIENT_USER_TYPE.DEFAULT:
        combined_data = asgnCombinations.map((cur, i) => {
          return {
            group_id: i,
            ...assignmentCombinator(cur),
            source_files: null,
            target_files: null,
            interim_output: false,
            delivery_notes: "",
          };
        });
        break;

      default:
        combined_data = asgnCombinations.map((cur, i) => {
          return {
            group_id: i,
            ...assignmentCombinator(cur),
            source_files: null,
            target_files: null,
            interim_output: false,
            delivery_notes: "",
          };
        });
        break;
    }
    setCombinationData(combined_data);
  };

  useEffect(() => {
    asgnCombinations && createCombinationData();
  }, []);

  useEffect(() => {
    asgnCombinations && createCombinationData();
  }, [addNewFileData, asgnCombinations]);

  return (
    <section className="batch-delivery-info-container card-layout">
      <div className="batch-delivery-info-head">
        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Requester Name</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.requester_name}
          </span>
        </div>

        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Client Code</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.client_code}
          </span>
        </div>

        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Charge Code</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.client_charge_code}
          </span>
        </div>

        <div className="batch-delivery-info">
          <span className="batch-delivery-info-label">Entity Code</span>
          <span className="batch-delivery-info-value">
            {addNewFileData?.entity_code}
          </span>
        </div>
      </div>

      <section className="batch-delivery-input-section">
        <div className="batch-delivery-input-section-head">Upload</div>
        <section className="batch-delivery-card-container card-layout">
          {/* {asgnCombinations?.map((cur) =>{return assignmentCombinator(cur)})?.map((batch_data_obj,i)=>{ */}
          {combinationData?.map((batch_data_obj, i) => {
            return (
              <BatchDeliveryCard
                key={i}
                batch_data_obj={batch_data_obj}
                // left_asgn_ids = {getLeftAssgnIds()}
                asgn_options={addNewFileData.data.map(
                  (cur) => cur.assignment_id,
                )}
                linkTextChoice={addNewFileData?.external_type}
                asgnCombinations={asgnCombinations}
                setAsgnCombinations={setAsgnCombinations}
                combinationData={combinationData}
                setCombinationData={setCombinationData}
                delivery_user_type={addNewFileData?.delivery_user_type}
              />
            );
          })}
        </section>
      </section>
    </section>
  );
};

const BatchDeliveryContainer = () => {
  const { deliverPage, setDeliverPage } = useContext(DeliveryPageContext);
  const { combinationData } = useContext(AsgnCombinationDataContext);
  const { addNewFileData } = useContext(AddNewFileDataContext);

  const ALERT_MSG_TYPES = {
    SOURCE_FILE_ERROR: "source-file-type",
    SOURCE_FILE_EMPTY_ERROR: "source-file-empty-type",
    TARGET_FILE_ERROR: "target-file-type",
    TARGET_FILE_EMPTY_ERROR: "target-file-empty-type",
    SOURCE_FILE_SIZE_ERROR: "source-file-size-type",
    TARGET_FILE_SIZE_ERROR: "target-file-size-type",
    EMAIL_ATTACHED_TARGET_FILE_ERROR: "email-attached-target-file-error",
    EXTERNAL_LINK_ERROR: "external-link-type",
    EXTERNAL_CODE_ERROR: "external-code-type",
  };

  const alertMsgFn = (type, group_id) => {
    const msg_types = {
      [ALERT_MSG_TYPES.SOURCE_FILE_ERROR]: `Please re-enter source files in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.SOURCE_FILE_EMPTY_ERROR]: `Source files were removed in ${
        group_id + 1
      }. Please re-enter the files.`,
      [ALERT_MSG_TYPES.TARGET_FILE_ERROR]: `Please re-enter target files in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.TARGET_FILE_EMPTY_ERROR]: `Target files were removed in ${
        group_id + 1
      }. Please re-enter the files.`,
      [ALERT_MSG_TYPES.EMAIL_ATTACHED_TARGET_FILE_ERROR]: `To send file in email you need to enter a target file in group ${
        group_id + 1
      }.`,
      [ALERT_MSG_TYPES.SOURCE_FILE_SIZE_ERROR]: `File size of a source file in group ${
        group_id + 1
      } is too large to send in email.`,
      [ALERT_MSG_TYPES.TARGET_FILE_SIZE_ERROR]: `Total file size of target files in group ${
        group_id + 1
      } is too large to send in email.`,
      [ALERT_MSG_TYPES.EXTERNAL_LINK_ERROR]: `Please check and enter a valid link in group ${
        group_id + 1
      }.\nFormat of link is: \nhttp://www.google.com\nor\nhttps://www.google.com`,
      [ALERT_MSG_TYPES.EXTERNAL_CODE_ERROR]: `Please enter the external code in group ${
        group_id + 1
      }.`,
    };
    return msg_types[type];
  };

  const validateInput = (data) => {
    let validation = { valid: true, group: null, err_msg: null };

    // ====== source_file validation
    data?.forEach((group_obj, i) => {
      if (!group_obj?.source_files) {
        // checking if source file present or not
        validation.valid = false;
        validation.group = i;
        validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.SOURCE_FILE_ERROR, i);
      } else {
        group_obj?.source_files?.forEach((file) => {
          // checking for each file in source files
          if (!file) {
            validation.valid = false;
            validation.group = i;
            validation.err_msg = alertMsgFn(
              ALERT_MSG_TYPES.SOURCE_FILE_ERROR,
              i,
            );
          }
        });
      }
    });

    // data?.forEach((group_obj,i)=>{ // target_file validation
    //   if (!group_obj?.target_files){
    //     validation.valid=false
    //     validation.group=i
    // validation.err_msg = alertMsgFn(
    //   ALERT_MSG_TYPES.TARGET_FILE_ERROR,
    //   i,
    // );
    //   } else {
    //     group_obj?.target_files?.forEach((file)=>{
    //       if (!file){
    //         validation.valid=false
    //         validation.group=i
    // validation.err_msg = alertMsgFn(
    //   ALERT_MSG_TYPES.TARGET_FILE_ERROR,
    //   i,
    // );
    //       }
    //     })
    //   }
    // })

    //  ================= external file validation ===================
    // if (addNewFileData?.delivery_user_type === CLIENT_USER_TYPE.EXTERNAL) {
    // ===== target_file validation
    // data?.forEach((group_obj, i) => {
    //   if (!group_obj?.target_files) {
    //     validation.valid = false;
    //     validation.group = i;
    //     validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.TARGET_FILE_ERROR, i);
    //   } else {
    //     group_obj?.target_files?.forEach((file) => {
    //       if (!file) {
    //         validation.valid = false;
    //         validation.group = i;
    //         validation.err_msg = alertMsgFn(
    //           ALERT_MSG_TYPES.TARGET_FILE_ERROR,
    //           i,
    //         );
    //       }
    //     });
    //   }
    // });
    // ===== external file validation
    // data?.forEach((group_obj, i) => {
    // if (
    //   group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.LINK
    // ) {
    //   // ===== external file link validation
    //   if (
    //     !group_obj?.external_link ||
    //     !isValidHttpUrl(group_obj?.external_link)
    //   ) {
    //     validation.valid = false;
    //     validation.group = i;
    //     validation.err_msg = alertMsgFn(
    //       ALERT_MSG_TYPES.EXTERNAL_LINK_ERROR,
    //       i,
    //     );
    //   }
    // } else if (
    //   group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.TEXT
    // ) {
    //   // ===== external file text validation
    //   if (!group_obj?.external_text || group_obj?.external_text === "") {
    //     validation.valid = false;
    //     validation.group = i;
    //     validation.err_msg = alertMsgFn(
    //       ALERT_MSG_TYPES.EXTERNAL_CODE_ERROR,
    //       i,
    //     );
    //   }
    // }
    // }
    // VALIDATION: only if we have target files in the group then we check for the external link or text
    // }

    //  ================= external file validation ===================
    if (addNewFileData?.delivery_user_type === CLIENT_USER_TYPE.EXTERNAL) {
      data?.forEach((group_obj, i) => {
        if (group_obj?.target_files) {
          // if there is target file in any group then there must be link/code in that group
          group_obj?.target_files?.forEach((file) => {
            if (file) {
              // ===== external file validation
              if (
                group_obj.external_link_text_choice ===
                EXT_LINK_TEXT_CHOICES.LINK
              ) {
                // ===== external file link validation
                if (
                  !group_obj?.external_link ||
                  !isValidHttpUrl(group_obj?.external_link)
                ) {
                  validation.valid = false;
                  validation.group = i;
                  validation.err_msg = alertMsgFn(
                    ALERT_MSG_TYPES.EXTERNAL_LINK_ERROR,
                    i,
                  );
                }
              } else if (
                group_obj.external_link_text_choice ===
                EXT_LINK_TEXT_CHOICES.TEXT
              ) {
                // ===== external file text validation
                if (
                  !group_obj?.external_text ||
                  group_obj?.external_text === ""
                ) {
                  validation.valid = false;
                  validation.group = i;
                  validation.err_msg = alertMsgFn(
                    ALERT_MSG_TYPES.EXTERNAL_CODE_ERROR,
                    i,
                  );
                }
              }
            }
          });
        }
        // if there is link/code in any gorup then there must be target file in that group
        if (
          group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.LINK &&
          group_obj?.external_link &&
          group_obj.external_link !== "" &&
          !group_obj?.target_files
        ) {
          validation.valid = false;
          validation.group = i;
          validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.TARGET_FILE_ERROR, i);
        } else if (
          group_obj.external_link_text_choice === EXT_LINK_TEXT_CHOICES.TEXT &&
          group_obj?.external_text &&
          group_obj.external_text !== "" &&
          !group_obj?.target_files
        ) {
          validation.valid = false;
          validation.group = i;
          validation.err_msg = alertMsgFn(ALERT_MSG_TYPES.TARGET_FILE_ERROR, i);
        }
      });
    }

    // Checking if the file size entered is below 25 mb if the email checkbox is checked
    data?.forEach((group_obj, i) => {
      if (group_obj.email_attachment) {
        // if email attachment is true there must be target files in the group
        if (!group_obj?.target_files) {
          validation.valid = false;
          validation.group = i;
          validation.err_msg = alertMsgFn(
            ALERT_MSG_TYPES.EMAIL_ATTACHED_TARGET_FILE_ERROR,
            i,
          );
        } else {
          // the total size of target files combined must be below 25mb
          const totalTargetFileSize = group_obj.target_files?.reduce(
            (total, file) => {
              return total + file.size;
            },
            0,
          );
          if (totalTargetFileSize > 25165824) {
            validation.valid = false;
            validation.group = i;
            validation.err_msg = alertMsgFn(
              ALERT_MSG_TYPES.TARGET_FILE_SIZE_ERROR,
              i,
            );
          }
        }
      }
    });

    return validation;
  };

  const HandleAsgnCombinationSubmit = () => {
    let valid_check = validateInput(combinationData);
    // put validation before sending data
    if (valid_check.valid) {
      setDeliverPage(DELIVERY_TYPE_PAGE.NEW.UPLOAD);
    } else {
      alert(valid_check.err_msg);
    }
  };

  return (
    <section className="batch-delivery-container">
      <div className="batch-delivery-head">
        <div className="batch-delivery-head-container">
          <button
            className="ops-dashboard-back-btn batch-delivery-head-back-btn"
            onClick={() => {
              if (deliverPage === DELIVERY_TYPE_PAGE.NEW.ADD) {
                setDeliverPage(DELIVERY_TYPE_PAGE.NEW.CREATE);
              } else {
                setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.CREATE);
              }
            }}
          >
            <FaAngleLeft />
          </button>
          <span className="batch-delivery-head-text">Enter Batch Details</span>
        </div>
        <div className="batch-delivery-head-container">
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-cancel-btn"
            onClick={() => {
              if (deliverPage === DELIVERY_TYPE_PAGE.NEW.ADD) {
                setDeliverPage(DELIVERY_TYPE_PAGE.NEW.CREATE);
              } else {
                setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.CREATE);
              }
            }}
          >
            Cancel
          </button>
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-submit-btn"
            onClick={() => {
              if (deliverPage === DELIVERY_TYPE_PAGE.NEW.ADD) {
                HandleAsgnCombinationSubmit();
              } else {
                setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.UPLOAD);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>

      <BatchDeliveryInfoContainer />
    </section>
  );
};

const AddDelivery = () => {
  const { setFileUploadData } = useContext(FileGroupsContext);

  // setting the fileGroups data to initial state ... this is bcz if a user clicks back the file gorups must revert back to initial state
  useEffect(() => {
    setFileUploadData(uploadFileInitialState);
  }, []);

  return (
    <main className="file-deliver-container card-layout">
      <BatchDeliveryHead />
      <BatchDeliveryContainer />
    </main>
  );
};

export default AddDelivery;
