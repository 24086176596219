import React, { createContext, useState } from "react";
import { DeliveryPageContext, DELIVERY_TYPE_PAGE } from "../OpsComponent.js";

import "./UpdateDelivery.css";

// Component Imports
import UpdateCreateDelivery from "./UpdateDeliverFileTabs/UpdateCreateDelivery/UpdateCreateDelivery.js";
import UpdateAddDelivery from "./UpdateDeliverFileTabs/UpdateAddDelivery/UpdateAddDelivery.js";
import UpdateUploadDelivery from "./UpdateDeliverFileTabs/UpdateUploadDelivery/UpdateUploadDelivery.js";

export const UpdateAddNewFileDataContext = createContext();
export const UpdateAsgnCombinationsContext = createContext();
export const UpdateAsgnCombinationDataContext = createContext();
export const UpdateAsgnOrgCombinationDataContext = createContext();

export const EXT_LINK_TEXT_CHOICES = {
  LINK: "external_link",
  TEXT: "external_text",
};

const UpdateDelivery = () => {
  const [deliverPage, setDeliverPage] = useState(DELIVERY_TYPE_PAGE.UPDATE.ADD);
  const [addNewFileData, setAddNewFileData] = useState(null);
  const [asgnCombinations, setAsgnCombinations] = useState(null); // combination for cards
  const [combinationData, setCombinationData] = useState(null); // all data that the group of assignment contains
  const [originalCombinationData, setOriginalCombinationData] = useState(null); // contains all the original data from the backend

  return (
    <UpdateAsgnCombinationsContext.Provider
      value={{ asgnCombinations, setAsgnCombinations }}
    >
      <UpdateAsgnCombinationDataContext.Provider
        value={{ combinationData, setCombinationData }}
      >
        <UpdateAsgnOrgCombinationDataContext.Provider
          value={{ originalCombinationData, setOriginalCombinationData }}
        >
          <UpdateAddNewFileDataContext.Provider
            value={{ addNewFileData, setAddNewFileData }}
          >
            <DeliveryPageContext.Provider
              value={{ deliverPage, setDeliverPage }}
            >
              <div className="file-deliver">
                {deliverPage === DELIVERY_TYPE_PAGE.UPDATE.CREATE && (
                  <UpdateCreateDelivery />
                )}
                {deliverPage === DELIVERY_TYPE_PAGE.UPDATE.ADD && (
                  <UpdateAddDelivery />
                )}
                {deliverPage === DELIVERY_TYPE_PAGE.UPDATE.UPLOAD && (
                  <UpdateUploadDelivery />
                )}
              </div>
            </DeliveryPageContext.Provider>
          </UpdateAddNewFileDataContext.Provider>
        </UpdateAsgnOrgCombinationDataContext.Provider>
      </UpdateAsgnCombinationDataContext.Provider>
    </UpdateAsgnCombinationsContext.Provider>
  );
};

export default UpdateDelivery;
