import { React, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  NotFoundErrorContext,
  OpsRouteContext,
  OPS_ROUTES,
} from "../../OpsComponent";
import { FaArchive } from "react-icons/fa";
import { lazy_load_image } from "../../../index.js";
import { useDispatch } from "react-redux";
import { getLiveAllAssignmentsData } from "../../../store/features/liveAllAssignmentsData/liveAllAssignmentsDataSlice";
import { getLiveAssignmentData } from "../../../store/features/liveAssignmentData/liveAssignmentDataSlice";

// For Add requester
// import { REQUESTER_ROUTES } from "../../../requester_module/utis/common";
// import { MdPersonAddAlt1 } from "react-icons/md";

import "./OpsSideNav.css";

export const showFilesSidebar = (e) => {
  e.preventDefault();
  document
    .querySelector("#ops-file-sidebar")
    .classList.toggle("ops-sidebar-show");
};

export const showArchiveSidebar = (e) => {
  e.preventDefault();
  document
    .querySelector("#ops-archive-sidebar")
    .classList.toggle("ops-sidebar-show");
};

export const toggleFilesSidebar = (e, route) => {
  if (route.includes(OPS_ROUTES.ASSIGNMENTS)) e?.preventDefault(); // if already on archive route then dont redirect it
  document
    .querySelector("#ops-file-sidebar")
    ?.classList.toggle("ops-sidebar-show");
};

export const closeFilesSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelector("#ops-file-sidebar")
    ?.classList.remove("ops-sidebar-show");
};

export const toggleArchiveSidebar = (e, route) => {
  if (route.includes(OPS_ROUTES.ARCHIVE)) e?.preventDefault(); // if already on archive route then dont redirect it
  document
    .querySelector("#ops-archive-sidebar")
    ?.classList.toggle("ops-sidebar-show");
};

export const closeArchiveSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelector("#ops-archive-sidebar")
    ?.classList.remove("ops-sidebar-show");
};

export const openArchiveSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelector("#ops-archive-sidebar")
    ?.classList.add("ops-sidebar-show");
};

const OpsSideNav = () => {
  const dispatch = useDispatch();
  // const liveAllAssignmentsData = useSelector(state => state.liveAllAssignmentsData)
  const history = useHistory();
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const route = useContext(OpsRouteContext);

  const hoverHiglight = (e) => {
    const navBtn = e.target.closest(".ops-sidenav-btn");
    if (e.type === "mouseenter") {
      navBtn.classList.add("ops-sidenav-btn-hover");
    } else if (e.type === "mouseleave") {
      navBtn.classList.remove("ops-sidenav-btn-hover");
    }
  };

  // const toggleSidebar = () => {
  //     document.querySelector('.sidebar').classList.toggle('hide-sidebar');
  // }

  // return (
  //     <div className="ops-sidenav">
  //         <div className="ops-sidenav-btns-container">
  //             <Link className='ops-sidenav-btn ops-sidenav-brand' to='/addnew'>
  //                 <div className="ez-logo" onClick={()=>{setNotFoundError(false);setLoader(false)}}>
  //                     <img src={process.env.PUBLIC_URL + "/logo.png"} alt="EZ"/>
  //                 </div>
  //             </Link>
  //             <Link className='ops-sidenav-btn' to="/deliver" onClick={()=>{setNotFoundError(false);setLoader(false)}}><img className="ops-sidenav-icon" src={process.env.PUBLIC_URL + "/resources/nav_icons/assignment.svg"}/></Link>
  //             <Link className='ops-sidenav-btn' to="/ops-archive" onClick={()=>{setNotFoundError(false);setLoader(false)}}><FaArchive className="ops-archive-btn-icon"/></Link>
  //         </div>

  //     </div>
  // )

  const handleAssignmentBtnClick = (e) => {
    e.preventDefault();
    closeArchiveSidebar();
    toggleFilesSidebar(e, route);
    setNotFoundError(false);
    // setOpsFilesArray(null); // ==> here
    if (route === OPS_ROUTES.ASSIGNMENTS) {
      // bcz we want to remove the search result
      dispatch(getLiveAllAssignmentsData({}));
      dispatch(getLiveAssignmentData({}));
    }
    history.push(`/deliver/`);
  };

  const mobileAssignmentSidebarBtnClick = (e) => {
    if (route !== OPS_ROUTES.ASSIGNMENTS) {
      history.push("/deliver");
    }
    // in case you would want to remove the search results and bring back the original data
    // if (liveAllAssignmentsData.type_of_data !== TYPE_OF_ALL_ASSIGNMENTS_DATA.RECENT && liveAllAssignmentsData.type_of_data !== TYPE_OF_ALL_ASSIGNMENTS_DATA.ALL){
    //     dispatch(getLiveAllAssignmentsData({}))
    // }
    closeArchiveSidebar();
    toggleFilesSidebar(e, route);
    setNotFoundError(false);
  };

  return (
    <div className="ops-sidenav">
      <Link
        className="ops-ez-logo"
        to="/addnew"
        onClick={() => {
          setNotFoundError(false);
        }}
      >
        <img
          className="lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/resources/nav_icons//logo.png"}
          alt="EZ"
        />
      </Link>
      <Link
        className={`ops-sidenav-btn files-btn ops-dashboard-sidebar-show-btn ${
          route === OPS_ROUTES.ASSIGNMENTS && "ops-sidenav-btn-active"
        }`}
        id="ops-dashboard-show-btn"
        to="/deliver"
        onClick={(e) => {
          handleAssignmentBtnClick(e);
        }}
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
      >
        <img
          className="ops-sidenav-icon lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/resources/nav_icons/assignment.svg"}
          alt="Btn"
        />
      </Link>
      <button
        className={`ops-sidenav-btn ops-mobile-sidebar-show-btn ${
          route === OPS_ROUTES.ASSIGNMENTS && "ops-sidenav-btn-active"
        }`}
        id="ops-file-show-btn"
        onClick={mobileAssignmentSidebarBtnClick}
      >
        <img
          className="ops-sidenav-icon lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/resources/nav_icons/assignment.svg"}
          alt="Btn"
        />
      </button>

      <Link
        className={`ops-sidenav-btn ops-archive-btn ${
          route === OPS_ROUTES.ARCHIVE && "ops-sidenav-btn-active"
        }`}
        to="/ops-archive"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={(e) => {
          closeFilesSidebar();
          toggleArchiveSidebar(e, route);
          setNotFoundError(false);
        }}
      >
        <FaArchive />
      </Link>
      {/* Link for Requester Route */}
      {/* <Link
        className={`ops-sidenav-btn ops-archive-btn ops-requester-btn ${
          route === REQUESTER_ROUTES.REQUESTER && "ops-sidenav-btn-active"
        }`}
        to="/requester"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={() => {
          closeFilesSidebar();
          setNotFoundError(false);
        }}
      >
        <MdPersonAddAlt1 />
      </Link> */}

      {/* <Link
                className={`ops-sidenav-btn ops-settings-btn ${(route===OPS_ROUTES.FAQ) && "ops-sidenav-btn-active"}`}
                to="/faq"
                onClick={()=>{closeFilesSidebar();closeArchiveSidebar();setNotFoundError(false)}}
                onMouseEnter={hoverHiglight}
                onMouseLeave={hoverHiglight}
            >
                    <img className="ops-sidenav-icon lazy-img" onLoad={lazy_load_image} src={process.env.PUBLIC_URL + "/resources/nav_icons/faq.svg"} alt="Btn"/>
            </Link> */}
    </div>
  );
};
export { OpsSideNav };
