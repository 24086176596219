import { TAB_CHOICE } from "../../../utils/CustomInputs/nameSpaces";
// import entity_list_data from "../../../archive/requester_test_data/entityData/entityList/entity_list_data.json";
// import entity_list_item_data from "../../../archive/requester_test_data/entityData/entityList/enity_list_item_data.json";

export const MODAL_TOGGLE = {
  OPEN: "open",
  CLOSE: "close",
};

export const tax_options = {
  tax_type: null,
  perc: 0,
};

export const price_per_unit_option = {
  id: null,
  service_id: null,
  sub_service: {
    selected_option: null,
    options: { list: [], loading: false, error: null },
  },
  list_price: 0,
  discount: 0,
  final_sell_price: 0,
  // service: null,
  // invoice_price: 0,
  // cum_discount: 0,
  // corporate_discounted_price: 10,
};

export const entity_item_details_data = {
  entity_item_head_info: {
    loading: false,
    data: null,
    error: null,
  },
  entity_item_data_info: {
    loading: false,
    data: null,
    error: null,
  },
  open: false,
};

export const formdataobj = {
  company_name: true,
  city: true,
  country: true,
};

const tax_type_options = [
  { label: "VAT", value: "VAT" },
  { label: "WHT", value: "WHT" },
  { label: "GST", value: "GST" },
];

const currency_options = [
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "AED", value: "AED" },
  // { label: "SAR", value: "SAR" },
  // { label: "GBP", value: "GBP" },
];

const share_options = [
  { label: "Text ID", value: "external_text" },
  { label: "Link Sharing", value: "external_link" },
];

export const getOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur.country || cur.city, value: cur.country || cur.city };
  });
  return options_format_data;
};

export const createOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur, value: cur };
  });
  return options_format_data;
};

export const createSubServiceOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: cur.display_txt, value: cur.id, unit: cur?.unit_type };
  });
  return options_format_data;
};

export const createOptionValue = (val) => {
  return { label: val, value: val };
};

export const createSubServiceOptionValue = (val) => {
  return { label: val.display_txt, value: val.id, unit: val?.unit_type };
};

export const initialState = {
  entity_form_data: {
    entity_id: null,
    entity_code: null,
    entity_details: {
      billing_info: {
        entity_name: null,
        address: {
          building_name: null,
          street: null,
          zip: null,
          country: null,
          city: {
            selected_option: null,
            options: { list: [], loading: false, error: null },
          },
        },
      },
      crn_details: {
        number: null,
        valid_till: { value: null, calendar_show: false },
      },
      trn_details: {
        number: null,
        valid_till: { value: null, calendar_show: false },
      },
      taxes: [tax_options],
      // file_sharing_details: {
      //   bool: false,
      //   value: null,
      // },
      charge_code_details: {
        bool: false,
        value: null,
      },
    },
    contract: {
      file: null,
      file_path: null,
      valid_thorugh: {
        startDate: null,
        endDate: null,
        key: "valid-through-date-range",
      },
      file_sharing_details: {
        bool: false,
        value: null,
        sharing_type: { label: "default", value: "default" },
      },
      contract_type: { is_msla: false, value: "NDA" },
      currency: null,
      file_storage_duration: null,
      // taxes: [tax_options],
    },
    price_per_unit: [price_per_unit_option],
    form_options: {
      country: { list: [], loading: false, error: null },
      currency: { list: currency_options, loading: false, error: null },
      tax_type: { list: tax_type_options, loading: false, error: null },
      share_type: { list: share_options, loading: false, error: null },
      service: { list: [], loading: false, error: null },
    },
    loading: false,
    error: null,
  },
  entity_form_valid_data: formdataobj,
  // ============= entity list data ================= //
  // entity_list_data: {
  //   entity: {
  //     entity_info: {
  //       entity_id: 1,
  //       entity_name: "ffm india",
  //       entity_code: "VANS0",

  //       entity_type: "default",
  //     },
  //     address: {
  //       city: "delhi",
  //       country: "india",
  //       address1: "building name/number",
  //       address2: "street",
  //       zip_code: "110929",
  //     },
  //     crn_details: {
  //       comp_reg_no: "23232Ad",
  //       comp_reg_val: "09 aug 2034",
  //     },
  //     trn_details: {
  //       tax_no: "23432ef",
  //       tax_val: "30 dec 2032",
  //     },
  //     msla_details: {
  //       msla_val_start: "02 aug 2023",
  //       msla_val_end: "02 aug 2023",
  //       file_name: "file.pdf",
  //       file_link: "https:google.com",
  //     },
  //     taxes: {
  //       tax_type: "vat",
  //       percentage: 10,
  //     },
  //   },
  // },
  // entity_item_data: {
  //   loading: false,
  //   item_data: null,
  //   error: null,
  // },
  // entity_list_data: {
  //   loading: false,
  //   list_data: null,
  //   error: null,
  // },
  entity_list_data: { list: [], error: null, loading: false },
  tab: TAB_CHOICE.LIST,
  pdf_modal: false,
  detail_entity_id: null,
  edit_entity_id: null,
  success: null,
};
