import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { postData } from "../../helpers/request";
import { makeSecureEncrypt, makeSecureDecrypt } from "../../helpers/security";
import { ApiContext, devEnv, ERROR_REGEX, ToolTipError } from "../../index.js";
import { FaEye } from "react-icons/fa";
import { toast } from "react-hot-toast";

// Importing Dummy Data
import dummy_login from "../../archive/test_data/dummy_data-v1/dummy_login.json";
import { LOGGED_USER_TYPE } from "../../utils/CustomInputs/userTypes";
// import { useDispatch } from "react-redux";
// import { addUserType } from "../../store/features/profileData/profileDataSlice";

//Error components
const EmailErrorModal = (props) => {
  return <div className="login-input-error email-error">{props.errorMsg}</div>;
};

const PasswordErrorModal = (props) => {
  return (
    <div className="login-input-error password-error">{props.errorMsg}</div>
  );
};

const OpsLoginForm = () => {
  const history = useHistory();
  const API_URL = useContext(ApiContext);

  // Using ref hook to capture user credentials
  const loggedUserEmail = useRef(null);
  const loggedUserPassword = useRef(null);
  const rememberUser = useRef(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const accountValidation = (email, password) => {
    // Checking for invalid email
    const emailRegex = /\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*/g;
    const valid = emailRegex.test(email);
    if (!valid) {
      setPasswordError(null);
      return { valid, error: { msg: "Invalid Email!", onInput: "email" } };
    } else {
      setEmailError(null);
    }

    // Checking for password
    if (password === "") {
      return {
        valid: false,
        error: { msg: "Empty password!", onInput: "password" },
      };
    } else {
      setPasswordError(null);
    }

    // Passed all test
    return { valid: true };
  };

  const saveToken = (token, verified) => {
    if (rememberUser.current.checked) {
      // Storing the values in local storage if remember is checked
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("is_verified", verified);
      localStorage.setItem("token", token);
      localStorage.setItem("is_verified", verified);
    } else {
      // Removing the values in local storage if remember is unchecked
      localStorage.removeItem("token");
      localStorage.removeItem("is_verified");
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("is_verified", verified);
    }
  };

  // Checking if there already is a user locally saved in the client browser.
  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      makeSecureDecrypt(localStorage.getItem("token"))
    ) {
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem(
        "is_verified",
        localStorage.getItem("is_verified"),
      );
      history.push("/deliver");
    }
    loggedUserEmail.current.focus();
  }, []); // this happens only on 1st render/load

  const clearInput = () => {
    // Clearing the fields
    loggedUserPassword.current.value = "";
    loggedUserEmail.current.value = "";
    rememberUser.current.checked = false;
  };

  // const redirectPage = () => {
  //   // if we have a redirected url then move to that one otherwise move to this decrypt
  //   sessionStorage.getItem("redirect")
  //     ? history.push(sessionStorage.getItem("redirect"))
  //     : history.push("/deliver");
  //   // clearing the session storage after redirecting
  //   sessionStorage.removeItem("redirect");
  // };

  const redirectPage = (userType) => {
    // console.log(userType);
    switch (userType) {
      case LOGGED_USER_TYPE.OPS_ADMIN:
        sessionStorage.getItem("redirect")
          ? history.push(sessionStorage.getItem("redirect"))
          : history.push("/deliver");
        sessionStorage.removeItem("redirect");
        break;
      case LOGGED_USER_TYPE.CRM:
        sessionStorage.getItem("redirect")
          ? history.push(sessionStorage.getItem("redirect"))
          : history.push("/requester");
        sessionStorage.removeItem("redirect");
        break;
      case LOGGED_USER_TYPE.OPS_USER:
        sessionStorage.getItem("redirect")
          ? history.push(sessionStorage.getItem("redirect"))
          : history.push("/deliver");
        sessionStorage.removeItem("redirect");
        break;
      case LOGGED_USER_TYPE.SALES:
        sessionStorage.getItem("redirect")
          ? history.push(sessionStorage.getItem("redirect"))
          : history.push("/requester");
        sessionStorage.removeItem("redirect");
        break;
      case LOGGED_USER_TYPE.TEAM_LEAD:
        sessionStorage.getItem("redirect")
          ? history.push(sessionStorage.getItem("redirect"))
          : history.push("/deliver");
        sessionStorage.removeItem("redirect");
        break;
      default:
        sessionStorage.getItem("redirect")
          ? history.push(sessionStorage.getItem("redirect"))
          : history.push("/deliver");
        sessionStorage.removeItem("redirect");
        break;
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const user = {
      username: loggedUserEmail.current.value.toLowerCase().trim(),
      password: loggedUserPassword.current.value,
    };
    const validation = accountValidation(user.email, user.password); // validatinng the input

    if (devEnv) {
      saveToken(
        makeSecureEncrypt(JSON.stringify(dummy_login.token.access_token)),
        makeSecureEncrypt(JSON.stringify(dummy_login.is_verified)),
      ); // Setting Dummy Data
      redirectPage(); // Setting Dummy Data
      return;
    }

    if (validation.valid) {
      clearInput();
    } else {
      postData(`${API_URL}/api-ops/auth/v1/login/`, user)
        .then((res) => {
          if (res.success === true) {
            saveToken(
              makeSecureEncrypt(JSON.stringify(res.token.access_token)),
              makeSecureEncrypt(JSON.stringify(res.is_verified)),
            );
            // setTimeout(() => {
            toast.success("Successfully Logged in!");
            // }, 1000);
            // redirectPage();
            // console.log(res.user_type);
            // dispatch(addUserType({ value: res?.user_type }));
            redirectPage(res.user_type);
          } else {
            if (res.message === "Incorrect Password") {
              setEmailError(null);
              setPasswordError(res.message);
            } else {
              setPasswordError(null);
              setEmailError(res.message);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  return (
    <form className="common-form login-form" onSubmit={handleLogin}>
      <div className="form-head">Operations Login</div>

      <input
        className="form-input"
        ref={loggedUserEmail}
        type="text"
        id="ops-email"
        name="ops-email"
        placeholder="Work Email"
      />
      {emailError && <EmailErrorModal errorMsg={emailError} />}

      <div className="ops-password-input">
        <input
          className="form-input"
          ref={loggedUserPassword}
          onChange={(e) => {
            ToolTipError(e, [
              ERROR_REGEX.PASSWORD_ERROR,
              ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
            ]);
          }}
          type="password"
          id="ops-password"
          name="ops-password"
          placeholder="Password"
        />
        <span
          className="pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            showPassword(loggedUserPassword);
            loggedUserPassword.current.focus();
          }}
        >
          <FaEye />
        </span>
      </div>

      {passwordError && <PasswordErrorModal errorMsg={passwordError} />}

      <div className="ops-login-btns-container">
        <div className="ops-form-login-text-btns">
          <Link
            className="ops-login-form-btns ops-anchor"
            to="/oppsforgotpassword"
          >
            Forgot Password
          </Link>
          <div className="form-remember-check">
            <input
              className="form-check"
              ref={rememberUser}
              type="checkbox"
              id="ops-remember"
              name="ops-remember"
            />
            <label className="form-label" htmlFor="ops-remember">
              Stay logged in
            </label>
          </div>
        </div>

        <div className="ops-login-btn">
          <button className="form-btn login-btn" type="submit">
            Login
          </button>
        </div>
      </div>
    </form>
  );
};

export { OpsLoginForm };
