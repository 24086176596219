/*
This is the reference obkjct to see how the data looks when completely filled

{
    "update_file_groups_data": {
        "file_groups": {
            "G_0": {
                "group_id": "G_0",
                "interim_output": false,
                "group": 0,
                "time": "2022-09-14T11:52:21.869Z",
                "delivery_id": "WSbatch_6588",
                "target_files": {
                    "target_file_0": {
                        "file_id": {
                            "group_id": "G_0",
                            "file_type": "target_files",
                            "file_type_id": "target_file_0"
                        },
                        "file": {},
                        "progress": "failed",
                        "uploading_chunk": 0,
                        "total_chunks": 1,
                        "upload_id": "OXmOIXSSDePVnDIbftg73QqXwylFCsEsrjrdRv.ZAxDVJJnHlsMoqTF1cEeivMTaG72jLx4ZVfqgZ.M6lJjU9QaktIUtpFzqbtO2pjO3Zoa1Dh9j2HXeY5F_EDGbtCt0",
                        "chunk_upload_urls": [
                            "https://ezdelivery1.s3.amazonaws.com/media/assignment_file/2022/09/14/WSbatch_6588/WSbatch_6588_g0/target/20210309%20EZ%20Assignment%20Delivery.pptx?uploadId=OXmOIXSSDePVnDIbftg73QqXwylFCsEsrjrdRv.ZAxDVJJnHlsMoqTF1cEeivMTaG72jLx4ZVfqgZ.M6lJjU9QaktIUtpFzqbtO2pjO3Zoa1Dh9j2HXeY5F_EDGbtCt0&partNumber=1&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT4PVRV52WIDLG3W7%2F20220914%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220914T115222Z&X-Amz-Expires=2400&X-Amz-SignedHeaders=host&X-Amz-Signature=77b7982583c77d115cce1d561526c75c4022c9e0c40524e677ad64d3ccaec739"
                        ],
                        "chunks_part_upload_info": []
                    }
                },
                "source_files": {
                    "source_file_0": {
                        "file_id": {
                            "group_id": "G_0",
                            "file_type": "source_files",
                            "file_type_id": "source_file_0"
                        },
                        "file": {},
                        "progress": "in-queue",
                        "uploading_chunk": null,
                        "total_chunks": null,
                        "upload_id": null,
                        "chunk_upload_urls": [],
                        "chunks_part_upload_info": []
                    }
                }
            }
        },
        "file_groups_ids_arr": [
            {
                "group_id": "G_0",
                "file_type": "target_files",
                "file_type_id": "target_file_0"
            },
            {
                "group_id": "G_0",
                "file_type": "source_files",
                "file_type_id": "source_file_0"
            }
        ],
        "chunk_size": 5,
        "uploading_file": 2,
        "get_links_api_url": "http://44.206.123.23:8001/api-ops/ops/v1/upload-get-url/",
        "upload_files_total_progress": "in-queue"
    },
    "combination_data": null
}

*/

export const initialState = {
  update_file_groups_data: {
    file_groups: null,
    file_groups_ids_arr: null,
    chunk_size: 10,
    uploading_file: 2,
    get_links_api_url: null,
    upload_files_total_progress: null,
  },
  combination_data: null,
};

export const UPDATE_FILES_PROGRESS_STATUS = {
  IN_QUEUE: "in-queue",
  UPLOADING: "uploading",
  FAILED: "failed",
  SUCCESS: "success",
  REJECT: "reject",
};

// creating the combination of source Files and target files to track progress for files tab
export const createUpdateGroupedFileData = (combined_data, delivery_id) => {
  let file_groups;
  let file_ids_arr = [];
  let current_time = new Date();

  // eslint-disable-next-line no-unused-vars
  combined_data.forEach((data) => {
    let file_data_group = {
      group_id: `G_${data.group_id}`,
      // this obj is made like (has target_files and source_files) this to create ui specific component for source and target
      target_files: {}, // keyname must be same as source_file_data object
      source_files: {}, // keyname must be same as target_file_data object
    };
    data.target_files?.forEach((file, idx) => {
      const target_file_id = {
        group_id: file_data_group.group_id,
        file_type: "target_files", // this must be same as file_data_group target file key
        file_type_id: `target_file_${idx}`,
      };
      file_ids_arr.push(target_file_id);
      let target_file_data = {
        ...file_data_group.target_files,
        [`target_file_${idx}`]: {
          file_id: target_file_id,
          file,
          progress: UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE,
          uploading_chunk: 0,
          total_chunks: 0,
          upload_id: null,
          chunk_upload_urls: [],
          chunks_part_upload_info: [], // It will save the "ETag" and "PartNumber" for every chunk of file and will be returned to COMBINE function
        },
      };
      file_data_group.target_files = target_file_data;
    });
    data.source_files?.forEach((file, idx) => {
      const source_file_id = {
        // this data is required to update the progress of the specific file in the state
        group_id: file_data_group.group_id,
        file_type: "source_files", // this must be same as file_data_group source file key
        file_type_id: `source_file_${idx}`,
      };
      file_ids_arr.push(source_file_id);
      let source_file_data = {
        ...file_data_group.source_files,
        [`source_file_${idx}`]: {
          // add file_data here in this object in case you want to send data to the api
          file_id: source_file_id,
          file,
          progress: UPDATE_FILES_PROGRESS_STATUS.IN_QUEUE,
          uploading_chunk: null,
          total_chunks: null,
          upload_id: null,
          chunk_upload_urls: [],
          chunks_part_upload_info: [], // It will save the "ETag" and "PartNumber" for every chunk of file and will be returned to COMBINE function
        },
      };
      file_data_group.source_files = source_file_data;
    });
    file_groups = {
      ...file_groups,
      [file_data_group.group_id]: {
        group_id: file_data_group.group_id,
        interim_output: data.interim_output,
        group: data.group_id,
        time: current_time.toISOString(),
        delivery_id: delivery_id,
        target_files: file_data_group.target_files,
        source_files: file_data_group.source_files,
      },
    };
  });
  return { file_groups, file_ids_arr };
};
