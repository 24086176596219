import React, { useContext, useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import {
  ApiContext,
  date_time_convertor,
  devEnv,
  DownloadFiles,
  emojis,
  FeedbackRatingEmoji,
} from "../../../../..";
import { getAuthData } from "../../../../../helpers/request";
import {
  OpsArchiveDashboardFileContext,
  OpsDashboardFileContext,
  OpsRouteContext,
  OPS_ROUTES,
  NotFoundErrorContext,
} from "../../../../OpsComponent";
import dummy_comments from "../../../../../archive/test_data/dummy_data-v1/dummy_comments.json";

import "./DeliveryTab.css";

export const RatingEmoji = ({ feedback }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="ops-feedback-rating-container">
      <span
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {emojis[feedback][hover ? "active" : "normal"]}
      </span>
    </div>
  );
};

const FileInfoCard = ({ activeTargetVersion }) => {
  return (
    <article className="file-info-section">
      <div className="file-info-head">
        <div className="file-info-head-title">
          Version {activeTargetVersion.version}
        </div>
        <div className="file-info-head-feedback">
          <FeedbackRatingEmoji
            feedback={activeTargetVersion.overall_feedback}
          />
        </div>
      </div>
      <div className="file-info-item" id="file-info-delivery-notes">
        <div className="file-info-item-head">Delivery Notes</div>
        <div className="file-info-item-value">
          {activeTargetVersion.delivery_notes?.map((source, idx) => {
            return (
              <div className="delivery-notes" key={idx}>
                {source.names.map((name, idx) => (
                  <span key={idx} className="delivery-notes-file-name">
                    @{name}
                  </span>
                ))}
                <span className="delivery-note">{source.notes}</span>
              </div>
            );
          })}
        </div>
      </div>
    </article>
  );
};

// ========================= File Download Card ========================

const TargetDownloadBtn = ({ ext, link, name }) => {
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const DownloadTarget = () => {
    const download_file_name =
      name.split(".").slice(0, -1).join(".") + "." + ext; // making the file name with the extention provided
    DownloadFiles([{ name: download_file_name, link: link }]);
    try {
      DownloadFiles([{ name: download_file_name, link: link }]);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <span className="file-info-file-download-btn" onClick={DownloadTarget}>
      <span>{ext.toUpperCase()}</span>
      <span>
        <FaDownload />
      </span>
    </span>
  );
};

const TargetFile = ({ file, idx, feedbackFiles, setFeedbackFiles }) => {
  const route = useContext(OpsRouteContext);
  const [isChecked, setIsChecked] = useState(false);

  const addFile = () => {
    setFeedbackFiles([...feedbackFiles, file]);
  };

  const removeFile = () => {
    let removedFileIdx = feedbackFiles.indexOf(file);
    if (removedFileIdx > -1) {
      // checking if the file even exists in the array of files.
      setFeedbackFiles((prevFiles) => {
        let new_arr = prevFiles.slice(); // using a copied array bcz if you use splice on prevFile then it would remove the file on the UI as well.
        new_arr.splice(removedFileIdx, 1);
        return new_arr;
      });
    }
  };

  const handleOnChange = () => {
    setIsChecked((prev) => {
      let checked = !prev;
      if (checked) {
        addFile(); // adding a file if checked
      } else {
        removeFile(); // removing a file if unchecked
      }
      return checked;
    });
  };

  useEffect(() => {
    feedbackFiles.includes(file) ? setIsChecked(true) : setIsChecked(false);
  }, [feedbackFiles]);

  return (
    <div className="file-info-file">
      <div className="file-info-file-name">
        {route === OPS_ROUTES.ASSIGNMENTS && (
          <input
            type="checkbox"
            value={idx}
            checked={isChecked}
            onChange={handleOnChange}
          />
        )}
        <div>{file.name}</div>
      </div>

      {route === OPS_ROUTES.ASSIGNMENTS && (
        <div className="file-info-file-download">
          {file?.links &&
            Object.entries(file.links).map(([ext, link], idx) => {
              return (
                <TargetDownloadBtn
                  key={idx}
                  ext={ext}
                  link={link}
                  name={file.name}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

const FileDownload = ({
  activeTargetVersion,
  feedbackFiles,
  setFeedbackFiles,
}) => {
  const route = useContext(OpsRouteContext);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleOnChange = () => {
    setIsAllChecked((prev) => {
      let checked = !prev;
      if (checked) {
        setFeedbackFiles(activeTargetVersion.files);
      } else {
        setFeedbackFiles([]);
      }
      return checked;
    });
  };

  useEffect(() => {
    feedbackFiles.length === activeTargetVersion.files.length
      ? setIsAllChecked(true)
      : setIsAllChecked(false);
  }, [feedbackFiles]);

  return (
    <article>
      <div className="file-info-item" id="file-info-files-list">
        <div className="file-info-item-head">
          <div className="file-info-file-name">
            {route === OPS_ROUTES.ASSIGNMENTS && (
              <input
                type="checkbox"
                name="all-files"
                value="all"
                checked={isAllChecked}
                onChange={handleOnChange}
              />
            )}
            <span>Files</span>
          </div>
          {route === OPS_ROUTES.ASSIGNMENTS && (
            <span className="file-info-file-download-btn">
              <span>Download All</span>
              <span>
                <FaDownload />
              </span>
            </span>
          )}
        </div>
        <div className="file-info-item-value" id="file-info-files-container">
          {activeTargetVersion?.files.map((file, idx) => {
            return (
              <TargetFile
                key={idx}
                file={file}
                idx={idx}
                feedbackFiles={feedbackFiles}
                setFeedbackFiles={setFeedbackFiles}
              />
            );
          })}
        </div>
      </div>
    </article>
  );
};

// =========================== Feedback Card ===========================

// const FileFeedbackForm = ({feedbackFiles,setFeedbackFiles}) => {
//     const {OpsDisplayedFile,setClientDisplayedFile} = useContext(OpsDashboardFileContext);
//     const assignmentId = OpsDisplayedFile.batch_id;
//     const API_URL = useContext(ApiContext);
//     const {setNotFoundError} = useContext(NotFoundErrorContext);
//     const [ratingEmptyError,setRatingEmptyError] = useState(false);

//     const commentsRef = useRef();

//     const [qualityOpt,setQualityOpt] = useState(null)

//     const [serviceOpt,setServiceOpt] = useState(null)

//     const [feedbackState, setFeedbackState] = useState({
//         quality: 0,
//         service: 0
//     })

//     const setValues = ()=>{
//         switch (feedbackState.quality) {
//             case Object.keys(emojis)[0]:
//                 setQualityOpt("Positive")
//                 break;
//             case Object.keys(emojis)[1]:
//                 setQualityOpt("Neutral")
//                 break;
//             case Object.keys(emojis)[2]:
//                 setQualityOpt("Negative")
//                 break;
//             default:
//                 setQualityOpt(FEEDBACK_EMOTIONS.EMPTY)
//                 break;
//         }

//         switch (feedbackState.service) {
//             case Object.keys(emojis)[0]:
//                 setServiceOpt("Positive")
//                 break;
//             case Object.keys(emojis)[1]:
//                 setServiceOpt("Neutral")
//                 break;
//             case Object.keys(emojis)[2]:
//                 setServiceOpt("Negative")
//                 break;
//             default:
//                 setServiceOpt(FEEDBACK_EMOTIONS.EMPTY)
//                 break;
//         }

//     }

//     useEffect(()=>{                         // This clears the selection if some other file us selected
//         setFeedbackState((prev) => {
//             return {...prev,quality: FEEDBACK_EMOTIONS.EMPTY, service: FEEDBACK_EMOTIONS.EMPTY}
//         })
//         setValues();
//     },[OpsDisplayedFile])

//     useEffect(setValues,[feedbackState])    // This updates the Remarks and Overall values on real time

//     const sendData = (data) => {
//         postAuthData(`${API_URL}/api/client/v1/submit-feedback/`, getAccessLogData(data))
//         .then((res) => {
//             if (res.success === true) {
//                 getAuthData(`${API_URL}/api/client/v1/all-assignments/${OpsDisplayedFile?.id}/`)
//                 .then((res) => {
//                     if (res.success === true) {
//                         setClientDisplayedFile(res.last_assignment)
//                     }
//                 })
//                 .catch(err => {
//                     setNotFoundError(true);
//                 })
//             }
//         })
//         .catch(err => {
//             setNotFoundError(true);
//         })
//     }

//     const clearFeedbackSelections = () => {
//         setFeedbackFiles([]);   // removing feedback files from the list
//         commentsRef.current.value = "";     // Clearing Comments
//         setFeedbackState({quality:0,service:0})
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const data = {comments: commentsRef.current.value, batch_id:assignmentId, ...feedbackState};
//         const feedback_data = feedbackFiles.map(({name})=>{return {...data,name:name}});    // Attaching feedback values to the feedback files
//         // Validation check
//         if (data.quality===FEEDBACK_EMOTIONS.EMPTY && data.service===FEEDBACK_EMOTIONS.EMPTY){
//             setRatingEmptyError("Please fill one rating.")
//         }  else {
//             setRatingEmptyError(false)
//             console.log(feedback_data)
//             clearFeedbackSelections();
//             // sendData(data);
//         }
//     }

//     return (
//         <article className='file-feedback-section'>
//                 <form onSubmit={handleSubmit}>

//                     <div className="ops-feedback-input">
//                         <div className='ops-message-feedback'>
//                             <div id="ops-feedback-comment">
//                                 {/* <label className="ops-feedback-label" id="comments-ops-feedback-label" htmlFor="commentsFeedback">Would you like to add any comments?</label> */}
//                                 <textarea className="ops-dashboard-input ops-feedback-comment" ref={commentsRef} id='commentsFeedback' name='commentsFeedback' placeholder="Type Here.."   />
//                             </div>
//                             <MdSend className="ops-feedback-submit-btn" type="submit" onClick={handleSubmit}/>
//                         </div>
//                     </div>

//                 </form>

//         </article>
//     )
// }

// ======================= COMMENTS Section ===========================
const COMMENTS_TYPE = {
  NOTIFICATION: "notification",
  SEND: "send",
  RECEIVE: "receive",
  FEEDBACK: "feedback",
};

const EmptyComment = ({ message }) => {
  return (
    <div>{message || "There are no Comments yet on this assignment!"}</div>
  );
};

const UnknownComment = () => {
  return (
    <div className="comment-msg comment-msg-unknown glass-container">
      An unknown type comment received.
    </div>
  );
};

const FeedbackComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-feedback glass-container">
      <div className="comment-msg-user">{comment.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename}
      </div>
      <div className="comment-msg-feedback-container">
        <div className="comment-feedback-quality comment-msg-service-feedback">
          <span className="comment-feedback-quality-label">
            Quality of Service
          </span>
          <FeedbackRatingEmoji feedback={comment?.message?.service} />
        </div>
        <div className="comment-feedback-quality comment-msg-output-feedback">
          <span className="comment-feedback-quality-label">
            Quality of Output
          </span>
          <FeedbackRatingEmoji feedback={comment?.message?.output} />
        </div>
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.comments}</div>
    </div>
  );
};

const SentComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-chat comment-msg-sent glass-container">
      <div className="comment-msg-user">{comment?.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const ReceivedComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-chat comment-msg-receive glass-container">
      <div className="comment-msg-user">{comment?.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const NotificationComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-notification glass-container">
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const CommentsSection = ({ displayedFile }) => {
  const API_URL = useContext(ApiContext);
  const [comments, setComments] = useState(null);
  const [fetching, setFetching] = useState(null);

  useEffect(() => {
    setFetching("Loading Comments..."); // Setting a loader

    if (devEnv) {
      setFetching(null);
      setComments(dummy_comments.comments); // Setting Dummy Data
      return;
    }

    // Re-rendering to comments
    displayedFile?.id &&
      getAuthData(
        `${API_URL}/api-ops/archive/v1/feedback/${displayedFile?.id}/`,
      )
        .then((res) => {
          if (res.success === true) {
            setComments(res.data);
            setFetching(null); // must be below setting comments
          }
        })
        .catch(() => {
          setFetching("Can't fetch comments!");
        });
  }, [displayedFile]);

  return (
    <div className="comments-section">
      {/* {(fetching) && <span>{fetching}</span>} */}
      {comments ? (
        comments?.map((comment, idx) => {
          switch (comment.type) {
            case COMMENTS_TYPE.FEEDBACK:
              return <FeedbackComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.SEND:
              return <SentComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.RECEIVE:
              return <ReceivedComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.NOTIFICATION:
              return <NotificationComment key={idx} comment={comment} />;
            default:
              return <UnknownComment key={idx} comment={comment} />;
          }
        })
      ) : (
        <EmptyComment message={fetching} />
      )}
    </div>
  );
};

const FileFeedback = ({ displayedFile, feedbackFiles }) => {
  // Donwloads based on file stage but in ops it doesnt matter HISTORY TAB and DETAILS TAB
  return (
    <article className="file-feedback-section">
      <div className="file-info-item" id="file-info-delivery-notes">
        <div className="file-info-item-head">Feedback</div>

        <CommentsSection
          displayedFile={displayedFile}
          feedbackFiles={feedbackFiles}
        />
      </div>
    </article>
  );
};

// ======================= Delivery tab UI ==================================

const DeliveryTab = ({ file }) => {
  const [activeTargetVersion, setActiveTargetVersion] = useState(
    file.target_versions.find((cur) => cur.version == file.current_version),
  );
  const [feedbackFiles, setFeedbackFiles] = useState([]);

  const [displayedFile, setDisplayedFile] = useState(file);
  const route = useContext(OpsRouteContext);
  const { OpsDisplayedFile } = useContext(OpsDashboardFileContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );

  useEffect(() => {
    if (route === OPS_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(OpsDisplayedFile);
      setActiveTargetVersion(
        OpsDisplayedFile.target_versions.find(
          (cur) => cur.version == OpsDisplayedFile.current_version,
        ),
      );
    } else if (route === OPS_ROUTES.ARCHIVE) {
      setDisplayedFile(opsArchiveDisplayedFile);
      setActiveTargetVersion(
        opsArchiveDisplayedFile.target_versions.find(
          (cur) => cur.version == opsArchiveDisplayedFile.current_version,
        ),
      );
    }
  }, [OpsDisplayedFile, opsArchiveDisplayedFile]);

  useEffect(() => {
    setFeedbackFiles([]);
  }, [OpsDisplayedFile?.id, opsArchiveDisplayedFile?.id]);

  useEffect(() => {
    setActiveTargetVersion(
      file.target_versions.find((cur) => cur.version == file.current_version),
    );
    setFeedbackFiles([]);
  }, []);

  // useEffect(() => {
  //   setFeedbackFiles([])
  // }, [OpsDisplayedFile,opsArchiveDisplayedFile]);  // This clears the selection if some other file us selected

  return (
    <section className="delivery-tab-section card-layout">
      <FileInfoCard
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
      />
      <FileDownload
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
        feedbackFiles={feedbackFiles}
        setFeedbackFiles={setFeedbackFiles}
      />
      <FileFeedback
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
        feedbackFiles={feedbackFiles}
        setFeedbackFiles={setFeedbackFiles}
      />
    </section>
  );
};

export default DeliveryTab;
