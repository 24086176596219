import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
  getAuthData,
  postAuthData,
  putAuthData,
} from "../../../helpers/request";
import { MODAL_TOGGLE } from "../entityData/common";
import { getLiveAllClientsData } from "../liveAllClientsData/liveAllClientsDataSlice";
import { getLiveClientData } from "../liveClientData/liveClientDataSlice";
import {
  createClientListOptionsArr,
  createClientOptionsArr,
  createOptionValue,
  initialState,
} from "./common";

// temp changes
// const token = "jbDLY7vcpZuGRdA8j3mmnlvkdEiFIf";

export const getExistingClients = createAsyncThunk(
  "client-data/getExistingClients",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/default-clients`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        // console.log(res.data);
        return createClientListOptionsArr(res.data);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getClientCode = createAsyncThunk(
  "client-data/getClientCode",
  async ({ clientCode }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/generate-client-code/?com=${clientCode}`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return res.client_code;
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const editCompanyCode = createAsyncThunk(
  "client-data/editCompanyCode",
  async ({ companyCode }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/verify-client-code/?cc=${companyCode}`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        if (!res.valid) {
          toast.error(res.message);
        } else if (res.valid) {
          toast.error("Company Code updated successfully!");
        }
      } else {
        toast.error(res.message + ". Please enter new company code");
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getClientCountryOptions = createAsyncThunk(
  "client-data/getClientCountryOptions",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_API_URL}/api-req/requester/v1/country/`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return createClientOptionsArr(res.country);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getClientCityOptions = createAsyncThunk(
  "client-data/getClientCityOptions",
  async ({ country = "India" }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_API_URL}/api-req/requester/v1/country/${country}`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return createClientOptionsArr(res.city);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getClientUserCityOptions = createAsyncThunk(
  "client-data/getClientUserCityOptions",
  async ({ country = "India" }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_API_URL}/api-req/requester/v1/country/${country}`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return createClientOptionsArr(res.city);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const postClientCreateFormData = createAsyncThunk(
  "client-data/postClientCreateFormData",
  async ({ data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/company/`;
    try {
      const res = await postAuthData(url, data);
      if (res.success) {
        thunkAPI.dispatch(resetClientFormData());
        thunkAPI.dispatch(getLiveClientData({ id: res?.data?.id }));
        thunkAPI.dispatch(getLiveAllClientsData({}));
        thunkAPI.dispatch(navigateToDashboard());
        return res;
      } else {
        // console.log(res?.message);
        toast.error(res?.message);
        return thunkAPI.rejectWithValue({ err: "Failed to create Client." });
      }
    } catch (err) {
      return { err: "Failed to create Client." };
    }
  },
);

export const putClientEditFormData = createAsyncThunk(
  "client-data/putClientEditFormData",
  async ({ id, data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/company/${id}/`;
    try {
      const res = await putAuthData(url, data);
      if (res.success) {
        thunkAPI.dispatch(resetClientEditFormData());
        thunkAPI.dispatch(getLiveClientData({ id: res?.data?.id }));
        thunkAPI.dispatch(getLiveAllClientsData({}));
        return res;
      } else {
        // console.log(res?.message);
        toast.error(res?.message);
        return thunkAPI.rejectWithValue({ err: "Failed to eidt Client." });
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ err: "Failed to edit Client." });
    }
  },
);

const clientDataSlice = createSlice({
  name: "client-data",
  initialState,
  reducers: {
    // ============ Reset Cient Details Form ================== //
    resetClientFormData: (state) => {
      state.client_form_data = initialState.client_form_data;
    },
    resetClientEditFormData: (state) => {
      state.edit_client_data = initialState.edit_client_data;
    },
    // **************** Client Form ******************** //
    // =================== Client Details =================== //
    toggleNoteCheckbox: (state) => {
      if (state.client_form_data.is_note_understand) {
        state.client_form_data.is_note_understand = false;
      } else if (!state.client_form_data.is_note_understand) {
        state.client_form_data.is_note_understand = true;
      }
    },
    toggleInstructionCheckbox: (state) => {
      if (state.client_form_data.is_instruction_understand) {
        state.client_form_data.is_instruction_understand = false;
      } else if (!state.client_form_data.is_instruction_understand) {
        state.client_form_data.is_instruction_understand = true;
      }
    },
    toggleCopyAddressCheckbox: (state) => {
      if (state.client_form_data.is_copy_address) {
        state.client_form_data.is_copy_address = false;
        state.client_form_data.user_data.user_country =
          initialState.client_form_data.user_data.user_country;
        state.client_form_data.user_data.user_city =
          initialState.client_form_data.entity_data.entity_city;
      } else if (!state.client_form_data.is_copy_address) {
        state.client_form_data.is_copy_address = true;
        state.client_form_data.user_data.user_country =
          state.client_form_data.entity_data.entity_country;
        state.client_form_data.user_data.user_city =
          state.client_form_data.entity_data.entity_city;
      }
    },
    addCompanyName: (state, { payload }) => {
      state.client_form_data.client_data.client_name = payload;
      if (payload.__isNew__) {
        state.client_form_data.client_data.client_domain_list = [];
        state.client_form_data.client_data.client_code.value = null;
      }
      if (payload.id) {
        state.client_form_data.client_data.client_id = payload?.id;
      }
      if (payload.domain) {
        state.client_form_data.client_data.client_domain_list = [];
        payload?.domain?.forEach((value) => {
          let val = state.client_form_data.client_data.client_domain_list.some(
            (cur) => cur.label === value,
          );

          if (!val) {
            state.client_form_data.client_data.client_domain_list = [
              ...state.client_form_data.client_data.client_domain_list,
              createOptionValue(value),
            ];
          }

          state.client_form_data.client_data.client_domain_list = [
            ...state.client_form_data.client_data.client_domain_list,
          ];
        });
      }
      if (payload.code) {
        state.client_form_data.client_data.client_code.value = payload?.code;
      }
    },
    addClientCode: (state, { payload }) => {
      state.client_form_data.client_data.client_code.value = payload;
    },
    addClientDomains: (state, { payload }) => {
      state.client_form_data.client_data.client_domain_list = payload;
    },

    // =================== Client Entity Details =================== //
    addClientEntityName: (state, { payload }) => {
      state.client_form_data.entity_data.entity_name = payload;
    },
    addClientEntityAddressBuilding: (state, { payload }) => {
      state.client_form_data.entity_data.address_1 = payload;
    },
    addClientEntityAddressStreet: (state, { payload }) => {
      state.client_form_data.entity_data.address_2 = payload;
    },
    addClientEntityAddressZip: (state, { payload }) => {
      state.client_form_data.entity_data.entity_zip_code = payload;
    },
    addClientEntityAddressCountry: (state, { payload }) => {
      state.client_form_data.entity_data.entity_country = payload;
      state.client_form_data.entity_data.entity_city = null;
    },
    addClientEntityAddressCity: (state, { payload }) => {
      state.client_form_data.entity_data.entity_city = payload;
    },

    // =================== Client User Details =================== //
    addClientUserIndustry: (state, { payload }) => {
      state.client_form_data.user_data.user_industry =
        createOptionValue(payload);
    },
    addClientUserName: (state, { payload }) => {
      state.client_form_data.user_data.user_name = payload;
    },
    addClientUserEmail: (state, { payload }) => {
      state.client_form_data.user_data.user_email = payload;
    },
    addClientUserPhone: (state, { payload }) => {
      state.client_form_data.user_data.user_phone_no = payload;
    },
    addClientUserAddressCountry: (state, { payload }) => {
      state.client_form_data.user_data.user_country = payload;
      state.client_form_data.user_data.user_city = null;
    },
    addClientUserAddressCity: (state, { payload }) => {
      state.client_form_data.user_data.user_city = payload;
    },
    addClientUserDesignation: (state, { payload }) => {
      state.client_form_data.user_data.user_designation = payload;
    },
    addClientUserJobFunc: (state, { payload }) => {
      state.client_form_data.user_data.user_job_function =
        createOptionValue(payload);
    },
    // ====================== Edit Client Details ============== //
    openClientEditModal: (state) => {
      state.edit_client_modal = MODAL_TOGGLE.OPEN;
    },
    closeClientEditModal: (state) => {
      state.edit_client_modal = MODAL_TOGGLE.CLOSE;
    },
    setClientEditFormData: (state, { payload }) => {
      state.edit_client_data.edit_client_name = payload.company_name;
      state.edit_client_data.edit_client_id = payload.id;
      state.edit_client_data.edit_client_code = payload.client_code;
      state.edit_client_data.edit_client_domain_list = [];
      payload?.domains
        ?.filter((element) => element !== null)
        ?.forEach((value) => {
          let val = state.edit_client_data.edit_client_domain_list.some(
            (cur) => cur.label === value,
          );
          if (!val) {
            state.edit_client_data.edit_client_domain_list = [
              ...state.edit_client_data.edit_client_domain_list,
              createOptionValue(value),
            ];
          }
          state.edit_client_data.edit_client_domain_list = [
            ...state.edit_client_data.edit_client_domain_list,
          ];
        });
    },
    addEditClientName: (state, { payload }) => {
      state.edit_client_data.edit_client_name = payload;
    },
    addEditClientDomains: (state, { payload }) => {
      state.edit_client_data.edit_client_domain_list = payload;
    },
    navigateToDashboard: () => {
      window.location.href = "/requester";
    },
  },
  extraReducers: {
    // Fetching Country Options //
    [getClientCountryOptions.pending]: (state) => {
      state.client_form_data.form_options.entity_country.loading = true;
      state.client_form_data.form_options.user_country.loading = true;
    },
    [getClientCountryOptions.fulfilled]: (state, { payload }) => {
      state.client_form_data.form_options.entity_country.loading = false;
      state.client_form_data.form_options.entity_country.list = payload;
      state.client_form_data.form_options.user_country.loading = false;
      state.client_form_data.form_options.user_country.list = payload;
    },
    [getClientCountryOptions.rejected]: (state, action) => {
      state.client_form_data.form_options.entity_country.loading = false;
      state.client_form_data.form_options.entity_country.error = action.message;
      state.client_form_data.form_options.user_country.loading = false;
      state.client_form_data.form_options.user_country.error = action.message;
    },
    // Fetching City Options //
    // for entity //
    [getClientCityOptions.pending]: (state) => {
      state.client_form_data.form_options.entity_city.loading = true;
    },
    [getClientCityOptions.fulfilled]: (state, { payload }) => {
      state.client_form_data.form_options.entity_city.loading = false;
      state.client_form_data.form_options.entity_city.list = payload;
    },
    [getClientCityOptions.rejected]: (state, action) => {
      state.client_form_data.form_options.entity_city.loading = false;
      state.client_form_data.form_options.entity_city.error = action.message;
    },
    // for user //
    [getClientUserCityOptions.pending]: (state) => {
      state.client_form_data.form_options.user_city.loading = true;
    },
    [getClientUserCityOptions.fulfilled]: (state, { payload }) => {
      state.client_form_data.form_options.user_city.loading = false;
      state.client_form_data.form_options.user_city.list = payload;
    },
    [getClientUserCityOptions.rejected]: (state, action) => {
      state.client_form_data.form_options.user_city.loading = false;
      state.client_form_data.form_options.user_city.error = action.message;
    },
    // Fetching Client code //
    [getClientCode.pending]: (state) => {
      state.client_form_data.client_data.client_code.loading = true;
    },
    [getClientCode.fulfilled]: (state, { payload }) => {
      state.client_form_data.client_data.client_code.loading = false;
      state.client_form_data.client_data.client_code.value = payload;
    },
    [getClientCode.rejected]: (state, action) => {
      state.client_form_data.client_data.client_code.loading = false;
      state.client_form_data.client_data.client_code.error = action.message;
    },
    [editCompanyCode.pending]: (state) => {
      state.client_form_data.client_data.client_code.loading = true;
    },
    [editCompanyCode.fulfilled]: (state) => {
      state.client_form_data.client_data.client_code.loading = false;
      // state.company_form_data.company_code = payload;
    },
    [editCompanyCode.rejected]: (state, action) => {
      state.client_form_data.client_data.client_code.loading = false;
      state.client_form_data.client_data.client_code.error = action.message;
    },
    // fetching clients //
    [getExistingClients.pending]: (state) => {
      state.client_form_data.form_options.existing_clients.loading = true;
    },
    [getExistingClients.fulfilled]: (state, { payload }) => {
      state.client_form_data.form_options.existing_clients.loading = false;
      state.client_form_data.form_options.existing_clients.list = payload;
    },
    [getExistingClients.rejected]: (state, action) => {
      state.client_form_data.form_options.existing_clients.loading = false;
      state.client_form_data.form_options.existing_clients.error =
        action.message;
    },
    // post client create data //
    [postClientCreateFormData.pending]: (state) => {
      state.client_form_data.loading = true;
    },
    [postClientCreateFormData.fulfilled]: (state) => {
      state.client_form_data.loading = false;
      state.client_form_data = initialState.client_form_data;
    },
    [postClientCreateFormData.rejected]: (state, action) => {
      state.client_form_data.loading = false;
      state.client_form_data.error = action.message;
    },
    // put client edit data //
    [putClientEditFormData.pending]: (state) => {
      state.edit_client_data.loading = true;
    },
    [putClientEditFormData.fulfilled]: (state) => {
      state.edit_client_data.loading = false;
      state.edit_client_data = initialState.edit_client_data;
      // dispatch(closeClientEditModal());
    },
    [putClientEditFormData.rejected]: (state, { payload }) => {
      state.edit_client_data.loading = false;
      state.edit_client_data.error = { payload }.message;
    },
  },
});

export const {
  // reset form data //
  resetClientFormData,
  // client form //
  toggleInstructionCheckbox,
  toggleNoteCheckbox,
  toggleCopyAddressCheckbox,
  addCompanyName,
  addClientCode,
  addClientDomains,
  addClientEntityName,
  addClientEntityAddressBuilding,
  addClientEntityAddressStreet,
  addClientEntityAddressZip,
  addClientEntityAddressCountry,
  addClientEntityAddressCity,
  addClientUserIndustry,
  addClientUserName,
  addClientUserEmail,
  addClientUserPhone,
  addClientUserAddressCountry,
  addClientUserAddressCity,
  addClientUserDesignation,
  addClientUserJobFunc,
  openClientEditModal,
  closeClientEditModal,
  setClientEditFormData,
  resetClientEditFormData,
  addEditClientName,
  addEditClientDomains,
  navigateToDashboard,
} = clientDataSlice.actions;

export default clientDataSlice.reducer;
