import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAuthData,
  postAuthData,
  putAuthData,
} from "../../../helpers/request";
import { TAB_CHOICE } from "../../../utils/CustomInputs/nameSpaces";
import roundNum from "../../../utils/roundNumber";
import {
  createOptionsArr,
  createOptionValue,
  createSubServiceOptionsArr,
  createSubServiceOptionValue,
  entity_item_details_data,
  initialState,
  MODAL_TOGGLE,
  tax_options,
  price_per_unit_option,
} from "./common";
import { CLIENT_USER_TYPE } from "../../../utils/externalClientUsers";
// import { daysNum } from "../../../utils/dateTimeConverter";
import { getLiveAllClientsData } from "../liveAllClientsData/liveAllClientsDataSlice";
import getUTCDate from "../../../utils/getUTCDate";
import getUTCDateRange from "../../../utils/getUTCDateRange";
// setting up other state values

// temp changes
// const token = "jbDLY7vcpZuGRdA8j3mmnlvkdEiFIf";

export const getEntityItemDetails = createAsyncThunk(
  "entity-data/getEntityItemDetails",
  async ({ url = "api-ops/client/v1/entity", id }, { rejectWithValue }) => {
    let get_entity_url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/${url}/${id}/`;
    try {
      const res = await getAuthData(get_entity_url); //temp changes
      if (res.success) {
        return res;
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getCountryOptions = createAsyncThunk(
  "entity-data/getCountryOptions",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/country/`;
    try {
      const res = await getAuthData(url); //temp changes
      if (res.success) {
        return createOptionsArr(res.country);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getCityOptions = createAsyncThunk(
  "entity-data/getCityOptions",
  async ({ country = "India" }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/country/${country}`;
    try {
      const res = await getAuthData(url); //temp changes
      if (res.success) {
        return createOptionsArr(res.city);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getCurrencyOptions = createAsyncThunk(
  "entity-data/getCurrencyOptions",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/country/`;
    try {
      const res = await getAuthData(url); //temp changes
      if (res.success) {
        return createOptionsArr(res.currency);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getUpdateCurrency = createAsyncThunk(
  "entity-data/getUpdateCurrency",
  async ({ id, currency }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/update-currency/${id}/?currency=${currency}`;
    try {
      const res = await getAuthData(url); //temp changes
      if (res.success) {
        return res;
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

// api-req/requester/v1/update-currency/26/?currency=USD

export const getTaxTypeOptions = createAsyncThunk(
  "entity-data/getTaxTypeOptions",
  async ({ rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/country/`;
    try {
      const res = await getAuthData(url); //temp changes
      if (res.success) {
        return createOptionsArr(res.tax_type);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getServiceOptions = createAsyncThunk(
  "entity-data/getServiceOptions",
  async ({ data }, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/service/`;
    try {
      const res = await postAuthData(url, data); //temp changes
      if (res.success) {
        return createOptionsArr(res.data);
      } else {
        return rejectWithValue(res.message);
      }
    } catch (err) {
      return err;
    }
  },
);

export const getSubServiceOptions = createAsyncThunk(
  "entity-data/getSubServiceOptions",
  async ({ id, service }, { rejectWithValue }) => {
    let serviceDataObj = { service: service };
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/service/`;
    try {
      const res = await postAuthData(url, serviceDataObj); // temp changes
      if (res.success) {
        return { idx: id, res: createSubServiceOptionsArr(res.data) };
      } else {
        return rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

export const getListPrice = createAsyncThunk(
  "entity-data/getListPrice",
  async ({ id }, thunkAPI) => {
    let sub_service =
      thunkAPI.getState().entityData.entity_form_data.price_per_unit[id]
        .sub_service.selected_option.value;
    let url = `${
      process.env.REACT_APP_NEW_REQUESTER_API_URL
    }/api-req/requester/v1/service/${sub_service}/?currency=${
      thunkAPI.getState().entityData.entity_form_data.contract.currency?.value
    }`;
    try {
      const res = await getAuthData(url); //temp changes
      if (res.success) {
        return { idx: id, res: res.list_price };
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

export const postEntityCreateFormData = createAsyncThunk(
  "entity-data/postEntityCreateFormData",
  async ({ data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/entity/`;
    try {
      const res = await postAuthData(url, data); // temp changes
      if (res.success) {
        thunkAPI.dispatch(resetEntityTab()); // reseting the whole state to initial state after submit
        thunkAPI.dispatch(resetEntityItemDetails()); // reseting the list item details after submit
        thunkAPI.dispatch(resetEntityFormData()); // reseting the entity form after submit
        thunkAPI.dispatch(
          getEntityList({
            id: thunkAPI.getState().liveClientData.requester_details.id,
          }),
        );
        thunkAPI.dispatch(getLiveAllClientsData({}));
        return res;
      } else {
        return thunkAPI.rejectWithValue({ err: "Failed to create entity." });
      }
    } catch (err) {
      return { err: "Failed to create entity." };
    }
  },
);

export const putEntityEditFormData = createAsyncThunk(
  "entity-data/putEntityEditFormData",
  async ({ entity_id, data }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/entity/${entity_id}/`;
    try {
      const res = await putAuthData(url, data); // temp changes
      if (res.success) {
        thunkAPI.dispatch(resetEntityTab()); // reseting the whole state to initial state after submit
        thunkAPI.dispatch(resetEntityFormData()); //resetting form after submit
        thunkAPI.dispatch(resetEntityItemDetails()); //resetting item details to fetch updated entity details.
        // thunkAPI.dispatch(
        //   getEntityListItemDetails({
        //     id: thunkAPI.getState().entityData.entity_form_data.entity_id,
        //   }),
        // ); //fetching updated values after editing entity
        return res;
      } else {
        return thunkAPI.rejectWithValue({ err: "Failed to update entity." });
      }
    } catch (err) {
      return { err: "Failed to create entity." };
    }
  },
);

export const getEditEntityInfo = createAsyncThunk(
  "entity-data/getEditEntityInfo",
  async ({ id, updateVal }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/entity/${id}/?is_update=${updateVal}`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        return res?.entity;
      } else {
        return thunkAPI.rejectWithValue({ err: res?.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

// ================ Entity List ================ //

export const getEntityList = createAsyncThunk(
  "entity-data/getEntityList",
  async ({ id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/entity/?cid=${id}`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        thunkAPI.dispatch(resetEntityItemDetails());
        return res;
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

export const getEntityListItemDetails = createAsyncThunk(
  "entity-data/getEntityListItemDetails",
  async ({ id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_NEW_REQUESTER_API_URL}/api-req/requester/v1/entity/${id}`;
    try {
      const res = await getAuthData(url); // temp changes
      if (res.success) {
        // thunkAPI.dispatch(resetEntityItemDetails());
        return { idx: id, res: res?.entity };
      } else {
        return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      }
    } catch (err) {
      return { idx: id, err: err };
    }
  },
);

// #################### SLICE #############################

const entityDataSlice = createSlice({
  name: "entity-data",
  initialState,
  reducers: {
    // TAB SWITCH
    toggleEntityTab: (state) => {
      if (state.tab === TAB_CHOICE.FORM) {
        state.tab = TAB_CHOICE.LIST;
      } else if (state.tab === TAB_CHOICE.LIST) {
        state.tab = TAB_CHOICE.FORM;
      }
    },
    openEntityForm: (state) => {
      state.tab = TAB_CHOICE.FORM;
    },
    openEntityList: (state) => {
      state.tab = TAB_CHOICE.LIST;
    },
    openEntityEditForm: (state, { payload }) => {
      state.tab = TAB_CHOICE.FORM;
      state.edit_entity_id = payload.id;
    },
    // toggle modal
    togglePreviewModal: (state) => {
      if (state.pdf_modal === MODAL_TOGGLE.OPEN) {
        state.pdf_modal = MODAL_TOGGLE.CLOSE;
      } else if (state.pdf_modal === MODAL_TOGGLE.CLOSE) {
        state.pdf_modal = MODAL_TOGGLE.OPEN;
      }
    },
    openPreviewFileModal: (state) => {
      state.pdf_modal = MODAL_TOGGLE.OPEN;
    },
    closePreviewFileModal: (state) => {
      state.pdf_modal = MODAL_TOGGLE.CLOSE;
    },
    // toggle list
    toggleEntityLIstCard: (state, { payload }) => {
      if (state.entity_list_data.list[payload.idx]?.open) {
        state.entity_list_data.list[payload.idx].open = false;
        state.detail_entity_id = null;
      } else if (!state.entity_list_data.list[payload.idx]?.open) {
        state.entity_list_data.list[payload.idx].open = true;
        state.detail_entity_id = payload.idx;
      }
    },
    openEntityListCard: (state) => {
      state.list = MODAL_TOGGLE.OPEN;
    },
    closeEntityListCard: (state) => {
      state.list = MODAL_TOGGLE.CLOSE;
    },
    // RESET DATA
    resetEntityFormData: (state) => {
      state.entity_form_data = initialState.entity_form_data;
    },
    resetEntityTab: () => initialState,
    // ============== toggle input valid ==============
    checkValidInput: (state, { payload }) => {
      state.entity_form_valid_data[payload.key] = payload.val;
    },
    // ================= Entity Details ===========================
    addEntityName: (state, { payload }) => {
      state.entity_form_data.entity_details.billing_info.entity_name = payload;
    },
    addEntityAddressBuilding: (state, { payload }) => {
      state.entity_form_data.entity_details.billing_info.address.building_name =
        payload;
    },
    addEntityAddressStreet: (state, { payload }) => {
      state.entity_form_data.entity_details.billing_info.address.street =
        payload;
    },
    addEntityAddressZip: (state, { payload }) => {
      state.entity_form_data.entity_details.billing_info.address.zip = payload;
    },
    addEntityAddressCountry: (state, { payload }) => {
      state.entity_form_data.entity_details.billing_info.address.country =
        payload;
      state.entity_form_data.entity_details.billing_info.address.city.selected_option =
        null;
    },
    addEntityAddressCity: (state, { payload }) => {
      // if (
      //   !state.entity_form_data.entity_details.billing_info.address.country
      //     ?.value
      // ) {
      state.entity_form_data.entity_details.billing_info.address.city.selected_option =
        payload;
      // } else {
      //   state.entity_form_data.entity_details.billing_info.address.city.selected_option.value =
      //     null;
      // }
    },
    addCRNNumber: (state, { payload }) => {
      state.entity_form_data.entity_details.crn_details.number = payload;
    },
    addCRNValidTill: (state, { payload }) => {
      state.entity_form_data.entity_details.crn_details.valid_till.value =
        getUTCDate(payload);
    },
    toggleCRNCalendarShow: (state, { payload }) => {
      state.entity_form_data.entity_details.crn_details.valid_till.calendar_show =
        payload ||
        !state.entity_form_data.entity_details.crn_details.valid_till
          .calendar_show;
    },
    addTRNNumber: (state, { payload }) => {
      state.entity_form_data.entity_details.trn_details.number = payload;
    },
    addTRNValidTill: (state, { payload }) => {
      state.entity_form_data.entity_details.trn_details.valid_till.value =
        getUTCDate(payload);
    },
    toggleTRNCalendarShow: (state, { payload }) => {
      state.entity_form_data.entity_details.trn_details.valid_till.calendar_show =
        payload ||
        !state.entity_form_data.entity_details.trn_details.valid_till
          .calendar_show;
    },
    addFileSharingType: (state, { payload }) => {
      state.entity_form_data.contract.file_sharing_details.sharing_type =
        payload;
    },
    addFileSharingBool: (state, { payload }) => {
      state.entity_form_data.contract.file_sharing_details.bool = payload;
    },
    addFileSharingLink: (state, { payload }) => {
      // if (
      //   state.entity_form_data.contract.file_sharing_details.bool === true
      // ) {
      state.entity_form_data.contract.file_sharing_details.value = payload;
      // return;
      // }
    },
    addContractType: (state, { payload }) => {
      state.entity_form_data.contract.contract_type.is_msla = payload?.bool;
      state.entity_form_data.contract.contract_type.value = payload?.value;
    },
    addChargeCodeBool: (state, { payload }) => {
      state.entity_form_data.entity_details.charge_code_details.bool = payload;
    },
    addChargeCode: (state, { payload }) => {
      if (
        state.entity_form_data.entity_details.charge_code_details.bool === true
      ) {
        state.entity_form_data.entity_details.charge_code_details.value =
          payload;
        return;
      }
    },

    // ======================= MSLA Details ============================
    addMSLAFile: (state, { payload }) => {
      state.entity_form_data.contract.file = payload;
    },
    addMSLAFilePath: (state, { payload }) => {
      state.entity_form_data.contract.file_path = payload;
    },
    addMSLAValidity: (state, { payload }) => {
      state.entity_form_data.contract.valid_thorugh = getUTCDateRange(payload);
      // state.entity_form_data.contract.file_storage_duration = daysNum(
      //   payload.startDate,
      //   payload.endDate,
      // );
    },
    addMSLACurrency: (state, { payload }) => {
      state.entity_form_data.contract.currency = payload;
    },
    addMSLAFileStorageDuration: (state, { payload }) => {
      state.entity_form_data.contract.file_storage_duration = payload;
    },
    addMSLATaxArrItem: (state) => {
      state.entity_form_data.entity_details.taxes = [
        ...state.entity_form_data.entity_details.taxes,
        tax_options,
      ];
    },
    addMSLATaxType: (state, { payload }) => {
      // required input format==> {item_id: 0, value: "VAT"}
      state.entity_form_data.entity_details.taxes[payload.item_id].tax_type =
        payload.value;
    },
    addMSLATaxPerc: (state, { payload }) => {
      // required input fromat ==> {item_id: 0, value: "VAT"}
      state.entity_form_data.entity_details.taxes[payload.item_id].perc =
        payload.value;
    },

    // ============================== Price per service ========================
    addPPUArrItem: (state) => {
      state.entity_form_data.price_per_unit = [
        ...state.entity_form_data.price_per_unit,
        price_per_unit_option,
      ];
    },
    removePPUArrItem: (state, { payload }) => {
      state.entity_form_data.price_per_unit.splice(payload.id, 1);
    },
    resetPPUArr: (state) => {
      state.entity_form_data.price_per_unit =
        initialState.entity_form_data.price_per_unit;
    },
    addPPUService: (state, { payload }) => {
      // required input format==> {item_id: 0, value: {label: somethign, value:"Translation"}}
      state.entity_form_data.price_per_unit[payload.item_id].service =
        payload.value;
    },
    addPPUSubService: (state, { payload }) => {
      // required input format==> {item_id: 0, value: {label: somethign, value:"Translation"}}
      state.entity_form_data.price_per_unit[
        payload.item_id
      ].sub_service.selected_option = payload.value;
    },
    addPPUListPrice: (state, { payload }) => {
      // required input format==> {item_id: 0, value: "Translation"}
      state.entity_form_data.price_per_unit[payload.item_id].list_price =
        payload.value;
    },
    addPPUInvoicePrice: (state, { payload }) => {
      if (!state.entity_form_data.price_per_unit[payload.item_id].list_price) {
        alert("List price not found, Invoice Price can't be applied");
        return;
      }
      // required input format==> {item_id: 0, value: "Translation"}
      state.entity_form_data.price_per_unit[payload.item_id].final_sell_price =
        payload.value;
      // state.entity_form_data.price_per_unit[payload.item_id].cum_discount =
      //   roundNum(
      //     100 -
      //       (100 * payload.value) /
      //         state.entity_form_data.price_per_unit[payload.item_id].list_price,
      //   );
      // state.entity_form_data.price_per_unit[payload.item_id].final_sell_price =
      //   roundNum(
      //     100 -
      //       (1000 * payload.value) /
      //         (9 *
      //           state.entity_form_data.price_per_unit[payload.item_id]
      //             .list_price),
      //   );
    },
    addPPUDiscount: (state, { payload }) => {
      // required input format==> {item_id: 0, value: "Translation"}
      if (!state.entity_form_data.price_per_unit[payload.item_id].list_price) {
        alert("List price not found, Invoice Price can't be applied");
        return;
      }
      state.entity_form_data.price_per_unit[payload.item_id].discount =
        payload.value;

      state.entity_form_data.price_per_unit[payload.item_id].final_sell_price =
        roundNum(
          (9 *
            (100 -
              state.entity_form_data.price_per_unit[payload.item_id].discount) *
            state.entity_form_data.price_per_unit[payload.item_id].list_price) /
            1000,
        );
    },
    // if we need to put values into edit enity frm without async call
    setEditEntityFormData: (state, { payload }) => {
      state.entity_form_data.entity_details.billing_info.entity_name =
        payload.edit_entity_data.entity_name;
      state.entity_form_data.entity_details.billing_info.address.building_name =
        payload.edit_entity_data.address1;
      state.entity_form_data.entity_details.billing_info.address.street =
        payload.edit_entity_data.address2;
      state.entity_form_data.entity_details.billing_info.address.zip =
        payload.edit_entity_data.zip_code;
      state.entity_form_data.entity_details.billing_info.address.country =
        createOptionValue(payload.edit_entity_data.country);
      state.entity_form_data.entity_details.billing_info.address.city.selected_option =
        createOptionValue(payload.edit_entity_data.city);
      state.entity_form_data.entity_details.crn_details.number =
        payload.edit_entity_data.comp_reg_no;
      state.entity_form_data.entity_details.crn_details.valid_till.value =
        new Date(payload.edit_entity_data.comp_reg_val);
      state.entity_form_data.entity_details.trn_details.number =
        payload.edit_entity_data.tax_reg_no;
      state.entity_form_data.entity_details.trn_details.valid_till.value =
        new Date(payload.edit_entity_data.tax_validity);
      state.entity_form_data.contract.file_sharing_details.bool =
        payload.edit_entity_data.entity_type === CLIENT_USER_TYPE.EXTERNAL
          ? true
          : false;
      state.entity_form_data.contract.file_sharing_details.link =
        payload.edit_entity_data.entity_type;
      state.entity_form_data.entity_details.charge_code_details.bool = payload
        .edit_entity_data?.charge_code
        ? true
        : false;
      state.entity_form_data.entity_details.charge_code_details.value =
        payload.edit_entity_data.charge_code;
      state.entity_form_data.contract.file =
        payload.edit_entity_data.contract.file_name;
      state.entity_form_data.contract.file_path =
        payload.edit_entity_data.contract.file_link;
      state.entity_form_data.contract.valid_thorugh.startDate = new Date(
        payload.edit_entity_data.msla_val_start,
      );
      state.entity_form_data.contract.valid_thorugh.endDate = new Date(
        payload.edit_entity_data.msla_val_end,
      );
      state.entity_form_data.contract.currency = createOptionValue(
        payload.edit_entity_data.currency,
      );
      state.entity_form_data.contract.file_storage_duration =
        payload.edit_entity_data.log_duration;
      // tax arr items
      state.entity_form_data.entity_details.taxes = []; // this clears out the default one tax value before fillign the taxes in edit form
      payload.edit_entity_data.taxes?.forEach((tax) => {
        // checking if options has this value or we need to add it in the values.
        let check_tax = state.entity_form_data.form_options.tax_type.list.some(
          (cur) => cur.label === tax.tax_type,
        );
        if (!check_tax) {
          state.entity_form_data.form_options.tax_type.list = [
            ...state.entity_form_data.form_options.tax_type.list,
            createOptionValue(tax.tax_type),
          ];
        }
        let tax_arr_val = JSON.parse(JSON.stringify(tax_options));
        tax_arr_val.tax_type = createOptionValue(tax.tax_type);
        tax_arr_val.perc = tax.percentage;
        // adding val in arr
        state.entity_form_data.entity_details.taxes = [
          ...state.entity_form_data.entity_details.taxes,
          tax_arr_val,
        ];
      });
      // services arr item
      state.entity_form_data.price_per_unit = []; // this clears out the default one services value before fillign the taxes in edit form
      payload.edit_entity_data.list_of_services?.forEach((service_opt) => {
        let service_arr_val = JSON.parse(JSON.stringify(price_per_unit_option));
        service_arr_val.id = service_opt?.id;
        service_arr_val.service = createOptionValue(service_opt.service);
        service_arr_val.sub_service.selected_option =
          createSubServiceOptionValue(service_opt.sub_service);
        service_arr_val.list_price = service_opt.list_price;
        service_arr_val.final_sell_price = service_opt.offered_price;
        service_arr_val.discount = service_opt.discount;
        // adding value in arr
        state.entity_form_data.price_per_unit = [
          ...state.entity_form_data.price_per_unit,
          service_arr_val,
        ];
      });
    },

    //Entity List data
    resetEntityItemDetails: (state) => {
      state.entity_list_data = initialState.entity_list_data;
    },
  },
  extraReducers: {
    // Fetching Entity Details
    [getEntityItemDetails.pending]: (state) => {
      state.entity_form_data.loading = true;
    },
    [getEntityItemDetails.fulfilled]: (state) => {
      state.entity_form_data.loading = false;
    },
    [getEntityItemDetails.rejected]: (state, action) => {
      state.entity_form_data.loading = false;
      state.entity_form_data.error = action.message;
    },
    // Fetching Country Options
    [getCountryOptions.pending]: (state) => {
      state.entity_form_data.form_options.country.loading = true;
    },
    [getCountryOptions.fulfilled]: (state, { payload }) => {
      state.entity_form_data.form_options.country.loading = false;
      state.entity_form_data.form_options.country.list = payload;
    },
    [getCountryOptions.rejected]: (state, action) => {
      state.entity_form_data.form_options.country.loading = false;
      state.entity_form_data.form_options.country.error = action.message;
    },

    // Fetching City Options
    [getCityOptions.pending]: (state) => {
      state.entity_form_data.entity_details.billing_info.address.city.options.loading = true;
    },
    [getCityOptions.fulfilled]: (state, { payload }) => {
      state.entity_form_data.entity_details.billing_info.address.city.options.loading = false;
      state.entity_form_data.entity_details.billing_info.address.city.options.list =
        payload;
    },
    [getCityOptions.rejected]: (state, action) => {
      state.entity_form_data.entity_details.billing_info.address.city.options.loading = false;
      state.entity_form_data.entity_details.billing_info.address.city.options.error =
        action.message;
    },

    // Fetching Currency Options
    [getCurrencyOptions.pending]: (state) => {
      state.entity_form_data.form_options.currency.loading = true;
    },
    [getCurrencyOptions.fulfilled]: (state, { payload }) => {
      state.entity_form_data.form_options.currency.loading = false;
      state.entity_form_data.form_options.currency.list = payload;
    },
    [getCurrencyOptions.rejected]: (state, action) => {
      state.entity_form_data.form_options.currency.loading = false;
      state.entity_form_data.form_options.currency.error = action.message;
    },

    // Fetching Updated Currency
    [getUpdateCurrency.pending]: (state) => {
      state.entity_form_data.form_options.currency.loading = true;
    },
    [getUpdateCurrency.fulfilled]: (state) => {
      // console.log(payload);
      state.entity_form_data.form_options.currency.loading = false;
      // state.entity_form_data.contract.currency = payload;
    },
    [getUpdateCurrency.rejected]: (state, action) => {
      state.entity_form_data.form_options.currency.loading = false;
      state.entity_form_data.form_options.currency.error = action.message;
    },

    // Fetching Tax Type Options
    [getTaxTypeOptions.pending]: (state) => {
      state.entity_form_data.form_options.tax_type.loading = true;
    },
    [getTaxTypeOptions.fulfilled]: (state, { payload }) => {
      state.entity_form_data.form_options.tax_type.loading = false;
      state.entity_form_data.form_options.tax_type.list = payload;
    },
    [getTaxTypeOptions.rejected]: (state, action) => {
      state.entity_form_data.form_options.tax_type.loading = false;
      state.entity_form_data.form_options.tax_type.error = action.message;
    },

    // Fetching Service Options
    [getServiceOptions.pending]: (state) => {
      state.entity_form_data.form_options.service.loading = true;
    },
    [getServiceOptions.fulfilled]: (state, { payload }) => {
      state.entity_form_data.form_options.service.loading = false;
      state.entity_form_data.form_options.service.list = payload;
    },
    [getServiceOptions.rejected]: (state, action) => {
      state.entity_form_data.form_options.service.loading = false;
      state.entity_form_data.form_options.service.error = action.message;
    },

    // Fetching Sub Service Options
    [getSubServiceOptions.pending]: (state, action) => {
      state.entity_form_data.price_per_unit[
        action.meta.arg.id
      ].sub_service.options.loading = true;
    },
    [getSubServiceOptions.fulfilled]: (state, { payload }) => {
      state.entity_form_data.price_per_unit[
        payload.idx
      ].sub_service.options.loading = false;
      state.entity_form_data.price_per_unit[
        payload.idx
      ].sub_service.options.list = payload.res;
      // removing invoice pirce so as to get new invoice price and calculate discount
      state.entity_form_data.price_per_unit[payload.idx].final_sell_price = 0;
      // state.entity_form_data.price_per_unit[payload.idx].cum_discount = 0;
      state.entity_form_data.price_per_unit[payload.idx].list_price = 0;
      state.entity_form_data.price_per_unit[payload.idx].discount = 0;
      // state.entity_form_data.price_per_unit[
      //   payload.idx
      // ].sub_service.selected_option =
      //   initialState.entity_form_data.price_per_unit[
      //     payload.idx
      //   ].sub_service.selected_option;
    },
    [getSubServiceOptions.rejected]: (state, action) => {
      state.entity_form_data.price_per_unit[
        action.meta.arg.id
      ].sub_service.options.loading = false;
      state.entity_form_data.price_per_unit[
        action.meta.arg.id
      ].sub_service.options.error = action.payload;
    },

    // Fetching List Price
    [getListPrice.pending]: (state, action) => {
      state.entity_form_data.price_per_unit[action.meta.arg.id].list_price =
        "Loading...";
    },
    [getListPrice.fulfilled]: (state, action) => {
      state.entity_form_data.price_per_unit[action.meta.arg.id].list_price =
        action.payload.res;
      // removing invoice pirce so as to get new invoice price and calculate discount
      state.entity_form_data.price_per_unit[
        action.meta.arg.id
      ].final_sell_price = 0;
      state.entity_form_data.price_per_unit[action.meta.arg.id].discount = 0;
      // state.entity_form_data.price_per_unit[
      //   action.meta.arg.id
      // ].discount = 0;
    },
    [getListPrice.rejected]: (state, action) => {
      state.entity_form_data.price_per_unit[action.meta.arg.id].list_price =
        null;
      alert(
        "List Price with this sub service can't be found, Please select another sub service",
      );

      // clearing the selected sub-service whose list price is not found after alert
      state.entity_form_data.price_per_unit[
        action.meta.arg.id
      ].sub_service.selected_option =
        initialState.entity_form_data.price_per_unit[
          action.meta.arg.id
        ].sub_service.selected_option;
    },

    // Send Entity Create Form Data
    [postEntityCreateFormData.pending]: (state) => {
      state.entity_form_data.loading = true;
    },
    [postEntityCreateFormData.fulfilled]: (state) => {
      state.entity_form_data.loading = false;
      state.entity_form_data = initialState.entity_form_data; // resets the entity form
      state.tab = TAB_CHOICE.LIST; // sends the entity to the list page
    },
    [postEntityCreateFormData.rejected]: (state, action) => {
      state.entity_form_data.loading = false;
      state.entity_form_data.error = action.message;
    },

    // Updating the entity
    [putEntityEditFormData.pending]: (state) => {
      state.entity_form_data.loading = true;
    },
    [putEntityEditFormData.fulfilled]: (state) => {
      state.entity_form_data.loading = false;
      state.entity_form_data = initialState.entity_form_data; // resets the entity form
      state.tab = TAB_CHOICE.LIST; // sends the entity to the list page
    },
    [putEntityEditFormData.rejected]: (state, action) => {
      state.entity_form_data.loading = false;
      state.entity_form_data.error = action.message;
    },

    // Fetch Edit Entity Info
    [getEditEntityInfo.pending]: (state) => {
      state.entity_form_data.loading = true;
    },
    [getEditEntityInfo.fulfilled]: (state, { payload }) => {
      // console.log(payload);
      state.entity_form_data.loading = false;
      state.entity_form_data.entity_id = payload?.id;
      // state.entity_form_data.entity_code = payload?.entity_code;
      state.entity_form_data.entity_details.billing_info.entity_name =
        payload?.entity_name;
      state.entity_form_data.entity_details.billing_info.address.building_name =
        payload?.address1;
      state.entity_form_data.entity_details.billing_info.address.street =
        payload?.address2;
      state.entity_form_data.entity_details.billing_info.address.zip =
        payload?.zip_code;
      state.entity_form_data.entity_details.billing_info.address.country =
        createOptionValue(payload?.country);
      state.entity_form_data.entity_details.billing_info.address.city.selected_option =
        createOptionValue(payload?.city);
      state.entity_form_data.entity_details.crn_details.number =
        payload?.company_reg_no;
      state.entity_form_data.entity_details.crn_details.valid_till.value =
        new Date(payload?.company_reg_validity);
      state.entity_form_data.entity_details.trn_details.number =
        payload?.tax_reg_no;
      state.entity_form_data.entity_details.trn_details.valid_till.value =
        new Date(payload?.tax_validity);
      state.entity_form_data.contract.file_sharing_details.bool =
        payload?.is_external;
      state.entity_form_data.contract.file_sharing_details.value =
        payload?.file_sharing_platform;
      state.entity_form_data.contract.file_sharing_details.sharing_type =
        createOptionValue(payload?.file_shared_type);
      state.entity_form_data.entity_details.charge_code_details.bool =
        payload?.uses_charge_code;
      state.entity_form_data.contract.contract_type.value =
        payload?.contract_file_type;
      state.entity_form_data.contract.contract_type.is_msla = payload?.is_msla;
      // if (
      //   state.entity_form_data.entity_details.charge_code_details.bool === true
      // ) {
      //   state.entity_form_data.entity_details.charge_code_details.value =
      //     payload?.charge_code_value;
      // }
      state.entity_form_data.contract.file = payload?.contract?.filename;
      state.entity_form_data.contract.file_path = payload?.contract?.s3_path;
      state.entity_form_data.contract.valid_thorugh.startDate = new Date(
        payload?.contract_validity_start,
      );
      state.entity_form_data.contract.valid_thorugh.endDate = new Date(
        payload?.contract_validity_end,
      );
      state.entity_form_data.contract.currency = createOptionValue(
        payload?.invoice_details?.currency,
      );
      state.entity_form_data.contract.file_storage_duration =
        payload?.file_duration;
      // tax arr items
      state.entity_form_data.entity_details.taxes = []; // this clears out the default one tax value before fillign the taxes in edit form
      {
        Array.isArray(payload?.tax_list) &&
          payload?.tax_list?.forEach((tax) => {
            // checking if options has this value or we need to add it in the values.
            let check_tax =
              state.entity_form_data.form_options.tax_type.list.some(
                (cur) => cur.label === tax.tax_type,
              );
            if (!check_tax) {
              state.entity_form_data.form_options.tax_type.list = [
                ...state.entity_form_data.form_options.tax_type.list,
                createOptionValue(tax.tax_type),
              ];
            }
            let tax_arr_val = JSON.parse(JSON.stringify(tax_options));
            tax_arr_val.tax_type = createOptionValue(tax.tax_type);
            tax_arr_val.perc = tax.percentage;
            // adding val in arr
            state.entity_form_data.entity_details.taxes = [
              ...state.entity_form_data.entity_details.taxes,
              tax_arr_val,
            ];
          });
      }
      // services arr item
      state.entity_form_data.price_per_unit = []; // this clears out the default one services value before fillign the services in edit form
      payload?.invoice_details?.services?.forEach((service_opt) => {
        let service_arr_val = JSON.parse(JSON.stringify(price_per_unit_option));
        service_arr_val.id = service_opt?.id;
        service_arr_val.service = createOptionValue(
          service_opt.service_mapper.service,
        );
        service_arr_val.sub_service.selected_option =
          createSubServiceOptionValue(service_opt.service_mapper);
        service_arr_val.list_price = service_opt.list_price;
        service_arr_val.final_sell_price = service_opt.offered_price;
        service_arr_val.discount = service_opt.discount;
        // service_arr_val.cum_discount = service_opt.cumm_discount_mslaprice;
        // adding value in arr
        state.entity_form_data.price_per_unit = [
          ...state.entity_form_data.price_per_unit,
          service_arr_val,
        ];
      });
      // opening the form after loading the data
      state.tab = TAB_CHOICE.FORM;
      state.edit_entity_id = payload?.id;
    },
    [getEditEntityInfo.rejected]: (state, action) => {
      state.entity_form_data.loading = false;
      state.entity_form_data.error = action.message;
    },

    // Fetch Entity List Items
    [getEntityList.pending]: (state) => {
      state.entity_list_data.loading = true;
    },
    [getEntityList.fulfilled]: (state, { payload }) => {
      state.entity_list_data.loading = false;

      if (!state.entity_list_data.list.length) {
        payload?.data?.forEach((entity) => {
          let entity_arr_val = JSON.parse(
            JSON.stringify(entity_item_details_data),
          );
          entity_arr_val.entity_item_head_info.loading = false;
          entity_arr_val.entity_item_head_info.data = entity;
          state.entity_list_data.list = [
            ...state.entity_list_data.list,
            entity_arr_val,
          ];
        });
      }
    },
    [getEntityList.rejected]: (state, action) => {
      state.entity_list_data.loading = false;
      state.entity_list_data.error = action.message;
    },
    // Fetch Entity Item data
    [getEntityListItemDetails.pending]: (state, action) => {
      state.entity_list_data.list[
        action.meta.arg.idx
      ].entity_item_data_info.loading = true;
    },
    [getEntityListItemDetails.fulfilled]: (state, action) => {
      state.entity_list_data.list[
        action.meta.arg.idx
      ].entity_item_data_info.loading = false;
      state.entity_list_data.list[
        action.meta.arg.idx
      ].entity_item_data_info.data = action.payload.res;
    },
    [getEntityListItemDetails.rejected]: (state, action) => {
      state.entity_list_data.list[
        action.meta.arg.idx
      ].entity_item_data_info.loading = false;
      state.entity_list_data.list[
        action.meta.arg.idx
      ].entity_item_data_info.error = action.err;
    },
  },
});

export const {
  // Entity Tab
  toggleEntityTab,
  openEntityForm,
  openEntityList,
  openEntityEditForm,
  // toggle modal
  togglePreviewModal,
  openPreviewFileModal,
  closePreviewFileModal,
  // toggle list
  toggleEntityLIstCard,
  openEntityListCard,
  closeEntityListCard,
  // Reset entity form data
  resetEntityFormData,
  resetEntityTab,
  // ENTITY DETAILS
  addEntityName,
  addEntityAddressBuilding,
  addEntityAddressStreet,
  addEntityAddressZip,
  addEntityAddressCountry,
  addEntityAddressCity,
  addCRNNumber,
  addCRNValidTill,
  toggleCRNCalendarShow,
  addTRNNumber,
  addTRNValidTill,
  toggleTRNCalendarShow,
  addFileSharingBool,
  addFileSharingLink,
  addFileSharingType,
  addChargeCodeBool,
  addChargeCode,
  addContractType,

  // MSLA ACTIONS
  addMSLAFile,
  addMSLAFilePath,
  addMSLAValidity,
  addMSLACurrency,
  addMSLAFileStorageDuration,
  addMSLATaxArrItem,
  addMSLATaxType,
  addMSLATaxPerc,
  // PPU ACTIONS
  addPPUArrItem,
  removePPUArrItem,
  resetPPUArr,
  addPPUService,
  addPPUSubService,
  addPPUListPrice,
  addPPUInvoicePrice,
  addPPUDiscount,
  // edit entity form data
  setEditEntityFormData,
  //Entity list data
  resetEntityItemDetails,
  checkValidInput,
} = entityDataSlice.actions;

export default entityDataSlice.reducer;
