import React, { useContext, useEffect, useRef, useState } from "react";
import { FaCopy, FaDownload, FaExternalLinkAlt } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import {
  ApiContext,
  date_time_convertor,
  devEnv,
  DownloadFiles,
  FeedbackRatingEmoji,
  getAccessLogData,
} from "../../../../..";
import { getAuthData, postAuthData } from "../../../../../helpers/request";
import {
  OpsArchiveDashboardFileContext,
  OpsRouteContext,
  OPS_ROUTES,
  NotFoundErrorContext,
} from "../../../../OpsComponent";
import dummy_comments from "../../../../../archive/test_data/dummy_data-v1/dummy_comments.json";

import "./DeliveryTab.css";
import { useSelector } from "react-redux";
import { file_links_obj } from "../../../../../store/features/liveAssignmentData/common";

const FileInfoCard = ({ activeTargetVersion }) => {
  return (
    <article className="file-info-section">
      <div className="file-info-head">
        <div className="file-info-head-title">
          Version {activeTargetVersion.version}
        </div>
        <div className="file-info-head-feedback">
          <FeedbackRatingEmoji
            feedback={activeTargetVersion.overall_feedback}
          />
        </div>
      </div>
      <div className="file-info-item" id="file-info-delivery-notes">
        <div className="file-info-item-head">Delivery Notes</div>
        <div className="file-info-item-value">
          {activeTargetVersion.delivery_notes?.map((source, idx) => {
            return (
              <div className="delivery-notes" key={idx}>
                {source.names.map((name, idx) => (
                  <span key={idx} className="delivery-notes-file-name">
                    @{name}
                  </span>
                ))}
                <span className="delivery-note">{source.notes}</span>
              </div>
            );
          })}
        </div>
      </div>
    </article>
  );
};

// ========================= File Download Card ========================

// const TargetDownloadBtn = ({ ext, link, name }) => {
//   const { setNotFoundError } = useContext(NotFoundErrorContext);

//   const DownloadTarget = (e) => {
//     const download_file_name =
//       name.split(".").slice(0, -1).join(".") + "." + ext; // making the file name with the extention provided
//     DownloadFiles([{ name: download_file_name, link: link }]);
//     try {
//       DownloadFiles([{ name: download_file_name, link: link }]);
//     } catch (err) {
//       setNotFoundError(true);
//     }
//   };

//   return (
//     <span className="live-file-info-file-download-btn" onClick={DownloadTarget}>
//       <span>{ext.toUpperCase()}</span>
//       <span>
//         <FaDownload />
//       </span>
//     </span>
//   );
// };

const FileDownloadButton = ({ link_obj }) => {
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const DownloadTarget = () => {
    try {
      DownloadFiles([{ name: link_obj.name, link: link_obj?.link }]);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <span className="live-file-info-file-download-btn" onClick={DownloadTarget}>
      <span>{link_obj.ext.toUpperCase()}</span>
      <span>
        <FaDownload />
      </span>
    </span>
  );
};

const ExternalLinkButton = ({ link_obj }) => {
  return (
    <a
      className="live-file-info-file-download-btn"
      target="_blank"
      href={link_obj?.link}
      rel="noreferrer"
    >
      <span>External Link</span>
      <span>
        <FaExternalLinkAlt />
      </span>
    </a>
  );
};

const ExternalTextButton = ({ text_obj }) => {
  const copyTextRef = useRef(null);
  const copyBtnRef = useRef(null);
  const copyCode = () => {
    try {
      navigator.clipboard.writeText(text_obj.text);
      copyTextRef.current.innerText = "Code Copied!";
      copyBtnRef.current.classList.add("file-info-file-copy-btn-active");
      setTimeout(() => {
        copyTextRef.current.innerText = "Copy Code";
        copyBtnRef.current.classList.remove("file-info-file-copy-btn-active");
      }, 1000);
    } catch (error) {
      alert("Failed to copy");
    }
  };
  return (
    <span
      ref={copyBtnRef}
      className="live-file-info-file-download-btn"
      onClick={copyCode}
    >
      <span ref={copyTextRef}>Copy Code</span>
      <span>
        <FaCopy />
      </span>
    </span>
  );
};

const TargetFile = ({ file, idx, feedbackFiles, setFeedbackFiles }) => {
  const route = useContext(OpsRouteContext);
  const [isChecked, setIsChecked] = useState(false);

  const addFile = () => {
    setFeedbackFiles([...feedbackFiles, file]);
  };

  const removeFile = () => {
    let removedFileIdx = feedbackFiles.indexOf(file);
    if (removedFileIdx > -1) {
      // checking if the file even exists in the array of files.
      setFeedbackFiles((prevFiles) => {
        let new_arr = prevFiles.slice(); // using a copied array bcz if you use splice on prevFile then it would remove the file on the UI as well.
        new_arr.splice(removedFileIdx, 1);
        return new_arr;
      });
    }
  };

  const handleOnChange = () => {
    setIsChecked((prev) => {
      let checked = !prev;
      if (checked) {
        addFile(); // adding a file if checked
      } else {
        removeFile(); // removing a file if unchecked
      }
      return checked;
    });
  };

  useEffect(() => {
    feedbackFiles?.includes(file) ? setIsChecked(true) : setIsChecked(false);
  }, [feedbackFiles]);

  return (
    <div className="file-info-file">
      <div className="file-info-file-name">
        {route === OPS_ROUTES.ASSIGNMENTS && (
          <input
            type="checkbox"
            value={idx}
            checked={isChecked}
            onChange={handleOnChange}
          />
        )}
        <div>{file.name}</div>
      </div>

      {route === OPS_ROUTES.ASSIGNMENTS && (
        <div className="file-info-file-download">
          {/* {(file?.links) && Object.entries(file?.links)?.map(([ext,link],idx)=>{
                        return (
                            <TargetDownloadBtn key={idx} ext={ext} link={link} name={file.name}/>
                        )
                    })} */}
          {file_links_obj(file)?.external_link?.map((link_obj, i) => {
            return <ExternalLinkButton key={i} link_obj={link_obj} />;
          })}
          {file_links_obj(file)?.external_text?.map((text_obj, i) => {
            return <ExternalTextButton key={i} text_obj={text_obj} />;
          })}
          {file_links_obj(file)?.file_links?.map((link_obj, i) => {
            return <FileDownloadButton key={i} link_obj={link_obj} />;
          })}
        </div>
      )}
    </div>
  );
};

const FileDownload = ({
  activeTargetVersion,
  feedbackFiles,
  setFeedbackFiles,
}) => {
  const route = useContext(OpsRouteContext);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const handleOnChange = () => {
    setIsAllChecked((prev) => {
      let checked = !prev;
      if (checked) {
        setFeedbackFiles(activeTargetVersion.files);
      } else {
        setFeedbackFiles([]);
      }
      return checked;
    });
  };

  useEffect(() => {
    feedbackFiles.length === activeTargetVersion.files.length
      ? setIsAllChecked(true)
      : setIsAllChecked(false);
  }, [feedbackFiles]);

  const handleDownloadTargetAll = (e) => {
    e.preventDefault();

    // let allFileLinks = activeTargetVersion?.files
    //   .map((file) => {
    //     return Object.entries(file.links).map((link) => {
    //       const filename =
    //         file.name.split(".").slice(0, -1).join(".") + "." + link[0];
    //       return { link: link[1], name: filename };
    //     });
    //   })
    //   .flat();

    let allFilesLinks = activeTargetVersion?.files
      ?.map((file) => file_links_obj(file).file_links)
      .flat();

    let zip_link_arr = activeTargetVersion?.zip_link &&
      activeTargetVersion?.zip_link?.name &&
      activeTargetVersion?.zip_link?.link && [
        {
          name: activeTargetVersion?.zip_link?.name,
          link: activeTargetVersion?.zip_link?.link,
        },
      ];

    try {
      if (zip_link_arr) {
        console.log(zip_link_arr);
        DownloadFiles(zip_link_arr, true);
      } else {
        DownloadFiles(allFilesLinks, true);
      }
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <article>
      <div className="file-info-item" id="file-info-files-list">
        <div className="file-info-item-head">
          <div className="file-info-file-name">
            {route === OPS_ROUTES.ASSIGNMENTS && (
              <input
                type="checkbox"
                name="all-files"
                value="all"
                checked={isAllChecked}
                onChange={handleOnChange}
              />
            )}
            <span>Files</span>
          </div>
          {route === OPS_ROUTES.ASSIGNMENTS &&
            activeTargetVersion?.files?.every((file) => {
              return file?.links && Object.entries(file?.links)?.length > 0;
            }) /* Checks if all links are present only then Downlaod all will appear */ && (
              <span
                className="live-file-info-file-download-btn"
                onClick={handleDownloadTargetAll}
              >
                <span>Download All</span>
                <span>
                  <FaDownload />
                </span>
              </span>
            )}
        </div>
        <div className="file-info-item-value" id="file-info-files-container">
          {activeTargetVersion?.files.map((file, idx) => {
            return (
              <TargetFile
                key={idx}
                file={file}
                idx={idx}
                feedbackFiles={feedbackFiles}
                setFeedbackFiles={setFeedbackFiles}
              />
            );
          })}
        </div>
      </div>
    </article>
  );
};

// =========================== Feedback Card ===========================
const FileFeedbackForm = ({ feedbackFiles, setComments, setFetching }) => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const assignmentId = liveAssignmentData?.file_details?.batch_id;
  const API_URL = useContext(ApiContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const commentsRef = useRef();

  const fetchComments = () => {
    getAuthData(
      `${API_URL}/api-ops/misc/v1/comment/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setComments(res.data);
          setFetching(null); // must be below setting comments
        }
      })
      .catch(() => {
        setFetching("Can't fetch comments!");
      });
  };

  const sendData = (data) => {
    postAuthData(`${API_URL}/api-ops/misc/v1/comment/`, getAccessLogData(data))
      .then((res) => {
        if (res.success === true) {
          fetchComments();
        }
      })
      .catch(() => {
        setNotFoundError(true);
      });
  };

  const clearFeedbackSelections = () => {
    // setFeedbackFiles([]);   // removing feedback files from the list
    commentsRef.current.value = ""; // Clearing Comments
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      message: commentsRef.current.value?.trim(),
      delivery_id: assignmentId,
    };
    // const feedback_data = feedbackFiles?.map(({name})=>{return {...data,filename:name}});    // Attaching feedback values to the feedback files
    const feedback_data = {
      ...data,
      filename: feedbackFiles.map((cur) => cur.name),
    };
    // Validation check
    if (data.message !== "") {
      // feedback_data?.forEach(msg_data => {
      //         if (!devEnv){
      //             sendData(msg_data);
      //         } else{
      //             console.log(msg_data)
      //         }
      //         clearFeedbackSelections();
      // });
      if (!devEnv) {
        sendData(feedback_data);
      } else {
        console.log(feedback_data);
      }
      clearFeedbackSelections();
    } else {
      alert("Please add a comment.");
    }
  };

  return (
    <article className="file-feedback-section">
      <form onSubmit={handleSubmit}>
        <div className="ops-feedback-input">
          <div className="ops-message-feedback">
            <div id="ops-feedback-comment">
              {/* <label className="ops-feedback-label" id="comments-ops-feedback-label" htmlFor="commentsFeedback">Would you like to add any comments?</label> */}
              <textarea
                className="ops-dashboard-input ops-feedback-comment"
                ref={commentsRef}
                id="commentsFeedback"
                name="commentsFeedback"
                placeholder="Type Here.."
              />
            </div>
            <MdSend
              className="ops-feedback-submit-btn"
              type="submit"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    </article>
  );
};

// ======================= COMMENTS Section ===========================
const COMMENTS_TYPE = {
  NOTIFICATION: "notification",
  SEND: "send",
  RECEIVE: "receive",
  FEEDBACK: "feedback",
};

const EmptyComment = ({ message }) => {
  return (
    <div>{message || "There are no Comments yet on this assignment!"}</div>
  );
};

const UnknownComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-unknown glass-container">
      {comment || "An unknown type comment received."}
    </div>
  );
};

const FeedbackComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-feedback glass-container">
      <div className="comment-msg-user">{comment.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename?.map((name, i) => {
          return <span key={i}>{name}</span>;
        })}
      </div>
      <div className="comment-msg-feedback-container">
        {comment?.message?.service && (
          <div className="comment-feedback-quality comment-msg-service-feedback">
            <span className="comment-feedback-quality-label">
              Quality of Service
            </span>
            <FeedbackRatingEmoji feedback={comment?.message?.service} />
          </div>
        )}
        {comment?.message?.output && (
          <div className="comment-feedback-quality comment-msg-output-feedback">
            <span className="comment-feedback-quality-label">
              Quality of Output
            </span>
            <FeedbackRatingEmoji feedback={comment?.message?.output} />
          </div>
        )}
      </div>
      {/* <div className='comment-msg-chat-text'>{comment?.message?.comments}</div> */}
    </div>
  );
};

const SentComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-chat comment-msg-sent glass-container">
      <div className="comment-msg-user">{comment?.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename?.map((name, i) => {
          return <span key={i}>{name}</span>;
        })}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const ReceivedComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-chat comment-msg-receive glass-container">
      <div className="comment-msg-user">{comment?.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename?.map((name, i) => {
          return <span key={i}>{name}</span>;
        })}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const NotificationComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-notification glass-container">
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const CommentsSection = ({ comments, setComments, fetching, setFetching }) => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const API_URL = useContext(ApiContext);

  const fetchComments = () => {
    setComments("");
    setFetching("Loading Comments..."); // Setting a loader
    if (devEnv) {
      setFetching(null);
      setComments(dummy_comments.comments); // Setting Dummy Data
      setFetching(null); // must be below setting comments
    } else {
      getAuthData(
        `${API_URL}/api-ops/misc/v1/comment/${liveAssignmentData?.file_details?.id}/`,
      )
        .then((res) => {
          if (res.success === true) {
            setComments(res.data);
            setFetching(null); // must be below setting comments
          }
        })
        .catch(() => {
          setFetching("Can't fetch comments!");
        });
    }
  };

  useEffect(() => {
    liveAssignmentData?.file_details?.id && fetchComments();
  }, [liveAssignmentData?.file_details]);

  // useEffect(() => {
  //   fetchComments()
  // }, [])

  return (
    <div className="comments-section">
      {/* {(fetching) && <span>{fetching}</span>} */}
      {comments && comments.length > 0 ? (
        comments?.map((comment, idx) => {
          switch (comment.type) {
            case COMMENTS_TYPE.FEEDBACK:
              return <FeedbackComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.SEND:
              return <SentComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.RECEIVE:
              return <ReceivedComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.NOTIFICATION:
              return <NotificationComment key={idx} comment={comment} />;
            default:
              return <UnknownComment key={idx} comment={comment} />;
          }
        })
      ) : (
        <EmptyComment message={fetching} />
      )}
    </div>
  );
};

const FileFeedback = ({ displayedFile, feedbackFiles, setFeedbackFiles }) => {
  // Donwloads based on file stage but in ops it doesnt matter HISTORY TAB and DETAILS TAB
  const route = useContext(OpsRouteContext);
  const [comments, setComments] = useState(null);
  const [fetching, setFetching] = useState(null);

  return (
    <article className="file-feedback-section">
      <div className="file-info-item" id="file-info-delivery-notes">
        <div className="file-info-item-head">Feedback</div>
        {route === OPS_ROUTES.ASSIGNMENTS && (
          <FileFeedbackForm
            displayedFile={displayedFile}
            feedbackFiles={feedbackFiles}
            setFeedbackFiles={setFeedbackFiles}
            setFetching={setFetching}
            setComments={setComments}
          />
        )}
        <CommentsSection
          displayedFile={displayedFile}
          feedbackFiles={feedbackFiles}
          comments={comments}
          setComments={setComments}
          fetching={fetching}
          setFetching={setFetching}
        />
      </div>
    </article>
  );
};

// ======================= Delivery tab UI ==================================

const DeliveryTab = ({ file }) => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  // file not right check dashboard file
  const [activeTargetVersion, setActiveTargetVersion] = useState(
    file.target_versions.find((cur) => cur.version == file.current_version),
  );
  const [feedbackFiles, setFeedbackFiles] = useState([]);

  const [displayedFile, setDisplayedFile] = useState(file);
  const route = useContext(OpsRouteContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );

  useEffect(() => {
    if (route === OPS_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(liveAssignmentData);
      setActiveTargetVersion(
        liveAssignmentData?.file_details?.target_versions.find(
          (cur) =>
            cur.version == liveAssignmentData?.file_details?.current_version,
        ),
      );
    } else if (route === OPS_ROUTES.ARCHIVE) {
      setDisplayedFile(opsArchiveDisplayedFile);
      setActiveTargetVersion(
        opsArchiveDisplayedFile.target_versions.find(
          (cur) => cur.version == opsArchiveDisplayedFile.current_version,
        ),
      );
    }
  }, [liveAssignmentData, opsArchiveDisplayedFile]);

  useEffect(() => {
    // clear files only if id changes
    setFeedbackFiles([]);
  }, [liveAssignmentData?.file_details?.id, opsArchiveDisplayedFile?.id]);

  useEffect(() => {
    setActiveTargetVersion(
      file.target_versions.find((cur) => cur.version == file.current_version),
    );
    setFeedbackFiles([]);
  }, []);

  // useEffect(() => {
  //   setFeedbackFiles([])
  // }, [liveAssignmentData,opsArchiveDisplayedFile]);  // This clears the selection if some other file us selected

  return (
    <section className="delivery-tab-section card-layout">
      <FileInfoCard
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
      />
      <FileDownload
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
        feedbackFiles={feedbackFiles}
        setFeedbackFiles={setFeedbackFiles}
      />
      <FileFeedback
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
        feedbackFiles={feedbackFiles}
        setFeedbackFiles={setFeedbackFiles}
      />
    </section>
  );
};

export default DeliveryTab;
