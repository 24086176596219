import React, { useContext, useRef, useState } from "react";
import { addAccessLogDataInFormData, ApiContext } from "../../index.js";
import {
  DeliverNewVersionInfoContext,
  OpsDashboardFileContext,
  NewSecureKeyContext,
  ModalDispatchContext,
  MODAL_TYPE,
} from "../OpsComponent";
import axios from "axios";
import { makeSecureDecrypt } from "../../helpers/security";
import { FaTimes } from "react-icons/fa";
import { getAuthData } from "../../helpers/request";
import { file_id } from "../OpsSidebar/OpsSideBar";
import { useSelector } from "react-redux";

const DeliverNewVersionModal = () => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);

  let fetchingText_interval;
  const API_URL = useContext(ApiContext);
  const { deliverNewVersionInfo, setDeliverNewVersionInfo } = useContext(
    DeliverNewVersionInfoContext,
  );
  const prevDeliverNewVersionData =
    deliverNewVersionInfo?.deliverNewVersionInfo;
  const newSecureKey = useContext(NewSecureKeyContext);
  const [fetching, setFetching] = useState(null);

  const assignmentIdRef = useRef(null);
  const updateDeliveryTimeRef = useRef(null);
  const deliveryNotes = useRef(null);
  const [targetFiles, setTargetFiles] = useState("");
  const { setOpsDisplayedFile } = useContext(OpsDashboardFileContext);
  const [FileError, setFileError] = useState();
  const modal_dispatch = useContext(ModalDispatchContext);
  const [enabled, setEnabled] = useState(true);
  // console.log(OpsDisplayedFile);

  const validatingFormInput = (data) => {
    let valid = true;
    Object.entries(data).forEach(([key, value]) => {
      if (!value) {
        if (key === Object.keys(data)[1]) {
          setTargetFiles(null);
          document.querySelector("#deliver-new-target-file").style.borderColor =
            "red";
          valid = false;
        }
      } else {
        // If input is given then the red border for invalid input is removed
        if (key === Object.keys(data)[1]) {
          document.querySelector("#deliver-new-target-file").style.borderColor =
            "var(--dark-blue,#112949)";
        }
      }
    });

    return valid;
  };

  const handleChange = (e) => {
    var files = e.target.files;
    var filesArray = [].slice.call(files);
    filesArray.forEach((e) => {
      let filename = e.name;
      // switch (filename?.split(".").at(-1).toLowerCase()) {
      switch (filename?.split(".").pop().toLowerCase()) {
        case "doc":
        case "docx":
        case "ppt":
        case "pptx":
        case "pdf":
        case "xlsx":
        case "jpeg":
        case "jpg":
        case "srt":
        case "mp4":
        case "png":
        case "psd":
        case "ai":
        case "eps":
        case "zip":
          document.querySelector("#deliver-new-target-file").style.borderColor =
            "var(--dark-blue,#112949)";
          setFileError(null);
          setEnabled(false);
          break;
        default: {
          setEnabled(true);
          document.querySelector("#deliver-new-target-file").value = null;
          document.querySelector("#deliver-new-target-file").style.borderColor =
            "red";
          setFileError("Invald File Format!! " + filename);
        }
      }
    });
  };
  const ShowErrror = ({ errorMsg }) => {
    return <div className="file-error">{errorMsg}</div>;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const new_data = () => {
      getAuthData(`${API_URL}/api-ops/ops/v1/all-assignments/${file_id}/`)
        .then((res) => {
          // console.log
          if (res.success === true) {
            setOpsDisplayedFile(res.last_assignment);
            console.log(res.last_assignment);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const deliverNewVersionData = {
      assignmentId: liveAssignmentData?.file_details?.assignment_id,
      updateDeliveryTime: updateDeliveryTimeRef.current.checked,
      uploadTarget: targetFiles,
      deliveryNotes: deliveryNotes.current.value,
    };
    if (validatingFormInput(deliverNewVersionData)) {
      setFetching("Fetching.");
      fetchingText_interval = setInterval(() => {
        console.log(fetching);

        setFetching((prev) => {
          console.log("in setState");
          if (prev === "Fetching.") {
            return "Fetching..";
          } else if (prev === "Fetching..") {
            return "Fetching...";
          } else if (prev === "Fetching...") {
            return "Fetching.";
          }
        });
      }, 1000);
      setDeliverNewVersionInfo(deliverNewVersionData);
      const user = JSON.parse(
        makeSecureDecrypt(sessionStorage.getItem("token")),
      );
      let formData = new FormData();
      formData.append("assignment_id", deliverNewVersionData.assignmentId);
      formData.append(
        "is_update_delivery_time",
        deliverNewVersionData.updateDeliveryTime,
      );
      deliverNewVersionData.uploadTarget.forEach((file) => {
        formData.append("target_file", file);
      });
      formData.append("delivery_notes", deliverNewVersionData.deliveryNotes);
      axios({
        method: "post",
        url: `${API_URL}/api-ops/ops/v1/upload-update-assignment/`,
        data: addAccessLogDataInFormData(formData),
        headers: { Authorization: `Bearer ${user}` },
      })
        .then((res) => {
          newSecureKey.setNewSecureKey(res.data.secure_key);
          new_data();
          clearInterval(fetchingText_interval);
          setFetching("Key generated successfully !");
          setTimeout(() => {
            setFetching(null);
            modal_dispatch({ modal_show_type: MODAL_TYPE.NONE }); // this line will close modal
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          clearInterval(fetchingText_interval);
          setFetching("Error in Ops sheet");
          setTimeout(() => {
            setFetching(null);
          }, 5000);
        });
    }
  };

  return (
    <div className="file-container deliver-file-contain-new">
      <div className="file-container-head-new">Deliver New Version</div>
      <button
        className="file-container-close-btn"
        onClick={() => {
          modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
        }}
      >
        <FaTimes />
      </button>
      <form className="ops-new-version-form">
        <div id="update-version-assignment-id">
          <label
            className="file-deliver-label-new"
            htmlFor="deliver-new-file-assignment-id"
          >
            Assignment ID
          </label>
          <div
            className="file-deliver-input-new"
            ref={assignmentIdRef}
            type="text"
            id="deliver-new-file-assignment-id"
            name="deliver-new-file-assignment-id"
          >
            {liveAssignmentData?.file_details?.assignment_id}
          </div>
        </div>

        <div id="update-version-checkbox">
          <label
            className="file-deliver-label-new"
            htmlFor="update-deliver-time-checkbox"
          >
            Upload Delivery Time
          </label>
          <input
            ref={updateDeliveryTimeRef}
            className="file-deliver-input-new"
            id="update-deliver-time-checkbox"
            type="checkbox"
            value="update-check"
          />
        </div>

        <div id="update-version-input">
          <label
            className="file-deliver-label-new"
            htmlFor="deliver-new-target-file"
          >
            Upload Target File(s)
          </label>
          <input
            className="file-deliver-input-new file-deliver-input-new-file"
            onChange={(e) => {
              handleChange(e);
              setTargetFiles(Array.from(e.target.files));
            }}
            type="file"
            id="deliver-new-target-file"
            name="deliver-new-target-file"
            multiple
            accept=".docx,.pptx,.xlsx,.pdf,.jpg,.jpeg,.mp4,.srt,.png,.ai,.eps,.psd,.zip"
            required
          />
          <span> {FileError && <ShowErrror errorMsg={FileError} />}</span>
        </div>

        <div id="update-version-notes">
          <label
            className="file-deliver-label delivery-notes-label-new"
            htmlFor="delivery-new-file-notes"
          >
            Delivery Notes{" "}
          </label>
          <textarea
            className="file-deliver-input delivery-notes-input-new"
            ref={deliveryNotes}
            id="delivery-new-file-notes"
            name="delivery-new-file-notes"
            defaultValue={prevDeliverNewVersionData?.deliveryNotes || ""}
          ></textarea>
        </div>

        <div
          className="deliver-new-version-btn-container"
          id="update-version-btns"
        >
          <button
            className="secure-key-cancel-btn"
            onClick={(e) => {
              e.preventDefault();
              modal_dispatch({ modal_show_type: MODAL_TYPE.NONE });
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={enabled}
            className="copy-secure-key-copy-btn"
            onClick={handleSubmit}
            style={fetching && { background: "var(--orange,#ea7b2c)" }}
          >
            {fetching || "Deliver"}
          </button>
        </div>
      </form>
    </div>
  );
};

export { DeliverNewVersionModal };
