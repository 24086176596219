import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

import "./OpsError.css";

function ComingSoonError() {
  return (
    <section className="ops-maintenance-error-section">
      <div className="ops-maintenance-head"></div>
      <div className="ops-maintenance-error-msg">
        <span className="ops-maintenance-error-msg-big">
          Innovation in Progress
        </span>
        <span className="ops-maintenance-error-msg-small">
          Please wait while we bring you the goodies!
        </span>
      </div>
      <img
        className="ops-maintenance-error-img"
        src={
          process.env.PUBLIC_URL + "/resources/client-errors/maintenance.png"
        }
        alt="Coming Soon"
      />
    </section>
  );
}

function NotFoundError() {
  return (
    <section className="ops-maintenance-error-section">
      <div className="ops-maintenance-head"></div>
      <div className="ops-maintenance-error-msg">
        <span className="ops-maintenance-error-msg-big">
          Looks like something went wrong, Try Again!
        </span>
      </div>
      <img
        className="ops-maintenance-error-img"
        src={process.env.PUBLIC_URL + "/resources/client-errors/not-found.png"}
        alt="Not Found"
      />
    </section>
  );
}

// eslint-disable-next-line no-unused-vars
function LoaderModal({ errMsg }) {
  return (
    <section className="ops-loader-modal">
      {/* <article className="ops-loader">
                <div className="spin-loader"><BiLoaderAlt/></div>
                <span>{errMsg}</span>
            </article> */}
      <div className="spin-loader">
        <BiLoaderAlt />
      </div>
      {/* <div className='img-loader'>
                <img  className='img-loader-img' src={process.env.PUBLIC_URL + "/resources/nav_icons/logo.png"} alt="EZ"/>
            </div> */}
    </section>
  );
}

export { ComingSoonError, NotFoundError, LoaderModal };
