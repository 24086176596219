import React, { useContext, useEffect, useRef, useState } from "react";
import { CLIENT_USER_TYPE } from "../../../../utils/externalClientUsers";
import { DeliveryPageContext, DELIVERY_TYPE_PAGE } from "../../../OpsComponent";
import { EXT_LINK_TEXT_CHOICES } from "../../NewDelivery";
import SuggestedInput from "../SuggestedInput/SuggestedInput";

import "./BatchDeliveryCard.css";

const getOptionsArr = (data) => {
  let options = data.map((cur) => {
    return { label: cur, value: cur };
  });
  return options;
};

const BatchDeliveryCard = ({
  batch_data_obj,
  asgn_options,
  linkTextChoice,
  asgnCombinations,
  setAsgnCombinations,
  setCombinationData,
  delivery_user_type,
}) => {
  const { deliverPage } = useContext(DeliveryPageContext);
  const extLinkRef = useRef(null);
  const extTextRef = useRef(null);
  const [extLinkTextChoice, setExtLinkTextChoice] = useState(
    linkTextChoice || EXT_LINK_TEXT_CHOICES.LINK,
  );
  const [selectedAsgnOptions, setSelectedAsgnOptions] = useState(
    batch_data_obj.assignment_ids,
  ); // change
  let cur_group_index = asgnCombinations?.findIndex((el) => {
    return JSON.stringify(el) == JSON.stringify(batch_data_obj.assignment_ids);
  });
  // const [selectedOptionsState,setSelectedOptionsState] = useState(getOptionsArr(batch_data_obj.assignment_ids))
  let interimValue = false;
  let emailAttachment = false;

  // ##################### Field types logic ##################

  const default_user_field_types = {
    // this will help target the update of a particular in the batch from the field in the form
    ASSIGNMENT_INPUT: "assignment_ids",
    SOURCE_INPUT: "source_files", // the values of these is the value of the keys in the combination data state objects
    TARGET_INPUT: "target_files",
    INTERIM_INPUT: "interim_output",
    NOTES_INPUT: "delivery_notes",
    DATE_INPUT: "date_input",
    TIME_INPUT: "time_input",
  };

  const external_user_field_types = {
    ASSIGNMENT_INPUT: "assignment_ids",
    SOURCE_INPUT: "source_files",
    TARGET_INPUT: "target_files",
    EXTERNAL_LINK_TEXT_CHOICE: "external_link_text_choice",
    EXTERNAL_LINK: "external_link",
    EXTERNAL_TEXT: "external_text",
    EMAIL_ATTACHMENT: "email_attachment",
    INTERIM_INPUT: "interim_output",
    NOTES_INPUT: "delivery_notes",
    DATE_INPUT: "date_input",
    TIME_INPUT: "time_input",
  };

  // const field_types = (delivery_user_type===CLIENT_USER_TYPE.EXTERNAL) ? external_user_field_types : default_user_field_types

  const field_types = (() => {
    // this is an IIFE invoked bcz we want to have cases in client_user_types
    switch (delivery_user_type) {
      case CLIENT_USER_TYPE.EXTERNAL:
        return external_user_field_types;
      case CLIENT_USER_TYPE.DEFAULT:
        return default_user_field_types;

      default:
        return default_user_field_types;
    }
  })();

  // ##################### Data Input Logic ##################

  const HandleDefaultUserGroupDataChange = (e, field) => {
    setCombinationData((prev) => {
      switch (field) {
        case field_types.NOTES_INPUT:
          prev[batch_data_obj.group_id][field] = e.target.value;
          break;
        case field_types.INTERIM_INPUT:
          prev[batch_data_obj.group_id][field] = interimValue;
          break;
        case field_types.SOURCE_INPUT:
          prev[batch_data_obj.group_id][field] = Array.from(e.target.files);
          break;
        case field_types.TARGET_INPUT:
          prev[batch_data_obj.group_id][field] = Array.from(e.target.files);
          break;

        default:
          break;
      }
      return prev;
    });
  };

  const HandleExternaltUserGroupDataChange = (e, field) => {
    setCombinationData((prev) => {
      switch (field) {
        case field_types.NOTES_INPUT:
          prev[batch_data_obj.group_id][field] = e.target.value;
          break;
        case field_types.INTERIM_INPUT:
          prev[batch_data_obj.group_id][field] = interimValue;
          break;
        case field_types.SOURCE_INPUT:
          prev[batch_data_obj.group_id][field] = Array.from(e.target.files);
          break;
        case field_types.TARGET_INPUT:
          prev[batch_data_obj.group_id][field] = Array.from(e.target.files);
          break;
        case field_types.EXTERNAL_LINK_TEXT_CHOICE:
          prev[batch_data_obj.group_id][field] = e;
          break;
        case field_types.EXTERNAL_LINK:
          prev[batch_data_obj.group_id][field] = e;
          break;
        case field_types.EXTERNAL_TEXT:
          prev[batch_data_obj.group_id][field] = e;
          break;
        case field_types.EMAIL_ATTACHMENT:
          prev[batch_data_obj.group_id][field] = emailAttachment;
          break;
        default:
          break;
      }
      return prev;
    });
  };

  const HandleGroupDataChange = (e, field) => {
    switch (delivery_user_type) {
      case CLIENT_USER_TYPE.EXTERNAL:
        HandleExternaltUserGroupDataChange(e, field);
        break;
      case CLIENT_USER_TYPE.DEFAULT:
        HandleDefaultUserGroupDataChange(e, field);
        break;

      default:
        HandleDefaultUserGroupDataChange(e, field);
        break;
    }
  };

  // ##################### Assignment combination change logic ##################

  const HandleOptionsChange = () => {
    const addArrItem = (arr, idx, item) => {
      // adding item in arr list
      return arr[idx]?.push(item);
    };

    const removeArrItem = (arr, idx, item) => {
      // removing item from arr list item
      if (arr[idx].length == 1) {
        return arr.splice(idx, 1);
      } else {
        let arr_item_el_idx = arr[idx].findIndex((el) => el == item);
        return arr[idx].splice(arr_item_el_idx, 1);
      }
    };

    const addAssignment = (asgn_id) => {
      let asgn_id_index_in_arr = asgnCombinations?.findIndex((el) => {
        return el?.includes(asgn_id);
      });
      setAsgnCombinations((prev) => {
        let new_arr = JSON.parse(JSON.stringify(prev)); // only a deep clone array can be changed and altered
        asgn_id_index_in_arr !== -1 &&
          removeArrItem(new_arr, asgn_id_index_in_arr, asgn_id);
        cur_group_index = new_arr?.findIndex((el) => {
          return (
            JSON.stringify(el) == JSON.stringify(batch_data_obj.assignment_ids)
          );
        });
        addArrItem(new_arr, cur_group_index, asgn_id);
        return new_arr;
      });
    };

    const removeAssignment = (asgn_id) => {
      let asgn_id_index_in_arr = asgnCombinations?.findIndex((el) => {
        return el.includes(asgn_id);
      });
      if (asgn_id_index_in_arr === -1) {
        // this will prevent the card from removing default assignment
        return;
      } else if (asgnCombinations[asgn_id_index_in_arr]?.length == 1) {
        // this will prevent user from removing the last item in the select
        return;
      } else {
        setAsgnCombinations((prev) => {
          let new_arr = JSON.parse(JSON.stringify(prev)); // only a deep clone array can be changed and altered
          removeArrItem(new_arr, asgn_id_index_in_arr, asgn_id);
          new_arr.push([asgn_id]);
          return new_arr;
        });
      }
    };

    if (selectedAsgnOptions.length > batch_data_obj.assignment_ids.length) {
      // find if the element is added
      let new_asgn_id = selectedAsgnOptions.find(
        (asgn_id) => !batch_data_obj.assignment_ids.includes(asgn_id),
      ); // find the new element
      addAssignment(new_asgn_id);
    } else {
      // removed (it can be at the end or in between)
      let removed_asgn_id = batch_data_obj.assignment_ids.find(
        (asgn_id) => !selectedAsgnOptions.includes(asgn_id),
      );
      removeAssignment(removed_asgn_id);
    }
  };

  useEffect(() => {
    if (
      JSON.stringify(selectedAsgnOptions) ===
      JSON.stringify(batch_data_obj.assignment_ids)
    )
      return;
    HandleOptionsChange();
  }, [selectedAsgnOptions]);

  // Handles input change and sets values of fields immediately
  // const handleInput = () => {
  //   setCreateFormData({assignment_id:createAsgnOptions, delivery_id:creatBatchOptions})
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (cur_group_index===0) addAssignment("assign2")
  //     if (cur_group_index===0) removeAssignment("GSA_826497")
  //   }, 3000);
  // }, [])

  // useEffect(() => {
  //   setSelectedOptionsState(getOptionsArr(batch_data_obj.assignment_ids))
  // }, [batch_data_obj])

  // check why suggested input does not update on state change and try to store the file information in a form data
  // change the selectedoptionsstate when the options change and based on the selections remove or add items.
  // form data collection and submission

  const cur_date = () => {
    const date = new Date();
    const day = date.toLocaleDateString("en-GB").split("/")[0];
    const month = date.toLocaleDateString("en-GB").split("/")[1];
    const year = date.toLocaleDateString("en-GB").split("/")[2];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return { date: `${year}-${month}-${day}`, time: `${hours}:${minutes}` };
  };

  const toggleExtLinkText = (e) => {
    if (e.target.checked) {
      HandleGroupDataChange(
        EXT_LINK_TEXT_CHOICES.TEXT,
        field_types.EXTERNAL_LINK_TEXT_CHOICE,
      ); // setting the choice to text
      setExtLinkTextChoice(EXT_LINK_TEXT_CHOICES.TEXT); // this is for the card inputs
      HandleGroupDataChange(null, field_types.EXTERNAL_LINK); // this clears any stored value in state
      extLinkRef.current.value = null; // this clears the input
    } else {
      HandleGroupDataChange(
        EXT_LINK_TEXT_CHOICES.LINK,
        field_types.EXTERNAL_LINK_TEXT_CHOICE,
      );
      setExtLinkTextChoice(EXT_LINK_TEXT_CHOICES.LINK);
      HandleGroupDataChange(null, field_types.EXTERNAL_TEXT);
      extTextRef.current.value = null;
    }
  };

  return (
    <form className="batch-delivery-form-card card-layout">
      <div
        className="batch-delivery-form-info"
        id="batch-delivery-form-group-assignment"
      >
        <div className="batch-delivery-form-info-label">Group Assignments</div>
        {/* <input className='ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-info-value' type='text'></input> */}
        <SuggestedInput
          placeholder="Enter assignments"
          setSelectedInputOptions={setSelectedAsgnOptions} // change
          optionsArr={getOptionsArr(asgn_options)}
          selectedValue={getOptionsArr(batch_data_obj.assignment_ids)}
          isSearchable={false}
        />
      </div>

      <div
        className="batch-delivery-form-info"
        id="batch-delivery-form-file-input"
      >
        <div className="batch-delivery-form-info-label">Input</div>
        <input
          className="ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-info-value"
          type="file"
          id="delivery-file-source-file"
          name="delivery-file-source-file"
          multiple
          accept=".docx,.pptx,.xlsx,.pdf,.jpg,.jpeg,.mp4,.srt,.png,.ai,.eps,.psd,.zip"
          onClick={(e) => (e.target.value = null)}
          onChange={(e) => {
            HandleGroupDataChange(e, field_types.SOURCE_INPUT);
          }}
        ></input>
      </div>

      <div
        className="batch-delivery-form-info"
        id="batch-delivery-form-file-output"
      >
        <div className="batch-delivery-form-info-label">Output</div>
        <input
          className="ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-info-value"
          type="file"
          id="delivery-file-target-file"
          name="delivery-file-target-file"
          multiple
          accept=".docx,.pptx,.xlsx,.pdf,.jpg,.jpeg,.mp4,.srt,.png,.ai,.eps,.psd,.zip"
          onClick={(e) => (e.target.value = null)}
          onChange={(e) => {
            HandleGroupDataChange(e, field_types.TARGET_INPUT);
          }}
        ></input>
      </div>

      {delivery_user_type === CLIENT_USER_TYPE.EXTERNAL && (
        <div
          className="batch-delivery-form-info"
          id="batch-delivery-form-external-file-link"
        >
          <div className="batch-delivery-form-info-label">
            {extLinkTextChoice === EXT_LINK_TEXT_CHOICES.LINK && (
              <span>External File Sharing Link</span>
            )}
            {extLinkTextChoice === EXT_LINK_TEXT_CHOICES.TEXT && (
              <span>External File Text/Code</span>
            )}
            {!linkTextChoice && (
              <div className="ops-dashboard-external-input-choice">
                <span>Link / Text</span>
                <label className="ops-dashboard-input-switch">
                  <input
                    onChange={toggleExtLinkText}
                    className="ops-dashboard-input ops-dashboard-input-switch-input"
                    type="checkbox"
                    checked={extLinkTextChoice === EXT_LINK_TEXT_CHOICES.TEXT}
                  />
                  <span className="ops-dashboard-input-switch-slider"></span>
                </label>
              </div>
            )}
          </div>
          {extLinkTextChoice === EXT_LINK_TEXT_CHOICES.LINK && (
            <input
              ref={extLinkRef}
              type="url"
              onChange={(e) =>
                HandleGroupDataChange(e.target.value, field_types.EXTERNAL_LINK)
              }
              className="ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-info-value"
              placeholder="https://www.link.com"
            ></input>
          )}
          {extLinkTextChoice === EXT_LINK_TEXT_CHOICES.TEXT && (
            <input
              ref={extTextRef}
              type="text"
              onChange={(e) =>
                HandleGroupDataChange(e.target.value, field_types.EXTERNAL_TEXT)
              }
              className="ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-info-value"
              placeholder="Enter Code"
            ></input>
          )}
        </div>
      )}

      <div
        className="batch-delivery-form-info"
        id="batch-delivery-form-delivery-notes"
      >
        <div className="batch-delivery-form-info-label">Delivery Notes</div>
        <textarea
          onChange={(e) => HandleGroupDataChange(e, field_types.NOTES_INPUT)}
          className="ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-info-value"
          placeholder="Type here..."
        ></textarea>
      </div>

      <div
        className="batch-delivery-form-info"
        id="batch-delivery-form-services"
      >
        <div className="batch-delivery-form-info-label">Services</div>
        <div className="batch-delivery-form-info-value">
          {/* {batch_data_obj?.assignment_services?.map((service,i)=>{return <span key={i}>{service}</span>})} */}
          <div className="batch-delivery-upload-info-service-value-container">
            {batch_data_obj?.assignment_services?.map((service) => {
              return service?.sub_services?.map((sub_service, idx) => {
                return (
                  <div
                    key={idx}
                    className="batch-delivery-upload-info-service-value-item"
                  >
                    <div className="batch-delivery-upload-info-service-value-item-text">
                      {service.service} ::{" "}
                      {`${sub_service.unit_count} ${sub_service.unit_type} ${sub_service.sub_service}`}
                    </div>
                  </div>
                );
              });
            })}
          </div>
        </div>
      </div>

      <div
        className="batch-delivery-form-info"
        id="batch-delivery-form-assignment-name"
      >
        <div className="batch-delivery-form-info-label">
          {deliverPage === DELIVERY_TYPE_PAGE.NEW.ADD
            ? "Assignment Name"
            : "Source File"}
        </div>
        <div className="batch-delivery-form-info-value">
          {batch_data_obj?.assignment_names?.map((name, i) => {
            return <span key={i}>{name}</span>;
          })}
        </div>
      </div>

      <div
        className="batch-delivery-form-info"
        id="batch-delivery-form-interim-output"
      >
        <div className="batch-delivery-form-info-label">
          Is this interim output?
        </div>
        <div id="batch-delivery-form-info-radio">
          <div className="batch-delivery-form-info-value">
            <input
              type="radio"
              name="interim_output"
              value={"no"}
              defaultChecked={!interimValue}
              onClick={(e) => {
                interimValue = false;
                HandleGroupDataChange(e, field_types.INTERIM_INPUT);
              }}
            />
            <span>No</span>
          </div>
          <div className="batch-delivery-form-info-value">
            <input
              type="radio"
              name="interim_output"
              value={"yes"}
              defaultChecked={interimValue}
              onClick={(e) => {
                interimValue = true;
                HandleGroupDataChange(e, field_types.INTERIM_INPUT);
              }}
            />
            <span>Yes</span>
          </div>
        </div>
      </div>

      {delivery_user_type === CLIENT_USER_TYPE.EXTERNAL && (
        <div
          className="batch-delivery-form-info"
          id="batch-delivery-form-email-attachment"
        >
          <div className="batch-delivery-form-info-label">
            Attach the file to the email? (Only up to 25 MB)
          </div>
          <div id="batch-delivery-form-info-radio">
            <div className="batch-delivery-form-info-value">
              <input
                type="radio"
                name="email_attachment"
                value={"no"}
                defaultChecked={!emailAttachment}
                onClick={(e) => {
                  emailAttachment = false;
                  HandleGroupDataChange(e, field_types.EMAIL_ATTACHMENT);
                }}
              />
              <span>No</span>
            </div>
            <div className="batch-delivery-form-info-value">
              <input
                type="radio"
                name="email_attachment"
                value={"yes"}
                defaultChecked={emailAttachment}
                onClick={(e) => {
                  emailAttachment = true;
                  HandleGroupDataChange(e, field_types.EMAIL_ATTACHMENT);
                }}
              />
              <span>Yes</span>
            </div>
          </div>
        </div>
      )}

      {deliverPage === DELIVERY_TYPE_PAGE.UPDATE.ADD && (
        <div
          className="batch-delivery-form-info"
          id="batch-delivery-form-committed-delivery"
        >
          <div className="batch-delivery-form-info-label">
            Committed Delivery
          </div>
          <div className="batch-delivery-form-committed-delivery-datetime-container">
            <div className="batch-delivery-form-committed-delivery-datetime-input-container">
              <input
                className="ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-committed-delivery-datetime-input"
                type="date"
                min="2022-01-01"
                max="2022-12-31"
                defaultValue={cur_date()["date"]}
              ></input>
              {/* <FaAngleDown/> */}
            </div>
            <div className="batch-delivery-form-committed-delivery-datetime-input-container">
              <input
                className="ops-dashboard-input batch-delivery-form-info-input batch-delivery-form-committed-delivery-datetime-input"
                type="time"
                defaultValue={cur_date()["time"]}
              ></input>
              {/* <FaAngleDown/> */}
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default BatchDeliveryCard;
