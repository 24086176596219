import React, { useState, useRef, useContext } from "react";
import { FaEye } from "react-icons/fa";
import { postData } from "../../helpers/request";
import { ApiContext } from "../../index.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { ForgetMailSentContext } from "../OpsComponent";

//Error components
const InputError = (props) => {
  return (
    <div className="login-input-error password-error">{props.errorMsg}</div>
  );
};

const PasswordErrorModal = (props) => {
  return (
    <div className="login-input-error password-error">{props.errorMsg}</div>
  );
};

const OpsChangePasswordForm = () => {
  const API_URL = useContext(ApiContext);
  const { search } = useLocation();
  const history = useHistory();
  const loggedOpsPassword = useRef(null);
  const loggedOpsConfirmPassword = useRef(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confrimPasswordError, setConfirmPasswordError] = useState(null);
  const { q } = queryString.parse(search);

  // Checking token
  // useEffect(() => {
  //     getData(`${API_URL}/api/auth/v1/verify-account/?q=${q}`)
  //         .then((res) => {
  //             if (res.success === false) {
  //                 if (res.message === "Your account is already verified.") {
  //                     history.push("/forgotpassword")
  //                 } else {
  //                     alert(res.message)
  //                 }
  //             }
  //         })
  //         .catch(err => {
  //             // console.error(err);
  //         })
  // }, [])

  const accountValidation = (password, confirmPassword) => {
    // Checking for password
    if (password === "") {
      setConfirmPasswordError(null);
      return {
        valid: false,
        error: { msg: "Empty password!", onInput: "password" },
      };
      // } else if (!passwordValidation){
      //     setConfirmPasswordError(null);
      //     return {valid:passwordValidation,error:{msg:"Invalid password!", onInput:'password'}}
    } else {
      setPasswordError(null);
    }

    // Checking for confirm password
    if (confirmPassword === "") {
      setPasswordError(null);
      return {
        valid: false,
        error: { msg: "Empty confirm password!", onInput: "confirm-password" },
      };
    } else if (confirmPassword !== password) {
      setPasswordError(null);
      return {
        valid: false,
        error: { msg: "Passwords don't match!", onInput: "confirm-password" },
      };
    } else {
      setConfirmPasswordError(null);
    }

    // Passed all test
    return { valid: true, data: { password } };
  };

  const clearInput = () => {
    // Clearing the fields
    loggedOpsPassword.current.value = "";
    loggedOpsConfirmPassword.current.value = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      password: loggedOpsPassword.current.value,
      confirmPassword: loggedOpsConfirmPassword.current.value,
    };
    const data = { password: loggedOpsPassword.current.value, q };
    const validation = accountValidation(user.password, user.confirmPassword); // validatinng the input
    if (validation.valid) {
      clearInput();
      postData(`${API_URL}/api-ops/auth/v1/change-password/`, data)
        .then((res) => {
          if (res.success === true) {
            history.push("/");
          } else {
            alert(res.message);
          }
        })
        .catch(() => {
          // console.error(err);
        });
    } else {
      validation.error.onInput === "password" &&
        setPasswordError(validation.error.msg);
      validation.error.onInput === "confirm-password" &&
        setConfirmPasswordError(validation.error.msg);
    }
  };

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  return (
    <form className="common-form login-form" onSubmit={handleSubmit}>
      <div className="form-head">Forgot Password?</div>

      <div className="ops-password-input">
        <input
          className="form-input"
          ref={loggedOpsPassword}
          type="password"
          id="ops-password"
          name="ops-password"
          placeholder="Password"
        />
        <span
          className="pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            showPassword(loggedOpsPassword);
            loggedOpsPassword.current.focus();
          }}
        >
          <FaEye style={{ fontSize: "1vw" }} />
        </span>
      </div>
      {passwordError && <PasswordErrorModal errorMsg={passwordError} />}

      <div className="ops-password-input">
        <input
          className="form-input"
          ref={loggedOpsConfirmPassword}
          type="password"
          id="ops-password"
          name="ops-password"
          placeholder="Confirm Password"
        />
        <span
          className="pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            showPassword(loggedOpsConfirmPassword);
            loggedOpsConfirmPassword.current.focus();
          }}
        >
          <FaEye style={{ fontSize: "1vw" }} />
        </span>
      </div>
      {confrimPasswordError && (
        <PasswordErrorModal errorMsg={confrimPasswordError} />
      )}

      <div className="ops-login-btns-container">
        <div className="ops-form-login-text-btns">
          <Link className="ops-login-form-btns ops-anchor" to="/">
            Login
          </Link>
          {/* <Link className="ops-login-form-btns ops-anchor" >Forgot Password</Link> */}
          <Link
            className="ops-login-form-btns ops-anchor"
            to="/oppsforgotpassword"
            style={{ float: "right", textDecoration: "none", color: "white" }}
          >
            Resend Link
          </Link>
        </div>

        <div className="ops-login-btn">
          <button
            className="form-btn login-btn"
            id="ops-update-password"
            type="submit"
          >
            Update Password
          </button>
        </div>
      </div>

      {/* <div className="login-input ops-password-input">
                <input className="client-input client-form-input" ref={loggedOpsPassword} type="password" id='client-password' name='client-password' placeholder="Password"></input>
                <span className="client-pswd-show-btn" onClick={(e)=>{
                    e.preventDefault();
                    e.target.closest('.client-pswd-show-btn').classList.toggle("client-pswd-show-btn-active")
                    showPassword(loggedOpsPassword);
                    loggedOpsPassword.current.focus();}
                }><FaEye/></span>
                {passwordError && <InputError errorMsg={passwordError} />}
            </div>

            <div className="login-input ops-password-input">
                <input className="client-input client-form-input" ref={loggedOpsConfirmPassword} type="password" id='client-conifrm-password' name='client-conifrm-password' placeholder="Confirm Password" />
                <span className="client-pswd-show-btn" onClick={(e)=>{
                    e.preventDefault();
                    e.target.closest('.client-pswd-show-btn').classList.toggle("client-pswd-show-btn-active")
                    showPassword(loggedOpsConfirmPassword)
                    loggedOpsConfirmPassword.current.focus();}
                }><FaEye/></span>
                {confrimPasswordError && <InputError errorMsg={confrimPasswordError} />}
            </div> */}

      {/* <table className="client-login-bottom-form-btns-table">
                <tbody>
                    <tr>
                        <td><Link className="ops-login-form-btns ops-anchor" to="/">Login</Link></td>
                        <td>
                            <Link className="ops-login-form-btns ops-anchor" to="/oppsforgotpassword" style={{float:"right", textDecoration:"none", color:"white"}}>Resend Link</Link>
                        </td>
                    </tr>
                    <tr>
                        <td><Link className="client-login-form-btns client-anchor" to="/signup">Sign Up</Link></td>
                        <td><button className="form-btn login-btn" id="ops-update-password" type='submit'>Update Password</button></td>
                    </tr>
                </tbody>
            </table> */}
    </form>
  );
};

// Forgot Password Form Component

const OpsForgetPasswordForm = () => {
  const { setOpsForgetMailSent } = useContext(ForgetMailSentContext);

  const API_URL = useContext(ApiContext);

  // Using ref hook to capture user credentials
  const loggedOpsEmail = useRef(null);
  const [emailError, setEmailError] = useState(null);

  const accountValidation = (email) => {
    const emailRegex = /\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*/g;
    const valid = emailRegex.test(email);
    if (email === "") {
      setEmailError("Empty email!");
    } else if (!valid) {
      setEmailError("Invalid Email");
    }
    return valid;
  };

  const clearInput = () => {
    loggedOpsEmail.current.value = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { email: loggedOpsEmail.current.value.toLowerCase().trim() };
    const validation = accountValidation(data.email); // validatinng the input
    if (validation) {
      clearInput();
      postData(`${API_URL}/api-ops/auth/v1/forget-password/`, data)
        .then((res) => {
          if (res.success === true) {
            // alert("Please check your email and find the link.")
            setOpsForgetMailSent(true);
          } else {
            setEmailError(res.message);
          }
        })
        .catch(() => {
          // console.error(err);
        });
    }
  };

  return (
    <form className="ops-login-form" onSubmit={handleSubmit}>
      <div className="form-head">Fogot Password?</div>

      <div className="login-input">
        <input
          className="form-input"
          ref={loggedOpsEmail}
          type="text"
          id="ops-email"
          name="ops-email"
          placeholder="Work Email"
        />
        {emailError && <InputError errorMsg={emailError} />}
      </div>
      <div className="ops-login-btns-section">
        <div className="ops-login-btn-part">
          <Link className="ops-login-form-btns ops-anchor" to="/">
            Login
          </Link>
          <button
            className="form-btn login-btn"
            id="forget-pswd-btn"
            type="submit"
          >
            Send Link
          </button>
        </div>
      </div>
    </form>
  );
};

const OpsCheckForgetVerificationEmail = () => {
  const { setOpsForgetMailSent } = useContext(ForgetMailSentContext);

  const resendVerification = (e) => {
    e.preventDefault();
    setOpsForgetMailSent(false);
  };

  return (
    <div className="ops-verify-container">
      <div className="form-head">Check your inbox form verification email.</div>
      <div
        className="ops-login-btns-section"
        id="ops-verify-forget-password-btns-section"
      >
        <div className="ops-login-btn-part">
          <span
            className="ops-login-form-btns ops-anchor"
            onClick={resendVerification}
          >
            Resend Verification
          </span>
          <Link className="ops-login-form-btns login-btn" to="/">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export {
  OpsForgetPasswordForm,
  OpsChangePasswordForm,
  OpsCheckForgetVerificationEmail,
};
