import React, { useContext, useEffect, useState } from "react";
import {
  DeliveryPageContext,
  DELIVERY_TYPE_PAGE,
  LoaderContext,
} from "../../../OpsComponent";
import BatchDeliveryHead from "../../components/BatchDeliveryHead/BatchDeliveryHead";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import { ApiContext, devEnv } from "../../../..";
import Select from "react-select";
import {
  customStyles,
  customTheme,
} from "../../components/SuggestedInput/SuggestedInput";

import dummy_batch_assignments from "../../../../archive/test_data/dummy_data-v1/DeliveryDummyData/dummy_create_new.json";
// import dummy_batch from "../../../../archive/test_data/dummy_data-v1/DeliveryDummyData/dummy_batchs.json";
// import dummy_delivery from "../../../../archive/test_data/dummy_data-v1/DeliveryDummyData/dummy_delivery.json";
import dummy_external_delivery from "../../../../archive/test_data/dummy_data-v1/DummyExternalUserData/ExternalDeliveryDummyData/dummy_ext_delivery.json";

import "./CreateDelivery.css";
import {
  AddNewFileDataContext,
  AsgnCombinationsContext,
} from "../../NewDelivery";
import { Link } from "react-router-dom";

const CreateDeliveryForm = ({ setCreateFormData }) => {
  const API_URL = useContext(ApiContext);
  const [batchInput, setBatchInput] = useState(null);
  // const [asgnInputText, setAsgnInputText] = useState(null);
  const [createAsgnOptions, setCreateAsgnOptions] = useState(null);
  const [createSelectedAsgns, setCreateSelectedAsgns] = useState(null);
  const [optionsData, setOptionsData] = useState(null);
  const { setLoader } = useContext(LoaderContext);

  // const [creatBatchOptions,setCreateBatchOptions]=useState(null); // if the batch input becomes searchable then use it
  // const [batchInputText,setBatchInputText]=useState(null); // if the batch input becomes searchable then use it
  // const [batchOptions,setBatchOptions]=useState([]);
  // const [asgnOptions,setAsgnOptions]=useState([]);

  // Handles input change and sets values of fields immediately
  const handleInput = () => {
    setCreateFormData({
      assignment_id: createSelectedAsgns?.map((cur) => cur.value),
      delivery_id: batchInput,
    });
  };

  // // Create Label Value pairs for the options format of Assginment Select that the suggestions input can understand
  // const getAsgnDataOptionsFormat = (data) => {
  //   if (data.length<1) return
  //   let options_format_data = data.map((cur)=>{
  //     return {label: `${cur.assignment_id} - ${cur.requester_name} (${cur.client_code})`,value: cur.assignment_id}
  //   })
  //   return options_format_data
  // }

  // // Fetches Assignments based on assignment input
  // const fetchAsgnOptions = (asgn_text) => {
  //   if (devEnv){
  //     const myPromise = new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         resolve(getAsgnDataOptionsFormat(dummy_batch_assignments.data));
  //       }, 1000);
  //     });
  //     return myPromise.then((options=>options))
  //   }
  //   // return getAuthData(`${API_URL}/api-ops/workspace/v1/assignment-api/${asgn_text}`)  // this will be used in case searching is implemented
  //   return getAuthData(`${API_URL}/api-ops/workspace/v1/assignment-api/`)
  //     .then((res) => {
  //         if (res.success === true) {
  //           return getAsgnDataOptionsFormat(res.data)
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // Create Label Value pairs for the options format of Assginment Select that the suggestions input can understand
  // const getBatchDataOptionsFormat = (data) => {
  //   let options_format_data = data.map((cur)=>{
  //     return {label: cur,value: cur}
  //   })
  //   return options_format_data
  // }

  // // Fetches Batches based on batch input
  // const fetchBatchOptions = (batch_text) => {
  //   if (devEnv){
  //     const myPromise = new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         resolve(getBatchDataOptionsFormat(dummy_batch.data));
  //       }, 1000);
  //     });
  //     return myPromise
  //       .then((options=>options))
  //   }

  //   return getAuthData(`${API_URL}/api-ops/workspace/v1/assignment-api/${batch_text}`)
  //     .then((res) => {
  //         if (res.success === true) {
  //           return getBatchDataOptionsFormat(res.data)
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // Create Label Value pairs for the options format of Assginment Select that the suggestions input can understand

  const getAsgnDataOptionsFormat = (
    data,
    requester_name = null,
    client_code = null,
  ) => {
    if (!data || data.length < 1) return;
    let options_format_data = data.map((cur) => {
      return {
        label: `${cur.assignment_id} - ${cur.requester_name} (${cur.client_code})`,
        value: cur.assignment_id,
        requester_name: cur.requester_name, // required for searching purposes
        client_code: cur.client_code, // required for searching purposes
      };
    });

    // categorising data based on requester name
    if (requester_name) {
      options_format_data = options_format_data.filter(
        (cur) => cur.requester_name === requester_name,
      );
    }

    // categorising data based on client code
    if (client_code) {
      options_format_data = options_format_data.filter(
        (cur) => cur.client_code === client_code,
      );
    }

    return options_format_data;
  };

  // Getting all the options
  const getAllAssignmentsOptions = () => {
    setLoader(true);
    if (devEnv) {
      setOptionsData(dummy_batch_assignments.data); // needed to do searching on the data
      setCreateAsgnOptions(
        getAsgnDataOptionsFormat(dummy_batch_assignments.data),
      );
      setLoader(false);
      return;
    }
    getAuthData(`${API_URL}/api-ops/workspace/v1/assignment-api/`)
      .then((res) => {
        if (res.success === true) {
          setOptionsData(res.data); // needed to do searching on the data
          setCreateAsgnOptions(getAsgnDataOptionsFormat(res.data));
        }
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    handleInput();
  }, [createSelectedAsgns, batchInput]);

  useEffect(() => {
    getAllAssignmentsOptions();
  }, []);

  const categoriseRequesterNameOptions = (val) => {
    setCreateAsgnOptions(
      getAsgnDataOptionsFormat(
        optionsData,
        val?.slice(-1)[0]?.requester_name || null,
        val?.slice(-1)[0]?.client_code || null,
      ),
    ); // filtering out options based on client code and requester name
  };

  const handleSelectChange = (val) => {
    // change
    val.length <= 1 && categoriseRequesterNameOptions(val);
    return setCreateSelectedAsgns(val);
    // return setCreateSelectedAsgns(getAsgnDataOptionsFormat(optionsData?.filter((cur)=>{return val.value.includes(cur.assignment_id)})))
  };

  return (
    <form className="batch-delivery-create-form-card card-layout">
      <div className="batch-delivery-form-info">
        <div className="batch-delivery-form-info-label">
          Workspace Assignment ID(s)
        </div>
        {/* <FetchSuggestedInput
          placeholder='Enter Workspace ID'
          fetchOptions={fetchAsgnOptions}
          setInputOptions={setCreateAsgnOptions}
          setInputText={setAsgnInputText}
        /> */}
        <Select
          onChange={handleSelectChange} // change
          noOptionsMessage={() => "No options found."}
          placeholder={
            createAsgnOptions
              ? "Enter Workspace ID"
              : "Loading selected values..."
          }
          value={createSelectedAsgns}
          isMulti
          isSearchable
          options={createAsgnOptions}
          theme={customTheme}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={customStyles}
        />
      </div>
      <div className="batch-delivery-form-info">
        <div className="batch-delivery-form-info-label">Batch ID</div>
        {/* <SuggestedInput
          placeholder='Enter Batch ID'
          setInputOptions={setCreateBatchOptions}
          fetchOptions={fetchBatchOptions}
          setInputText={setBatchInputText}
          multi={false}
        /> */}
        <input
          type="text"
          onChange={(e) => setBatchInput(e.target.value)}
          className="ops-dashboard-input batch-delivery-form-input"
          placeholder="Enter Batch"
        />
      </div>
    </form>
  );
};

const CreateDeliveryContainer = () => {
  const [canTakeRequest, setCanTakeRequest] = useState(false); // for disabling the "NEXT" button till Api request is finished
  const API_URL = useContext(ApiContext);
  const { setAddNewFileData } = useContext(AddNewFileDataContext);
  const { setAsgnCombinations } = useContext(AsgnCombinationsContext); // combination for cards
  const { deliverPage, setDeliverPage } = useContext(DeliveryPageContext);
  const [createFormData, setCreateFormData] = useState({
    delivery_id: null,
    assignment_id: null,
  });

  const handleSubmit = () => {
    if (deliverPage === DELIVERY_TYPE_PAGE.NEW.CREATE) {
      if (devEnv) {
        if (
          createFormData?.assignment_id?.length ||
          createFormData?.delivery_id
        ) {
          // validating assignment input
          console.log(createFormData);
          // ========== this is external delivery
          setAddNewFileData(dummy_external_delivery);
          setAsgnCombinations(dummy_external_delivery.default_groups);
          // ========== this is default delivery
          // setAddNewFileData(dummy_delivery)
          // setAsgnCombinations(dummy_delivery.default_groups);  // setting the combinations of groups
          setDeliverPage(DELIVERY_TYPE_PAGE.NEW.ADD);
        } else {
          alert("Please provide either batch id or assignments!");
        }
      } else {
        if (
          createFormData?.assignment_id?.length ||
          createFormData?.delivery_id
        ) {
          setCanTakeRequest(true);
          //validating assignment input
          postAuthData(
            `${API_URL}/api-ops/ops/v1/detail-assignment/`,
            createFormData,
          )
            .then((res) => {
              if (res.success === true) {
                setAddNewFileData(res); // loading file data
                setAsgnCombinations(res.default_groups); // setting the combinations of groups
                setDeliverPage(DELIVERY_TYPE_PAGE.NEW.ADD); // page change to add
              } else {
                alert(res?.message || "Cant upload file. Please try again.");
              }
              setCanTakeRequest(false);
            })
            .catch((err) => {
              console.log(err);
              alert(err?.message || "Cant upload file. Please try again.");
              setCanTakeRequest(false);
            });
        } else {
          alert("Please provide either batch id or assignments!");
          setCanTakeRequest(false);
        }
      }
    } else {
      setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.ADD);
      console.log(createFormData);
    }
  };

  return (
    <section className="batch-delivery-container">
      <div className="batch-delivery-head">
        <div className="batch-delivery-head-container">
          <span className="batch-delivery-head-text">Create Delivery</span>
        </div>
        <div className="batch-delivery-head-container">
          <Link
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-cancel-btn"
            to={`/deliver/`}
          >
            Cancel
          </Link>
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-submit-btn"
            onClick={handleSubmit}
            disabled={canTakeRequest}
          >
            Next
          </button>
        </div>
      </div>
      <CreateDeliveryForm
        createFormData={createFormData}
        setCreateFormData={setCreateFormData}
      />
    </section>
  );
};

const CreateDelivery = () => {
  return (
    <main className="file-deliver-container card-layout">
      <BatchDeliveryHead />
      <CreateDeliveryContainer />
    </main>
  );
};

export default CreateDelivery;
