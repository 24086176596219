import React, { useContext, useEffect, useState } from "react";
import { DeliveryPageContext, DELIVERY_TYPE_PAGE } from "../../../OpsComponent";
import UpdateBatchDeliveryHead from "../../components/UpdateBatchDeliveryHead/UpdateBatchDeliveryHead";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import { ApiContext, devEnv } from "../../../..";
import {
  UpdateAddNewFileDataContext,
  UpdateAsgnCombinationsContext,
} from "../../UpdateDelivery";
import {
  customStyles,
  customTheme,
} from "../../components/SuggestedInput/SuggestedInput";
import Select from "react-select";

import dummy_batch_assignments from "../../../../archive/test_data/dummy_data-v1/DeliveryDummyData/dummy_update_create.json";
import dummy_update_delivery from "../../../../archive/test_data/dummy_data-v1/DeliveryDummyData/dummy_update_delivery.json";

import "./UpdateCreateDelivery.css";
import { useSelector } from "react-redux";

const CreateDeliveryForm = ({ setCreateFormData }) => {
  const liveAssignmentData = useSelector((state) => state.liveAssignmentData);
  const API_URL = useContext(ApiContext);
  const [batchInput, setBatchInput] = useState(null);
  // const [asgnInputText, setAsgnInputText] = useState(null);
  const [createAsgnOptions, setCreateAsgnOptions] = useState(null);
  const [createSelectedAsgns, setCreateSelectedAsgns] = useState(null);
  const [, setOptionsData] = useState(null);
  const [resBatchId, setResBatchId] = useState(null);
  // const [creatBatchOptions,setCreateBatchOptions]=useState(null); // if the batch input becomes searchable then use it
  // const [batchInputText,setBatchInputText]=useState(null); // if the batch input becomes searchable then use it
  // const [batchOptions,setBatchOptions]=useState([]);
  // const [asgnOptions,setAsgnOptions]=useState([]);

  // Handles input change and sets values of fields immediately
  const handleInput = () => {
    setCreateFormData({
      assignment_id: createSelectedAsgns?.map((cur) => cur.value),
      batch_id: batchInput,
      delivery_id: liveAssignmentData?.file_details?.batch_id,
    });
  };

  // Create Label Value pairs for the options format of Assginment Select that the suggestions input can understand
  const getAsgnDataOptionsFormat = (
    data,
    fixedArr = [],
    requester_name = null,
    client_code = null,
  ) => {
    if (!data || data.length < 1) return [];
    let options_format_data = data.map((cur) => {
      return {
        label: `${cur.assignment_id} - ${cur.requester_name} (${cur.client_code})`,
        value: cur.assignment_id,
        isFixed: fixedArr.includes(cur.assignment_id),
        requester_name: cur.requester_name, // required for searching purposes
        client_code: cur.client_code, // required for searching purposes
      };
    });

    // categorising data based on requester name
    if (requester_name) {
      options_format_data = options_format_data.filter(
        (cur) => cur.requester_name === requester_name,
      );
    }

    // categorising data based on Client Code
    if (client_code) {
      options_format_data = options_format_data.filter(
        (cur) => cur.client_code === client_code,
      );
    }

    return options_format_data;
  };

  // Getting all the options
  const getAllAssignmentsOptions = () => {
    if (devEnv) {
      setOptionsData(dummy_batch_assignments.data); // needed to do searching on the data
      if (
        dummy_batch_assignments.default_data.asgn_ids.length > 0 && // if there are default assignments
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.requester_name && // if there is a requester name for that default assignment
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.requester_name !== "" && // if the requester name is not empty then only filter out the data other wise present the normal data as it is
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.client_code && // if there is a client code for that default assignment
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.client_code !== ""
      ) {
        // if the client code is not empty then only filter out the data other wise present the normal data as it is
        setCreateAsgnOptions(
          getAsgnDataOptionsFormat(
            dummy_batch_assignments.data,
            [],
            dummy_batch_assignments.data.find(
              (cur) =>
                cur.assignment_id ===
                dummy_batch_assignments.default_data.asgn_ids[0],
            )?.requester_name,
            dummy_batch_assignments.data.find(
              (cur) =>
                cur.assignment_id ===
                dummy_batch_assignments.default_data.asgn_ids[0],
            )?.client_code,
          ),
        );
      } else if (
        dummy_batch_assignments.default_data.asgn_ids.length > 0 && // if there are default assignments
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.requester_name && // if there is a requester name for that default assignment
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.requester_name !== ""
      ) {
        // if the requester name is not empty then only filter out the data other wise present the normal data as it is
        setCreateAsgnOptions(
          getAsgnDataOptionsFormat(
            dummy_batch_assignments.data,
            [],
            dummy_batch_assignments.data.find(
              (cur) =>
                cur.assignment_id ===
                dummy_batch_assignments.default_data.asgn_ids[0],
            )?.requester_name,
          ),
        );
      } else if (
        dummy_batch_assignments.default_data.asgn_ids.length > 0 && // if there are default assignments
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.client_code && // if there is a client code for that default assignment
        dummy_batch_assignments.data.find(
          (cur) =>
            cur.assignment_id ===
            dummy_batch_assignments.default_data.asgn_ids[0],
        )?.client_code !== ""
      ) {
        // if the client code is not empty then only filter out the data other wise present the normal data as it is
        setCreateAsgnOptions(
          getAsgnDataOptionsFormat(
            dummy_batch_assignments.data,
            [],
            dummy_batch_assignments.data.find(
              (cur) =>
                cur.assignment_id ===
                dummy_batch_assignments.default_data.asgn_ids[0],
            )?.client_code,
          ),
        );
      } else {
        setCreateAsgnOptions(
          getAsgnDataOptionsFormat(dummy_batch_assignments.data),
        );
      }
      setCreateSelectedAsgns(
        getAsgnDataOptionsFormat(
          dummy_batch_assignments.data?.filter((cur) => {
            return dummy_batch_assignments.default_data.asgn_ids.includes(
              cur.assignment_id,
            );
          }),
          dummy_batch_assignments.default_data.asgn_ids,
        ),
      );
      setBatchInput(dummy_batch_assignments.default_data.batch_id);
      setResBatchId(dummy_batch_assignments?.default_data?.batch_id);
      return;
    }
    getAuthData(
      `${API_URL}/api-ops/workspace/v1/update-assignment-api/${liveAssignmentData?.file_details?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setOptionsData(res.data.data); // needed to do searching on the data
          if (
            res.data.default_data.asgn_ids.length > 0 && // if there are default assignments
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.requester_name && // if there is a requester name for that default assignment
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.requester_name !== "" && // if the requester name is not empty then only filter out the data other wise present the normal data as it is
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.client_code && // if there is a client code for that default assignment
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.client_code !== ""
          ) {
            // if the client code is not empty then only filter out the data other wise present the normal data as it is
            setCreateAsgnOptions(
              getAsgnDataOptionsFormat(
                res.data.data,
                [],
                res.data.data.find(
                  (cur) =>
                    cur.assignment_id === res.data.default_data.asgn_ids[0],
                )?.requester_name,
              ),
            );
          } else if (
            res.data.default_data.asgn_ids.length > 0 && // if there are default assignments
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.requester_name && // if there is a requester name for that default assignment
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.requester_name !== ""
          ) {
            // if the requester name is not empty then only filter out the data other wise present the normal data as it is
            setCreateAsgnOptions(
              getAsgnDataOptionsFormat(
                res.data.data,
                [],
                res.data.data.find(
                  (cur) =>
                    cur.assignment_id === res.data.default_data.asgn_ids[0],
                )?.requester_name,
              ),
            );
          } else if (
            res.data.default_data.asgn_ids.length > 0 && // if there are default assignments
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.client_code && // if there is a client code for that default assignment
            res.data.data.find(
              (cur) => cur.assignment_id === res.data.default_data.asgn_ids[0],
            )?.client_code !== ""
          ) {
            // if the client code is not empty then only filter out the data other wise present the normal data as it is
            setCreateAsgnOptions(
              getAsgnDataOptionsFormat(
                res.data.data,
                [],
                res.data.data.find(
                  (cur) =>
                    cur.assignment_id === res.data.default_data.asgn_ids[0],
                )?.client_code,
              ),
            );
          } else {
            setCreateAsgnOptions(getAsgnDataOptionsFormat(res.data.data));
          }
          setCreateSelectedAsgns(
            getAsgnDataOptionsFormat(
              res.data.data?.filter((cur) => {
                return res.data.default_data.asgn_ids.includes(
                  cur.assignment_id,
                );
              }),
            ),
          );
          setBatchInput(res.data.default_data.batch_id);
          setResBatchId(res?.data?.default_data?.batch_id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // // Fetches Assignments based on assignment input
  // const fetchAsgnOptions = (asgn_text) => {
  //   if (devEnv){
  //     const myPromise = new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         resolve(getAsgnDataOptionsFormat(dummy_batch_assignments.data));
  //       }, 1000);
  //     });
  //     return myPromise.then((options=>options))
  //   }
  //   // return getAuthData(`${API_URL}/api-ops/workspace/v1/assignment-api/${asgn_text}`)  // this will be used in case searching is implemented
  //   return getAuthData(`${API_URL}/api-ops/workspace/v1/assignment-api/`)
  //     .then((res) => {
  //         if (res.success === true) {
  //           return getAsgnDataOptionsFormat(res.data)
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  // // Create Label Value pairs for the options format of Assginment Select that the suggestions input can understand
  // const getBatchDataOptionsFormat = (data) => {
  //   let options_format_data = data.map((cur)=>{
  //     return {label: cur,value: cur}
  //   })
  //   return options_format_data
  // }

  // // Fetches Batches based on batch input
  // const fetchBatchOptions = (batch_text) => {
  //   if (devEnv){
  //     const myPromise = new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         resolve(getBatchDataOptionsFormat(dummy_batch.data));
  //       }, 1000);
  //     });
  //     return myPromise
  //       .then((options=>options))
  //   }

  //   return getAuthData(`${API_URL}/api-ops/workspace/v1/assignment-api/${batch_text}`)
  //     .then((res) => {
  //         if (res.success === true) {
  //           return getBatchDataOptionsFormat(res.data)
  //         }
  //     })
  //     .catch(err => {
  //         console.error(err);
  //     })
  // }

  useEffect(() => {
    handleInput();
  }, [createSelectedAsgns, batchInput]);

  useEffect(() => {
    getAllAssignmentsOptions();
  }, []);

  // const categoriseRequesterNameOptions = (val) => {
  //   setCreateAsgnOptions(getAsgnDataOptionsFormat(optionsData,[],val.slice(-1)[0].requester_name));
  // }

  const handleSelectChange = (val) => {
    // change
    // categoriseRequesterNameOptions(val);
    return setCreateSelectedAsgns(val);
    // return setCreateSelectedAsgns(getAsgnDataOptionsFormat(optionsData?.filter((cur)=>{return val.value.includes(cur.assignment_id)})))
  };

  return (
    <form className="batch-delivery-create-form-card card-layout">
      <div className="batch-delivery-form-info">
        <div className="batch-delivery-form-info-label">
          Workspace Assignment ID(s)
        </div>
        {/* <SuggestedInput
          placeholder='Enter Workspace ID'
          optionsArr={createAsgnOptions}
          setInputOptions={setCreateSelectedAsgns}
          setInputText={setAsgnInputText}
        /> */}
        {/* {(createSelectedAsgnOptions) &&
          <Select
            onChange={handleSelectChange} // change
            noOptionsMessage={()=>'No options found.'}
            placeholder='Enter Workspace ID'
            defaultValue={createSelectedAsgnOptions}
            isMulti
            isSearchable
            options={createAsgnOptions}
            theme={customTheme}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            isClearable={createAsgnOptions.some(v => !v.isFixed)}
          />
        }
        {(!createSelectedAsgnOptions) &&
          <Select
            onChange={handleSelectChange} // change
            noOptionsMessage={()=>'Loading options...'}
            placeholder='Loading selected values...'
            isMulti
            isSearchable
            theme={customTheme}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            isDisabled={true}
          />
        } */}
        <Select
          onChange={handleSelectChange} // change
          noOptionsMessage={() =>
            createAsgnOptions ? "No options found." : "Loading options..."
          }
          placeholder={
            createAsgnOptions
              ? "Enter Workspace ID"
              : "Loading selected values..."
          }
          value={createSelectedAsgns}
          isMulti
          isSearchable
          options={createAsgnOptions}
          theme={customTheme}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={customStyles}
          isClearable={createAsgnOptions?.some((v) => !v.isFixed)}
        />
      </div>
      <div className="batch-delivery-form-info">
        <div className="batch-delivery-form-info-label">Batch ID</div>
        {/* <SuggestedInput
          placeholder='Enter Batch ID'
          setInputOptions={setCreateBatchOptions}
          fetchOptions={fetchBatchOptions}
          setInputText={setBatchInputText}
          multi={false}
        /> */}
        <input
          type="text"
          onChange={(e) => setBatchInput(e.target.value)}
          disabled={resBatchId}
          defaultValue={batchInput}
          className="ops-dashboard-input batch-delivery-form-input"
          placeholder="Batch Id"
        />
      </div>
    </form>
  );
};

const CreateDeliveryContainer = () => {
  const API_URL = useContext(ApiContext);
  const { setAddNewFileData } = useContext(UpdateAddNewFileDataContext);
  const { setAsgnCombinations } = useContext(UpdateAsgnCombinationsContext); // combination for cards
  const { deliverPage, setDeliverPage } = useContext(DeliveryPageContext);
  const [createFormData, setCreateFormData] = useState({
    delivery_id: null,
    assignment_id: null,
  });

  const handleSubmit = () => {
    if (deliverPage === DELIVERY_TYPE_PAGE.UPDATE.CREATE) {
      if (devEnv) {
        if (
          createFormData?.assignment_id?.length ||
          createFormData?.delivery_id
        ) {
          console.log(createFormData);
          setAddNewFileData(dummy_update_delivery);
          setAsgnCombinations(dummy_update_delivery.default_groups); // setting the combinations of groups
          setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.ADD);
        } else {
          alert("Please provide either batch id or assignments!");
        }
      } else {
        if (
          createFormData?.assignment_id?.length ||
          createFormData?.delivery_id
        ) {
          // validating assignment input
          postAuthData(
            `${API_URL}/api-ops/ops/v1/update-detail-assignments/`,
            createFormData,
          )
            .then((res) => {
              if (res.success === true) {
                setAddNewFileData(res); // loading file data
                setAsgnCombinations(res.default_groups); // setting the combinations of groups
                setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.ADD); // page change to add
              } else {
                alert(res?.message || "Cant upload file. Please try again.");
              }
            })
            .catch((err) => {
              alert(err?.message || "Cant upload file. Please try again.");
            });
        } else {
          alert("Please provide either batch id or assignments!");
        }
      }
    } else {
      setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.ADD);
      console.log(createFormData);
    }
  };

  return (
    <section className="batch-delivery-container">
      <div className="batch-delivery-head">
        <div className="batch-delivery-head-container">
          <span className="batch-delivery-head-text">Create Delivery</span>
        </div>
        <div className="batch-delivery-head-container">
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-cancel-btn"
            onClick={() => {
              setDeliverPage(DELIVERY_TYPE_PAGE.UPDATE.ADD);
            }}
          >
            Cancel
          </button>
          <button
            className="ops-btns batch-delivery-head-btn"
            id="batch-delivery-submit-btn"
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </div>
      <CreateDeliveryForm
        createFormData={createFormData}
        setCreateFormData={setCreateFormData}
      />
    </section>
  );
};

const UpdateCreateDelivery = () => {
  return (
    <main className="file-deliver-container card-layout">
      <UpdateBatchDeliveryHead />
      <CreateDeliveryContainer />
    </main>
  );
};

export default UpdateCreateDelivery;

// slect options cant handle state Chnge rrvert back to fetching options
