import React, { useContext, useEffect, useState } from "react";
import { date_time_convertor } from "../../../../..";
import {
  OpsArchiveDashboardFileContext,
  OpsDashboardFileContext,
  OpsRouteContext,
  OPS_ROUTES,
} from "../../../../OpsComponent";

import "./HistoryTab.css";

const VersionCard = ({ version, active, idx }) => {
  return (
    <div className="version-card card-layout" data-idx={idx}>
      <div className={`version-card-head`}>
        <div className="version-card-info">
          <span className="version-card-info-value">
            Version {version.version}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-value">
            {version.files.length} file(s)
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Committed </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.committed_delivery)}
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Delivered </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.actual_delivery)}
          </span>
        </div>
        <div className="version-card-info">
          {/* <RatingContainer feedback={version.overall_feedback}/> */}
        </div>
      </div>

      <div
        className={`version-card-content ${
          active && "version-card-content-show"
        }`}
      >
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Committed </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.committed_delivery)}
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Delivered </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.actual_delivery)}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-label">Delivery Notes</span>
          <span className="version-card-info-value">
            {version?.delivery_notes?.map((source, idx) => {
              return (
                <div className="version-card-delivery-notes" key={idx}>
                  {source?.names?.map((name, idx) => (
                    <span
                      key={idx}
                      className="version-card-delivery-notes-file-name"
                    >
                      @{name}
                    </span>
                  ))}
                  <span className="version-card-delivery-note">
                    {source.notes}
                  </span>
                </div>
              );
            })}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-label">File(s)</span>
          <span className="version-card-info-value">
            {version?.files?.map((file, i) => {
              return <span key={i}>{file.name}</span>;
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

const SourceFileCard = ({ active, displayedFile }) => {
  return (
    <div className="version-card card-layout" data-idx={0}>
      <div className={`version-card-head ${!active && "glass-container"}`}>
        <div className="version-card-info">
          <span className="version-card-info-value version-card-info-version-head">
            Input Files{" "}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-value">
            {displayedFile?.source_files?.length} file(s)
          </span>
        </div>
        <div className="version-card-info"></div>
      </div>

      <div
        className={`version-card-content ${
          active && "version-card-content-show"
        }`}
      >
        <div className="version-card-info">
          <div className="version-card-info-head">
            <span className="version-card-info-label">File(s)</span>
          </div>
          <span className="version-card-info-value">
            {displayedFile?.source_files?.map((file, i) => {
              return <span key={i}>{file.name}</span>;
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

const HistoryTab = ({ file }) => {
  const [activeCard, setActiveCard] = useState(0);

  const [displayedFile, setDisplayedFile] = useState(file);
  const route = useContext(OpsRouteContext);
  const { OpsDisplayedFile } = useContext(OpsDashboardFileContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );

  useEffect(() => {
    if (route === OPS_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(OpsDisplayedFile);
    } else if (route === OPS_ROUTES.ARCHIVE) {
      setDisplayedFile(opsArchiveDisplayedFile);
    }
  }, [OpsDisplayedFile, opsArchiveDisplayedFile]);

  const toggleActiveCard = (e) => {
    const activeVersionCard = e?.target?.closest(".version-card")?.dataset.idx; // this value is the index to activate that card
    if (
      (parseInt(activeVersionCard) || parseInt(activeVersionCard) == 0) &&
      parseInt(activeVersionCard) !== activeCard
    ) {
      // if the selected card and the active card id is same then the card will close (remove the last conditional in order to make one card open all the time)
      setActiveCard(parseInt(activeVersionCard));
    } else {
      setActiveCard(null); // closes any selected card if we click on the white space
    }
  };

  return (
    <section className="history-tab-section">
      <main className="history-tabs-container" onClick={toggleActiveCard}>
        {displayedFile?.target_versions?.map((version, i) => (
          <VersionCard
            key={version.version}
            version={version}
            active={activeCard == i}
            idx={i}
          />
        ))}
        <SourceFileCard active={true} displayedFile={displayedFile} />
      </main>
    </section>
  );
};

export default HistoryTab;
