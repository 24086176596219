import React, { useState, useContext, useReducer } from "react";
// import {Link,useLocation} from 'react-router-dom';
import {
  OpsArchiveDashboardFileContext,
  DeliveryFormInfoContext,
  BottomContext,
  BOTTOM_TAB,
} from "../OpsComponent";
// import ops_accounts from '../../test_data/accounts.json';
// import {OpsPswdChangeModal} from '../Modal/OpsPswdChangeModal.js';
// import {DeliverFileModal} from './DeliverFile.js';
// import {CopySecureKeyModal} from '../Modal/CopySecureKeyModal';
// import {DeliverNewVersionModal} from '../Modal/DeliverNewVersionModal';
// import {CopyNewVersionKeyModal} from '../Modal/CopyNewVersionKeyModal.js';
import { makeSecureDecrypt } from "../../helpers/security";
import DeliveryTab from "./components/Tabs/DeliveryTab/DeliveryTab";
import HistoryTab from "./components/Tabs/HistoryTab/HistoryTab";
import DetailsTab from "./components/Tabs/DetailsTab/DetailsTab";
import TopDashboard from "./components/TopDashboard/TopDashboard";
import LogsTab from "./components/Tabs/LogsTab/LogsTab";
import { FaUserPlus } from "react-icons/fa";
// import {Copy} from '../../../public/copy-solid.png'

// import {FaBars,FaHome, FaUsers, FaBriefcase, FaCog, FaSearch, FaRoad,FaCopy} from "react-icons/fa";
// import { ModalDispatchContext } from '../../client_module/Dashboard/ClientDashboard';

// Demo logged in user
// const user = ops_accounts.ops_accounts[2];

// All contexts
// export const UserContext = React.createContext();                       // Stores user data
// export const ModalDispatchContext = React.createContext();              // Stores the information about which modal is to be shown
export const FileContext = React.createContext(); // The File displayed in dashboard
// export const DeliveryFormInfoContext = React.createContext();           // To store the form information from Deliver File Modal
// export const DeliverNewVersionInfoContext = React.createContext();      // To store the form information from New Version Deliver File Modal
// export const FilesArrayContext = React.createContext();                 // To save Files history data
export const DashboardFileContext = React.createContext(); // To save dashboard file
// export const NewSecureKeyContext = React.createContext();
export const OpsDisplayedPageContext = React.createContext();

// FILE ICON
const FileIcon = ({ filename }) => {
  let url;
  if (!filename) {
    url = "/resources/file_type_icons/file.svg";
  } else {
    // switch (filename?.split(".").at(-1).toLowerCase()) {
    switch (filename?.split(".").pop().toLowerCase()) {
      case "doc":
      case "docx":
        url = "/resources/file_type_icons/word.svg";
        break;
      case "ppt":
      case "pptx":
        url = "/resources/file_type_icons/powerpoint.svg";
        break;
      case "pdf":
        url = "./resources/file_type_icons/pdf.svg";
        break;
      case "xlsx":
        url = "/resources/file_type_icons/excel.svg";
        break;
      case "jpeg":
      case "jpg":
        url = "/resources/file_type_icons/image.svg";
        break;
      case "srt":
        url = "/resources/file_type_icons/subtitles.svg";
        break;
      case "mp4":
        url = "/resources/file_type_icons/video.svg";
        break;
      case "png":
        url = "/resources/file_type_icons/png.svg";
        break;
      case "psd":
        url = "/resources/file_type_icons/photoshop.svg";
        break;
      case "ai":
      case "eps":
        url = "/resources/file_type_icons/vector.svg";
        break;
      default:
        url = "/resources/file_type_icons/file.svg";
        break;
    }
  }
  // if (filetype?.includes("VG") && filetype?.includes("TR")){
  //     type="T+V";
  // } else if (filetype?.includes("VG")){
  //     type="VG";
  // } else if (filetype?.includes("TR")){
  //     type="TR";
  // } else {
  //     type=null;
  // }

  return (
    <div className="file-icon">
      <img src={process.env.PUBLIC_URL + url} alt="File" />
      {/* {type && <span className="ops-file-type">{type}</span>} */}
    </div>
  );
};

// ================== MODAL COMPONENT AND REQUIRED FUCNTIONS =====================

export const MODAL_TYPE = {
  OPS_PASSWORD_CHANGE: "ops-password-change",
  COPY_SECURE_KEY: "copy-secure-key",
  COPY_NEW_VERSION_KEY: "copy-new-version-key",
  NONE: "none",
};

export const modalReducer = (state, action) => {
  switch (action.modal_show_type) {
    case MODAL_TYPE.NONE:
      return { modal_show_type: MODAL_TYPE.NONE };
    case MODAL_TYPE.OPS_PASSWORD_CHANGE:
      return { modal_show_type: MODAL_TYPE.OPS_PASSWORD_CHANGE };
    case MODAL_TYPE.COPY_SECURE_KEY:
      return { modal_show_type: MODAL_TYPE.COPY_SECURE_KEY };
    case MODAL_TYPE.DELIVER_NEW_VERSION:
      return { modal_show_type: MODAL_TYPE.DELIVER_NEW_VERSION };
    default:
      return state;
  }
};

// ============== Topnav in the dashboard page ==========================
// const Topnav = () => {
//     const API_URL = useContext(ApiContext)
//     const user = useContext(UserContext);

// const {displayedPage,setDisplayedPage} = useContext(OpsDisplayedPageContext)
// const {opsArchiveDisplayedFile,} = useContext(OpsArchiveDashboardFileContext);

//     const toggleLogoutBox = (e) => {
//         if (e.target.closest('.user') || e.target.closest('.ops-logout-box')){
//             const logoutBox = document.querySelector('.ops-logout-box');
//             if (getComputedStyle(logoutBox).display === "none"){
//                 document.querySelector('.ops-logout-box').style.display = "inline-flex";
//                 document.querySelector('.user-name').style.color = "var(--dark-blue,#112949)";
//             } else if (getComputedStyle(logoutBox).display === "flex"){
//                 document.querySelector('.ops-logout-box').style.display = "none";
//                 document.querySelector('.user-name').style.color = "white";
//             }
//         } else if (!e.target.closest('.user') && !e.target.closest('.ops-logout-box')){
//             document.querySelector('.ops-logout-box').style.display = "none";
//             document.querySelector('.user-name').style.color = "white";
//         }
//     }

//     const handleLogout = () => {
//         postAuthData(`${API_URL}/api/auth/v1/logout/`)
//                 .then((res) => {
//                     if (res.success === true) {
//                         window.localStorage.clear();
//                         window.location.href = '/ops_login';
//                     }
//                 })
//                 .catch(err => {
//                     console.error(err);
//                 })
//     }

//     return (
//         <div className="ops-topnav">
//             <div className="ez-logo">
//                 <img src="logo.png" alt="EZ"/>
//             </div>
//             <div className="user" onClick={toggleLogoutBox}>
//                 <div className='user-name'>Hi {opsArchiveDisplayedFile?.user_name || "User"},</div>
//                 <img className="user-img" src="./resources/user.svg" alt={user.firstname}/>
//             </div>
//             <div className="ops-logout-box">
//                 <div className="ops-logout-btns" onClick={toggleLogoutBox}></div>
//                 <button className="ops-logout-btns" onClick={()=>{return setDisplayedPage(PAGE_TYPE.ACCOUNT_SETTINGS)}}>Account Setting</button>
//                 <button className="ops-logout-btns" onClick={handleLogout}>Logout</button>
//             </div>
//         </div>
//     )
// }

// ============== SIDENAV ================================================

// const SideNav = () => {

//     const {displayedPage,setDisplayedPage} = useContext(OpsDisplayedPageContext)

//     const toggleSidebar = () => {
//         document.querySelector('.sidebar').classList.toggle('hide-sidebar');
//     }

//     return (
//         <div className="ops-sidenav">
//             {(displayedPage!==PAGE_TYPE.DASHBOARD) && <button className='sidenav-btn' onClick={()=>{return setDisplayedPage(PAGE_TYPE.DASHBOARD)}}><FaHome className="sidenav-icon" /></button>}
//             {(displayedPage===PAGE_TYPE.DASHBOARD) && <button className='sidenav-btn' onClick={toggleSidebar}><FaBars className="sidenav-icon" /></button>}
//             {(displayedPage===PAGE_TYPE.USER_SETTINGS || displayedPage===PAGE_TYPE.CLIENT_SETTINGS) && <button className='sidenav-btn' onClick={()=>{return setDisplayedPage(PAGE_TYPE.CLIENT_SETTINGS)}}><FaBriefcase className="sidenav-icon" /></button>}
//             {(displayedPage===PAGE_TYPE.CLIENT_SETTINGS || displayedPage===PAGE_TYPE.USER_SETTINGS) && <button className='sidenav-btn' onClick={()=>{return setDisplayedPage(PAGE_TYPE.USER_SETTINGS)}}><FaUsers className="sidenav-icon" /></button>}
//             <button className='sidenav-btn settings-btn' onClick={()=>{return setDisplayedPage(PAGE_TYPE.CLIENT_SETTINGS)}}><FaCog className="sidenav-icon" /></button>
//         </div>
//     )
// }

// ============== SIDEBAR ================================================

// const File = ({file,setOpsArchiveDisplayedFile}) => {

//     const API_URL = useContext(ApiContext)
//     const showFileDetails = (e) => {
//         // Removing active class from all other files
//         document.querySelectorAll('.sidebar-ops-file').forEach((el)=>{
//             el.classList.remove('sidebar-ops-file-active')
//         })
//         // adding active file to the clicked file
//         e.target.closest('.sidebar-ops-file').classList.toggle('sidebar-ops-file-active');
//         getAuthData(`${API_URL}/api-ops/archive/v1/all-assignments/${file?.id}`)
//                 .then((res) => {
//                     if (res.success === true) {
//                         setOpsArchiveDisplayedFile(res.last_assignment)
//                     }
//                 })
//                 .catch(err => {
//                     console.error(err);
//                 })

//     }

//     return (
//         <div className="sidebar-ops-file" onClick={showFileDetails}>
//             <div className="sidebar-file-info sidebar-file-icon"><FileIcon filename={file?.file_name} filetype={file.assignment_stages}/></div>
//             <div className="sidebar-file-info sidebar-file-steps">{file?.assignment_stages}</div>
//             <div className="sidebar-file-info sidebar-file-word-count">{file?.word_count}</div>
//             <div className="sidebar-file-info sidebar-delivered">Delivered <span className="delivered-time" style={{color: (parseInt(file?.duration)>0) ? 'rgb(80, 207, 80)':'red',}}>{file.duration}</span></div>
//             <div className="sidebar-file-info sidebar-owner">{file?.requester_name}</div>
//             <div className="sidebar-file-info sidebar-committed-delivery">{(new Date(file?.commited_delivery)).toLocaleString()}</div>
//         </div>
//     )
// }

// const FileHistoryHead = () => {
//     const deliverInfoContext = useContext(DeliveryFormInfoContext);
//     const modal_dispatch = useContext(ModalDispatchContext);
//     const {displayedPage,setDisplayedPage} = useContext(OpsDisplayedPageContext)
//     const handleSearch = (e) => {e.preventDefault()};

//     const handleSort = (e) => {e.preventDefault()};

//     return (
//         <div className="file-history-head">
//             <div className="sidebar-head">All Assignments</div>
//             <button className='deliver-btn' onClick={()=>{
//                 // modal_dispatch({modal_show_type:MODAL_TYPE.DELIVER_FILE})
//                 // deliverInfoContext.setDeliverFileInfo({})
// return setDisplayedPage(PAGE_TYPE.DELIVER_FILE)
//                 }}
//             >Deliver File</button>
//             <form className="search-sort-form">
//                 <select className="search-input search-requester" name="requester" id="requester">
//                     <option value="">Requester's Name</option>
//                     <option value="name1">Name 1</option>
//                     <option value="name2">Name 2</option>
//                     <option value="name3">Name 3</option>
//                 </select>
//                 <input type="text" className="search-input search-file" name="search" id="search" placeholder="Search..."/>
//                 <button className="search-btn" onClick={handleSearch}><FaSearch/></button>
//                 <button className="sort-btn" onClick={handleSort}>
//                     <span>Sort</span>
//                     <img src="sort.svg" alt="sort"/>
//                 </button>
//             </form>
//         </div>
//     )
// }

// const FileHistory = ({setOpsArchiveDisplayedFile}) => {
//     const {files} = useContext(FilesArrayContext);
//     return (
//         <div className="files-history">
//             <FileHistoryHead />
//             <div className="assignments">
//                 {files?.map((file) => {
//                     return (
//                         <File key={file?.id} file={file} setOpsArchiveDisplayedFile={setOpsArchiveDisplayedFile} />
//                     )
//                 })}
//             </div>
//         </div>
//     )
// }

// const OpsSidebar = ({setOpsArchiveDisplayedFile}) => {

//     return (
//         <div className="sidebar">
//             <FileHistory setOpsArchiveDisplayedFile={setOpsArchiveDisplayedFile} />
//         </div>
//     )
// }

// =============== Delivery plan in details tab ==========================

// const DeliveryPlan = () => {

//     const {opsArchiveDisplayedFile,} = useContext(OpsArchiveDashboardFileContext);

//     const delivery_plans = opsArchiveDisplayedFile?.delivery_plan && jsonFormatter(opsArchiveDisplayedFile?.delivery_plan);

//     return (
//         <section className="delivery-plan-tab">
//             <table className="bottom-dash-head-table">
//                 <thead>
//                         <tr>
//                             <th>
//                                 <span className='tab-head'>Delivery Plan</span>
//                             </th>
//                         </tr>
//                     </thead>
//             </table>

//             <table className="delivery-plan-table-head">
//                 <thead>
//                     <tr>
//                         <th>
//                         <span className=''>Part</span>
//                         </th>
//                         <th>
//                             <span className=''>Translation</span>
//                         </th>
//                         <th>
//                             <span className=''>Proof Reading</span>
//                         </th>
//                         <th>
//                             <span className=''>Visual Graphics</span>
//                         </th>
//                         <th>
//                             <span className=''>Quality Assurance</span>
//                         </th>
//                     </tr>
//                 </thead>
//             </table>

//             {delivery_plans && Object.values(delivery_plans)?.map((plan,idx) => <DeliveryPlanCard key={idx} plan={plan} />)}
//         </section>
//     )
// }

// =========================== FEEDBACK Popup ================================
// const feedbackToogle = () => {
//     document.querySelector(".ops-feedback-popup").classList.toggle("ops-feedback-popup-active")
// }

// const FeedbackItem = ({feedback_data}) => {

//     return (
//         <div className="ops-feedback">

//             <div className="ops-feedback-item">
//                 <div className="ops-feedback-heading">
//                     User
//                 </div>
//                 <div className="ops-feedback-value">
//                     {(!feedback_data.first_name && !feedback_data.last_name) ? "-" : feedback_data?.first_name + " " + feedback_data?.last_name}
//                 </div>
//             </div>

//             <div className="ops-feedback-item">
//                 <div className="ops-feedback-heading">
//                     Email
//                 </div>
//                 <div className="ops-feedback-value">
//                     {feedback_data.email || "-"}
//                 </div>
//             </div>

//             <div className="ops-feedback-item">
//                 <div className="ops-feedback-heading">
//                     Quality
//                 </div>
//                 <div className="ops-feedback-value">
//                     {/* {(feedback_data.quality==="empty") ? "No feedback on this." : capitalizeWord(feedback_data.quality || "-")} */}
//                     {capitalizeWord(feedback_data.quality || "-")}
//                 </div>
//             </div>

//             <div className="ops-feedback-item">
//                 <div className="ops-feedback-heading">
//                     Service
//                 </div>
//                 <div className="ops-feedback-value">
//                     {/* {(feedback_data.service==="empty") ? "No feedback on this." : (capitalizeWord(feedback_data.service || "-"))} */}
//                     {capitalizeWord(feedback_data.service || "-")}
//                 </div>
//             </div>

//             <div className="ops-feedback-item" id="ops-feedback-comment">
//                 <div className="ops-feedback-heading">
//                     Comments
//                 </div>
//                 <div className="ops-feedback-value">
//                     {feedback_data.comments || "-"}
//                 </div>
//             </div>

//         </div>
//     )
// }

// const FeedbackPopup = () => {
//     const {opsArchiveDisplayedFile,} = useContext(OpsArchiveDashboardFileContext);

//     return (
//         <section className="ops-feedback-popup">
//             <div className="ops-feedback-popup-head-container">
//                 <span className="ops-feedback-popup-head">Assignment Feedback</span>
//                 <button className="file-container-close-btn" onClick={(e) => { e.preventDefault(); feedbackToogle();}}><FaTimes style={{ color: "black" }} /></button>
//             </div>
//             {/* <div className="ops-feedbacks-container">
//                 {(opsArchiveDisplayedFile?.feedback?.length!==0)?(opsArchiveDisplayedFile?.feedback?.map((feedback,i)=>{
//                     return (
//                         <div key={i} className="ops-feedbacks-version-container">
//                             <div className="ops-feedback-item" id="ops-feedback-head">
//                                 <span className="ops-feedback-item-version-head">{feedback?.version || "-"}</span>
//                             </div>
//                             {(feedback?.data?.map((feedback_data,i)=>{
//                                 return (<FeedbackItem key={i} feedback_data={feedback_data}/>)
//                             }))}
//                         </div>
//                     )
//                 }))
//                 :
//                 <span className="empty-data-msg">No feedback recieved yet!</span>
//             }
//             </div> */}
//         </section>
//     )
// }

//============================= Details Tab ==================================

// const DetailsTab = (props) => {

//     const {opsArchiveDisplayedFile,} = useContext(OpsArchiveDashboardFileContext);

//     return (
//         <>
//         <div className="p-1">
//         <section className="bottom-dashboard-tab details-tab">
//             <div className='bottom-tab-head'>
//                 <div className="bottom-tab-head-item bottom-tab-head-heading">Assignment Information</div>
//                 <div className="bottom-tab-head-item bottom-tab-head-dash"></div>
//             </div>

//             <table className="assignment-info-table">
//                     <tbody>
//                         <tr>
//                             <td>
//                                 <div className="assignment-info-label">Assignment Type</div>
//                                 <div className="assignment-info-value">
//                                     {opsArchiveDisplayedFile?.assignment_type?.split(",")?.map((type,i)=> {return (<div key={i}>{type}</div>)}) || "-"}
//                                 </div>
//                             </td>
//                             <td>
//                                 <div className="assignment-info-label">Word Count</div>
//                                 <div className="assignment-info-value">{opsArchiveDisplayedFile?.word_count || "-"}</div>
//                             </td>
//                             <td>
//                                 <div className="assignment-info-label">Committed Delivery</div>
//                                 <div className="assignment-info-value">{date_time_convertor(opsArchiveDisplayedFile?.committed_delivery) || "-"}</div>
//                             </td>
//                         </tr>
//                         <tr>
//                             <td>
//                                 <div className="assignment-info-label">Language Pair</div>
//                                 <div className="assignment-info-value">{opsArchiveDisplayedFile?.language_pair || "-"}</div>
//                             </td>
//                             <td>
//                                 <div className="assignment-info-label">Page Count</div>
//                                 <div className="assignment-info-value">{opsArchiveDisplayedFile?.page_count || "-"}</div>
//                             </td>
//                             <td>
//                                 <div className="assignment-info-label">Discount Code</div>
//                                 <div className="assignment-info-value">{opsArchiveDisplayedFile?.discount_code || "-"}</div>
//                             </td>
//                         </tr>
//                         <tr>
//                             <td>
//                                 <div className="assignment-info-label">Comments</div>
//                                 <div className="assignment-info-value">{opsArchiveDisplayedFile?.comments || "-"}</div>
//                             </td>
//                             <td>
//                                 <div className="assignment-info-label">Feedback</div>
//                                 <div className="assignment-info-value"><button id="opps-feedback-btn" onClick={(e)=>{feedbackToogle();}}>Click</button></div>
//                             </td>
//                             <td>
//                                 <div className="assignment-info-label">Reference</div>
//                                 <div className="assignment-info-value">{opsArchiveDisplayedFile?.reference || "-"}</div>
//                             </td>
//                         </tr>
//                     </tbody>
//                 </table>
//         </section>
//         </div>
//         <div className="padding-delivery">
//         {opsArchiveDisplayedFile && <DeliveryPlan file={opsArchiveDisplayedFile}/>}
//         </div>
//         </>
//     )
// }

// =============================== Delivery Tab ==========================

// export const DeliveryTab = ({versions}) => {
//     const modal_dispatch = useContext(ModalDispatchContext);

//     return (
//         <div className="padding-delivery">
//         <section className="bottom-dashboard-tab delivery-tab">
//         <div className='delivered-version'>
//                 <div className='bottom-tab-head'>
//         <div className="bottom-tab-head-item bottom-tab-head-heading">Output</div>
//         {/* <div className="bottom-tab-head-item bottom-tab-head-btn">
//             <button className="deliver-version-btn" onClick = {() => modal_dispatch({modal_show_type:MODAL_TYPE.DELIVER_NEW_VERSION})}>New Version</button>
//         </div> */}

//         </div>
//         </div>
//             {versions?.map((version,idx,arr) => {
//                 let file_version = {...version,version_id:`${arr.length-(idx)}`};
//                     if (idx===0){
//                         return  <DeliveredVersion key={idx} version={file_version} btn={'on'} />
//                     }
//                     return <DeliveredVersion key={idx} version={file_version} />
//                 })
//             }

//         </section>
//         </div>
//     )
// }

// Versions of the delivered files
// const DeliveredVersion = ({version,btn}) => {
//    let getVersion=version.version_id;
//     const API_URL = useContext(ApiContext)
//     const {opsArchiveDisplayedFile,} = useContext(OpsArchiveDashboardFileContext);
//     const newSecureKeyContext = useContext(NewSecureKeyContext);
//     const {_,setNotFoundError} = useContext(NotFoundErrorContext);
//     const { setLoader } = useContext(LoaderContext);

//     const getNewKey = (e) => {
//         const data = {assignment_id: opsArchiveDisplayedFile.assignment_id};
//         setLoader("Please stay on the page, so the new key can be copied in your clipboard.")

//         // API call
//         // Styling changes
//         // postAuthData(`${API_URL}/api-ops/ops/v1/generate-key/`, data)
//         //     .then((res) => {
//         //         if (res.success === true) {
//         //             newSecureKeyContext.setNewSecureKey(res.secure_key);
//         //             navigator.clipboard.writeText(res.secure_key);
//         //             const btn = e.target.closest('.get-version-key');
//         //             const btnText=btn.querySelector(".deliver-btn-div");
//         //             btnText.innerText = "Copied!";
//         //             setTimeout(() => {
//         //                 btnText.innerText="Copy";
//         //                 setLoader(false);
//         //             }, 2000);
//         //         }

//         //     })
//         //     .catch(err => {
//         //         setLoader(false);
//         //         setNotFoundError(true);
//         //     })

//     }

//     return (
//         <>
//             <div className='delivered-version'>
//                 <div className='bottom-tab-head'>
//                     {/* <div className="bottom-tab-head-item bottom-tab-head-heading">{version.version_id}</div> */}
//                     {/* <div className="bottom-tab-head-item bottom-tab-head-dash"><span className="tab-head-line"></span></div> */}
//                     {/* {(btn) && (<div className="bottom-tab-head-item bottom-tab-head-btn">
//                         <button className="deliver-version-btn" onClick = {() => modal_dispatch({modal_show_type:MODAL_TYPE.DELIVER_NEW_VERSION})}>New Version</button></div>
//                     )} */}
//                 </div>

//                 <table className="bottom-dashboard-table">
//                 <tbody>
//                     <tr>
//                         <td  style={{height:'5vh'}}>
//                             <div className="bottom-dashboard-table-label">File Name</div>
//                             <div className="bottom-dashboard-table-value">
//                             {/* {version.target_file} */}
//                                     { version.target_file.split(",").map((file,idx)=>(
//                                             <div className="file-logo-new" key={idx}>
//                                                 <FileIcon filename={file} key={idx}/>
//                                                 <div className="filename-new">
//                                                    {file}
//                                                  </div>
//                                             </div>

//                                     ))}
//                             </div>
//                         </td>
//                         <td style={{width:'15%'}}>
//                             <div className="bottom-dashboard-table-label">Version</div>
//                             <div className="bottom-dashboard-table-value">{version.version_id}</div>
//                         </td>

//                         <td rowSpan='2'>
//                             <div className="bottom-dashboard-table-label">Delivery Notes</div>
//                             <div className="bottom-dashboard-table-value version-delivery-notes">{version.delivery_notes}</div>
//                         </td>
//                         {/* <td className="deliver-btn-td" width={{width:'5%'}}>
//                             <div className="bottom-dashboard-table-label deliver-version-label">{(btn) && ("Delivery ID")}</div>
//                             <div className="bottom-dashboard-table-value">{(btn) && (<button className="get-version-key"  onClick={getNewKey}><FaCopy className="deliver-version-icon"/><span className="deliver-btn-div">Copy</span></button>)}</div>
//                         </td> */}
//                         <td>
//                             <div className="bottom-dashboard-table-label">Delivered By</div>
//                             <div className="bottom-dashboard-table-value">{version.delivered_by}</div>
//                         </td>
//                     </tr>
//                     {/* <tr>
//                         <td>
//                             <div className="bottom-dashboard-table-label">Delivered By</div>
//                             <div className="bottom-dashboard-table-value">{version.delivered_by}</div>
//                         </td>
//                     </tr> */}
//                 </tbody>
//             </table>
//         </div>
//         {(() => {
//         if (getVersion>1) {
//           return (
//             <hr className="delivery-dash"></hr>
//           )
//         }
//       })()}
//         </>
//     )
// }

// ==================== Shared list card =====================

// ===================================== Share card ==============================================

// ========================= Access Logs =======================================

// this function will split the text upon a specific point and apply hover on the 2nd part after the split

export const access_log_sort_order = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

// // This combines all the logs tab i.e. shared and access

// const LogsTab = () => {
//     return (
//         <div className="ops-logs-container">
//             <OpsShareCard/>
//             <OpsAccessLogCard/>
//         </div>
//     )
// }

// ======================= Dashoard =================

const Dashboard = () => {
  const { bottomTab, setBottomTab } = useContext(BottomContext);
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );
  // useEffect(()=>{
  //     if(bottomTab==='delivery'){
  //         document.querySelectorAll(".bottom-dashboard-tab-btn").forEach(el => el.classList.remove("ops-dashboard-selector-btn-active"));
  //         document.querySelectorAll(".bottom-dashboard-tab-btn")[1].classList.add("ops-dashboard-selector-btn-active");
  //     }
  //     else{
  //         document.querySelectorAll(".bottom-dashboard-tab-btn").forEach(el => el.classList.remove("ops-dashboard-selector-btn-active"));
  //         document.querySelectorAll(".bottom-dashboard-tab-btn")[0].classList.add("ops-dashboard-selector-btn-active");
  //     }
  // })
  const showDashboardOutput = (e) => {
    e.preventDefault();
    const btn = e.target.closest(".bottom-dashboard-tab-btn");
    setBottomTab(btn.value);
  };

  // const BottomTab = (e) => {
  //     e.preventDefault();
  //     document.querySelectorAll(".bottom-dashboard-tab-btn").forEach(el => el.classList.remove("ops-dashboard-selector-btn-active"));
  //     const btn = e.target.closest(".bottom-dashboard-tab-btn");
  //     btn.classList.toggle("ops-dashboard-selector-btn-active");
  //     setBottomTab(btn.value.toLowerCase());
  // }

  return (
    <div className="ops-dashboard">
      {/* <FeedbackPopup/> */}
      <TopDashboard file={opsArchiveDisplayedFile} />
      <div className="bottom-dashboard">
        <div className="ops-dashboard-selector">
          <div className="ops-dashboard-selector-btn-container">
            <button
              className={`bottom-dashboard-tab-btn ${
                bottomTab === BOTTOM_TAB.DELIVERY &&
                "ops-dashboard-selector-btn-active"
              }`}
              onClick={(e) => showDashboardOutput(e)}
              value={BOTTOM_TAB.DELIVERY}
            >
              DELIVERY
            </button>
            <button
              className={`bottom-dashboard-tab-btn ${
                bottomTab === BOTTOM_TAB.HISTORY &&
                "ops-dashboard-selector-btn-active"
              }`}
              onClick={(e) => showDashboardOutput(e)}
              value={BOTTOM_TAB.HISTORY}
            >
              HISTORY
            </button>
            <button
              className={`bottom-dashboard-tab-btn ${
                bottomTab === BOTTOM_TAB.DETAILS &&
                "ops-dashboard-selector-btn-active"
              }`}
              onClick={(e) => showDashboardOutput(e)}
              value={BOTTOM_TAB.DETAILS}
            >
              DETAILS
            </button>
          </div>
          <div className="ops-dashboard-selector-btn-container">
            <button
              className={`bottom-dashboard-tab-btn bottom-dashboard-share-tab-btn ${
                bottomTab === BOTTOM_TAB.SHARING &&
                "ops-dashboard-selector-btn-active"
              }`}
              title="Share Assignments from here"
              value={BOTTOM_TAB.SHARING}
              onClick={(e) => showDashboardOutput(e)}
            >
              <FaUserPlus />
            </button>
          </div>
        </div>
        {bottomTab === BOTTOM_TAB.DETAILS && (
          <DetailsTab file={opsArchiveDisplayedFile} />
        )}
        {bottomTab === BOTTOM_TAB.DELIVERY && (
          <DeliveryTab file={opsArchiveDisplayedFile} />
        )}
        {bottomTab === BOTTOM_TAB.SHARING && <LogsTab />}
        {bottomTab === BOTTOM_TAB.HISTORY && (
          <HistoryTab file={opsArchiveDisplayedFile} />
        )}
      </div>
    </div>
  );
  // return (
  //     <div className="ops-dashboard">
  //         <FeedbackPopup/>
  //         <div className="top-dashboard">
  //             <table className="top-dash-table">
  //                 <thead>
  //                     <tr>
  //                         <th colSpan="2">
  //                             <div className="file-logo">
  //                                 <FileIcon filename={opsArchiveDisplayedFile?.file_name} />
  //                                 <div className="filename">
  //                                     {opsArchiveDisplayedFile?.file_name}
  //                                 </div>
  //                             </div>
  //                         </th>
  //                         <th>
  //                         <div className="file-steps">{opsArchiveDisplayedFile?.assignment_stages}</div>
  //                         </th>
  //                     </tr>
  //                 </thead>
  //                 <tbody>
  //                     <tr>
  //                         <td>
  //                             <div className="top-dashboard-label">Client Code</div>
  //                             <div className="top-dashboard-value">{opsArchiveDisplayedFile?.client_code || "-"}</div>
  //                         </td>
  //                         <td>
  //                             <div className="top-dashboard-label">Client Charge Code</div>
  //                             <div className="dashboard-value">{opsArchiveDisplayedFile?.client_charge_code || "-"}</div>
  //                         </td>
  //                         <td>
  //                             <div className="top-dashboard-label">Delivered</div>
  //                             <div className="top-dashboard-value" style={{color: (parseInt(opsArchiveDisplayedFile?.delivered)>=0) ? 'rgb(80, 207, 80)':'red'}}>{opsArchiveDisplayedFile?.delivered}</div>
  //                         </td>
  //                     </tr>
  //                     <tr>
  //                         <td>
  //                             <div className="top-dashboard-label">Requester Name</div>
  //                             <div className="dashboard-value">{opsArchiveDisplayedFile?.requester_name || "-"}</div>
  //                         </td>
  //                         <td>
  //                             <div className="top-dashboard-label">Date Received</div>
  //                             <div className="top-dashboard-value">{opsArchiveDisplayedFile?.requested_delivery || "-"}</div>
  //                             {/* <div className="top-dashboard-value">{date_time_convertor(opsArchiveDisplayedFile?.requested_delivery) || "-"}</div> */}
  //                         </td>
  //                         <td>
  //                             <div className="top-dashboard-label">Committed Delivery</div>
  //                             {/* <div className="top-dashboard-value committed-delivery">{opsArchiveDisplayedFile?.committed_delivery+" IST" || "-"}</div> */}
  //                             <div className="top-dashboard-value committed-delivery">{date_time_convertor(opsArchiveDisplayedFile?.committed_delivery) || "-"}</div>
  //                         </td>
  //                     </tr>
  //                 </tbody>
  //             </table>
  //         </div>

  //         <div className="bottom-dashboard">
  //             <div className="ops-dashboard-selector">
  //                 <div>
  //                     <button className="bottom-dashboard-tab-btn ops-dashboard-selector-btn-active" value={BOTTOM_TAB.DETAILS} onClick={(e)=>BottomTab(e)}>DETAILS</button>
  //                     <button className="bottom-dashboard-tab-btn" value={BOTTOM_TAB.DELIVERY}  onClick={(e)=>BottomTab(e)}>DELIVERY</button>
  //                 </div>
  //                 <div className="ops-dashboard-selector-btn-container">
  //                     <button className="bottom-dashboard-tab-btn bottom-dashboard-share-tab-btn" value={BOTTOM_TAB.SHARING} onClick={(e)=>BottomTab(e)}><FaUserPlus/></button>
  //                 </div>
  //             </div>
  //             {(bottomTab===BOTTOM_TAB.DETAILS) && <DetailsTab file={opsArchiveDisplayedFile}/>}
  //             {(bottomTab===BOTTOM_TAB.DELIVERY) && <DeliveryTab versions={opsArchiveDisplayedFile?.delivery} />}
  //             {(bottomTab===BOTTOM_TAB.SHARING) && <LogsTab />}
  //         </div>

  //     </div>
  // )
};

// // ======================== Displayed Page =================================

// export const PAGE_TYPE = {
//     DASHBOARD : "dashboard",
//     USER_SETTINGS : "users-settings",
//     CLIENT_SETTINGS : "client-settings",
//     ACCOUNT_SETTINGS : "ops_account_settings"
// }

// // const RenderPage = ({page}) => {
// //     const {_,setOpsArchiveDisplayedFile} = useContext(OpsArchiveDashboardFileContext);

// //     switch (page) {
// //         case PAGE_TYPE.DASHBOARD:
// //             return (
// //                 <>

// //                     <Dashboard />

// //                 </>
// //         )

// //         case  PAGE_TYPE.DELIVER_FILE:
// //             return(
// //                 <>

// //                  <DeliverFileModal />
// //                 </>
// //             )
// //         case PAGE_TYPE.USER_SETTINGS:
// //             return (
// //                 <>
// //                     <OpsUserSettings />
// //                 </>
// //         )
// //         case PAGE_TYPE.CLIENT_SETTINGS:
// //             return (
// //                 <>
// //                     <OpsClientSettings />
// //                 </>
// //         )
// //         case PAGE_TYPE.ACCOUNT_SETTINGS:
// //             return (
// //                 <>
// //                     <OpsAccountSettings />
// //                 </>
// //         )
// //         default:
// //             return (
// //                 <>
// //                     <Dashboard />
// //                 </>
// //             );
// //     }
// // }

// // ======================== Complete dashboard page ========================

const OpsArchive = () => {
  const { opsArchiveDisplayedFile } = useContext(
    OpsArchiveDashboardFileContext,
  );
  const validUser =
    sessionStorage.getItem("is_verified") &&
    JSON.parse(makeSecureDecrypt(sessionStorage.getItem("is_verified")));

  // eslint-disable-next-line no-unused-vars
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    modal_show_type: !validUser
      ? MODAL_TYPE.OPS_PASSWORD_CHANGE
      : MODAL_TYPE.NONE,
  }); // Stores information about which modal is to be shown
  const [deliverFileInfo, setDeliverFileInfo] = useState({}); // Stores the values from the deliver file modal to be displayed when edit is clicked.
  // const [deliverNewVersionInfo, setDeliverNewVersionInfo] = useState({})  // Stores the values from the new version deliver file modal to be displayed when edit is clicked.

  // Saving array state from fetch
  // const [opsArchiveDisplayedFile, setOpsArchiveDisplayedFile] = useState({});     // Saving dashboard display state
  const [displayedPage, setDisplayedPage] = useState("dashboard");

  // useEffect(() => {
  //     // setLoader("Please wait while the data is being loaded.")
  //     if(devEnv){
  //         setOpsArchiveFilesArray(dummy_all_assignment.data);
  //         setOpsArchiveDisplayedFile(dummy_all_assignment.last_assignment);
  //         setLoader(false)
  //     }else{
  //     getAuthData(`${API_URL}/api-ops/archive/v1/all-assignments/?id=${asgn_id}`)
  //         .then((res) => {
  //             if (res.success === true) {
  //                 setOpsArchiveFilesArray(res.data);
  //                 setOpsArchiveDisplayedFile(res.last_assignment);
  //                 // console.log(res.last_delivery);
  //                 setLoader(false)
  //             }
  //         })
  //         .catch(err => {
  //             setNotFoundError(true)
  //             setLoader(false);
  //         })
  //     }
  // }, [asgn_id])

  return (
    <>
      {!opsArchiveDisplayedFile?.id ? (
        <OpsEmptyDashboard />
      ) : (
        <DeliveryFormInfoContext.Provider
          value={{ deliverFileInfo, setDeliverFileInfo }}
        >
          <OpsDisplayedPageContext.Provider
            value={{ displayedPage, setDisplayedPage }}
          >
            <Dashboard />
          </OpsDisplayedPageContext.Provider>
        </DeliveryFormInfoContext.Provider>
      )}
    </>
  );
};

const OpsEmptyDashboard = () => {
  return (
    <section className="ops-empty-dashboard">
      <span className="ops-empty-dashbaord-text"></span>
    </section>
  );
};

export { OpsArchive, FileIcon };
