import getUTCDate from "./getUTCDate";

const getUTCDateRange = (dateObj) => {
  let start = getUTCDate(dateObj.startDate);
  let end = getUTCDate(dateObj.endDate);
  // creating a new date range obj with converted start and end date
  // { startDate: {}, key: "abcd", endDate: {} }
  const dateRange = {};
  const dateRangeObj = {
    ...dateRange,
    startDate: start,
    endDate: end,
    key: dateObj.key,
  };
  return dateRangeObj;
};

export default getUTCDateRange;
