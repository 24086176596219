import { configureStore } from "@reduxjs/toolkit";
import archiveAllAssignmentsDataSlice from "./features/archiveAllAssignmentsData/archiveAllAssignmentsDataSlice";
import archiveAssignmentDataSlice from "./features/archiveAssignmentData/archiveAssignmentDataSlice";
import liveAllAssignmentsDataSlice from "./features/liveAllAssignmentsData/liveAllAssignmentsDataSlice";
import liveAssignmentDataSlice from "./features/liveAssignmentData/liveAssignmentDataSlice";
import profileDataSlice from "./features/profileData/profileDataSlice";
import shareDataSlice from "./features/shareData/shareDataSlice";
import updateDataSlice from "./features/updateData/updateDataSlice";
import uploadDataSlice from "./features/uploadData/uploadDataSlice";
import utilsDataSlice from "./features/utilsData/utilsDataSlice";
import clientDataSlice from "./RequesterFeatures/clientData/clientDataSlice";
import entityDataSlice from "./RequesterFeatures/entityData/entityDataSlice";
import invoiceDataSlice from "./RequesterFeatures/invoiceData/invoiceDataSlice";

// requester data slices
import liveAllClientsDataSlice from "./RequesterFeatures/liveAllClientsData/liveAllClientsDataSlice";
import liveClientDataSlice from "./RequesterFeatures/liveClientData/liveClientDataSlice";
import userDataSlice from "./RequesterFeatures/userData/userDataSlice";

const store = configureStore({
  reducer: {
    liveAllAssignmentsData: liveAllAssignmentsDataSlice,
    liveAssignmentData: liveAssignmentDataSlice,
    archiveAllAssignmentsData: archiveAllAssignmentsDataSlice,
    archiveAssignmentData: archiveAssignmentDataSlice,
    uploadData: uploadDataSlice,
    updateData: updateDataSlice,
    shareData: shareDataSlice,
    profileData: profileDataSlice,
    utilsData: utilsDataSlice,
    // requester slices
    liveAllClientsData: liveAllClientsDataSlice,
    liveClientData: liveClientDataSlice,
    entityData: entityDataSlice,
    invoiceData: invoiceDataSlice,
    userData: userDataSlice,
    clientData: clientDataSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
