import React, { createContext, useState } from "react";
import { DeliveryPageContext, DELIVERY_TYPE_PAGE } from "../OpsComponent.js";
// import {ApiContext,ToolTipError,ERROR_REGEX} from '../../index.js';
// import axios from 'axios';
// import { makeSecureDecrypt } from '../../helpers/security';
// import { getAuthData } from '../../helpers/request';

// Component Imports
import CreateDelivery from "./DeliverFileTabs/CreateDelivery/CreateDelivery.js";
import AddDelivery from "./DeliverFileTabs/AddDelivery/AddDelivery.js";
import UploadDelivery from "./DeliverFileTabs/UploadDelivery/UploadDelivery.js";

// Style Imports
import "./Delivery.css";

export const AddNewFileDataContext = createContext();
export const AsgnCombinationsContext = createContext();
export const AsgnCombinationDataContext = createContext();

export const EXT_LINK_TEXT_CHOICES = {
  LINK: "external_link",
  TEXT: "external_text",
};

const NewDelivery = () => {
  const [deliverPage, setDeliverPage] = useState(DELIVERY_TYPE_PAGE.NEW.CREATE);
  const [addNewFileData, setAddNewFileData] = useState(null);
  const [asgnCombinations, setAsgnCombinations] = useState(null); // combination for cards
  const [combinationData, setCombinationData] = useState(null); // all data that the group of assignment contains

  return (
    <AsgnCombinationsContext.Provider
      value={{ asgnCombinations, setAsgnCombinations }}
    >
      <AsgnCombinationDataContext.Provider
        value={{ combinationData, setCombinationData }}
      >
        <AddNewFileDataContext.Provider
          value={{ addNewFileData, setAddNewFileData }}
        >
          <DeliveryPageContext.Provider value={{ deliverPage, setDeliverPage }}>
            <div className="file-deliver">
              {deliverPage === DELIVERY_TYPE_PAGE.NEW.CREATE && (
                <CreateDelivery />
              )}
              {deliverPage === DELIVERY_TYPE_PAGE.NEW.ADD && <AddDelivery />}
              {deliverPage === DELIVERY_TYPE_PAGE.NEW.UPLOAD && (
                <UploadDelivery />
              )}
            </div>
          </DeliveryPageContext.Provider>
        </AddNewFileDataContext.Provider>
      </AsgnCombinationDataContext.Provider>
    </AsgnCombinationsContext.Provider>
  );
};

export default NewDelivery;
